// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Text } from 'grommet';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import CATextInput from '../../../../../shared/form/CATextInput';
import { parseStringAsPercent } from './utils';

const WindowsBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _onChangeUtilization = (property, value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config[property] = parseStringAsPercent(value);
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _getEditableLayout = () => (
    <FormField label={props.labelUtilization ?? 'Per core utilization'} help={props.noteUtilization}>
      <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center' pad={{ vertical: 'small' }}>
        <Box style={{ minWidth: '12px' }} />
        <div>
          <CATextInput
            value={serviceEditor.options.config.billingMethodUtilization}
            name='perCoreUtilization'
            onChange={(value) => {
              _onChangeUtilization('billingMethodUtilization', value);
            }}
            id='perCoreUtilization'
            plain={true}
          />
        </div>
        <Text weight={100} size='small'>Utilization %</Text>
      </Box>
    </FormField>
  );

  const _getReadOnlyLayout = () => {
    const labelFromBillingMethodMap = new Map()
      .set('PER_CORE_UTILIZATION', 'Per core utilization');

    const { windowsUtilBillingMethod } = serviceEditor.options.config;

    return (
      <ReadOnlyField label='Per core utilization' value={labelFromBillingMethodMap.get(windowsUtilBillingMethod)} />
    );
  };

  return props.readOnly ? _getReadOnlyLayout() : _getEditableLayout();
};

WindowsBillingMethodField.propTypes = {
  noteUtilization: PropTypes.string,
  labelUtilization: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default WindowsBillingMethodField;
