// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Text,
} from 'grommet';

const GLBMDataSummary = ({
  total = 0,
  filtered = 0,
}) => {
  const isFiltered = useMemo(() => (filtered !== undefined && total !== filtered), [total, filtered]);

  return (
    <Box
      direction='row'
      align='center'
      gap='small'
      justify='start'
      margin={{ horizontal: 'xxsmall' }}
      pad={{ horizontal: 'small', bottom: 'xsmall' }}
      flex={false}
    >
      {isFiltered ? (
        <Text id='allRowsNumber'>
          {`${filtered?.toLocaleString()} of ${total?.toLocaleString()} items`}
          {' '}
        </Text>

      ) : (
        <Text id='allRowsNumber'>
          {`${total?.toLocaleString()} items`}
          {' '}
        </Text>
      )}
    </Box>
  );
};

GLBMDataSummary.propTypes = {
  total: PropTypes.number,
  filtered: PropTypes.number,
};

export default GLBMDataSummary;
