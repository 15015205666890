// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import '@babel/polyfill';
import '../scss/index.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';

import axios from 'axios';
import './polyfills';
import App from './App';
import RESTActions from './components/shared/actions/RESTActions';

// eslint-disable-next-line @typescript-eslint/no-require-imports,no-undef
require('es6-promise').polyfill();
// eslint-disable-next-line @typescript-eslint/no-require-imports,no-undef
require('isomorphic-fetch');

// eslint-disable-next-line func-names,no-global-assign
fetch = (function (origFetch) {
  return function newFetch() {
    const result = origFetch.apply(this, arguments);
    result.then(RESTActions.responseInterceptor);
    return result;
  };
}(fetch));

// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  (error) => {
    RESTActions.responseInterceptor(error.response.data);
    return Promise.reject(error);
  }
);

/**
 * Load webapp content when we are ready to do so
 */
function loadWebappContent() {
  const container = document.getElementById('content');
  const root = createRoot(container);
  root.render(<App config={window.config} />);
}

let timer;

/**
 * Check to make sure window.config.apiUrl is defined before loading webapp
 */
function checkToLoadWebapp() {
  if (window.config.apiUrl) {
    window.APIURL = window.config.apiUrl;
    clearInterval(timer);
    loadWebappContent();
  }
}

window.onload = () => {
  timer = setInterval(checkToLoadWebapp, 25);
};
