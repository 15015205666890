// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Button, FormField, Header, Heading, TextInput } from 'grommet';
import { Add, FormTrash } from 'grommet-icons';
import CurrencyUtils from '../../../../../i18n/CurrencyUtil';
import { UserType } from '../../../../../shared/constants/UserType';
import IDUtil from '../../../../../shared/util/IDUtil';
import { MarkupType } from '../../../../../constants/MarkupType';
import {
  calculateDownstreamRate,
  calculateMarkup,
  isLessThanFiveDecimalPlaces,
  isLessThanNineDecimalPlaces,
  isNumber,
} from '../util';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import GLBMDataTable from '../../../../../shared/component/GLBMDataTable';
import { insertIf } from '../../../../../shared/util/BasicUtil';

const RatesStepEditor = (props) => {
  const [{
    customer,
    permissions,
  }] = useServiceEditorContext();
  const [steps, setSteps] = useState(Object.entries(props.steps)
    // eslint-disable-next-line no-nested-ternary
    .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
    .reduce((arr, [threshold, {
      rate,
      name,
      markup,
      downstreamRate,
    }]) => (
      [...arr, {
        threshold,
        rate,
        name,
        markup,
        downstreamRate,
      }]
    ), []));

  useEffect(() => {
    const newSteps = Object.entries(props.steps)
      // eslint-disable-next-line no-nested-ternary
      .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
      .reduce((arr, [threshold, {
        rate,
        name,
        markup,
        downstreamRate,
      }]) => (
        [...arr, {
          threshold,
          rate,
          name,
          markup,
          downstreamRate,
        }]
      ), []);
    setSteps(newSteps);
  }, [props.markupType]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updateMaster = (steps) => {
    const newSteps = JSON.parse(JSON.stringify(props.steps));
    Object.keys(newSteps)
      .forEach((key) => {
        delete newSteps[key];
      });
    Object.assign(newSteps, steps.reduce((obj, {
      threshold,
      rate,
      name,
      markup,
      downstreamRate,
    }) => {
      // eslint-disable-next-line no-param-reassign
      obj[threshold] = {
        rate: rate !== undefined ? parseFloat(rate) : undefined,
        name,
        markup: markup !== undefined ? parseFloat(markup) : undefined,
        downstreamRate: downstreamRate !== undefined ? parseFloat(downstreamRate) : undefined,
      };
      return obj;
    }, {}));
    props.setSteps(newSteps);
  };

  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  const _onChange = ({
                       target: {
                         value,
                         name,
                       },
                     } = { target: { name: null } }, index) => {
    const newSteps = JSON.parse(JSON.stringify(steps));

    if (name) {
      switch (name) {
        case 'name':
          newSteps[index][name] = value;
          break;
        case 'markup':
        case 'downstreamRate': {
          // if user presses minus and not at the beginning of the string
          const minusCount = (value.match(/-/g) || []).length;
          if (minusCount === 1) {
            const minusIndex = value.indexOf('-');
            if (minusIndex !== -1 && minusIndex > 0) {
              // eslint-disable-next-line no-param-reassign
              value = `-${value.split('-')
                .join('')}`;
            }
          } else if (minusCount === 2) {
            // if user presses minus a second time:
            // eslint-disable-next-line no-param-reassign
            value = value.split('-')
              .join('');
          }

          if (value === '-') {
            newSteps[index][name] = value;
          } else if (value === '') {
            newSteps[index][name] = undefined;
          } else if (isNumber(value)) {
            if (name === 'markup' && isLessThanFiveDecimalPlaces(value)) {
              newSteps[index][name] = value;
            } else if (name === 'downstreamRate' && isLessThanNineDecimalPlaces(value)) {
              newSteps[index][name] = value;
            }
          }

          // calculate and set the 'other' value:
          if (name === 'markup') {
            newSteps[index].downstreamRate = calculateDownstreamRate(newSteps[index].markup, newSteps[index].rate);
          } else if (name === 'downstreamRate') {
            newSteps[index].markup = calculateMarkup(newSteps[index].downstreamRate, newSteps[index].rate);
          }

          break;
        }
        default:
          if (!value || value === '.' || (isNumber(value) && isLessThanNineDecimalPlaces(value))) {
            newSteps[index][name] = value;
          }
          break;
      }
    }
    updateMaster(newSteps);
    setSteps(newSteps);
  };

  const nameBandRenderer = (step, index) => (
    <Box flex={false} style={{ whiteSpace: 'nowrap' }}>
      {
        (props.canEdit && (props.userType === UserType.SUPER.enumKey || props.userType === UserType.SERVICE_DEV.enumKey)) ? (
          <FormField
            required={true}
            error={!step.name || step.name === undefined ? 'Required' : undefined}
            margin={{ right: 'small' }}
          >
            <TextInput
              value={step.name}
              name='name'
              onChange={event => _onChange(event, index)}
              id={IDUtil.getId('MeterStepEditorNameInput', index)}
            />
          </FormField>
        ) : (
          <Box>
            {step.name}
          </Box>
        )
      }
    </Box>
  );

  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  const _onBlur = ({ target: { name } } = { target: { name: null } }, index) => {
    const newSteps = JSON.parse(JSON.stringify(steps));
    if (newSteps && newSteps.length) {
      const step = newSteps[index];
      const value = step[name];
      if (!isNumber(value)) {
        switch (name) {
          case 'rate':
            step[name] = 0;
            break;
          default:
            step[name] = undefined;
        }
      }
    }

    updateMaster(newSteps);
    setSteps(newSteps);
  };

  const _getMarkup = (step, index) => {
    const { markup } = step;
    const { markupType } = props;
    if (markupType === MarkupType.MARKUP_PERCENT) {
      return (
        <Box direction='row' align='center'>
          <Box flex={true}>
            <TextInput
              value={markup === undefined ? '' : markup}
              name='markup'
              style={{ background: 'white' }}
              onChange={(event) => {
                _onChange(event, index);
              }}
              onBlur={event => _onBlur(event, index)}
              id={IDUtil.getId('MeterStepEditorMarkupInput', index)}
            />
          </Box>
        </Box>
      );
    }

    if (markupType === MarkupType.DIRECT_RATE) {
      if (markup !== undefined) {
        return (
          <Box direction='row'>
            <Box
              margin={{ left: 'small' }}
              flex={true}
            >
              {(!markup || markup === Infinity) ? '-' : `${CurrencyUtils.getNumberString(parseFloat(markup), 4)}%`}
            </Box>
          </Box>
        );
      }
    }
    return (
      <Box direction='row'>
        <Box margin={{ left: 'small' }} flex={true}>
          -
        </Box>
      </Box>
    );
  };

  const getCustomerRate = ({ downstreamRate }, index) => {
    const { markupType } = props;
    const { contractCurrency } = customer;

    if (markupType === MarkupType.DIRECT_RATE) {
      return (
        <Box flex={true}>
          <TextInput
            value={downstreamRate === undefined ? '' : downstreamRate}
            name='downstreamRate'
            style={{ background: 'white' }}
            onChange={(event) => {
              _onChange(event, index);
            }}
            onBlur={event => _onBlur(event, index)}
            id={IDUtil.getId('MeterStepEditorCustomerRateInput', index)}
          />
        </Box>
      );
    }

    if (markupType === MarkupType.MARKUP_PERCENT) {
      if (downstreamRate !== undefined) {
        return (
          <Box direction='row'>
            <Box
              margin={{ left: 'small' }}
              flex={true}
            >
              {`${CurrencyUtils.getNumberString(parseFloat(downstreamRate), 8, null, contractCurrency)}`}
            </Box>
          </Box>
        );
      }
    }

    return 'Not Available';
  };

  const startBandRenderer = (step, index) => {
    const { threshold } = step;
    const textBand = `>= ${threshold}`;
    return (
      <Box direction='row' style={{ alignItems: 'baseline' }}>
        {index === 0
          ? (
            <Box
              flex={true}
              fill={true}
            >
              {textBand}
            </Box>
          ) : (
            <Box flex={true} direction='row' style={{ alignItems: 'baseline' }}>
              <Box margin={{ right: 'small' }}>{'>'}</Box>
              {
                props.canEdit ? (
                  <FormField
                    required={true}
                    error={!threshold || threshold === undefined ? 'Required' : undefined}
                    margin={{ right: 'small' }}
                  >
                    <TextInput
                      value={threshold}
                      name='threshold'
                      onChange={(event) => {
                        _onChange(event, index);
                      }}
                      id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
                    />
                  </FormField>
                ) : threshold
              }
            </Box>
          )}
      </Box>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const endBandRenderer = (index, steps) => {
    const { threshold } = steps[index + 1] || {};
    return (
      <Box
        direction='column'
      >
        <Box
          margin={{ right: 'small' }}
          fill={true}
        >
          {threshold ? `and <= ${threshold}` : 'and up'}
        </Box>
      </Box>
    );
  };

  const rateRenderer = ({ rate }, index) => {
    const { contractCurrency } = customer;
    return (
      <Box>
        {
          // eslint-disable-next-line no-nested-ternary
          props.canEdit ? (
            <FormField
              required={true}
              error={rate === undefined ? 'Required' : undefined}
            >
              <TextInput
                value={rate === undefined ? '' : rate}
                name='rate'
                onChange={(event) => {
                  _onChange(event, index);
                }}
                onBlur={event => _onBlur(event, index)}
                id={IDUtil.getId('MeterStepEditorRateInput', index)}
              />
            </FormField>
          ) : (rate !== undefined ? `${CurrencyUtils.getNumberString(rate, 8, null, contractCurrency)}` : 'Not Available')
        }
      </Box>
    );
  };

  const onStepAdd = () => {
    const newSteps = JSON.parse(JSON.stringify(steps));
    if (steps.length) {
      newSteps.push({
        name: `Band ${newSteps.length + 1}`,
        threshold: parseFloat(newSteps[newSteps.length - 1].threshold || 0) + 1,
        rate: newSteps[newSteps.length - 1].rate,
      });
    } else {
      newSteps.push({
        name: 'Band 1',
        threshold: 0,
        rate: 0,
      });
    }

    updateMaster(newSteps);
    setSteps(newSteps);
  };

  const onStepRemove = (index) => {
    const newSteps = JSON.parse(JSON.stringify(steps));
    newSteps.splice(index, 1);
    if (newSteps.length) {
      newSteps[0].threshold = 0;
    }
    if (newSteps.length > 0) {
      updateMaster(newSteps);
      setSteps(newSteps);
    } else {
      props.showNoBandsError();
    }
  };

  const removeBand = index => (props.canEdit ? (
    <Box pad={{ top: 'xsmall' }}>
      <Button
        icon={<FormTrash />}
        key={index}
        onClick={() => onStepRemove(index)}
        id={IDUtil.getId('MeterStepEditorDeleteButton', index)}
        a11yTitle='Delete Step'
      />
    </Box>
  ) : null);

  const getCurrencySymbol = (contractCurrency, locale) => {
    if (contractCurrency) {
      return CurrencyUtils.getCurrencyString(undefined, undefined, locale, contractCurrency)
        .replace(/[0,.]+/gi, '')
        .trim();
    }
    return '$';
  };

  const getDataColumns = () => {
    const { canMarkupRates } = permissions;
    const {
      contractCurrency,
      locale,
    } = customer;
    return [
      {
        header: 'Name',
        property: 'name',
        render: step => nameBandRenderer(step, step.index),
        size: 'small',
        plain: false,
        verticalAlign: 'middle',
      }, {
        header: 'Start',
        property: 'threshold',
        render: step => startBandRenderer(step, step.index),
        size: 'small',
        plain: false,
        verticalAlign: 'middle',
      },
      {
        header: 'End',
        property: 'threshold',
        render: step => endBandRenderer(step.index, steps),
        size: 'small',
        plain: false,
        verticalAlign: 'middle',
      },
      {
        header: 'Rate Per Unit',
        property: 'rate',
        render: step => rateRenderer(step, step.index),
        size: 'small',
        plain: false,
        verticalAlign: 'middle',
        align: 'right',
        units: getCurrencySymbol(contractCurrency, locale),
      },
      ...insertIf(canMarkupRates, [
        {
          header: 'Markup',
          property: 'markup',
          render: step => _getMarkup(step, step.index),
          size: 'small',
          plain: false,
          verticalAlign: 'middle',
          align: 'right',
          units: '%',
        },
        {
          header: 'Customer Rate',
          property: 'downstreamRate',
          render: step => getCustomerRate(step, step.index),
          size: 'small',
          plain: false,
          verticalAlign: 'middle',
          align: 'right',
          units: getCurrencySymbol(contractCurrency, locale),
        },
      ]),
      ...insertIf(props.canEdit, [
        {
          header: 'Action',
          property: 'action',
          render: step => removeBand(step.index),
          plain: false,
          verticalAlign: 'middle',
          align: 'center',
          size: 'xsmall',
        },
      ]),
    ];
  };

  return (
    <Box flex='grow' className='rates-steps-editor tiny-inputs'>
      <Header size='small' justify='between'>
        <Heading level='3'>Steps and Rates</Heading>
        {props.canEdit
          ? (
            <Button
              icon={<Add />}
              onClick={props.canEdit ? onStepAdd : undefined}
              id={IDUtil.getId('MeterStepEditorAddButton')}
              a11yTitle='Add Step'
            />
          ) : undefined}
      </Header>
      <Box
        direction='column'
        fill='vertical'
        data-e2e='steps-and-rates'
      >
        <GLBMDataTable
          columns={getDataColumns()}
          data={steps.map((r, index) => ({
            ...r,
            index,
          }))}
          primaryKey='effectiveDate'
          sortable={false}
          resizeable={false}
          background={[]} // to remove the background for every other row, which doesn't make sense in this context
        />
      </Box>
    </Box>
  );
};

RatesStepEditor.propTypes = {
  steps: PropTypes.object.isRequired,
  setSteps: PropTypes.func.isRequired,
  showNoBandsError: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  markupType: PropTypes.string.isRequired,

};

export default RatesStepEditor;
