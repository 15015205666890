// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Footer, FormField, Paragraph, RadioButton } from 'grommet';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import { ReportBy } from '../../../model';

const ReportByEditor = ({ reportBy: initialReportBy, reportByOptions, onChange, onClose, heading }) => {
  const [reportBy, setReportBy] = useState(initialReportBy);

  const onSubmit = () => {
    const service = {
      reportBy,
    };
    onChange(service);
  };

  const onReportByChange = (event) => {
    let value;
    if (event) {
      switch (event.target.type) {
        case 'radio':
          // eslint-disable-next-line prefer-destructuring
          value = event.target.name.split('-')[1];
          break;
        default:
          value = event.target.value;
          break;
      }
    }
    setReportBy(value);
  };

  const getExplanation = () => {
    return ReportBy.enumValueOf(reportBy).description;
  };

  const renderReportByRadioButtons = () => {
    return reportByOptions.map(reportByEnum => (
      <RadioButton
        key={reportByEnum.enumKey}
        id={`reportBy-${reportByEnum.enumKey}`}
        name={`reportBy-${reportByEnum.enumKey}`}
        label={reportByEnum.label}
        checked={reportBy === reportByEnum.enumKey}
        onChange={onReportByChange}
      />
    ));
  };

  const renderReportBy = () => {
    return (
      <FormField label='Report By'>
        {renderReportByRadioButtons()}
      </FormField>
    );
  };

  let closeControl;
  if (onClose) {
    closeControl = (
      <Button
        label='Cancel'
        onClick={() => onClose()}
        secondary={true}
      />
    );
  }

  return (
    <GLBMLayer
      position='right'
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      full='vertical'
      title={heading}
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
        flex={true}
        style={{
          'maxWidth': '575px',
          'width': '575px',
        }}
      >
        <Box direction='row' align='center' pad='medium' gap='small'>
          <StatusIcon value='warning' size='medium' />
          <Paragraph>
            Changing this setting for an individual service is very uncommon. It
            will likely invalidate existing rates and equipment mappings.
          </Paragraph>
        </Box>
        <Box flex={true} pad='medium'>
          {renderReportBy()}
          <Box
            margin={{
            top: 'small',
            bottom: 'small',
          }}
          >
            <Paragraph margin='none'>{getExplanation()}</Paragraph>
          </Box>
        </Box>

        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            <Button primary={true} onClick={() => onSubmit()} label='Apply' />
            {closeControl}
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

ReportByEditor.propTypes = {
  heading: PropTypes.string.isRequired,
  reportBy: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  reportByOptions: PropTypes.array.isRequired,
};

export default ReportByEditor;
