// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import moment from 'moment';
import { CapacityOption } from '../../../model';

// eslint-disable-next-line no-restricted-globals
export const isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);

export const calculateDownstreamRate = (markup, rate) => {
  if (isNumber(markup) && isNumber(rate)) {
    return Number(rate) + (Number(markup) * (Number(rate) / 100));
  }
  return undefined;
};

/**
 * Calculate and return markup in % based on entered downstream rate and customer rate
 * @param downstreamRate
 * @param rate
 * @returns {string}
 */
export const calculateMarkup = (downstreamRate, rate) => {
  if (isNumber(downstreamRate) && isNumber(rate)) {
    return ((Number(downstreamRate) - Number(rate)) / Number(rate)) * 100;
  }
  return undefined;
};

export const isLessThanFiveDecimalPlaces = (n) => {
  const lessThanFiveDecimalPlaces = new RegExp('^-?\\d*(\\.\\d?\\d?\\d?\\d?)?$', 'g');
  return lessThanFiveDecimalPlaces.test(n);
};

export const isLessThanNineDecimalPlaces = (n) => {
  const lessThanFiveDecimalPlaces = new RegExp('^-?\\d*(\\.\\d?\\d?\\d?\\d?\\d?\\d?\\d?\\d?)?$', 'g');
  return lessThanFiveDecimalPlaces.test(n);
};

export const isInstalledCapacityRequired = (serviceType) => {
  const options = (serviceType?.rateManagement ? serviceType.rateManagement.capacityOptions : []);
  return ((options || []).includes(CapacityOption.REQUIRE_INSTALLED_CAPACITY));
};

export const isRateRevisionOverCapactity = (revision, capacities) => {
  const { effectiveDate: rateEffectiveDate, tiers } = revision;
  const foundCapacity = capacities?.find((cap, index, allCapacities) => {
    const currentEffectiveDate = cap?.effectiveDate;
    const nextEffectiveDate = allCapacities[index + 1]?.effectiveDate;
    return moment(currentEffectiveDate, 'YYYY-MM-DD').isSameOrBefore(moment(rateEffectiveDate, 'YYYY-MM-DD')) && (!nextEffectiveDate || moment(nextEffectiveDate, 'YYYY-MM-DD').isAfter(moment(rateEffectiveDate, 'YYYY-MM-DD')));
  });
  const limit = foundCapacity?.committedCapacityOverrideEnabled ? foundCapacity?.committedCapacityOverride : foundCapacity?.committedCapacity;
  const formattedLimit = limit && (typeof limit === 'string') ? +limit : limit;
  return Object.keys(tiers).some(tier => formattedLimit && (+tier >= formattedLimit));
};

export const isMeterDiscontinued = (rate) => {
  const hasCapacityRevisions = rate?.capacityRevisions?.length > 1;
  const hasRateRevisions = rate?.rateRevisions?.length > 1;

  const hasLastCapacityZero = rate?.capacityRevisions?.slice(-1)[0]?.committedCapacity === 0;
  const hasLastRateZero = Object.values(rate?.rateRevisions?.slice(-1)[0]?.tiers || {}).some(tier => tier.rate === 0);

  return hasCapacityRevisions && hasRateRevisions && hasLastCapacityZero && hasLastRateZero;
};

export const canMeterBeDicontinued = (rate) => {
  const hasCapacityRevisions = rate?.capacityRevisions?.length > 0;
  const hasRateRevisions = rate?.rateRevisions?.length > 0;

  const hasNonZeroCapacity = rate?.capacityRevisions?.some(revision => revision.committedCapacity > 0);
  const hasNonZeroRate = rate?.rateRevisions?.some(revision => Object.values(revision.tiers).some(tier => tier.rate > 0));

  return hasCapacityRevisions && hasRateRevisions && hasNonZeroCapacity && hasNonZeroRate;
};

export const requireInstalledCapacities = (serviceType) => {
  const options = (serviceType?.rateManagement?.capacityOptions || []);
  return ((options).includes(CapacityOption.REQUIRE_INSTALLED_CAPACITY));
};
