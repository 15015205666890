// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from 'grommet';
import GLBMLayer from '../../../shared/component/GLBMLayer';
import GLBMNameValueList from '../../../shared/component/GLBMNameValueList';

const CatalogPlanDialog = (props) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });
  };

  const renderPlanDetails = () => {
    const { plan } = props;
    return (
      <Box margin='medium'>
        <GLBMNameValueList
          title='Selected Plan'
          data={[
            {
              label: 'Instance Id',
              value: plan?.instanceId,
            },
            {
              label: 'Id',
              value: plan?.planId,
            },
            {
              label: 'Name',
              value: plan?.planName,
            },
            {
              label: 'Deprovisioned Date',
              value: plan?.deprovisioned ? formatDate(plan.deprovisioned) : '-',
            },
          ]}
        />
      </Box>
    );
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={props.onClose}
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      overlayClose={true}
      title={props.heading}
    >
      <Box flex={true}>
        {renderPlanDetails()}
      </Box>
      <Box flex={false} justify='between' direction='row' pad='medium'>
        { props.onClose && (
          <Button
            label='Cancel'
            onClick={props.onClose}
            secondary={true}
          />
        )}
      </Box>
    </GLBMLayer>
  );
};

CatalogPlanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
};

export default CatalogPlanDialog;
