// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, FormField, Select, SelectMultiple,
} from 'grommet';
import { useQueryExtractAssignedUsersList } from '../../../../core';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';
import { useFormFieldProps } from '../../../shared/component/InputProperties/utils';
import { UserType } from '../../../shared/constants/UserType';

const ExtractAssignedUsersDialog = ({ action, onClose }) => {
  const getFormFieldProps = useFormFieldProps();
  const [roles, setRoles] = useState([{ label: 'All', value: null }]);
  const [email, setEmail] = useState('HPE');
  const [user, setUser] = useState('USER');

  // mutation function to extract the assigned users list:
  const {
    isSuccess,
    mutate: extract,
    isPending,
    isError,
    error
  } = useQueryExtractAssignedUsersList();

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  // need to get an 'All' option in the roleOptions:
  const { current: roleOptions } = useRef(
    [
      { label: 'All', value: null },
      ...UserType.enumValues
        .map(({ enumKey, label }) => ({
          label,
          value: enumKey
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    ]
  );

  const { current: emailTypeOptions } = useRef([
    {
      label: 'All',
      value: null,
    }, {
      label: 'HPE Emails',
      value: 'HPE',
    }, {
      label: 'Non-HPE Emails',
      value: 'NON_HPE',
    },
  ]);

  const { current: userTypeOptions } = useRef([
    {
      label: 'All',
      value: null,
    }, {
      label: 'Users',
      value: 'USER',
    }, {
      label: 'API Clients',
      value: 'API_CLIENT',
    },
  ]);

  const onExecuteReport = () => {
    // build up params to pass to the mutation function only if the values are not null:
    const payload = {};
    if (roles?.length > 0 && !(roles.length === 1 && roles[0].value === null)) {
      payload.roles = roles.map(role => role.value);
    }
    if (email) {
      payload.email = email;
    }
    if (user) {
      payload.type = user;
    }
    extract(payload);
  };

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='medium' flex={true} margin='small'>
        <FormField {...getFormFieldProps('role', 'Role')} htmlFor='roleDropdown'>
          <SelectMultiple
            id='roleDropdown'
            name='roleDropdown'
            options={roleOptions}
            value={roles}
            onChange={({ value: nextValue }) => {
              // make sure the nextValue is not null or empty
              if (!nextValue || nextValue.length === 0) {
                setRoles([roleOptions[0]]);
              } else if (nextValue && nextValue[nextValue.length - 1].value === null) {
                setRoles([roleOptions[0]]);
              } else if (nextValue.some(item => item.value === null)) {
                setRoles(nextValue.filter(item => item.value !== null));
              } else {
                setRoles(nextValue);
              }
            }}
            labelKey='label'
            valueKey={{ key: 'value' }}
          />
        </FormField>
        <FormField {...getFormFieldProps('email', 'Email')} htmlFor='emailType'>
          <Select
            id='emailType'
            name='emailType'
            options={emailTypeOptions}
            value={email}
            labelKey='label'
            data-e2e='emailTypeOptions'
            valueKey={{ key: 'value', reduce: true }}
            onChange={({ value: nextValue }) => setEmail(nextValue)}
          />
        </FormField>
        <FormField {...getFormFieldProps('userType', 'User Type')} htmlFor='userType'>
          <Select
            id='userType'
            name='userType'
            options={userTypeOptions}
            value={user}
            labelKey='label'
            data-e2e='userTypeOptions'
            valueKey={{ key: 'value', reduce: true }}
            onChange={({ value: nextValue }) => setUser(nextValue)}
          />
        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title='Unable to extract user list'
            message={error.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={onExecuteReport}
              busy={isPending}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractAssignedUsersDialog.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractAssignedUsersDialog;
