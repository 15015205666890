// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CheckBox,
  FormField,
  Header,
  Select,
  Text,
  TextInput,
} from 'grommet';
import { Trash } from 'grommet-icons';

const operatorOptions = [
  {
    label: 'Is',
    value: 'equals',
  },
  {
    label: 'Is not',
    value: 'doesNotEqual',
  },
  {
    label: 'Is empty',
    value: 'empty',
  },
  {
    label: 'Is not empty',
    value: 'notEmpty',
  },
  {
    label: 'Starts with',
    value: 'startsWith',
  },
  {
    label: 'Ends with',
    value: 'endsWith',
  },
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Does not contain',
    value: 'doesNotContain',
  },
  {
    label: 'Wildcard',
    value: 'wildcard',
  },
  {
    label: 'Matches regex',
    value: 'regex',
  },
  {
    label: 'Greater than',
    value: 'greaterThan',
  },
  {
    label: 'Less than',
    value: 'lessThan',
  }];

const CustomTiersCriteria = (props) => {
  const [model, setModel] = useState(props.model);

  const fields = useMemo(() => {
    const options = [];
    if (props.fields && props.fields.length) {
      for (let i = 0; i < props.fields.length; i += 1) {
        options.push({
          value: props.fields[i].id,
          label: props.fields[i].name,
        });
      }
    }
    return options;
  }, [props.fields]);

  const onDeleteRule = (index, parent, parentIndex) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!parent) {
      criteria.criteria.splice(index, 1);
    } else {
      criteria.groups[parentIndex].criteria.splice(index, 1);
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onDeleteGroup = (index) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    criteria.groups.splice(index, 1);
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onAddRuleToGroup = (parent, parentIndex) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    const newCriteria = {
      field: fields[0].value,
      operator: operatorOptions[0].value,
      value: '0',
      caseInsensitive: false,
    };
    if (!parent) {
      criteria.criteria.push(newCriteria);
    } else {
      criteria.groups[parentIndex].criteria.push(newCriteria);
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onAddGroup = () => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!criteria.groups) {
      criteria.groups = [];
    }
    criteria.groups.push({
      matchAll: false,
      criteria: [{
        field: fields[0].value,
        operator: operatorOptions[0].value,
        value: '0',
        caseInsensitive: false,
      }],
    });
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onMatchChange = (parent, parentIndex) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!parent) {
      criteria.matchAll = !criteria.matchAll;
    } else {
      criteria.groups[parentIndex].matchAll = !criteria.groups[parentIndex].matchAll;
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onFieldChange = (index, parent, parentIndex, event) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!parent) {
      criteria.criteria[index].field = event.value.value;
    } else {
      criteria.groups[parentIndex].criteria[index].field = event.value.value;
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onOperatorChange = (index, parent, parentIndex, event) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!parent) {
      criteria.criteria[index].operator = event.value.value;
    } else {
      criteria.groups[parentIndex].criteria[index].operator = event.value.value;
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onCaseInsensitiveChange = (index, parent, parentIndex,) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!parent) {
      criteria.criteria[index].caseInsensitive = !criteria.criteria[index].caseInsensitive;
    } else {
      criteria.groups[parentIndex].criteria[index].caseInsensitive = !criteria.groups[parentIndex].criteria[index].caseInsensitive;
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const onValueChange = (index, parent, parentIndex, event) => {
    const modifiedModel = { ...model };
    const criteria = modifiedModel.criteria[0];
    if (!parent) {
      criteria.criteria[index].value = event.target.value;
    } else {
      criteria.groups[parentIndex].criteria[index].value = event.target.value;
    }
    props.onModelChange(modifiedModel);
    setModel(modifiedModel);
  };

  const renderModel = () => {
    const results = [];
    model.criteria.forEach((criteria) => {
      results.push(renderCriteria(criteria));
    });
    return results;
  };

  const canDeleteRule = (group) => {
    return (group.criteria.length > 1);
  };

  const isHidden = (rule) => {
    if (['empty', 'notEmpty'].indexOf(rule.operator) >= 0) {
      // eslint-disable-next-line no-param-reassign
      rule.value = '0';
      return true;
    }
    return false;
  };

  const getValueError = (rule) => {
    if (rule && rule.value.length === 0) {
      return 'a value is required';
    }
    return undefined;
  };

  const showValue = (rule, index, parent, parentIndex) => {
    if (isHidden(rule)) {
      return (<Box flex={true} pad='small' />);
    }
    const results = [];
    results.push(
      <Box flex={true} pad='small'>
        <FormField error={getValueError(rule)}>
          <TextInput
            name='value'
            value={rule.value}
            onChange={event => onValueChange(index, parent, parentIndex, event)}
          />
        </FormField>
      </Box>,
    );
    results.push(
      <Box flex={false} pad='small'>
        <CheckBox
          label='Case insensitive'
          name='caseInsensitive'
          checked={rule.caseInsensitive}
          onChange={() => onCaseInsensitiveChange(index, parent, parentIndex)}
        />
      </Box>,
    );
    return results;
  };

  const renderCriteria = (group, parent = undefined, isGroup = false, parentIndex = -1) => {
    const results = [];
    if (group) {
      results.push(
        <Box
          direction='row'
          align='baseline'
          colorIndex={(isGroup) ? 'light-2' : 'light-1'}
          pad='small'
          key='group'
        >
          <Box
            flex={false}
            direction='row'
            align='center'
            justify='center'
            className='group-match-control'
          >
            <span>Any</span>
            <CheckBox
              label='All'
              toggle={true}
              checked={group.matchAll}
              onChange={() => onMatchChange(parent, parentIndex)}
            />
          </Box>
          <Box flex={true} />
          <Box flex={false} direction='row' gap='small'>
            <Button
              secondary={true}
              label='Add Rule'
              onClick={() => onAddRuleToGroup(parent, parentIndex)}
            />
            {(!isGroup) ? (
              <Button
                secondary={true}
                label='Add Group'
                onClick={() => onAddGroup(group)}
              />
            ) : ''}
            {(isGroup) ? (
              <Button
                secondary={true}
                label='Delete'
                critical={true}
                icon={<Trash />}
                onClick={() => { onDeleteGroup(parentIndex); }}
              />
            ) : ''}
          </Box>
        </Box>,
      );
      group.criteria.forEach((rule, index) => {
        const key = `rule-group-${index}`;
        results.push((
          <Box
            direction='row'
            align='center'
            fill='horizontal'
            colorIndex={(isGroup) ? 'light-2' : 'light-1'}
            pad='small'
            className='tree-branch'
            key={key}
          >
            <Box flex={true} pad='small'>
              <Select
                data-e2e='field'
                placeHolder='Field'
                options={fields}
                value={rule?.field}
                onChange={event => onFieldChange(index, parent, parentIndex, event)}
                labelKey='label'
                valueKey={{
                  key: 'value',
                  reduce: false,
                }}
              />
            </Box>
            <Box flex={true} pad='small'>
              <Select
                data-e2e='operator'
                placeHolder='Operator'
                options={operatorOptions}
                value={rule?.operator}
                onChange={event => onOperatorChange(index, parent, parentIndex, event)}
                labelKey='label'
                valueKey={{
                  key: 'value',
                  reduce: false,
                }}
              />
            </Box>
            {showValue(rule, index, parent, parentIndex)}
            <Box margin='small' flex={false}>
              <Button
                critical={true}
                label='Delete'
                icon={<Trash />}
                disabled={group.criteria.length === 1}
                onClick={canDeleteRule(group) ? () => onDeleteRule(index, parent, parentIndex) : null}
              />
            </Box>
          </Box>
        ));
      });
      if (group.groups) {
        group.groups.forEach((subGroup, index) => {
          results.push((
            <Box className='custom-tiers-editor-rule tree-branch'>
              {renderCriteria(subGroup, group, true, index)}
            </Box>
          ));
        });
      }
    }
    return results;
  };

  return (
    <Box className='custom-tiers-editor' flex={false}>
      <Header>
        <Text size='large' weight='bold'>Tier rules</Text>
      </Header>
      {renderModel()}
    </Box>
  );
};

CustomTiersCriteria.propTypes = {
  service: PropTypes.object,
  model: PropTypes.object,
  fields: PropTypes.array,
  onModelChange: PropTypes.func,
  isCopy: PropTypes.bool,
};

export default CustomTiersCriteria;
