// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Grid, Heading, Main, Text
} from 'grommet';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useNavigate, useParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import {
  useCustomerQuery,
  useMutationCapacityAlertConfig,
  useQueryCapacityAlertConfig,
  useQueryCapacityAlertExceptionDefinitions,
} from '../../core';
import type { CapacityAlertSettingsV2 } from '../../core/types';
import GLBMHeading from '../shared/component/GLBMHeading';
import { pagePermissions } from '../shared/constants/Permissions';
import SaveChangesDialog from '../shared/dialogs/SaveChangesDialog';
import { usePermissionChecker } from '../shared/hooks';
import IDUtil from '../shared/util/IDUtil';
import UserStore from '../stores/UserStore';
import ExceptionsEditor from './ExceptionsEditor';
import RecipientList from './RecipientList';
import ThresholdComponent from './ThresholdComponent';
import ThresholdEditor from './ThresholdEditor';
import { isCategoryDisabled } from './utils';

const CapacityAlertSettingsPage = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [settings, setSettings] = useState<CapacityAlertSettingsV2>();
  const [editThresholdType, setEditThresholdType] = useState<string>();
  const [layer, setLayer] = useState<string>();

  const {
    data: customer,
    isFetching: isLoadingCustomer,
  } = useCustomerQuery(customerId);

  const {
    data: alertConfig,
    isFetching: isLoadingAlertConfig,
  } = useQueryCapacityAlertConfig(customerId);

  const {
    data: definitions,
    isFetching: isLoadingDefinitions,
    isError: isDefinitionError,
  } = useQueryCapacityAlertExceptionDefinitions(customerId);

  useEffect(() => {
    if (alertConfig) {
      setSettings(alertConfig);
    }
  }, [alertConfig]);

  const {
    mutate: updateSettings,
  } = useMutationCapacityAlertConfig(customerId, {
    onSuccess: () => {
      setTimeout(() => {
        navigate('/customers');
      }, 100);
    }
  });

  const me = useMemo(() => UserStore.getUser(), []);

  const getCanEdit = () => {
    if (me && customer) {
      if (me.role === 'ASM') {
        const { asmConfig } = customer;

        const myId = me.id;

        const myAsmRoles = asmConfig.asms.filter(asm => asm.id === myId);
        return (myAsmRoles[0].roles[0] !== 'READ');
      }
      return true;
    }
    return false;
  };

  const { hasPermissions } = usePermissionChecker();
  const canEdit = getCanEdit() && hasPermissions(pagePermissions.customers.view.capacityalert.actions.edit);

  const onSubmit = useCallback(() => {
    updateSettings(settings);
  }, [settings, updateSettings]);

  const onClickCancel = useCallback(() => {
    navigate('/customers');
  }, [navigate]);

  const isDirty = useMemo(() => (alertConfig && JSON.stringify(settings) !== JSON.stringify(alertConfig)), [settings, alertConfig]);

  const onCloseLayer = useCallback(() => {
    setLayer(undefined);
  }, []);

  const onEditorApplied = useCallback((type: string, threshold: CapacityAlertSettingsV2['categories'][number]) => {
    setSettings(prevState => ({
      ...prevState,
      categories: map(prevState.categories, cat => (cat.id === type ? threshold : cat))
    }));
    setLayer(undefined);
    setEditThresholdType(undefined);
  }, []);

  const layerNode = useMemo(() => {
    if (layer) {
      if (layer === 'removeConfirm') {
        return null;
      }
      if (layer === 'editThreshold') {
        const threshold = find(settings.categories, ['id', editThresholdType]);
        if (threshold) {
          return (
            <ThresholdEditor
              onClose={onCloseLayer}
              onChange={onEditorApplied}
              threshold={threshold}
            />
          );
        }
      } else if (layer === 'editException') {
        const threshold = find(settings.categories, ['id', editThresholdType]);
        if (threshold) {
          return (
            <ExceptionsEditor
              onClose={onCloseLayer}
              onChange={onEditorApplied}
              customerId={customerId}
              threshold={threshold}
              canEdit={canEdit}
            />
          );
        }
      }
    }
    return null;
  }, [canEdit, customerId, editThresholdType, layer, onCloseLayer, onEditorApplied, settings]);

  const onEditThreshold = useCallback((type: string) => {
    setEditThresholdType(type);
    setLayer('editThreshold');
  }, []);

  const onExceptionThreshold = useCallback((type: string) => {
    setEditThresholdType(type);
    setLayer('editException');
  }, []);

  const title = useMemo(() => {
    const result = 'Capacity Alert Settings';
    if (customer) {
      return `${result}: ${customer.name} (${customer.id})`;
    }
    return result;
  }, [customer]);

  const sortedCategories = useMemo(() => {
    const sortedByName = sortBy(settings?.categories, 'displayName');
    return sortBy(sortedByName, ({ id }) => isCategoryDisabled(id, definitions, isLoadingDefinitions));
  }, [definitions, isLoadingDefinitions, settings?.categories]);

  if (!settings || isLoadingCustomer || isLoadingAlertConfig || isLoadingDefinitions) {
    return (<Box />);
  }

  return (
    <Main overflow='hidden'>
      <GLBMHeading
        title={title}
        back='/customers'
      />
      <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
        <Box flex={false}>
          <Heading level='3'>Default Thresholds</Heading>
          {!isDefinitionError ? (
            <Grid columns={{ count: 'fit', size: ['small', 'auto'] }} rows='auto' gap='medium'>
              {map(sortedCategories, cfg => (
                <ThresholdComponent
                  key={cfg.id}
                  type={cfg.id}
                  customerId={customer.id}
                  canEdit={canEdit}
                  threshold={cfg}
                  onEdit={onEditThreshold}
                  onException={onExceptionThreshold}
                />
              ))}
            </Grid>
          ) : <Text>Unable to load capacity alert settings</Text>}
        </Box>

        <Box margin={{ top: 'small' }} flex={true}>
          <RecipientList
            recipients={settings.recipients}
            canEdit={canEdit}
            onChange={(newRecipients) => {
              setSettings(prevState => ({
                ...prevState,
                recipients: newRecipients,
              }));
            }}
          />
        </Box>
      </Box>

      <Box direction='row' border='top' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} flex={false}>
        {canEdit
          ? (
            <Box direction='row' gap='small'>
              <Button
                label='Save'
                type='button'
                primary={true}
                id={IDUtil.getId('EditorViewToolbarSaveButton')}
                onClick={onSubmit}
              />
              <Button
                label='Cancel'
                id={IDUtil.getId('EditorViewToolbarCancelButton')}
                type='button'
                secondary={true}
                onClick={onClickCancel}
              />
            </Box>
          )
          : (
            <Button
              label='Close'
              type='button'
              secondary={true}
              id={IDUtil.getId('EditorViewToolbarCancelButton')}
              onClick={onClickCancel}
            />
          )}
      </Box>
      {layerNode}
      <ReactRouterPrompt when={isDirty}>
        {({ onConfirm, onCancel }) => (
          <SaveChangesDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
    </Main>
  );
};

CapacityAlertSettingsPage.contextTypes = {
  router: PropTypes.object,
};

export default CapacityAlertSettingsPage;
