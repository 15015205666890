// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'grommet-icons';
import { Box, TextInput } from 'grommet';
import { debounce } from '../../../../../shared/util/BasicUtil';
import GLBMHeading from '../../../../../shared/component/GLBMHeading';
import MeterListByLocation from './MeterListByLocation';
import MeterListFlat from './MeterListFlat';

const MeterList = ({
  options = undefined,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  const groupByLocation = options?.config?.reportBy === 'LOCATION';

  return (
    <Box
      direction='column'
      size='medium'
      fill='vertical'
      margin={{ right: 'small' }}
      pad={{ vertical: 'small' }}
      width='medium'
    >
      <Box
        flex={true}
      >
        <Box direction='column' flex={false} fill='vertical' background='background-contrast'>
          <GLBMHeading
            title={groupByLocation ? 'Location/Meters' : 'Meters'}
            size='large'
            height='42px'
            level='3'
          />
          <Box flex={true} overflow='auto'>
            {groupByLocation
              ? <MeterListByLocation options={options} searchText={searchText} {...rest} />
              : <MeterListFlat options={options} searchText={searchText} {...rest} />}
          </Box>
        </Box>
      </Box>
      <Box
        background='background-contrast'
      >
        <TextInput
          placeholder='Search'
          icon={<Search />}
          onChange={debounce(event => setSearchText(event.target.value), 200)}
        />
      </Box>
    </Box>
  );
};

MeterList.propTypes = {
  options: PropTypes.shape({
    config: PropTypes.shape({
      reportBy: PropTypes.string,
    }),
  }),
};

export default MeterList;
