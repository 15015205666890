// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField } from 'grommet';
import { FormEdit } from 'grommet-icons';
import ReadOnlyField from '../../shared/component/ReadOnlyField';
import DateTime from '../../shared/component/DateTime';
import { getError } from '../../shared/util/FormUtil';
import IDUtil from '../../shared/util/IDUtil';
import EditPromotedStartMonthDialog from './EditPromotedStartMonthDialog';


const StartMonthField = ({ contractStartMonth, startMonthErrors, production, readOnly, onChange, contractEndMonth }) => {
    const [openEditor, setOpenEditor] = useState(false);

    const readOnlyField = (
      <ReadOnlyField
        label='Start Month'
        id='startMonthReadOnly'
        value={contractStartMonth || ''}
      />
    );
    const editableField = (
      <FormField
        label={(
          <Box direction='row' align='center'>
            <Box>Start Month</Box>
            {production && (
              <Button
                id={IDUtil.getId('StartMonthEdit')}
                data-testid='StartMonthEditIcon'
                icon={<FormEdit />}
                onClick={() => setOpenEditor(true)}
                key='StartMonthEdit'
              />
)}
          </Box>
)}
        error={getError(startMonthErrors)}
        disabled={production}
      >
        <DateTime
          id={IDUtil.getId('BillingInfoEditorContractStartDateInput')}
          name='contractStartMonth'
          format='YYYY-MM'
          disabled={production}
          onChange={(event) => {
                    onChange(event);
                }}
          value={contractStartMonth || ''}
          calendarProps={{
                    bounds: ['2013-01-01', '2116-01-01'],
                }}
        />
      </FormField>
    );

    return (
      <>
        {readOnly ? readOnlyField : editableField}
        {openEditor && (
        <EditPromotedStartMonthDialog
          contractStartMonth={contractStartMonth}
          onSubmit={(v) => {
            onChange(v);
            setOpenEditor(false);
        }}
          onClose={() => setOpenEditor(false)}
          contractEndMonth={contractEndMonth}
        />
)}
      </>
);
};

StartMonthField.propTypes = {
    contractStartMonth: PropTypes.string,
    contractEndMonth: PropTypes.string,
    startMonthErrors: PropTypes.array,
    production: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
};

export default StartMonthField;
