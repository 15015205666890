// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, RadioButton, Text, Tip } from 'grommet';

const CARadioButton = ({
                         id,
                         name,
                         checked,
                         label,
                         onChange,
                         tooltip = undefined,
                       }) => {
  return (
    <Box
      margin={{ bottom: '4px' }}
    >
      <RadioButton
        id={id}
        name={name}
        label={
          tooltip ? (
            <Tip
              content={(
                <Box width='medium'>
                  {tooltip}
                </Box>
              )}
              dropProps={{ align: { left: 'right' } }}
            >
              <Text
                style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)' }}
              >
                {label}
              </Text>
            </Tip>
          ) : <Text>{label}</Text>
        }
        checked={checked}
        className={tooltip ? 'dotted-tooltip-radio' : ''}
        onChange={onChange}
      />
    </Box>
  );
};

CARadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CARadioButton;
