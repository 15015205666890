// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Main,
  Notification,
} from 'grommet';
import {
  Add, Refresh,
} from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';

import {
  useCompaniesQuery, useCountriesQuery,
} from '../../core';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import { usePermissionChecker, useStateWithSessionStorage } from '../shared/hooks';
import { pagePermissions } from '../shared/constants/Permissions';
import { insertIf } from '../shared/util/BasicUtil';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import CompanyDeleteConfirmationDialog from './dialogs/CompanyDeleteConfirmationDialog';
import { useColumns } from './utils';

const CompanyListPage = () => {
  const { hasPermissions } = usePermissionChecker();
  const navigate = useNavigate();
  const [filters, setFilters] = useStateWithSessionStorage('companies-filters', {
    list: {
      searchText: '',
      sort: {
        property: 'name',
        direction: 'asc',
        external: true,
      },
    },
  });
  const [layer, setLayer] = useState(undefined);
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const [response, setResponse] = useState(undefined);

  const {
    data: countriesData,
    isSuccess: isCountriesSuccess,
  } = useCountriesQuery();

  const countryMap = useMemo(() => {
    if (isCountriesSuccess && countriesData) {
      // create a map of countries for use in the form:
      return countriesData.reduce((acc, country) => {
        acc[country.countryCode] = country.displayName;
        return acc;
      }, {});
    }
    return {};
  }, [isCountriesSuccess, countriesData]);

  const {
    data: companyList,
    isFetching: loading,
    refetch: refreshCompanies,
  } = useCompaniesQuery();

  const onSearchChange = (event) => {
    const search = event;
    setFilters(prevState => ({
      ...prevState,
      list: {
        ...prevState.list,
        searchText: search,
      }
    }));
  };

  const _onSortColumn = (sort) => {
    setFilters(prevState => ({
      ...prevState,
      list: {
        ...prevState.list,
        sort,
      }
    }));
  };

  const filteredCompanies = useMemo(
    () => {
      const { list: { searchText } } = filters;
      const filterByText = (!!searchText);

      if (!searchText) {
        return companyList || [];
      }
      const results = [];

      if (Array.isArray(companyList)) {
        const properties = [
          'name',
          'address1',
          'address2',
          'city',
          'stateOrProvince',
          'postalCode',
          'country',
        ];


        for (let i = 0; i < companyList.length; i += 1) {
          const company = companyList[i];

          let matchOnText;

          // see if the company is a match by text:
          if (filterByText) {
            for (let k = 0; k < properties.length; k += 1) {
              if (company.lookup(properties[k]) !== undefined && company.lookup(properties[k]).toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                matchOnText = true;
                break;
              }
            }
          }

          if (filterByText) {
            if (matchOnText) {
              results.push(company);
            }
          }
        }
      }
      return results;
    },
    [filters, companyList],
  );

  const renderLayer = useMemo(() => (layer === 'removeConfirm' && selectedCompanyId ? (
    <CompanyDeleteConfirmationDialog
      companyId={selectedCompanyId}
      onClose={() => {
        setSelectedCompanyId(undefined);
        setLayer(undefined);
        refreshCompanies();
      }}
    />
  ) : undefined), [layer, selectedCompanyId, refreshCompanies]);

  const renderToast = useMemo(() => (response ? (
    <Notification
      toast={true}
      title={response.title}
      status={response.status}
      onClose={() => setResponse(undefined)}
      message={response.message}
    />
  ) : ''), [response]);

  const {
    searchText,
    sort,
  } = filters.list;

  const onRemove = (companyId) => {
    if (companyId) {
      setLayer('removeConfirm');
      setSelectedCompanyId(companyId);
    }
  };

  const columns = useColumns(searchText, countryMap, onRemove);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Companies'
        search={(
          <GLBMSearch
            value={searchText}
            onChange={onSearchChange}
          />
        )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => refreshCompanies()}
            a11yTitle='Refresh Company List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={loading}
          />,
          ...insertIf(hasPermissions(pagePermissions.companies.add), [
            <Button
              kind='toolbar'
              label='Create'
              icon={<Add />}
              onClick={() => navigate('/companies/add')}
              a11yTitle='New Company'
              id={IDUtil.getId('ListViewToolbarAddButton')}
              key='newBtn'
            />]),
        ]}
      />
      <GLBMDataSummary
        total={companyList?.length}
        filtered={filteredCompanies?.length}
      />
      <GLBMDataTable
        columns={columns}
        data={filteredCompanies}
        sortable={true}
        onSort={_onSortColumn}
        sort={sort}
        loading={loading}
        total={companyList?.length}
        primaryKey='id'
      />
      {renderLayer}
      {renderToast}
    </Main>
  );
};

CompanyListPage.contextTypes = {
  router: PropTypes.object,
};

CompanyListPage.propTypes = {
  companies: PropTypes.shape({
    filters: PropTypes.shape({
      list: PropTypes.shape({
        searchText: PropTypes.string,
        sort: PropTypes.string,
      }),
    }),
  }),
};

export default CompanyListPage;
