// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { queryConfig } from '../../query/config';
import PromoteDialog from './PromoteDialog';

const PromoteLayer = ({
                        onClose,
                        customer,
                      }) => {
  const customerName = customer.name;
  const customerId = customer.id;

  // Create a client
  const queryClient = new QueryClient(queryConfig);

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      full='vertical'
      title={`Promote ${customerName} (${customerId})`}
    >
      <QueryClientProvider client={queryClient}>
        <PromoteDialog onClose={onClose} customer={customer} />
      </QueryClientProvider>
    </GLBMLayer>
  );
};

PromoteLayer.propTypes = {
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default PromoteLayer;
