// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Footer, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import moment from 'moment';
import { useUserHistoryQuery } from '../../core';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMLayer from '../shared/component/GLBMLayer';
import { useToast } from '../shared/toast/ToastProvider';
import IDUtil from '../shared/util/IDUtil';

const UserHistoryDetails = ({ onClose, user }) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState({ property: 'time', direction: 'desc', external: undefined });
  const { addToast } = useToast();

  const columns = useMemo(() => [
    {
      property: 'time',
      header: 'Date',
      primary: true,
      size: 'small',
      render: ({ time }) => moment(time).format('ll LTS'),
    },
    {
      property: 'userEmail',
      header: 'User',
      primary: false,
      size: 'small',
    }, {
      property: 'description',
      header: 'Description',
      primary: false,
    },
  ], []);

  const {
    isLoading,
    data: historyData,
  } = useUserHistoryQuery(user.id, {
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Fetch Customers Error',
        message: err?.message,
      });
    })
  });

  const filteredHistory = useMemo(() => {
    if (!searchText) {
      return historyData || [];
    }

    return historyData.filter(({
      time, description, userEmail,
    }) => {
      const searchableText = `${time + description + userEmail}`;
      return searchableText.toLowerCase().includes(searchText.toLowerCase());
    }).map((el, index) => ({ ...el, index }));
  }, [searchText, historyData]);

  return (
    <GLBMLayer
      flush={true}
      overlayClose={true}
      onEsc={onClose}
      onClickOutside={onClose}
      title={`User History For: ${user.firstName} ${user.lastName}`}
      full={true}
      margin='xlarge'
    >
      <Box direction='column' fill={true}>
        <Box
          flex={true}
          pad={{ horizontal: 'small' }}
          direction='column'
          fill='vertical'
          overflow='hidden'
          gap='xsmall'
        >
          <TextInput
            id={IDUtil.getId('UserHistoryDetailsToolbarSearchInput')}
            size='medium'
            placeholder='Search'
            icon={<Search />}
            value={searchText || ''}
            onChange={event => setSearchText(event.target.value)}
            data-testid='UserHistoryDetailsToolbarSearchInput'
          />
          <GLBMDataTable
            searchText={searchText || ''}
            data={isLoading ? [] : filteredHistory}
            columns={columns}
            loading={isLoading}
            total={isLoading ? 0 : filteredHistory.length}
            sort={sort}
            onSort={setSort}
          />
        </Box>
        <Box border='top' pad='small' margin={{ top: 'small' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            <Button
              label='Close'
              key='closeButton'
              secondary={true}
              onClick={onClose}
            />
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

UserHistoryDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserHistoryDetails;
