// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
/* eslint-disable @typescript-eslint/no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import GLBMTooltip from '../shared/component/GLBMTooltip';
import Loader from '../shared/loader';
import { StatusIcon } from '../shared/component/StatusIcon';
import Toast from '../shared/component/Toast';
import {
  useServiceEditorContext,
} from '../services/service-edit/contexts/ServiceEditorContext';
import { getIssues, hasIssues } from '../services/validation/utils';

const WizardStepValidation = () => {
  const [{ validation }] = useServiceEditorContext();
  const getIssuesNodes = (issues) => {
    const issueMap = issues?.reduce((acc, issue) => {
      if (issue.severity !== 'ok') {
        if (!Object.prototype.hasOwnProperty.call(acc, issue.severity)) {
          acc[issue.severity] = [];
        }
        acc[issue.severity].push(issue);
      }
      return acc;
    }, {});

    const getTotalErrors = (issues, severity) => {
      if (severity === 'warning') return issues[severity]?.length;
      const errors = issues[severity];
      const ratesOverLimitError = errors?.find(el => el.label?.includes('start range that exceeds'));
      const restErrors = errors?.filter(el => !el.label?.includes('start range that exceeds'));
      const numbeRatesOverLimitError = ratesOverLimitError?.label.split(' ')[0];
      return (numbeRatesOverLimitError ? +numbeRatesOverLimitError : 0) + (restErrors?.length || 0);
    };
    return (
      <Box direction='row' align='center' gap='small'>
        {
          Object.keys(issueMap).map(severity => (
            <GLBMTooltip
              dropProps={{ align: { bottom: 'top' } }}
              content={(
                <>
                  <div>Issue Types:</div>
                  {
                    issueMap[severity].reduce((acc, issue) => {
                      if (acc.indexOf(issue) === -1) {
                        acc.push(issue);
                      }
                      return acc;
                    }, []).map(issue => (
                      <div key={issue.name}>
                        -
                        {issue.label}
                        .
                      </div>
                    ))
                  }
                </>
              )}
            >
              <Box
                key={severity}
                direction='row'
                align='center'
                gap='small'
              >
                <StatusIcon value={severity} />
                <Text weight={100} className='dotted-tooltip' size='medium' margin='none'>
                  {getTotalErrors(issueMap, severity)}
                  {' '}
                  {severity === 'warning' ? 'Warning' : 'Error'}
                  {getTotalErrors(issueMap, severity) > 1 ? 's' : ''}
                </Text>
              </Box>
            </GLBMTooltip>
          ))
        }
      </Box>
    );
  };

  const { isFetching, properties, errors } = validation;

  return (
    <Box direction='row'>
      {isFetching
        ? (
          <Box direction='row' align='center' gap='small' justify='center' fill={true}>
            <Loader text='Validating ...' />
          </Box>
        ) : ''}
      {!isFetching && properties && hasIssues(properties || {})
        ? (getIssuesNodes(getIssues(properties))) : ''}
      {!isFetching && errors
        ? <Toast open={!isFetching && errors} status='critical'>{errors}</Toast> : ''}
    </Box>
  );
};

WizardStepValidation.contextTypes = {
  router: PropTypes.object,
};

export default WizardStepValidation;
