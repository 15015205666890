// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, FormField, Select
} from 'grommet';
import moment from 'moment';
import {
  usePSADataViewsQuery,
  useQueryExtractBillingPSAData,
} from '../../../../core';
import DateTime from '../../../shared/component/DateTime';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';

const ExtractBillingPSAData = ({ action, onClose }) => {
  const [viewType, setViewType] = useState();
  const [date, setDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const onDateChange = (newDate) => {
    const momentDate = moment(newDate, 'YYYY-MM', true);
    let newError = momentDate.isAfter(moment(), 'month') ? 'Select a month prior to or equal to the current month and year' : undefined;
    newError = momentDate.isValid() ? newError : 'Invalid month format (YYYY-MM)';
    setDate(newDate);
    setError(newError);
  };

  const viewValuesQuery = usePSADataViewsQuery();

  const viewValuesOptions = useMemo(() => {
    if (viewValuesQuery.status === 'success' && viewValuesQuery?.data) {
      return Object.entries(viewValuesQuery?.data).map(([key, value]) => ({ label: key, value }));
    }
    return [];
  }, [viewValuesQuery]);

  // once we fetch the first option, and nothing is yet selected, user option 1 as selected value:
  useEffect(() => {
    if (!viewType && viewValuesOptions?.length) {
      setViewType(viewValuesOptions[0].value);
    }
  }, [viewType, viewValuesOptions]);

  const {
    mutate: extract, isPending, isError, error: extractError, isSuccess
  } = useQueryExtractBillingPSAData(date, viewType);

  const onExtract = () => {
    setShowError(true);
    if (!error) {
      extract();
    }
  };

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='small' flex={true} margin='small'>
        <FormField label='Billing Accounts'>
          <Select
            options={viewValuesOptions}
            value={viewType}
            labelKey='label'
            data-e2e='viewTypeOptions'
            valueKey={{ key: 'value', reduce: true }}
            onChange={({ value: nextValue }) => setViewType(nextValue)}
            placeholder='Select Type'
          />
        </FormField>
        <FormField error={showError && error} label='Invoice Month'>
          <DateTime
            id='extractAllBillingPSADateTime'
            name='billingPSAExtractDate'
            format='YYYY-MM'
            onChange={onDateChange}
            value={date}
          />
        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title={`Unable to extract ${action.name}`}
            message={extractError.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={onExtract}
              busy={isPending}
              data-testid='extractButton'
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractBillingPSAData.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractBillingPSAData;
