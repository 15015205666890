// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FormAdd } from 'grommet-icons';
import { Box, Button, CheckBox, FormField, Select, TextInput } from 'grommet';
import { DrilldownType } from '@saturn/ca-invoice';
import DrilldownFieldsForm from '../shared/component/ManyFieldsForm';
import IDUtil from '../shared/util/IDUtil';
import { MeterType } from '../constants/MeterType';
import { ServiceCategory } from '../services';
import { MeterUnit } from '../constants/MeterUnit';
import { MinChargeInterval } from '../constants/MinChargeInterval';
import { RateInterval } from '../constants/RateInterval';
import { SampleInterval } from '../constants/SampleInterval';
import { AggregationInterval } from '../constants/AggregationInterval';
import ServiceTypeStore from '../stores/ServiceTypeStore';

const ServiceMeterForm = ({
  meter,
  isNew = false,
  readOnly = false,
  errors = {},
  onChange,
  drilldownFieldsValidation = () => undefined,
}) => {
  const renderTypeOptions = () => {
    const options = MeterType.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Type' error={errors.type} required={true}>
        {readOnly
          ? <TextInput value={meter.type} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.type}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.type = e.value;
                if (updatedMeter.type === MeterType.Consumption.label) {
                  delete updatedMeter.allocationMinChargeInterval;
                  delete updatedMeter.allocationRateInterval;
                  delete updatedMeter.allocationSampleMethod;
                  delete updatedMeter.allocationAggregationInterval;
                }
                onChange(updatedMeter, 'type');
              }}
            />
          )}
      </FormField>
    );
  };
  const renderIdField = () => {
    return (
      <FormField
        label='ID'
        error={errors.id}
        required={true}
      >
        {!isNew
          ? <TextInput value={meter.id} disabled={true} />
          : (
            <Box
              direction='row'
              justify='between'
              style={{ height: '36px' }}
            >
              <input
                id={IDUtil.getId('MeterIdInput')}
                name='id'
                value={meter.id || ''}
                onChange={(e) => {
                  const updatedMeter = { ...meter };
                  updatedMeter.id = e.target.value;
                  onChange(updatedMeter, 'id');
                }}
                style={{
                  width: '330px',
                  minWidth: '330px',
                  border: 'none',
                  outline: 'none',
                  paddingLeft: '12px',
                }}
              />
              <Box direction='column' justify='center' pad={{ end: 'small' }}>
                <Button
                  label='UUIDv4'
                  plain={true}
                  icon={<FormAdd />}
                  onClick={() => {
                    const updatedMeter = { ...meter };
                    updatedMeter.id = uuidv4();
                    onChange(updatedMeter, 'id');
                  }}
                />
              </Box>
            </Box>
          )}
      </FormField>
    );
  };
  const renderNameField = () => {
    return (
      <FormField label='Name' error={errors.name} required={true}>
        <TextInput
          id={IDUtil.getId('CatalogServiceEditorNameInput')}
          name='name'
          value={meter.name || ''}
          onChange={(e) => {
            const updatedMeter = { ...meter };
            updatedMeter.name = e.target.value;
            onChange(updatedMeter, 'name');
          }}
          onBlur={() => onChange({ ...meter, name: meter?.name?.trim() })}
          disabled={readOnly}
        />
      </FormField>
    );
  };
  const renderUnitNameField = () => {
    return (
      <FormField label='Unit Name' error={errors.unitName} required={true}>
        <TextInput
          id={IDUtil.getId('CatalogServiceEditorUnitNameInput')}
          name='name'
          value={meter.unitName || ''}
          onChange={(e) => {
            const updatedMeter = { ...meter };
            updatedMeter.unitName = e.target.value;
            onChange(updatedMeter, 'unitName');
          }}
          onBlur={() => onChange({ ...meter, unitName: meter?.unitName?.trim() })}
          disabled={readOnly}
        />
      </FormField>
    );
  };
  const renderNonBillableField = () => {
    return (
      <FormField label='Non Billable' error={errors.nonBillable} required={true}>
        {readOnly
          ? <TextInput value={meter.nonBillable} disabled={true} />
          : (
            <CheckBox
              checked={meter.nonBillable}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.nonBillable = e.target.checked;
                onChange(updatedMeter);
              }}
            />
          )}
      </FormField>
    );
  };
  const renderUnitOptions = () => {
    const options = MeterUnit.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Unit of measure' error={errors.unitOfMeasure} required={true}>
        {readOnly
          ? <TextInput value={meter.unitOfMeasure} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.unitOfMeasure}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.unitOfMeasure = e.value;
                onChange(updatedMeter, 'unitOfMeasure');
              }}
            />
          )}
      </FormField>
    );
  };

  const renderServiceCategoryOptions = () => {
    const options = ServiceCategory.enumValues.map(({ enumKey, label }) => ({
      label: label(),
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Service Category' error={errors.serviceCategory} required={true}>
        {readOnly
          ? <TextInput value={meter.serviceCategory} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.serviceCategory}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.serviceCategory = e.value;
                onChange(updatedMeter, 'serviceCategory');
              }}
            />
          )}
      </FormField>
    );
  };
  const renderServiceTypeField = () => {
    return (
      <FormField label='Service Type' error={errors.serviceType} required={true}>
        <TextInput
          id={IDUtil.getId('MeterServiceTypeInput')}
          name='name'
          value={(ServiceTypeStore.getService(meter.serviceType) && ServiceTypeStore.getService(meter.serviceType).label) || ''}
          disabled={readOnly}
        />
      </FormField>
    );
  };
  const renderMinChargeIntervalOptions = () => {
    const options = MinChargeInterval.enumValues.map(({ enumKey, label, order }) => ({
      label,
      value: enumKey,
      order,
    })).sort((a, b) => a.order - b.order);

    return (
      <FormField
        label='Allocation Min Charge Interval'
        error={errors.allocationMinChargeInterval}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.allocationMinChargeInterval} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationMinChargeInterval}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.allocationMinChargeInterval = e.value;
                onChange(updatedMeter, 'allocationMinChargeInterval');
              }}
            />
          )}
      </FormField>
    );
  };
  const renderRateIntervalOptions = () => {
    const options = RateInterval.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Allocation Rate Interval' error={errors.allocationRateInterval} required={true}>
        {readOnly
          ? <TextInput value={meter.allocationRateInterval} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationRateInterval}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.allocationRateInterval = e.value;
                onChange(updatedMeter, 'allocationRateInterval');
              }}
            />
          )}
      </FormField>
    );
  };
  const renderSampleIntervalOptions = () => {
    const options = SampleInterval.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField
        label='Allocation Sample Method'
        error={errors.allocationSampleMethod}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.allocationSampleMethod} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationSampleMethod}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.allocationSampleMethod = e.value;
                onChange(updatedMeter, 'allocationSampleMethod');
              }}
            />
          )}
      </FormField>
    );
  };
  const renderAggregationIntervalOptions = () => {
    const options = AggregationInterval.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField
        label='Aggregation Interval'
        error={errors.allocationAggregationInterval}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.allocationAggregationInterval} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationAggregationInterval}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.allocationAggregationInterval = e.value;
                onChange(updatedMeter, 'allocationAggregationInterval');
              }}
            />
          )}
      </FormField>
    );
  };
  const renderDrillDownOptions = () => {
    const options = DrilldownType.enumValues.map(({ enumKey }) => ({
      label: enumKey,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField
        label='Drilldown Type'
        error={errors.drillDownType}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.drillDownType} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.drillDownType}
              options={options}
              onChange={(e) => {
                const updatedMeter = { ...meter };
                updatedMeter.drillDownType = e.value;
                onChange(updatedMeter, 'drillDownType');
              }}
            />
          )}
      </FormField>
    );
  };

  return (
    <form>
      {renderServiceTypeField()}
      {renderNameField()}
      {renderIdField()}
      {renderTypeOptions()}
      {renderServiceCategoryOptions()}
      {renderUnitOptions()}
      {meter.unitOfMeasure === 'Each' && renderUnitNameField()}
      {renderNonBillableField()}
      {meter.type === 'Allocation'
        && (
        <>
          {renderMinChargeIntervalOptions()}
          {renderRateIntervalOptions()}
          {renderSampleIntervalOptions()}
          {renderAggregationIntervalOptions()}
        </>
        )}
      {renderDrillDownOptions()}
      <DrilldownFieldsForm
        label='Drilldown fields'
        name='Drilldown fields'
        values={meter?.drilldownFields || []}
        disabled={readOnly}
        error={errors.drilldownFields}
        fieldsValidation={drilldownFieldsValidation}
        onChange={(value) => {
          onChange({ ...meter, drilldownFields: value });
        }}
      />
    </form>
  );
};

ServiceMeterForm.propTypes = {
  meter: PropTypes.any.isRequired,
  isNew: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.any,
  customer: PropTypes.any,
  currencies: PropTypes.array,
  canEditCurrency: PropTypes.bool,
  onDOMChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  drilldownFieldsValidation: PropTypes.func,
};


export default ServiceMeterForm;
