// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Box, Button, FormField, Select, TextInput } from 'grommet';
import { Next, Previous, Search } from 'grommet-icons';
import { each } from 'lodash';
import CustomerSelector from '../../shared/component/CustomerSelector';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import StatusLabel from '../../shared/component/StatusLabel';
import { useCustomersQuery, useProcessedFilesDataQuery } from '../../../core';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import DateTime from '../../shared/component/DateTime';
import { useStateWithSessionStorage } from '../../shared/hooks';
import { useSelectedCustomerId, useSelectedCustomerInfo } from '../../contexts';
import ProcessedFilesUtil from './ProcessedFilesUtil';
import ProcessedFilesGrid from './ProcessedFilesGrid';

const ProcessedFilesPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/naming-convention
  const [_, setSelectedCustomerId] = useSelectedCustomerId();
  const selectedCustomer = useSelectedCustomerInfo();
  const [filters, setFilters] = useStateWithSessionStorage('processedFilesFilters', {
    customerId: selectedCustomer?.id,
    customerName: selectedCustomer?.name,
    tenant: 'master',
    tenants: [],
    selectedDate: moment.utc().startOf('day').subtract(1, 'days').format('M/D/YYYY'),
    stage: 'preconfig',
    stages: ProcessedFilesUtil.getStages('master'),
    service: undefined,
    customerServices: selectedCustomer?.services,
    searchText: undefined,
  });

  const [gridData, setGridData] = useState(undefined);
  const [filterCount, setFilterCount] = useState(0);

  const {
    data: customerData,
  } = useCustomersQuery();

  const {
    isFetching: loadingData,
    data: usageData,
  } = useProcessedFilesDataQuery(
    filters.customerId,
    moment(filters.selectedDate).format('YYYYMMDD'),
    filters.stage,
    filters.service,
    filters.tenant,
    {
      onError: () => {
        setGridData(ProcessedFilesUtil.getGridData());
      }
    }
  );

  useEffect(() => {
    if (usageData) {
      setGridData(ProcessedFilesUtil.getGridData(usageData));
    }
  }, [usageData]);

  const _getOptionIndex = (options, selected) => {
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === selected) {
        return i;
      }
    }
    return -1;
  };

  const _getCustomerTenants = (customer) => {
    if (customer) {
      const result = [{ label: 'Master', value: 'master' }];
      if (customer.distributorPartnerId) {
        result.push({
          label: 'Distributor',
          value: 'distributor'
        });
      }
      if (customer.resellerPartnerId) {
        result.push({
          label: 'Reseller',
          value: 'reseller'
        });
      }
      if (customer.customerTenantId) {
        result.push({
          label: 'Customer',
          value: 'customer'
        });
      }
      return result;
    }
    return [];
  };

  const _onSelectCustomer = (id) => {
    if (!id) {
      return;
    }
    const customer = customerData.find(c => id === c.id);
    setSelectedCustomerId(id);
    const newFilters = { ...filters };

    newFilters.customerId = customer.id;
    newFilters.customerName = customer.name;
    newFilters.customerServices = customer.services;
    newFilters.tenants = _getCustomerTenants(customer);
    if (customer.id !== filters.customerId) {
      newFilters.tenant = 'master';
      newFilters.service = undefined; // if changing customer --> clear the previously selected service
    }

    setFilters(newFilters);
  };

  const _onDateChange = (date) => {
    const newFilters = { ...filters };
    newFilters.selectedDate = date;
    setFilters(newFilters);
  };

  const _onStageChange = (stage) => {
    const newFilters = { ...filters };
    newFilters.stage = stage;
    setFilters(newFilters);
  };

  const _onTenantChange = (tenant) => {
    const newFilters = { ...filters };
    newFilters.tenant = tenant;
    newFilters.stages = ProcessedFilesUtil.getStages(newFilters.tenant);
    if (_getOptionIndex(newFilters.stages, newFilters.stage) === -1) {
      newFilters.stage = newFilters.stages[0].value;
    }
    setFilters(newFilters);
  };

  const _renderBillingAccountOptions = () => (
    <FormField label='Billing Account'>
      <CustomerSelector
        initialSelection={selectedCustomer?.id}
        onCustomerSelected={option => _onSelectCustomer(option)}
        persistSelection={true}
        plain={true}
      />
    </FormField>
  );

  // service:
  const _renderServiceOptions = () => {
    const newFilters = { ...filters };
    function statusSeverity(status) {
      switch (status) {
        case 'NEW': return 'unknown';
        case 'COMPLETE': return 'ok';
        case 'INCOMPLETE': return 'warning';
        default: return 'unknown';
      }
    }

    let options = [];
    if (newFilters && Object.hasOwn(newFilters, 'customerServices')) {
      each(newFilters.customerServices, (service) => {
        const serviceEnum = ServiceTypeStore.getService(service.type);
        options.push({
          description: serviceEnum ? serviceEnum.label : service.type,
          label: <StatusLabel value={statusSeverity(service.status)} label={serviceEnum ? serviceEnum.label : service.type} />,
          value: serviceEnum ? serviceEnum.type : service.type,
        });
      });
      options.sort((a, b) => a.description.localeCompare(b.description));

      options = [{
        label: <Box height='34px' justify='center' pad={{ horizontal: '23px' }}>All</Box>,
        value: 0,
      }].concat(options);
    }

    let value;
    if (newFilters && Object.hasOwn(newFilters, 'service')) {
      const c = options.find(option => option.value === newFilters.service);
      if (c) {
        value = c;
      } else {
        [value] = options;
      }
    } else {
      [value] = options;
    }

    return (
      <FormField label='Service'>
        <Select
          labelKey='label'
          valueKey={{ key: 'value', reduce: true }}
          valueLabel={<Box width='215px' height='34px' justify='center'>{value?.label}</Box>}
          value={value.value}
          options={options}
          onChange={(event) => {
            if (!event.value) {
              delete newFilters.service;
            } else {
              newFilters.service = event.value;
            }
            setFilters(newFilters);
          }}
          disabled={options.length === 1}
        />
      </FormField>
    );
  };

  const _renderDateOption = () => (
    <Box direction='row' align='end' gap='xsmall'>
      <FormField label='Date' width='160px' margin={{ top: '-6px' }}>
        <DateTime
          id='processedFilesPageDateTime'
          name='effectiveMonth'
          format='M/D/YYYY'
          onChange={_onDateChange}
          value={filters.selectedDate}
          required={true}
        />
      </FormField>
      <Box direction='row' gap='xsmall' pad={{ bottom: 'xsmall' }}>
        <Button
          kind='toolbar'
          icon={<Previous />}
          onClick={() => {
            _onDateChange(moment(filters.selectedDate).startOf('day').subtract(1, 'days').format('M/D/YYYY'));
          }}
        />
        <Button
          kind='toolbar'
          icon={<Next />}
          onClick={() => {
            _onDateChange(moment(filters.selectedDate).startOf('day').add(1, 'days').format('M/D/YYYY'));
          }}
        />
      </Box>
    </Box>
  );

  // stage
  const _renderStageOptions = () => {
    const options = ProcessedFilesUtil.getStages(filters.tenant);

    let value;
    if (filters && Object.hasOwn(filters, 'stage')) {
      const c = options.find(option => option.value === filters.stage);
      if (c) {
        value = c.value;
      } else {
        value = { label: 'Pre Transform', value: 'pretransform' };
      }
    } else {
      value = { label: 'Pre Transform', value: 'pretransform' };
    }

    return (
      <Box direction='row' gap='xsmall' align='end'>
        <FormField label='Stage'>
          <Select
            labelKey='label'
            valueKey={{ key: 'value', reduce: true }}
            style={{ width: '160px' }}
            value={value}
            options={options}
            onChange={(event) => {
              _onStageChange(event.value);
            }}
          />
        </FormField>
        <Box direction='row' gap='xsmall' pad={{ bottom: 'xsmall' }}>
          <Button
            kind='toolbar'
            icon={<Previous />}
            disabled={_getOptionIndex(filters.stages, filters.stage) <= 0}
            onClick={() => {
              const index = _getOptionIndex(filters.stages, filters.stage);
              _onStageChange(filters.stages[index - 1].value);
            }}
          />
          <Button
            kind='toolbar'
            icon={<Next />}
            disabled={_getOptionIndex(filters.stages, filters.stage) >= filters.stages.length - 1}
            onClick={() => {
              const index = _getOptionIndex(filters.stages, filters.stage);
              _onStageChange(filters.stages[index + 1].value);
            }}
          />
        </Box>
      </Box>
    );
  };

  // tenant
  const _renderTenantOptions = () => {
    if (filters && filters.tenants.length === 0) {
      filters.tenants = _getCustomerTenants(selectedCustomer);
    }
    let options = [{ label: 'Initial render', value: 'master' }];
    if (filters && Object.hasOwn(filters, 'tenant')) {
      options = filters.tenants;
    }

    let value;
    if (filters && Object.hasOwn(filters, 'tenant')) {
      const c = options.find(option => option.value === filters.tenant);
      if (c) {
        value = c.value;
      } else {
        value = _getCustomerTenants(selectedCustomer);
      }
    } else {
      value = _getCustomerTenants(selectedCustomer);
    }
    return (
      <Box direction='row' gap='xsmall' align='end'>
        <FormField label='Tenant'>
          <Select
            labelKey='label'
            valueKey={{ key: 'value', reduce: true }}
            style={{ width: '160px' }}
            multiple={false}
            value={value}
            options={options}
            onChange={(event) => {
              _onTenantChange(event.value || 'master');
            }}
          />
        </FormField>
        <Box direction='row' gap='xsmall' pad={{ bottom: 'xsmall' }}>
          <Button
            kind='toolbar'
            icon={<Previous />}
            disabled={_getOptionIndex(filters.tenants, filters.tenant) <= 0}
            onClick={() => {
              const index = _getOptionIndex(filters.tenants, filters.tenant);
              _onTenantChange(filters.tenants[index - 1].value);
            }}
          />
          <Button
            kind='toolbar'
            icon={<Next />}
            disabled={_getOptionIndex(filters.tenants, filters.tenant) >= filters.tenants.length - 1}
            onClick={() => {
              const index = _getOptionIndex(filters.tenants, filters.tenant);
              _onTenantChange(filters.tenants[index + 1].value);
            }}
          />
        </Box>
      </Box>
    );
  };

  const _renderSearchInput = () => {
    const newFilters = { ...filters };

    return (
      <FormField label='Search'>
        <TextInput
          type='search'
          id='filter-text-box'
          placeholder='Search'
          icon={<Search />}
          value={newFilters.searchText}
          onChange={(event) => {
            newFilters.searchText = event.target.value;
            setFilters(newFilters);
          }}
        />
      </FormField>
    );
  };
  return (
    <Box direction='column' fill='vertical'>
      <Box direction='row' pad={{ horizontal: 'small', top: 'small' }} flex={false} wrap={true} gap='small' align='end' justify='start'>
        <Box data-testid='billing-dropdown'>
          {_renderBillingAccountOptions()}
        </Box>
        <Box data-testid='service-dropdown'>
          {_renderServiceOptions()}
        </Box>
        <Box data-testid='date-dropdown'>
          {_renderDateOption()}
        </Box>
        <Box data-testid='stage-dropdown'>
          {_renderStageOptions()}
        </Box>
        <Box data-testid='tenant-dropdown'>
          {_renderTenantOptions()}
        </Box>
        <Box data-testid='search-textbox'>
          {_renderSearchInput()}
        </Box>
      </Box>
      <GLBMDataSummary total={usageData?.data?.length} filtered={filterCount} />
      <Box direction='column' fill='vertical' flex={true}>
        <ProcessedFilesGrid
          loading={loadingData}
          data={gridData}
          search={filters.searchText}
          onSearchChange={rowCount => setFilterCount(rowCount)}
        />
      </Box>
    </Box>
  );
};

export default ProcessedFilesPage;
