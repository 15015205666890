// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { useErrorToast } from '../../components/shared/hooks';
import {
  executeGet, executePut
} from '../api';
import { ApiContext } from '../../AppContext';

export const useASMUsersQuery = (options) => {
  const path = useContext(ApiContext)('asm.users.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:ASM-USERS-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch ASM Users Error');
};

export const useSaveASMMutate = (customerId, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('asm.update.path', { customerId });
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMERS-LIST']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMER', customerId]
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'ASMs Successfully Updated',
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Update ASMs Error',
        message: error?.response?.data?.message,
      });
    }
  });
};
