// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box, Heading } from 'grommet';
import { useMessages } from '../../hooks';

const browsers = ['Google Chrome', 'Mozilla Firefox', 'Microsoft Edge'];

const UnsupportedBrowserPage = () => {
  const getMessage = useMessages;
  return (
    <Box background='neutral-1' pad='medium' fill='vertical' align='center' justify='center'>
      <Box
        background='light-1'
        align='center'
        pad='medium'
        style={{
          borderRadius: '12px',
          boxShadow: '5px 10px 8px #888888',
        }}
      >
        <img src='/img/hpe_pri_grn_pos_rgb.svg' height='177' alt='HPE Logo' />

        <Heading strong={true} tag='h3'>
          {getMessage('app.name')}
        </Heading>

        <Box pad='small'>
          <Box>
            <p style={{ fontSize: '28px', margin: '12px' }}>
              Access to HPE GreenLake Billing Manager via Internet Explorer is no longer
              supported. Please use one of the supported browsers:
            </p>
          </Box>
        </Box>

        <Box pad='medium' direction='column'>
          {browsers.map(title => (
            <Box pad='small' key={title}>
              <Heading
                level='3'
                strong={true}
                margin='none'
              >
                {title}
              </Heading>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UnsupportedBrowserPage;
