// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { executeGet, executePost } from '../api';

export const useAlertsQuery = (daysBack, queryParams, options) => {
  const path = useContext(ApiContext)('alerts.list.path', {}, {
    customerId: queryParams.customerId,
    serviceType: queryParams.serviceType,
    alertType: queryParams.alertType,
    customerStatus: queryParams.accountStatus,
    daysBack,
    ack: 'UNACK',
  });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:ALERTS-LIST', daysBack, queryParams],
    queryFn: () => executeGet(path),
    gcTime: 0,
    ...(options || []),
  }), 'Fetch Alert Error');
};

export const useAlertCountQuery = (options) => {
  const path = useContext(ApiContext)('alerts.count.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:ALERTS-COUNT'],
    queryFn: () => executeGet(path),
    refetchInterval: (60000 * 30),
    ...(options || []),
  }), 'Error fetching alert count');
};

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export const useAlertDetailQuery = (customerId, alertId, isInvalidUsageAlert = false, options) => {
  const path = useContext(ApiContext)('alerts.read.path', {
    customerId,
    alertId,
    invalidUsage: isInvalidUsageAlert ? 'invalid_usage' : '',
  });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:ALERT-DETAIL', customerId, alertId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Alert Error');
};

export const useAlertAcknowlegeMutate = (options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: ({ isBulk, alert }) => {
      if (isBulk) {
        const path = contextFn('alerts.ack.bulk.path', {
          alertType: alert.type,
          customerId: alert.customerId,
        });
        return executePost(path);
      }
      const path = contextFn('alerts.ack.single.path');
      return executePost(path, {
        id: alert.id,
        customerId: alert.customerId,
      });
    },
    ...(options || {}),
    onSuccess: (_data) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:ALERTS-LIST']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:ALERTS-COUNT']
      });
      options?.onSuccess?.(_data);
    },
    onError: (data) => {
      addToast({
        status: 'critical',
        title: 'Alert Acknowledge Not Successful',
        message: data.response.data.message,
      });
    },
  });
};

export const useAlertRetryPSAMutation = (options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (customerId) => {
      const path = contextFn('alerts.psa.path', { customerId });
      return executePost(path);
    },
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:ALERTS-LIST']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:ALERTS-COUNT']
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Alert PSA Retry Successful',
      });
      options?.onSuccess?.();
    },
    onError: (data) => {
      addToast({
        status: 'critical',
        title: 'Alert PSA Retry Not Successful',
        message: data.response.data.message,
      });
    },
  });
};
