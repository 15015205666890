// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import { useUser } from '../../stores/UserStore';

export const useStateWithSessionStorage = (keyPrefix, defaultValue) => {
  const user = useUser();
  const key = `glbm-${user?.id}-${keyPrefix}`;

  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue === null) {
      return defaultValue;
    }
    return JSON.parse(storedValue);
  });
  useEffect(() => {
    const storedValue = sessionStorage.getItem(key);
    if (isNil(storedValue)) {
      setValue(defaultValue);
    } else {
      setValue(JSON.parse(storedValue));
    }
    // Don't include defaultValue... we always use the original defaultValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
  const set = useCallback((val) => {
    setValue((prevVal) => {
      const newValue = isFunction(val) ? val(prevVal) : val;
      if (isNil(newValue) || isEqual(newValue, defaultValue)) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      }
      return newValue;
    });
    // Don't include defaultValue... we always use the original defaultValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
  return [value, set];
};
