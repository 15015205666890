// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeDelete, executeGet, executePost,
  executePut
} from '../api';
import { ApiContext } from '../../AppContext';

export const useMockDataListQuery = (options) => {
  const path = useContext(ApiContext)('administration.mockData.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:MOCK-LIST'],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Mock Data Error');
};

export const useMockDataByIdQuery = (id, options) => {
  const path = useContext(ApiContext)('administration.mockData.read.path', { id });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:MOCK-LIST', id],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Mock Data Error');
};

export const useMutationDeleteMock = (options) => {
  const path = useContext(ApiContext)('administration.mockData.delete.path');
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: id => executeDelete(path + id),
    ...(options || []),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:MOCK-LIST']
      });
      options?.onSuccess?.();
    }
  });
};

export const useMutationRunMock = (id, options) => {
  const path = useContext(ApiContext)('administration.mockData.run.path', { id });
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || []),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:MOCK-LIST']
      });
      options?.onSuccess?.();
    }
  });
};
export const useMutationCreateMock = () => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('administration.mockData.create.path');
  const { addToast } = useToast();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Mock data was not generated',
        message: error.response.data.message,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:MOCK-LIST']
      });
      addToast({
        status: 'normal',
        title: 'Success',
        message: 'Mock data generated successfully',
      });
      navigate('/administration', { state: { tab: 'mockData' } });
    },
  });
};

export const useMutationUpdateMock = (id) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('administration.mockData.update.path', { id });
  const { addToast } = useToast();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Mock data was not updated',
        message: error.response.data.message,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:MOCK-LIST']
      });
      addToast({
        status: 'normal',
        title: 'Success',
        message: 'Mock data updated successfully',
      });
      navigate('/administration', { state: { tab: 'mockData' } });
    },
  });
};

export const useQueryAttributes = () => {
  const path = useContext(ApiContext)('administration.mockData.attributes.path');
  return useErrorToast(useQuery({
    queryKey: ['ATTRIBUTES'],
    queryFn: () => executeGet(path),
  }), 'Fetch Attributes Error');
};
