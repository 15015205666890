// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Button } from 'grommet';
import { Add, Copy, Edit, Revert, Trash } from 'grommet-icons';
import { cloneDeep } from 'lodash';
import CurrencyUtils from '../../../../i18n/CurrencyUtil';
import IDUtil from '../../../../shared/util/IDUtil';
import UserStore from '../../../../stores/UserStore';
import ConfirmationDialog from '../../../../shared/dialogs/ConfirmationDialog';
import GLBMNameValueList from '../../../../shared/component/GLBMNameValueList';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import { useServiceEditorContext } from '../../contexts/ServiceEditorContext';
import GLBMDataTable from '../../../../shared/component/GLBMDataTable';
import { insertIf } from '../../../../shared/util/BasicUtil';
import { ValueCell } from '../../../../shared/tablecells';
import MeterRatesEditor from './MeterRatesEditor';
import BaseContainer from './components/BaseContainer';
import { isRateRevisionOverCapactity } from './util';

const MeterRates = (props) => {
  const [layer, setLayer] = useState(undefined);
  const [selectedRevision, setSelectedRevision] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [lastEditedRevision, setLastEditedRevision] = useState(undefined);
  const [{ permissions }] = useServiceEditorContext();

  useEffect(() => {
    if (lastEditedRevision) setLastEditedRevision(undefined);
  }, [lastEditedRevision]);

  const getAddAction = () => {
    setLayer('add');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const getEditAction = (revision, index) => {
    setLayer('edit');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const getCopyAction = (revision, index) => {
    setLayer('copy');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const getDeleteAction = (index) => {
    const rate = cloneDeep(props.rate);
    rate.rateRevisions.splice(index, 1);
    props.onChange(rate);
  };

  const getResetMarkupAction = () => {
    setLayer('confirmResetMarkups');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const getActions = () => {
    const addNew = (!props.rate || props.readOnly ? undefined : (
      <Button
        key='addRevision'
        icon={<Add />}
        label='Add Revision'
        id={IDUtil.getId('BillingInfoEditorAddRevisionRates')}
        onClick={getAddAction}
        hoverIndicator={true}
        kind='toolbar'
      />
    ));
    const resetMarkups = (!permissions.canMarkupRates ? undefined : (
      <Button
        key='resetMarkup'
        icon={<Revert />}
        label='Reset Pricing'
        onClick={getResetMarkupAction}
        hoverIndicator={true}
        kind='toolbar'
      />
    ));

    return ([
      addNew,
      resetMarkups,
    ]);
  };

  const getCurrencySymbol = (customer) => {
    if (customer) {
      return CurrencyUtils.getCurrencyString(undefined, undefined, customer.locale, customer.contractCurrency)
        .replace(/[0,.]+/gi, '')
        .trim();
    }
    return '$';
  };

  // once we introduce 'Tiered with max/cap', then we will put this back into place:
  const isTieredRate = () => props?.rate?.tierType === 'TIERED_CAP';

  const getBandCompareFn = () => ([a], [b]) => parseFloat(a) - parseFloat(b);

  const getDataColumns = () => {
    const { canMarkupRates } = permissions;
    const {
      readOnly,
      customer,
    } = props;
    return [
      ...insertIf(isTieredRate(), [{
        header: 'Status',
        property: 'Status',
        render: r => (
          <ValueCell
            id={IDUtil.getId('RatesStatus', r.index)}
            value={getStatus(r)}
          />
        ),
        verticalAlign: 'top',
      }]),
      {
        header: 'Start Date',
        property: 'effectiveDate',
        render: r => (
          <ValueCell
            primary={true}
            id={IDUtil.getId('RateStartDate', r.index)}
            value={formatDate(r.effectiveDate)}
          />
        ),
        primary: true,
        verticalAlign: 'top',
        size: 'small',
      },
      {
        header: 'Bands',
        property: 'bands',
        render: (revision) => {
          const bandTableData = Object.entries(revision.tiers || [])
            .sort(getBandCompareFn())
            .map(([threshold, { name }], _index, _bandEntries) => {
              const [nextThreshold] = _bandEntries[_index + 1] || [];
              return getBandTd(
                  threshold,
                  nextThreshold,
                  {
                    firstBand: _index === 0,
                    lastBand: _index === _bandEntries.length - 1,
                    multipleBands: _bandEntries.length > 1,
                    name,
                  },
                  revision.index,
                );
            });
          return (
            <Box gap='xsmall'>
              {bandTableData}
            </Box>
          );
        },
        verticalAlign: 'top',
      },
      {
        header: 'Rate Per Unit',
        property: 'ratePerUnit',
        render: (revision) => {
          const bandTableData = Object.entries(revision.tiers || [])
            .sort(getBandCompareFn())
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([threshold, { rate }], _index) => {
              return getUnitTd(revision.effectiveDate + _index, rate);
            });

          return (
            <Box gap='xsmall'>
              {bandTableData}
            </Box>
          );
        },
        align: 'end',
        verticalAlign: 'top',
        units: getCurrencySymbol(customer),
      },
      ...insertIf(canMarkupRates, [
        { header: 'Pricing Method',
          property: 'pricingMethod',
          verticalAlign: 'top',
          render: (r) => {
            if (r.explicitDownstreamRate === undefined) {
              return '-';
            }
            return r.explicitDownstreamRate ? 'Direct Rate' : 'Markup Percentage';
          } },
        {
          header: 'Markup',
          property: 'markup',
          align: 'end',
          verticalAlign: 'top',
          render: (revision) => {
            const bandTableData = Object.entries(revision.tiers || [])
              .sort(getBandCompareFn())
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .map(([threshold, {
                rate,
                markup,
                downstreamRate,
              }]) => {
                return [
                  !markup || markup === Infinity || (rate === undefined && downstreamRate !== undefined)
                    ? '-'
                    : `${CurrencyUtils.getNumberString(parseFloat(markup), 4)}%`
                ];
              });
            return (
              <Box gap='xsmall'>
                {bandTableData}
              </Box>
            );
          }
        },
        {
          header: 'Customer Rate',
          align: 'end',
          property: 'downstreamRate',
          verticalAlign: 'top',
          render: (revision) => {
            const bandTableData = Object.entries(revision.tiers || [])
              .sort(getBandCompareFn())
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .map(([threshold, {
                rate,
                markup,
                downstreamRate,
              }]) => {
                return [
                  (() => {
                    if (downstreamRate !== undefined) {
                      return CurrencyUtils.getNumberString(downstreamRate, 8, null, customer.contractCurrency);
                    }
                    if (rate === undefined && markup !== undefined) {
                      return 'Not Available';
                    }
                    return '--';
                  })()
                ];
              });
            return (
              <Box gap='xsmall'>
                {bandTableData}
              </Box>
            );
          },
          units: getCurrencySymbol(customer),
        },
        {
          header: 'Action',
          property: 'actions',
          render: revision => (
            <Button
              size='large'
              id={IDUtil.getId('MeterListItemEditButton', revision.index)}
              icon={(
                <Edit
                  style={{
                    height: '18px',
                    width: '18px',
                  }}
                />
              )}
              onClick={() => getEditAction(revision, revision.index)}
              style={{ padding: '0' }}
            />
          ),
          verticalAlign: 'top',
          size: 'xsmall',
        },
      ]),
      ...insertIf(!readOnly, [{
        header: 'Action',
        property: 'actions',
        render: r => (
          <Box direction='row' gap='medium' align='center'>
            <Button
              size='large'
              id={IDUtil.getId('RatesListItemEditButton', r.index)}
              icon={(
                <Edit
                  style={{
                height: '18px',
                width: '18px',
              }}
                />
)}
              onClick={() => getEditAction(r, r.index)}
              style={{ padding: '0' }}
            />
            <Button
              size='large'
              id={IDUtil.getId('RatesListItemCopyButton', r.index)}
              icon={(
                <Copy
                  style={{
                height: '18px',
                width: '18px',
              }}
                />
)}
              onClick={() => getCopyAction(r, r.index)}
              style={{ padding: '0' }}
            />
            <Button
              size='large'
              id={IDUtil.getId('RatesListItemDeleteButton', r.index)}
              icon={(
                <Trash
                  style={{
                height: '18px',
                width: '18px',
              }}
                />
)}
              disabled={props.rate.rateRevisions.length === 1}
              onClick={() => getDeleteAction(r.index)}
              style={{ padding: '0' }}
            />
          </Box>
        ),
        verticalAlign: 'top',
        size: 'small',
      }]),

    ];
  };

  const formatDate = date => new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });

  const canEdit = () => {
    if (!permissions) {
      return true;
    }
    return permissions.canEditRates;
  };

  const getBandTd = (threshold, nextThreshold, {
    firstBand,
    lastBand,
    multipleBands,
    name,
  }, index) => {
    const key = `${threshold}-${name}-${index}`;

    if (firstBand && !multipleBands) {
      return (<ValueCell truncate={true} id={IDUtil.getId('RatesBand', index)} key={key} value={`${name}: >= 0`} />);
    }
    if (firstBand && multipleBands) {
      return (<ValueCell truncate={true} id={IDUtil.getId('RatesBand', index)} key={key} value={`${name}: >= 0 to <= ${(nextThreshold)}`} />);
    }
    if (!lastBand) {
      return (<ValueCell truncate={true} id={IDUtil.getId('RatesBand', index)} key={key} value={`${name}: > ${threshold} to <= ${(nextThreshold)}`} />);
    }
    return (<ValueCell truncate={true} id={IDUtil.getId('RatesBand', index)} key={key} value={`${name}: > ${threshold}`} />);
  };

  const getUnitTd = (key, rate) => {
    const {
      meter: { unitLabel },
      customer: { contractCurrency },
    } = props;
    return (
      <ValueCell
        truncate={true}
        key={key}
        value={rate !== undefined ? `${CurrencyUtils.getNumberString(rate, 8, null, contractCurrency)} / ${unitLabel}` : 'Not Available / ${unitLabel}'}
      />
    );
  };

  const getDefaultRevision = () => ({
    'effectiveDate': undefined,
    'tiers': {
      '0.0': {
        'rate': 0,
        'name': 'Band 1',
      },
    },
  });

  const getStatus = (revision) => {
    const isError = isRateRevisionOverCapactity(revision, props.rate?.capacityRevisions);
    return isError ? (
      <GLBMTooltip
        content={(
          <Box pad='xxsmall' width={{ max: 'medium' }}>
            The start range for one or more pricing bands is higher than the
            Reserved Capacity in the corresponding Capacities revision. Please
            remove the offending pricing bands or increase the Reserved Capacity
            to fix this issue.
          </Box>
        )}
      >
        <Box data-testid='critical'><StatusIcon value='critical' size='small' /></Box>
      </GLBMTooltip>
    ) : <Box data-testid='ok'><StatusIcon value='ok' size='small' /></Box>;
  };

  const onLayerClose = () => {
    setLayer(undefined);
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const onLayerChange = (revision) => {
    const rate = cloneDeep(props.rate);

    if (layer === 'add' || layer === 'copy') {
      rate.rateRevisions.push(revision);
    } else if (layer === 'edit') {
      rate.rateRevisions[selectedIndex] = revision;
    }

    onLayerClose();
    props.onChange(rate);
    setLastEditedRevision(revision);
  };

  const onResetMarkupsConfirmed = (data) => {
    if (data.rate) {
      const { rate } = data;
      if (rate.rateRevisions) {
        rate.rateRevisions.forEach((revision) => {
          // eslint-disable-next-line no-param-reassign
          delete revision.explicitDownstreamRate;
          Object.keys(revision.tiers)
            .forEach((tierValue) => {
              // eslint-disable-next-line no-param-reassign
              delete revision.tiers[tierValue].markup;
              // eslint-disable-next-line no-param-reassign
              delete revision.tiers[tierValue].downstreamRate;
              // eslint-disable-next-line no-param-reassign
              revision.dirty = true;
            });
        });
      }

      onLayerClose();
      props.onChange(rate);
    }
  };

  const renderConfirmationDetails = (serviceType, meter) => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Meter'
        data={[
          {
            label: 'Service',
            value: serviceType.label,
          },
          {
            label: 'Meter',
            value: meter.name,
          },
        ]}
      />
    </Box>
  );

  const renderLayer = () => {
    const {
      customer,
      meter,
      rate,
    } = props;

    const startDates = (rate ? rate.rateRevisions : []).reduce(
      (map, revision) => {
        map.push(revision.effectiveDate);
        return map;
      },
      [],
    );

    if (layer === 'add') {
      return (
        <MeterRatesEditor
          onClose={onLayerClose}
          revision={getDefaultRevision()}
          onChange={onLayerChange}
          capacities={props.rate?.capacityRevisions}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          userType={UserStore.getUser().role}
          user={UserStore.getUser()}
          isTieredRate={isTieredRate()}
          {...permissions}
        />
      );
    }
    if ((layer === 'edit' || layer === 'copy') && selectedRevision) {
      if (layer === 'edit') {
        startDates.splice(startDates.indexOf(selectedRevision.effectiveDate), 1);
      } else {
        selectedRevision.effectiveDate = undefined;
      }

      // fix for missing tiers array:
      if (!Object.prototype.hasOwnProperty.call(selectedRevision, 'tiers')) {
        selectedRevision.tiers = {};
      }

      return (
        <MeterRatesEditor
          onClose={onLayerClose}
          revision={selectedRevision}
          capacities={props.rate?.capacityRevisions}
          onChange={onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          userType={UserStore.getUser().role}
          user={UserStore.getUser()}
          isTieredRate={isTieredRate()}
          {...permissions}
        />
      );
    }
    if (layer === 'confirmResetMarkups') {
      return (
        <ConfirmationDialog
          data={{
            meter: props.meter,
            rate: { ...props.rate },
          }}
          title='Reset Pricing'
          text='Reset all service pricing will clear all entered service pricing for the selected service meter and return back to the initial state.'
          submitLabel='Yes, reset all Service Pricing'
          cancelLabel='Cancel'
          onClose={onLayerClose}
          onChange={onResetMarkupsConfirmed}
          details={renderConfirmationDetails(props.serviceType, props.meter)}
        />
      );
    }
    return null;
  };

  return (
    <BaseContainer
      title='Rates'
      actions={getActions()}
      expanded={props.expanded === 'NONE' || props.expanded === 'RATES'}
      data-testid='rates-container'
    >
      <Box
        direction='column'
        fill='vertical'
        data-e2e='rates'
      >
        <GLBMDataTable
          columns={getDataColumns()}
          data={(props?.rate?.rateRevisions || []).map((r, index) => ({
            ...r,
            index,
          }))}
          primaryKey='effectiveDate'
          sortable={false}
          resizeable={false}
        />
      </Box>
      {renderLayer()}
    </BaseContainer>
  );
};

MeterRates.propTypes = {
  rate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  expanded: PropTypes.string,
  readOnly: PropTypes.bool,
  customer: PropTypes.object,
  meter: PropTypes.object,
  serviceType: PropTypes.object,
  canMarkupRates: PropTypes.bool,
  permissions: PropTypes.object,
  onToggleExpansion: PropTypes.func,
};

export default MeterRates;
