// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import { executeGet, executePut } from '../api';
import { ApiContext } from '../../AppContext';

export const useCapacityAlertUpdateMutate = (customerId, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('capacity.alert.update.path', { customerId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMERS-LIST']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMER', customerId]
      });
      options?.onSuccess?.();
    }
  });
};

export const useCapacityAlertExceptionDefinitionsQuery = (customerId, serviceCategory, options) => {
  const path = useContext(ApiContext)('capacity.alert.exceptionDefinitions.path', { customerId, serviceCategory });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMERS-CAPACITY-ALERT-EXCEPTION', customerId, serviceCategory],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Capacity Alert Definitions Error');
};
