// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import {
  executeDelete, executeGet, executePost, executePut
} from '../api';

export const useCompaniesQuery = (options) => {
  const path = useContext(ApiContext)('companies.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:COMPANIES-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Companies Error');
};

export const useCompaniesQueryMap = options => useCompaniesQuery({
  ...options,
  select: data => data?.reduce((_map, c) => {
    // eslint-disable-next-line no-param-reassign
    _map[c.id] = {
      id: c.id,
      name: c.name,
    };
    return _map;
  }, {}),
});

export const useCompanyQuery = (companyId, options) => {
  const path = useContext(ApiContext)('companies.get.path', { companyId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:COMPANY', companyId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Company Error');
};

export const useCompanyCreateMutate = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('companies.create.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:COMPANIES-LIST'],
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Company Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Company Create Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useCompanyUpdateMutate = (companyId, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('companies.update.path', { companyId });
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:COMPANIES-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:COMPANY', companyId]
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Company Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Company Create Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useCompanyDeleteMutate = (options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (companyId) => {
      const path = contextFn('companies.delete.path', { companyId });
      return executeDelete(path);
    },
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:COMPANIES-LIST']
      });
      addToast({
        status: 'normal',
        title: 'Deleted',
        message: 'Company Successfully Deleted.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Company Delete Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};
