// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import { toRelativeUrl } from '@okta/okta-auth-js'; // version.js (could be behind)
import { Anchor, Box, Text } from 'grommet';
import { Info } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import prevVersion from '../../version';
import AuthActions from '../actions/AuthActions';
import Badge from '../shared/badges/Badge';
import Toast from '../shared/component/Toast';
import * as EnvironmentService from '../shared/environment/EnvironmentUtil';

const refreshMinutes = 15; // How many minutes between refresh attempts
/**
 * To test, launch chrome on cmd line with:
 * 'open /Applications/Google\ Chrome.app --args --user-data-dir="/var/tmp/Chrome dev session" --disable-web-security'
 * ... this is to bypass CORS issue.
 * and provide
 * 'https://fc.consumption-dev.support.hpe.com/'
 * to the AuthAction.version() call below
 * @returns {JSX.Element}
 * @constructor
 */

const VersionPanel: React.FC = () => {
  const navigate = useNavigate();
  const [expired, setExpired] = useState(false);
  const [curVersion, setCurVersion] = useState(null); // version.txt (always current)
  const isPreview = sessionStorage.getItem('previews') !== null;
  let interval;

  const doRefresh = () => {
    if (!EnvironmentService.isLocalhost()) {
      AuthActions.version()
        .then((response) => {
          if (response) {
            setCurVersion(response);
            const appExpired = !!prevVersion.version.localeCompare(response);
            setExpired(appExpired);
          }
          // eslint-disable-next-line no-console
          console.groupCollapsed('GLBM.Version');
          // eslint-disable-next-line no-console
          console.log('Previous : ', prevVersion.version);
          // eslint-disable-next-line no-console
          console.log('Current  : ', response);
          // eslint-disable-next-line no-console
          console.log('Expired  : ', expired);
          // eslint-disable-next-line no-console
          console.groupEnd();
        }, (error) => {
          console.error('GLBM.Version', 'version.error', error);
        });
    }
  };

  const scheduleRefresh = () => {
    doRefresh();
    interval = setInterval(() => {
      doRefresh();
    }, 60000 * refreshMinutes);
  };

  useEffect(() => {
    scheduleRefresh();
  }, []);

  const onRefreshBrowser = () => {
    if (interval) {
      window.clearInterval(interval);
    }
    navigate(toRelativeUrl('', window.location.origin), { replace: true });
    navigate(0);
  };

  return (
    <Box
      gap='small'
      justify='start'
      align='start'
      direction='row'
      flex={true}
      margin={{ horizontal: 'small' }}
    >
      {prevVersion
        && (
          <Box align='end'>
            <Text size='small' weight={100}>
              {isPreview
                ? <Badge label='Preview' value={`v${prevVersion.version}`} />
                : `v${prevVersion.version}`}
            </Text>
          </Box>
        )}
      {expired
        && (
          <Anchor
            label={`(Update to v${curVersion})`}
            onClick={onRefreshBrowser}
          />
        )}
      {(expired && !isPreview)
        && (
          <Toast open={expired}>
            <Box direction='row' gap='small' onClick={onRefreshBrowser}>
              <Info />
              <span>{`A new webapp version is available. Refresh your browser to load GreenLake Billing Manager v${curVersion}`}</span>
            </Box>
          </Toast>
        )}
    </Box>
  );
};

export default VersionPanel;
