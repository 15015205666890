// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Heading,
  Main,
  Text,
} from 'grommet';
import { useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';
import { kebabCase } from 'lodash';
import IDUtil from '../shared/util/IDUtil';
import {
  useCompaniesQuery,
  useCurrenciesQuery,
  useCustomerQuery,
  useLocalesQuery,
  usePartnersQuery,
} from '../../core';
import GLBMHeading from '../shared/component/GLBMHeading';
import DetailsBox from '../shared/component/DetailsBox';
import Loader from '../shared/loader';
import { BilledByType } from '../shared/constants/BilledByType';
import { PurposeType } from '../shared/constants/PurposeType';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import { DealType } from '../shared/constants/DealType';
import { ReportBy } from '../services';


const CustomerInfoPage = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const {
    data,
    isFetching
  } = useCustomerQuery(customerId);

  const {
    data: currenciesData,
    isFetching: isCurrenciesFetching,
  } = useCurrenciesQuery();

  const { data: companiesData } = useCompaniesQuery();

  const {
    data: partners,
    isFetching: isPartnersFetching,
  } = usePartnersQuery();

  const {
    data: localesData,
    isFetching: localeLoading,
  } = useLocalesQuery();


  const onCloseOrCancel = () => {
    navigate(-1);
  };

  const customerData = useMemo(() => data ? {
    ...data,
    promotionDate: data.promotionDate ? moment(data.promotionDate).format('L LTS') : undefined,
    contractCurrency: currenciesData ? currenciesData.find(currency => currency.currencyCode === data.contractCurrency)?.displayName : undefined,
    secureSite: data.secureSite !== undefined && data.secureSite.toString(),
    companyId: companiesData ? companiesData.find(company => company.id === data.companyId)?.name : undefined,
    billedBy: data.billedBy ? BilledByType.enumValueOf(data.billedBy)?.label : undefined,
    purpose: data.purpose ? PurposeType.enumValueOf(data.purpose)?.label : undefined,
    psaProjectServices: data.psaProjectServices ? data.psaProjectServices.map(service => ServiceTypeStore.getService(service)?.label).join(', ') : undefined,
    dealType: data.dealType ? DealType.enumValueOf(data.dealType)?.label : undefined,
    resellerPartnerId: data.resellerPartnerId ? partners.find(partner => partner.id === data.resellerPartnerId)?.name : undefined,
    distributorPartnerId: data.distributorPartnerId ? partners.find(partner => partner.id === data.distributorPartnerId)?.name : undefined,
    locale: localesData?.find(locale => locale.localeCode === data?.locale)?.displayName || data?.locale,
  } : {}, [data, currenciesData, companiesData, partners, localesData]);

  const serviceSettingsData = useMemo(() => data?.defaultServiceConfig ? {
    ...data.defaultServiceConfig,
    reportBy: data.defaultServiceConfig.reportBy ? ReportBy.enumValueOf(data.defaultServiceConfig.reportBy)?.label : undefined,
    maxShrink: data.defaultServiceConfig.maxShrink ? `${data.defaultServiceConfig.maxShrink}%` : undefined,
    committedPercent: data.defaultServiceConfig.committedPercent ? `${data.defaultServiceConfig.committedPercent}%` : undefined,
    unitPrecision: data.defaultServiceConfig.unitPrecision ? `${data.defaultServiceConfig.unitPrecision}` : undefined,
    ratePrecision: data.defaultServiceConfig.ratePrecision ? `${data.defaultServiceConfig.ratePrecision}` : undefined,
  } : undefined, [data]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden' justify='between'>
      <Box overflow='hidden'>
        <GLBMHeading
          back='/customers'
          title={`View Billing Info: ${customerId}`}
        />
        {isFetching || isCurrenciesFetching || isPartnersFetching || localeLoading ? <Loader text='Loading customer...' /> : (
          <Box direction='row' justify='start' align='start' fill='horizontal' overflow='auto' gap='xlarge' margin={{ horizontal: 'small' }}>
            <Box
              basis='1/3'
              direction='column'
              justify='start'
              align='start'
            >
              <DetailsBox
                detailsBoxHeading='General Information'
                detailsData={customerData}
                detailsSubHeading={{
                  name: 'Billing Account Name',
                  promotionDate: 'Consumption Billing Promotion Date',
                  contractStartMonth: 'Start Month',
                  contractEndMonth: 'End Month',
                  contractCurrency: 'Billing Currency',
                  locale: 'Locale',
                  secureSite: 'Secure Sites Customer',
                  companyId: 'Company',
                  billedBy: 'Billed By',
                  purpose: 'Purpose',
                }}
              />
              <DetailsBox
                detailsBoxHeading='PSA Project Info'
                detailsData={customerData}
                detailsSubHeading={{
                  psaProjectId: 'PSA Project ID',
                  psaProjectServices: 'Project Services',
                  psaPortalActivationDate: 'Activation Date',
                }}
              />
            </Box>
            <Box
              basis='1/3'
              direction='column'
              justify='start'
              align='start'
            >
              <DetailsBox
                detailsBoxHeading='Partner Relationships'
                detailsData={customerData}
                detailsSubHeading={{
                  dealType: 'Deal Type',
                  ...(customerData.dealType === DealType.DISTRIBUTOR_RESELLER.label ? { distributorPartnerId: 'Distributor' } : {}),
                  ...(customerData.dealType === DealType.RESELLER.label || customerData.dealType === DealType.DISTRIBUTOR_RESELLER.label ? { resellerPartnerId: 'Reseller' } : {}),
                }}
              />
              {serviceSettingsData
                && (
                  <DetailsBox
                    detailsBoxHeading='Default service settings'
                    detailsData={serviceSettingsData}
                    detailsSubHeading={{
                      reportBy: 'Report By',
                      maxShrink: 'Max Shrink',
                      committedPercent: 'Reserved Percent',
                      unitPrecision: 'Unit Precision',
                      ratePrecision: 'Rate Precision',
                    }}
                  />
                )
              }
              <Box
                margin='small'
                fill='horizontal'
                id={`detailsbox-wrapper-${kebabCase('Billing account locations')}`}
                data-testid={`detailsbox-wrapper-${kebabCase('Billing account locations')}`}
                gap='small'
              >
                <Heading
                  id={`detailsbox-detailsBoxHeading-${kebabCase('Billing account locations')}`}
                  data-testid={`detailsbox-detailsBoxHeading-${kebabCase('Billing account locations')}`}
                  level='2'
                  margin={{
                    vertical: 'xsmall',
                    horizontal: 'none',
                  }}
                >
                  Billing account locations
                </Heading>
                <Text>{customerData?.locations?.map(el => el.name).join(', ')}</Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        direction='row'
        gap='small'
        align='end'
        flex={false}
        pad={{
          horizontal: 'small',
          vertical: 'small',
        }}
        border='top'
      >
        <Button
          label='Close'
          id={IDUtil.getId('EditorViewToolbarCloseButton')}
          type='button'
          secondary={true}
          onClick={onCloseOrCancel}
        />
      </Box>
    </Main>
  );
};

CustomerInfoPage.contextTypes = {
  router: PropTypes.object,
};

export default CustomerInfoPage;
