// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, Footer, Spinner, Text } from 'grommet';
import GridUtil from '../shared/util/GridUtil';
import { useAlertDetailQuery } from '../../core';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import GLBMLayer from '../shared/component/GLBMLayer';
import AgGridContainer from '../shared/grid/AgGridContainer';

const HeaderLabel = ({ toFlex = undefined, label = '', value = '' }) => (
  <Box
    direction='column'
    align='start'
    flex={toFlex}
    style={{ overflow: 'hidden' }}
    gap='small'
  >
    <strong>{label}</strong>
    <Box direction='row' align='start'>
      <Text>{value}</Text>
    </Box>
  </Box>
);

HeaderLabel.propTypes = {
  toFlex: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  value: PropTypes.string,
};

const DetailGrid = ({ gridOptions: initialGridOptions = {} }) => {
  const gridOptions = useMemo(() => ({
    animateRows: true,
    columnDefs: initialGridOptions.columnDefs,
    rowData: initialGridOptions.rowData,
  }), []);

  const resizeColumns = (params) => {
    const allColumnIds = [];
    params.api.getColumns()
      .forEach((column) => {
        allColumnIds.push(column.colId);
      });
    params.api.autoSizeColumns(allColumnIds);
  };

  const defaultColDef = {
    sortable: true,
  };

  return (
    <AgGridContainer
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={resizeColumns}
        defaultColDef={defaultColDef}
      />
    </AgGridContainer>
  );
};

DetailGrid.propTypes = {
  gridOptions: PropTypes.object,
};

const assembleMissingMeter = (alert, results) => {
  const count = (results && results.details ? results.details.count : 0);

  const contextColumns = {
    headerName: (`${count.toLocaleString()} Resource${count !== 1 ? 's' : ''}  with no Meter IDs`),
  };

  // assemble columns:
  const serviceType = ServiceTypeStore.getService(alert.serviceType);
  if (serviceType) {
    const {
      inventoryManagement,
      meteringResource,
      diagnosticDetails: {
        meteringDistinctColumns,
        equipmentDistinctColumns,
      },
    } = serviceType;
    contextColumns.children = GridUtil.createColumns(serviceType, inventoryManagement.includes(meteringResource) ? meteringDistinctColumns : equipmentDistinctColumns);
  }

  const rowData = [];
  switch (alert.serviceType) {
    case 'THREEPAR':
    case 'SYNERGYS':
    case 'PRIMERA':
      if (results && results.details && results.details.equipment) {
        for (let i = 0; i < results.details.equipment.length; i += 1) {
          const array = results.details.equipment[i];
          if (array && array.details && array.details.drives) {
            for (let j = 0; j < array.details.drives.length; j += 1) {
              const drive = array.details.drives[j];
              rowData.push({ ...array, ...drive });
            }
          }
        }
      }
      break;
    default:
      if (results && results.details && results.details.equipment) {
        for (let i = 0; i < results.details.equipment.length; i += 1) {
          const resource = results.details.equipment[i];
          rowData.push(Object.assign(resource, resource.details));
        }
      }
  }

  return {
    columnDefs: [contextColumns],
    rowData,
  };
};

const assembleMissingLocations = (alert, results) => {
  const count = results.details.missingLocations.length;

  const contextColumns = {
    headerName: (`${count.toLocaleString()} Resource${count !== 1 ? 's' : ''} with no Location`),
  };

  // assemble columns:
  const serviceType = ServiceTypeStore.getService(alert.serviceType);
  if (serviceType) {
    const {
      diagnosticDetails: {
        meteringDistinctColumns,
        equipmentDistinctColumns,
      },
    } = serviceType;
    if (meteringDistinctColumns && equipmentDistinctColumns) {
      contextColumns.children = GridUtil.createColumns(serviceType, equipmentDistinctColumns);
    } else if (equipmentDistinctColumns) {
      contextColumns.children = GridUtil.createColumns(serviceType, equipmentDistinctColumns);
    } else {
      contextColumns.children = GridUtil.createColumns(serviceType, meteringDistinctColumns);
    }
  }

  // assemble rows:
  const rowData = [];
  if (results && results.details && results.details.missingLocations) {
    for (let i = 0; i < results.details.missingLocations.length; i += 1) {
      const resource = results.details.missingLocations[i];
      rowData.push(resource);
    }
  }

  return {
    columnDefs: [contextColumns],
    rowData,
  };
};

const assembleMissingRates = (results) => {
  const rowData = [];

  const rates = results.details.missingRates;

  if (rates && rates.length) {
    for (let i = 0; i < rates.length; i += 1) {
      const m = rates[i];

      rowData.push({
        meterId: m.meterId,
        meterName: m.meterName,
        meterDescription: m.description,
      });
    }
  }

  const count = results.details.missingRates.length;
  const contextColumns = {
    headerName: (`${count.toLocaleString()} Meter ID${count !== 1 ? 's' : ''} with no Rate`),
    children: [
      {
        field: 'meterId',
        headerName: 'Meter ID',
        sortable: true,
        width: 450,
      },
      {
        field: 'meterName',
        headerName: 'Meter Name',
        sortable: true,
        width: 450,
      },
      {
        field: 'meterDescription',
        headerName: 'Description',
        sortable: true,
        width: 450,
      },
    ],
  };

  return {
    columnDefs: [contextColumns],
    rowData,
  };
};

const invalidUsageRecords = (results) => {
  const {
    data: rows,
    columns,
  } = results.details;

  const columnDefs = columns.map(column => ({
    field: column.fieldName,
    headerName: column.displayName,
  }));

  const reasonColumn = columnDefs.find(column => column.field === 'reason');
  const otherColumns = columnDefs.filter(column => column.field !== 'reason');

  const updatedColumns = [
    reasonColumn,
    ...otherColumns,
  ];

  return {
    INVALID_USAGE_RECORDS: {
      columnDefs: updatedColumns,
      rowData: rows,
    },
  };
};

const unconfiguredServiceRecords = (results, alert) => ({
  UNCONFIGURED_SERVICE: {
    missingMeter: assembleMissingMeter(alert, results),
    // eslint-disable-next-line no-prototype-builtins
    missingLocations: (results && results.details.hasOwnProperty('missingLocations') ? assembleMissingLocations(alert, results) : undefined),
    missingRates: assembleMissingRates(results),
  },
});

const newEquipmentRecords = (results, alert) => {
  let columns;

  // assemble columns:
  const serviceDef = ServiceTypeStore.getService(alert.serviceType);
  if (serviceDef) {
    columns = GridUtil.createColumns(serviceDef, serviceDef.diagnosticDetails.meteringDistinctColumns || serviceDef.diagnosticDetails.equipmentDistinctColumns);
  }

  const rowData = [];
  switch (alert.serviceType) {
    case 'THREEPAR':
    case 'SYNERGYS':
    case 'PRIMERA':
      if (results && results.details) {
        const array = results.details;
        if (array && array.details && array.details.drives) {
          for (let j = 0; j < array.details.drives.length; j += 1) {
            const drive = array.details.drives[j];
            rowData.push({ ...array, ...drive });
          }
        }
      }
      break;
    default:
      if (results && results.details && results.details) {
        rowData.push(results.details);
      }
  }
  return {
    NEW_EQUIPMENT: {
      columnDefs: columns,
      rowData,
    },
  };
};

const duplicateRecords = (results) => {
  // assemble columns:
  function getQuickFilterText(params) {
    return params.value;
  }

  const columns = [{
    field: 'feeds',
    headerName: 'Feeds',
    type: '',
    sortable: true,
    getQuickFilterText,
  }];

  const rowData = [];
  if (results && results.details && results.details) {
    for (let j = 0; j < results.details.feeds.length; j += 1) {
      rowData.push({
        feeds: results.details.feeds[j],
      });
    }
  }

  return {
    DUPLICATE_RESOURCE: {
      columnDefs: columns,
      rowData,
    },
  };
};

const AlertDetails = ({ alert, onConfigure, onClose }) => {
  const [grids, setGrids] = useState();

  const isInvalidUsageAlert = useMemo(() => alert.type === 'INVALID_USAGE_RECORDS', []);

  const {
    isSuccess,
    data,
  } = useAlertDetailQuery(alert.customerId, alert.id, isInvalidUsageAlert);

  useEffect(() => {
    if (isSuccess) {
      switch (alert.type) {
        case 'INVALID_USAGE_RECORDS':
          setGrids(invalidUsageRecords(data));
          break;
        case 'UNCONFIGURED_SERVICE':
          setGrids(unconfiguredServiceRecords(data, alert));
          break;
        case 'NEW_EQUIPMENT':
          setGrids(newEquipmentRecords(data, alert));
          break;
        case 'DUPLICATE_RESOURCE':
          setGrids(duplicateRecords(data));
          break;
        default:
          break;
      }
    }
  }, [isSuccess, alert, data]);

  const renderAlertContext = useMemo(() => (
    <Box
      direction='row'
      flex='grow'
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      gap='large'
      margin={{
        top: 'none',
        bottom: 'small',
      }}
      colorIndex='light-2'
    >
      <HeaderLabel
        label='Billing Account Name'
        value={alert.properties.customerName}
        toFlex={false}
      />
      <HeaderLabel
        label='Billing ID'
        value={alert.customerId}
        toFlex={false}
      />
      <HeaderLabel
        label='Service'
        value={alert.serviceTypeDescription}
        toFlex={false}
      />
      <HeaderLabel label='Date' value={alert.start} toFlex={false} />
      <HeaderLabel label='Message' value={alert.message} toFlex={true} />
    </Box>
  ), [alert]);

  const renderDetailGrids = useMemo(() => {
    let gridOptions;
    if (alert && grids) {
      // eslint-disable-next-line default-case
      switch (alert.type) {
        case 'INVALID_USAGE_RECORDS':
        case 'DUPLICATE_RESOURCE':
        case 'NEW_EQUIPMENT':
          gridOptions = grids[alert.type];
          if (gridOptions) {
            return (
              <Box flex={true} direction='column' gap='small'>
                <DetailGrid gridOptions={gridOptions} />
              </Box>
            );
          }
          break;
        case 'UNCONFIGURED_SERVICE':
          gridOptions = grids[alert.type];
          if (gridOptions) {
            return (
              <Box flex={true} direction='column' gap='small'>
                {gridOptions.missingMeter
                  && <DetailGrid gridOptions={gridOptions.missingMeter} />}
                {gridOptions.missingLocations
                  && <DetailGrid gridOptions={gridOptions.missingLocations} />}
                {gridOptions.missingRates
                  && <DetailGrid gridOptions={gridOptions.missingRates} />}
              </Box>
            );
          }
          break;
      }
    } else {
      return (
        <Box
          fill={true}
          size='full'
          direction='column'
          justify='center'
        >
          <Box
            direction='row'
            gap='small'
            justify='center'
          >
            <Spinner />
            <Box>
              <Text weight='bold'>Loading ...</Text>
            </Box>
          </Box>
        </Box>
      );
    }
    return null;
  }, [alert, grids]);

  const renderButtons = () => {
    const actions = [];

    if (onConfigure) {
      actions.push(
        <Button
          label='Configure Service'
          key='configService'
          onClick={onConfigure}
          primary={true}
        />,
      );
    }

    actions.push(
      <Button
        label='Close'
        key='closeButton'
        secondary={true}
        onClick={onClose}
      />,
    );

    return (actions.length > 0
      && (
        <Box border='top' pad='small' margin={{ top: 'small' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            {actions}
          </Footer>
        </Box>
      ));
  };

  return (
    <GLBMLayer
      flush={true}
      overlayClose={true}
      onEsc={onClose}
      onClickOutside={onClose}
      title={`Alert Details: ${alert.typeDescription}`}
      full={true}
      margin='xlarge'
    >
      <Box direction='column' fill={true}>
        <Box flex={false} pad={{ horizontal: 'small' }}>
          {renderAlertContext}
        </Box>
        <Box flex={true} pad={{ horizontal: 'small' }}>
          {renderDetailGrids}
        </Box>
        {renderButtons()}
      </Box>
    </GLBMLayer>
  );
};

AlertDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfigure: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

export default AlertDetails;
