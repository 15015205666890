// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { useErrorToast } from '../../components/shared/hooks';
import { executeGet, executePut } from '../api';
import { ApiContext } from '../../AppContext';

export const useServiceMappingsQuery = (options) => {
  const params = '';
  const path = useContext(ApiContext)('serviceMappings.list.path', { params });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-MAPPINGS-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Service Mappings Error');
};

export const useServiceMappingSaveMutate = (options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const path = useContext(ApiContext)('serviceMappings.update.path');
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Service Mapping was successfully updated.',
        message: '',
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-MAPPINGS-LIST']
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Saving Service Mapping Failed',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    })
  });
};
