// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { ThemeContext } from 'grommet/contexts';

import {
  AllCommunityModule,
  iconSetQuartzLight,
  ModuleRegistry,
  provideGlobalGridOptions,
  themeQuartz,
} from 'ag-grid-community';

import { Box } from 'grommet';
import { getRGBA, getRGBArray, normalizeColor } from 'grommet/utils';
import { agGridIcons } from './utils';

const StyledBox
  = styled(Box)`${({ theme }) => `
& {
    position: relative;

  // Custom class styles
  .background-contrast {
    background: ${normalizeColor('background-contrast', theme)};
  }
  .background-back {
    background: ${normalizeColor('background-back', theme)};
  }
  .disabled-cell {
    color: ${normalizeColor('disabled-text', theme)};
  }
  .bold-cell {
    font-weight: bold;
  }
  .transparent-background{
    background-color: ${normalizeColor('background-contrast', theme)};
    text-align: 'right';
  }
  .transparent-background-total{
    background-color: ${getRGBA(normalizeColor('background-contrast', theme), getRGBArray(normalizeColor('background-contrast', theme))[3] * 2)};
    text-align: 'right';
    justify-content: right;
  }
  .header-left-aligned .ag-header-cell-label {
    justify-content: left;
  }
  .centered-cell .ag-header-group-cell-label {
     justify-content: center;
  }
  .ag-total-cell-value{
    font-weight: 500;
  }

  // Billing Estimate styles
  .ag-cell-state-cell {
    padding-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .ag-cell-consumed {
    padding-right: 4px;
  }
  .ag-cell-state-cell-on {
    background: ${normalizeColor('validation-ok', theme)};
  }
  .ag-cell-state-cell-off {
  }
  .ag-cell-consumed-estimated {
    background-color: ${normalizeColor('background-back', theme)}
  }
  .ag-cell-state-on-estimated {
    background-color:  ${normalizeColor('light-5', theme)}
  }
  .ag-cell-state-off-estimated {
    background-color: ${normalizeColor('background-back', theme)}
  }

  // Borders
  .ag-header {
    border-bottom: solid ${theme.global.borderSize.xsmall} ${normalizeColor('border', theme)};
  }
  .ag-floating-bottom, .ag-row-footer {
    border-top: solid ${theme.global.borderSize.xsmall} ${normalizeColor('border', theme)};
  }
  .ag-pinned-right-cols-container, .ag-pinned-right-floating-bottom {
    border-left: solid ${theme.global.borderSize.xsmall} ${normalizeColor('border', theme)};
  }
  .ag-pinned-left-cols-container, .ag-pinned-left-floating-bottom, .ag-pinned-left-sticky-top, .ag-pinned-left-sticky-bottom {
    border-right: solid ${theme.global.borderSize.xsmall} ${normalizeColor('border', theme)};
  }

  // Headers
  .ag-header-cell-label {
    height: 100%;
    text-align: left;
    padding: 0 !important;
  }

  // Styles related to text input in a cell
  input {
    line-height: ${theme.text.medium.height};
  }
  .ag-has-focus .ag-cell-focus, .ag-column-active {
    border: solid ${theme.global.borderSize.small} ${normalizeColor('focus', theme)};
    box-shadow: 0 0 ${theme.global.borderSize.small} ${theme.global.borderSize.small} ${normalizeColor('focus', theme)};
  }
  .ag-center-cols-viewport {
    // so focus border in right most cell has complete right side
    padding-right: 4px;
  }

  // General styling
  --ag-header-column-resize-handle-display: block;
  .ag-paging-panel {
    padding-top: ${theme.global.edgeSize.small};
    justify-content: flex-start;
  }
  .ag-row-footer {
    background: #f3f3f3;
    border-color: ${normalizeColor('background', theme)};
  }
  .ag-row-footer.ag-row-level-0 {
    background: #f3f3f3;
  }
  .ag-row-footer.ag-row-level--1 {
    background: inherit;
  }
  .ag-floating-bottom-container .ag-cell-value, .ag-row-footer .ag-cell-value {
    font-weight: 500;
  }

  // Menu styles
  .ag-menu {
    .ag-menu-list > .ag-menu-separator {
      height: ${theme.global.borderSize.xsmall};
    }
    .ag-menu-list > .ag-menu-separator > .ag-menu-separator-part {
      height: ${theme.global.borderSize.xsmall};
      border-top: ${theme.global.borderSize.xsmall} solid ${normalizeColor('border', theme)};
    }
    .ag-menu-list > .ag-menu-option > .ag-menu-option-popup-pointer {
      background-size: contain;
    }
    .ag-menu-list > .ag-menu-option {
      font-weight: normal;
    }
  }
  .ag-tabs-header {
    display: none !important;
  }
  --ag-menu-shadow: ${theme.global.elevation.light.medium};
  --ag-menu-border: 'none';
`}`;

ModuleRegistry.registerModules([
  AllCommunityModule,
]);

const AgGridContainer = (props) => {
  const theme = useContext(ThemeContext);

  // to use glbmTheme in an application, pass it to the theme grid option
  const glbmTheme = themeQuartz
    .withPart(iconSetQuartzLight)
    .withParams({
      backgroundColor: '#FFFFFF',
      borderColor: '#D7E2E6',
      borderRadius: 2,
      browserColorScheme: 'light',
      cellHorizontalPaddingScale: 1,
      chromeBackgroundColor: {
        ref: 'backgroundColor'
      },
      columnBorder: false,
      fontFamily: 'inherit',
      fontSize: theme.text.small.size,
      foregroundColor: '#555B62',
      headerBackgroundColor: normalizeColor('background', theme),
      headerCellHoverBackgroundColor: normalizeColor('background-contrast', theme),
      headerColumnBorderHeight: '50%',
      headerColumnResizeHandleColor: normalizeColor('border', theme),
      headerColumnResizeHandleHeight: '50%',
      headerColumnResizeHandleWidth: theme.global.borderSize.xsmall,
      headerFontWeight: theme.dataTable.header.font.weight,
      headerHeight: `calc(${theme.text.small.height} + ${theme.global.edgeSize.small} * 1.25)`,
      headerFontSize: theme.text.small.size,
      headerTextColor: normalizeColor('text-strong', theme),
      oddRowBackgroundColor: normalizeColor('light-1', theme),
      rangeSelectionBackgroundColor: 'transparent',
      rangeSelectionBorderColor: normalizeColor('focus', theme),
      rangeSelectionBorderStyle: 'solid',
      rangeSelectionHighlightColor: normalizeColor('validation-ok', theme),
      rowBorderColour: normalizeColor('border', theme),
      rowBorder: true,
      rowGroupIndentSize: '0px',
      rowHeight: `calc(${theme.text.small.height} + ${theme.global.edgeSize.small} * 1)`,
      rowHoverColor: normalizeColor('background-contrast', theme),
      sidePanelBorder: true,
      spacing: '4px',
      wrapperBorder: true,
      wrapperBorderRadius: 2
    });

  provideGlobalGridOptions({
    theme: glbmTheme,
    icons: agGridIcons,
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledBox {...props} />
    </ThemeProvider>
  );
};

export default AgGridContainer;

