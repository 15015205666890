// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Button, Text } from 'grommet';
import { View } from 'grommet-icons';
import IDUtil from '../../../shared/util/IDUtil';
import GLBMTooltip from '../../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../../shared/component/StatusIcon';
import CatalogPlanDialog from './CatalogPlanDialog';

const CatalogPlanList = ({ plans }) => {
  const [viewPlanIndex, setViewPlanIndex] = useState();
  const [layer, setLayer] = useState();

  const onPlanView = (index) => {
    setViewPlanIndex(index);
    setLayer('viewPlan');
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });
  };

  const renderPlanFields = () => {
    const template = plans;
    return (
      <Box>
        {
          template.length === 0 && (
            <Box pad={{ vertical: 'large' }} align='center'>
              <Text>You do not have any provisioned plans defined at the moment.</Text>
            </Box>
          )
        }
        <Box>
          {
            template.map((plan, index) => (
              <GLBMTooltip content={!plan.deprovisioned ? 'This plan is active' : 'This plan is inactive'}>
                <Box
                  key={plan.planId}
                  style={{ height: '50px' }}
                  justify='between'
                  pad='none'
                  border={index === 0 ? false : 'top'}
                  responsive={false}
                  direction='row'
                  align='center'
                >
                  <Box direction='row' justify='between' flex={true} align='center'>
                    <Box direction='row' gap='small' align='center'>
                      <Box margin={{ left: 'small' }}>
                        {!plan.deprovisioned ? <StatusIcon size='small' value='ok' /> : <StatusIcon size='small' value='critical' />}
                      </Box>
                      <Box>{plan.planName}</Box>
                    </Box>
                    <Box margin={{ right: 'medium' }} align='center'>
                      {plan.deprovisioned ? formatDate(plan.deprovisioned) : '-'}
                    </Box>
                  </Box>
                  <Box direction='row' flex={false} align='end' justify='end'>
                    <Button
                      icon={<View />}
                      id={IDUtil.getId('LocationListEditButton', index)}
                      onClick={() => onPlanView(index)}
                      a11yTitle={`View ${plan.name} Plan`}
                    />
                  </Box>
                </Box>
              </GLBMTooltip>
            ))
          }
        </Box>
      </Box>
    );
  };

  const onLayerClose = () => {
    setLayer(undefined);
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'viewPlan') {
        const plan = plans[viewPlanIndex];
        result = (
          <CatalogPlanDialog
            onClose={onLayerClose}
            heading='Plan Details'
            plan={plan}
          />
        );
      }
    }
    return result;
  };


    return (
      <Box>
        {renderPlanFields()}
        {renderLayer()}
      </Box>
    );
};

CatalogPlanList.propTypes = {
  plans: PropTypes.array.isRequired,
};

export default CatalogPlanList;
