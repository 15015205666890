// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Box,
  Button,
  Data,
  DataFilters,
  DataSearch,
  DataSummary,
  DataTableColumns,
  Main,
  Menu,
  Notification,
  Text,
  Toolbar,
} from 'grommet';
import { Add, More, Refresh } from 'grommet-icons';
import moment from 'moment';
import Highlight from 'react-highlighter';
import { useNavigate } from 'react-router-dom';
import { BillingType } from '../shared/constants/BillingType';
import { DealType } from '../shared/constants/DealType';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import { isProd } from '../shared/environment/EnvironmentUtil';
import IDUtil from '../shared/util/IDUtil';
import {
  useCompaniesQuery,
  useCurrenciesQuery,
  useCustomerClearEndMonthMutate,
  useCustomerDeleteMutate,
  useCustomerResetMutate,
  useCustomersQuery,
} from '../../core';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import OptionalAnchor from '../shared/component/OptionalAnchor';
import { StatusIcon } from '../shared/component/StatusIcon';
import { pagePermissions, roles } from '../shared/constants/Permissions';
import {
  usePermissionChecker,
  useRoleChecker,
  useStateWithSessionStorage,
} from '../shared/hooks';
import { insertIf } from '../shared/util/BasicUtil';
import PromoteLayer from '../tenant/promote/PromoteLayer';
import { PurposeType } from '../shared/constants/PurposeType';
import { BilledByType } from '../shared/constants/BilledByType';
import AddEndMonthLayer from './AddEndMonthLayer';
import { useFilterProperties, usePartnerName } from './utils';

const CustomersListPage = () => {
  const navigate = useNavigate();

  const [view, setView] = useStateWithSessionStorage('customers-filters', {
    search: '', properties: {}, sort: {
      direction: 'asc',
      property: 'id'
    },
    columns: ['status', 'name', 'id', 'services', 'fixedCharges', 'asm', 'actions'],
  });

  const [layer, setLayer] = useState(undefined);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [response, setResponse] = useState(undefined);

  const { hasPermissions } = usePermissionChecker();
  const { isRole } = useRoleChecker();
  const getPartnerName = usePartnerName();

  const {
    data: currenciesData,
  } = useCurrenciesQuery();

  const {
    data: customerList,
    refetch: refreshCustomers,
    isFetching: isCustomersLoading,
  } = useCustomersQuery();

  const {
    data: companyList,
  } = useCompaniesQuery();

  const {
    mutate: clearEndMonth,
  } = useCustomerClearEndMonthMutate({
    onSuccess: () => {
      setLayer(undefined);
      setSelectedCustomer(undefined);
      setTimeout(() => {
        refreshCustomers();
      }, 0);
    },
  });

  const {
    mutate: resetCustomer,
  } = useCustomerResetMutate({
    onSuccess: () => {
      setTimeout(() => {
        setLayer(undefined);
        setSelectedCustomer(undefined);
        refreshCustomers();
      }, 500);
    },
  });

  const {
    mutate: deleteCustomer,
  } = useCustomerDeleteMutate({
    onSuccess: () => {
      setLayer(undefined);
      setSelectedCustomer(undefined);
      setTimeout(() => {
        refreshCustomers();
      }, 0);
    },
  });

  const sortCustomers = (customers) => {
    if (!customers) return [];

    let sortedList = [];
    switch (view.sort.property) {
      case 'status': // status
      case 'name': // name
      case 'id': { // account ID
        const { property } = view.sort;
        sortedList = customers.sort((a, b) => (a[property]?.localeCompare(b[property]) || 1));
        break;
      }
      case 'services': { // services
        const countActiveServices = services => services.reduce((acc, cur) => (cur.status !== 'NEW' ? acc + 1 : acc), 0);

        sortedList = customers.sort((a, b) => {
          const aActive = countActiveServices(a.services);
          const aTotal = a.services.length;
          const bActive = countActiveServices(b.services);
          const bTotal = b.services.length;

          return aActive - bActive || aTotal - bTotal;
        });
        break;
      }
      case 'fixedCharges': { // fixed charges
        const property = 'fixedMeterIds';
        sortedList = customers.sort((a, b) => {
          const aLength = a[property]?.length || 0;
          const bLength = b[property]?.length || 0;
          return aLength - bLength;
        });
        break;
      }
      case 'asm': { // ASMs
        sortedList = customers.sort((a, b) => a.asmConfig.asms.length - b.asmConfig.asms.length);
        break;
      }
      default:
        sortedList = customers;
    }

    return (view.sort.direction === 'asc') ? sortedList : sortedList.reverse();
  };

  const canPromoteCustomer = customer => (customer.status === BillingType.INCOMPLETE.enumKey);

  const getStatus = (status) => {
    const billingType = BillingType.enumValueOf(status);
    return (
      <Box direction='row' align='center'>
        <Box margin={{ right: 'small' }}>
          <StatusIcon
            size='small'
            value={billingType !== undefined ? billingType.severity : 'unknown'}
          />
        </Box>
        {billingType ? billingType.label : status}
      </Box>
    );
  };

  const addCustomer = () => {
    navigate('/customers/add');
  };

  const getPromotedDate = (date) => {
    if (date) {
      return moment(date)
        .format('lll');
    }
    return '';
  };

  const renderConfirmationDetails = customer => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Billing Account'
        data={[
          {
            label: 'Billing ID',
            value: customer.id,
          },
          {
            label: 'Billing Account Name',
            value: customer.name,
          },
          {
            label: 'Status',
            value: getStatus(customer.status),
          },
          {
            label: 'Promoted on',
            value: getPromotedDate(customer.promotedDateTime),
          },
          {
            label: 'Deal Type',
            value: DealType.enumValueOf(customer.dealType).label,
          },
        ]}
      />
    </Box>
  );

  const renderClearEndMonthDetails = customer => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Billing Account'
        data={[
          {
            label: 'Billing ID',
            value: customer.id,
          },
          {
            label: 'Billing Account Name',
            value: customer.name,
          },
          {
            label: 'End Month',
            value: customer.contractEndMonth,
          },
        ]}
      />
    </Box>
  );

  const onLayerClosed = () => {
    setLayer(undefined);
    setSelectedCustomer(undefined);
    setTimeout(() => {
      refreshCustomers();
    }, 0);
  };

  const onResetConfirmed = (data) => {
    const { customer } = data;
    resetCustomer(customer.id);
  };

  const onDeleteConfirmed = (data) => {
    const { customer } = data;
    deleteCustomer(customer.id);
  };

  const onResetClick = (customer) => {
    setLayer('resetConfirm');
    setSelectedCustomer(customer);
  };

  const onClearEndMonthConfirmed = (data) => {
    const { customer } = data;
    clearEndMonth(customer.id);
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'promote') {
        const customer = selectedCustomer;
        result = (
          <PromoteLayer onClose={onLayerClosed} customer={customer} />
        );
      } else if (layer === 'deleteConfirm') {
        result = (
          <ConfirmationDialog
            data={{ customer: selectedCustomer }}
            title='Are You Sure?'
            submitLabel='Yes, delete the Billing Account'
            cancelLabel='Cancel'
            text="Deleting will permanently erase the Billing Account and any services created for the Billing Account.  Additionally, deleting the Billing Account will delete their HPE Consumption Analytics Portal tenant, if the Billing Account is In Production.  Please ensure you are intending to completely remove this Billing Account before choosing 'Yes' below."
            onClose={onLayerClosed}
            onChange={onDeleteConfirmed}
            details={renderConfirmationDetails(selectedCustomer)}
          />
        );
      } else if (layer === 'resetConfirm') {
        result = (
          <ConfirmationDialog
            data={{ customer: selectedCustomer }}
            title='Are You Sure?'
            submitLabel='Yes, reset the Billing Account'
            cancelLabel='Cancel'
            text='Resetting will set the Billing Account and any services back to Requested / New state.  Additionally, resetting the Billing Account will delete their HPE Consumption Analytics Portal tenant, if the Billing Account is In Production.'
            onClose={onLayerClosed}
            onChange={onResetConfirmed}
            details={renderConfirmationDetails(selectedCustomer)}
          />
        );
      } else if (layer === 'addEndMonth') {
        result = (
          <AddEndMonthLayer
            data={{ customer: selectedCustomer }}
            onClose={onLayerClosed}
          />
        );
      } else if (layer === 'clearEndMonth') {
        result = (
          <ConfirmationDialog
            data={{ customer: selectedCustomer }}
            title='Are You Sure?'
            submitLabel='OK'
            cancelLabel='Cancel'
            text="Reactivating a billing account restart all usage processing and billing for that billing account. If this is not your intention, please click the 'Cancel' button."
            onClose={onLayerClosed}
            onChange={onClearEndMonthConfirmed}
            details={renderClearEndMonthDetails(selectedCustomer)}
          />
        );
      }
    }
    return result;
  };

  const onPromoteClick = (customer) => {
    setLayer('promote');
    setSelectedCustomer(customer);
  };

  const onManageTenantClick = (customer) => {
    navigate(`/customers/${customer.id}/tenant`);
  };

  const onManagePSAClick = (customer) => {
    navigate(`/customers/${customer.id}/psa`);
  };

  const onManageEncyptionKeysClick = (customer) => {
    navigate(`/customers/${customer.id}/account-keys`);
  };

  const canManagePSA = (customer) => {
    if (customer.psaNotRequired) {
      return hasPermissions(pagePermissions.customers.view.psa.page_notRequired);
    }
    return hasPermissions(pagePermissions.customers.view.psa.page);
  };

  const onAddEndMonthClick = (customer) => {
    setLayer('addEndMonth');
    setSelectedCustomer(customer);
  };

  const onClearEndMonthClick = (customer) => {
    setLayer('clearEndMonth');
    setSelectedCustomer(customer);
  };

  const onDeleteClick = (customer) => {
    setLayer('deleteConfirm');
    setSelectedCustomer(customer);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response?.status || 'critical'}
          title={response.title}
          message={response.message}
          onClose={() => setResponse(undefined)}
        />
      );
    }
    return message;
  };

  const getActions = (customer) => {
    // eslint-disable-next-line default-case
    switch (customer.status) {
      case BillingType.REQUEST.enumKey:
        if (hasPermissions(pagePermissions.customers.view.actions.edit)) {
          return [
            {
              onClick: () => navigate(`/customers/${customer.id}`),
              label: 'Edit Billing Info',
              id: IDUtil.getId('EditBillingInfo', customer.index),
            },
          ];
        }
        break;
      case BillingType.INCOMPLETE.enumKey:
        return [
          ...insertIf(hasPermissions(pagePermissions.customers.view.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}`),
              label: 'Edit Billing Info',
              id: IDUtil.getId('EditBillingInfo', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.end) && !customer.contractEndMonth, [
            {
              onClick: () => onAddEndMonthClick(customer),
              label: 'Set End Month',
              id: IDUtil.getId('AddEndMonth', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.reactivate) && customer.contractEndMonth, [
            {
              onClick: () => onClearEndMonthClick(customer),
              label: 'Reactivate',
              id: IDUtil.getId('ClearEndMonth', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.services.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/services`),
              label: 'Edit Services',
              id: IDUtil.getId('EditServices', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.features.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/features`),
              label: 'Edit Features',
              id: IDUtil.getId('EditFeatures', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.charges.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/charges`, { state: { tenant: 'MASTER' } }),
              label: 'Edit Non-Metered Services',
              id: IDUtil.getId('EditNonMeteredServices', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.charges.actions.editPartner) && !isProd() && Object.hasOwn(customer, 'distributorPartnerId'), [
            {
              onClick: () => navigate(`/customers/${customer.id}/charges`, { state: { tenant: 'DISTRIBUTOR' } }),
              label: 'Edit Non-Metered Distributor Rates',
              id: IDUtil.getId('EditNonMeteredDistributorRates', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.charges.actions.editPartner) && !isProd() && Object.hasOwn(customer, 'resellerPartnerId'), [
            {
              onClick: () => navigate(`/customers/${customer.id}/charges`, { state: { tenant: 'RESELLER' } }),
              label: 'Edit Non-Metered Reseller Rates',
              id: IDUtil.getId('EditNonMeteredResellerRates', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.admins.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/admins`),
              label: 'Edit ASMs',
              id: IDUtil.getId('EditAsm', customer.index),
            },
          ]),
          ...insertIf(!hasPermissions(pagePermissions.customers.view.admins.actions.edit) && hasPermissions(pagePermissions.customers.view.admins.page), [
            {
              onClick: () => navigate(`/customers/${customer.id}/admins`),
              label: 'View ASMs',
              id: IDUtil.getId('EditAsm', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.capacityalert.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/capacityalert`),
              label: 'Edit Capacity Alerts',
              id: IDUtil.getId('EditCapacityAlerts', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.tenant.actions.connect), [
            {
              onClick: () => onPromoteClick(customer),
              disabled: !canPromoteCustomer(customer),
              label: 'Promote',
              id: IDUtil.getId('Promote', customer.index),
            },
          ]),
          ...insertIf(canManagePSA(customer), [
            {
              onClick: () => onManagePSAClick(customer),
              label: 'Manage PSA Info',
              id: IDUtil.getId('ManagePsaInfo', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.encryptionKey.page), [
            {
              onClick: () => onManageEncyptionKeysClick(customer),
              label: 'Manage Metering Encryption',
              id: IDUtil.getId('ManageEncryptionKeys', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.delete), [
            {
              onClick: () => onDeleteClick(customer),
              label: 'Delete Billing Info',
              id: IDUtil.getId('DeleteBillingInfo', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.reset) && !isProd(), [
            {
              onClick: () => onResetClick(customer),
              label: 'Reset Billing Info',
              id: IDUtil.getId('ResetBillingInfo', customer.index),
            },
          ]),
        ];
      case BillingType.BILLABLE.enumKey:
      case BillingType.ENDED.enumKey:
        return [
          ...insertIf(hasPermissions(pagePermissions.customers.view.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}`),
              label: 'Edit Billing Info',
              id: IDUtil.getId('EditBillingInfo', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.end) && !customer.contractEndMonth, [
            {
              onClick: () => onAddEndMonthClick(customer),
              label: 'Set End Month',
              id: IDUtil.getId('AddEndMonth', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.reactivate) && customer.contractEndMonth, [
            {
              onClick: () => onClearEndMonthClick(customer),
              label: 'Reactivate',
              id: IDUtil.getId('ClearEndMonth', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.services.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/services`),
              label: 'Edit Services',
              id: IDUtil.getId('EditServices', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.features.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/features`),
              label: 'Edit Features',
              id: IDUtil.getId('EditFeatures', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.charges.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/charges`, { state: { tenant: 'MASTER' } }),
              label: 'Edit Non-Metered Services',
              id: IDUtil.getId('EditNonMeteredServices', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.charges.actions.editPartner) && !isProd() && Object.hasOwn(customer, 'distributorPartnerId'), [
            {
              onClick: () => navigate(`/customers/${customer.id}/charges`, { state: { tenant: 'DISTRIBUTOR' } }),
              label: 'Edit Non-Metered Distributor Rates',
              id: IDUtil.getId('EditNonMeteredDistributorRates', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.charges.actions.editPartner) && !isProd() && Object.hasOwn(customer, 'resellerPartnerId'), [
            {
              onClick: () => navigate(`/customers/${customer.id}/charges`, { state: { tenant: 'RESELLER' } }),
              label: 'Edit Non-Metered Reseller Rates',
              id: IDUtil.getId('EditNonMeteredResellerRates', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.admins.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/admins`),
              label: 'Edit ASMs',
              id: IDUtil.getId('EditAsm', customer.index),
            },
          ]),
          ...insertIf(!hasPermissions(pagePermissions.customers.view.admins.actions.edit) && hasPermissions(pagePermissions.customers.view.admins.page), [
            {
              onClick: () => navigate(`/customers/${customer.id}/admins`),
              label: 'View ASMs',
              id: IDUtil.getId('EditAsm', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.capacityalert.actions.edit), [
            {
              onClick: () => navigate(`/customers/${customer.id}/capacityalert`),
              label: 'Edit Capacity Alerts',
              id: IDUtil.getId('EditCapacityAlerts', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.tenant.page), [
            {
              onClick: () => onManageTenantClick(customer),
              label: 'Manage Connections',
              id: IDUtil.getId('ManageConnections', customer.index),
            },
          ]),
          ...insertIf(canManagePSA(customer), [
            {
              onClick: () => onManagePSAClick(customer),
              label: 'Manage PSA Info',
              id: IDUtil.getId('ManagePsaInfo', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.view.encryptionKey.page), [
            {
              onClick: () => onManageEncyptionKeysClick(customer),
              label: 'Manage Metering Encryption',
              id: IDUtil.getId('ManageEncryptionKeys', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.delete), [
            {
              onClick: () => onDeleteClick(customer),
              label: 'Delete Billing Info',
              id: IDUtil.getId('DeleteBillingInfo', customer.index),
            },
          ]),
          ...insertIf(hasPermissions(pagePermissions.customers.actions.reset) && !isProd(), [
            {
              onClick: () => onResetClick(customer),
              label: 'Reset Billing Info',
              id: IDUtil.getId('ResetBillingInfo', customer.index),
            },
          ]),
        ];
    }
    return [];
  };

  const getColumns = (searchText = '') => [
    {
      property: 'status',
      header: 'Status',
      render: datum => (
        <div
          id={IDUtil.getId('Status', datum.index)}
          key={IDUtil.getId('Status', datum.index)}
        >
          {getStatus(datum.status)}
        </div>
      ),
      disabled: true,
      pinned: true,
    },
    {
      property: 'name',
      header: 'Billing Account Name',
      render: ({
        id,
        name,
        index,
      }) => (
        <OptionalAnchor
          onClick={() => navigate(`/customers/${id}`)}
          permissions={pagePermissions.customers.view.page}
          highlight={searchText}
          id={IDUtil.getId('BillingAccountName', index)}
          key={IDUtil.getId('BillingAccountName', index)}
          weight='bold'
        >
          {name}
        </OptionalAnchor>
      ),
      size: 'medium',
      disabled: true,
      pinned: true,
    },
    {
      property: 'id',
      header: 'Billing ID',
      primary: true,
      render: ({
        id,
        index,
      }) => (
        <div id={IDUtil.getId('BillingId', index)} key={IDUtil.getId('BillingId', index)}>
          <Text>
            <Highlight
              search={searchText}
            >
              {id}
            </Highlight>
          </Text>
        </div>
      ),
      size: '250px',
      disabled: true,
      pinned: true,
    },
    {
      property: 'services',
      header: 'Services',
      dataCallback: ({ services }) => (services ? services.length : 0),
      render: (datum) => {
        const serviceText = `${datum.services.reduce((acc, cur) => ((cur.status !== 'NEW') ? acc + 1 : acc), 0)} of ${datum.services.length}`;
        return (
          <Anchor
            id={IDUtil.getId('Services', datum.index)}
            key={IDUtil.getId('Services', datum.index)}
            disabled={datum.status === BillingType.REQUEST.enumKey}
            onClick={() => navigate(`/customers/${datum.id}/services`)}
          >
            {serviceText}
          </Anchor>
        );
      },
    },
    {
      property: 'fixedCharges',
      header: 'Non-Metered Services',
      dataCallback: ({ fixedMeterIds }) => (fixedMeterIds ? fixedMeterIds.length : 0),
      render: (datum) => {
        const fixedMeterIdsText = `${datum.fixedMeterIds ? datum.fixedMeterIds.length : 0}`;
        return (
          <OptionalAnchor
            onClick={() => navigate(`/customers/${datum.id}/charges`, { state: { tenant: 'MASTER' } })}
            permissions={pagePermissions.customers.view.charges.page}
            disabled={isRole(roles.SERVICE_DEV) || datum.status === BillingType.REQUEST.enumKey}
            highlight={searchText}
            id={IDUtil.getId('NonMeteredServices', datum.index)}
            key={IDUtil.getId('NonMeteredServices', datum.index)}
          >
            {fixedMeterIdsText}
          </OptionalAnchor>
        );
      },
    },
    {
      property: 'asm',
      header: 'ASMs',
      dataCallback: ({ asmConfig }) => (asmConfig && asmConfig.asms ? asmConfig.asms.length : 0),
      render: (datum) => {
        const asmText = `${datum.asmConfig && datum.asmConfig.asms ? datum.asmConfig.asms.length : 0}`;
        return (
          <OptionalAnchor
            onClick={() => navigate(`/customers/${datum.id}/admins`)}
            id={IDUtil.getId('ASM', datum.index)}
            key={IDUtil.getId('ASM', datum.index)}
            highlight={searchText}
            permissions={pagePermissions.customers.view.admins.page}
            disabled={datum.status === BillingType.REQUEST.enumKey}
          >
            {asmText}
          </OptionalAnchor>
        );
      },
    },
    {
      property: 'purpose',
      header: 'Billing Account Purpose',
      dataCallback: ({ purpose }) => purpose ? PurposeType.enumValueOf(purpose)?.label : '--',
    },
    {
      property: 'billedBy',
      header: 'Billing Account Billed By',
      dataCallback: ({ billedBy }) => billedBy ? BilledByType.enumValueOf(billedBy)?.label : '--',
    },
    {
      property: 'contractStartMonth',
      header: 'Start Month',
      dataCallback: ({ contractStartMonth }) => contractStartMonth || '--',
    },
    {
      property: 'contractCurrency',
      header: 'Billing Currency',
      dataCallback: ({ contractCurrency }) => contractCurrency ? (currenciesData?.find(({ currencyCode }) => currencyCode === contractCurrency)?.displayName || contractCurrency) : '--',
    },
    {
      property: 'secureSite',
      header: 'Secure Site flag',
      dataCallback: ({ secureSite }) => (secureSite ? 'Yes' : 'No'),
    },
    {
      property: 'psaProject.projectId',
      header: 'Base Project ID',
      dataCallback: ({ psaProject }) => psaProject?.projectId || '--',
    },
    {
      property: 'dealType',
      header: 'Deal Type',
      dataCallback: ({ dealType }) => dealType ? DealType.enumValueOf(dealType).label : '--',
    },
    {
      property: 'distributorPartnerId',
      header: 'Distributor',
      dataCallback: ({ distributorPartnerId }) => distributorPartnerId ? getPartnerName(distributorPartnerId) : '--',
    },
    {
      property: 'resellerPartnerId',
      header: 'Reseller',
      dataCallback: ({ resellerPartnerId }) => resellerPartnerId ? getPartnerName(resellerPartnerId) : '--',
    },
    {
      property: 'companyId',
      header: 'Company',
      dataCallback: ({ companyId }) => companyList?.find(company => company.id === companyId)?.name || '--',
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: datum => (
        <Menu
          {...{ 'data-testid': IDUtil.getId('Actions', datum.index) }}
          id={IDUtil.getId('Actions', datum.index)}
          key={IDUtil.getId('Actions', datum.index)}
          icon={<More />}
          items={getActions(datum)}
        />
      ),
      sortable: false,
      disabled: true,
      pinned: true,
    },
  ];

  const properties = useFilterProperties();
  const columns = useMemo(() => getColumns(view.search), [view.search, companyList, pagePermissions, getPartnerName]);
  const options = useMemo(() => columns.map(({ header: label, property, disabled, pinned }) => ({ label, property, pinned, disabled })), [columns]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const filterCustomers = (customerList, {
    search: searchText,
    properties: panel,
    columns: selectedColumns
  }, columnDefs) => {
    const filterByText = (!!searchText);
    const filterByFilter = panel && (Object.keys(panel).length > 0);

    if (!searchText && Object.keys(panel || {}).length === 0) {
      return customerList;
    }
    const results = [];

    if (Array.isArray(customerList)) {
      const allProperties = [
        'id',
        'name',
        'purpose',
        'billedBy',
        'contractCurrency',
        'psaProject.projectId',
        'dealType',
        'distributorPartnerId',
        'resellerPartnerId',
        'companyId',
      ];
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const properties = allProperties.filter(p => selectedColumns.includes(p));
      for (let i = 0; i < customerList.length; i += 1) {
        const customer = customerList[i];

        let matchOnText;
        let matchOnFilter;

        // see if the customer is a match by text:
        if (filterByText) {
          matchOnText = properties.some((property) => {
            const value = columnDefs.find(col => col.property === property)?.dataCallback?.(customer) || customer[property];
            return value?.toLowerCase()
              .includes(searchText?.toLowerCase());
          });
        }

        // see if the customer is a match by panel:
        if (filterByFilter) {
          matchOnFilter = true; // default to true, and flip to false when we find our first negative result:

          if (Object.prototype.hasOwnProperty.call(panel, 'accountStatus')) {
            if (panel.accountStatus.indexOf(customer.status) === -1) {
              matchOnFilter = false;
            }
          }
          if (Object.prototype.hasOwnProperty.call(panel, 'serviceType')
            || Object.prototype.hasOwnProperty.call(panel, 'serviceStatus')) {
            const serviceTypes = panel.serviceType;
            const serviceStatuses = panel.serviceStatus;

            const serviceList = customer.services.map(s => ({
              type: s.type,
              status: s.status,
            }));
            if (serviceList && serviceList.length > 0) {
              // calculate intersection between the two lists
              let result = [];
              if (serviceTypes && serviceStatuses) {
                result = serviceList.filter(n => serviceTypes.includes(n.type) && serviceStatuses.includes(n.status));
              } else if (serviceTypes) {
                result = serviceList.filter(n => serviceTypes.includes(n.type));
              } else if (serviceStatuses) {
                result = serviceList.filter(n => serviceStatuses.includes(n.status));
              }
              // if we have no intersection, ie, user selected a service type that the customer does not have, then no match:
              if (!result || result.length === 0) {
                matchOnFilter = false;
              }
            } else {
              matchOnFilter = false;
            }
          }

          if (Object.prototype.hasOwnProperty.call(panel, 'purpose')) {
            if (panel.purpose.indexOf(customer.purpose) === -1) {
              matchOnFilter = false;
            }
          }

          if (Object.prototype.hasOwnProperty.call(panel, 'dealType')) {
            if (panel.dealType.indexOf(customer.dealType) === -1) {
              matchOnFilter = false;
            }
          }

          if (Object.prototype.hasOwnProperty.call(panel, 'billedBy')) {
            if (panel.billedBy.indexOf(customer.billedBy) === -1) {
              matchOnFilter = false;
            }
          }

          if (Object.prototype.hasOwnProperty.call(panel, 'companyId')) {
            if (panel.companyId.indexOf(customer.companyId) === -1) {
              matchOnFilter = false;
            }
          }

          if (Object.prototype.hasOwnProperty.call(panel, 'resellerPartnerId')) {
            if (panel.resellerPartnerId.indexOf(customer.resellerPartnerId) === -1) {
              matchOnFilter = false;
            }
          }

          if (Object.prototype.hasOwnProperty.call(panel, 'distributorPartnerId')) {
            if (panel.distributorPartnerId.indexOf(customer.distributorPartnerId) === -1) {
              matchOnFilter = false;
            }
          }

          if (Object.keys(panel)
            .some(el => el !== 'accountStatus' && el !== 'serviceType' && el !== 'serviceStatus' && el !== 'purpose' && el !== 'dealType' && el !== 'billedBy' && el !== 'companyId' && el !== 'resellerPartnerId' && el !== 'distributorPartnerId')) {
            const unsupportedKeys = Object.keys(panel)
              .filter(el => el !== 'accountStatus' && el !== 'serviceType' && el !== 'serviceStatus' && el !== 'purpose' && el !== 'dealType' && el !== 'billedBy' && el !== 'companyId' && el !== 'resellerPartnerId' && el !== 'distributorPartnerId');
            console.error('Unsupported Customer Filter Type: ', ...unsupportedKeys);
          }
        }

        // if both search text + filter is specified, then they both needs to be found for a customer to match:
        if (filterByText && filterByFilter) {
          if (matchOnText && matchOnFilter) {
            results.push(customer);
          }
        } else if (filterByText) {
          if (matchOnText) {
            results.push(customer);
          }
        } else if (filterByFilter) {
          if (matchOnFilter) {
            results.push(customer);
          }
        }
      }
    }
    return results;
  };

  const filteredCustomers = useMemo(() => filterCustomers(customerList, view, columns), [customerList, view, columns]);

  const originalCustomers = customerList?.map((el, i) => ({
    ...el,
    index: i,
  })) || [];

  const customers = sortCustomers(filteredCustomers)?.map((el, i) => ({
    ...el,
    index: i,
  })) || [];

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <Data
        data={originalCustomers}
        fill={true}
        overflow='inherit'
        properties={properties}
        view={view}
        onView={setView}
        total={originalCustomers.length}
        filteredTotal={customers.length}
      >
        <GLBMHeading
          title='Billing Accounts'
        />
        <Toolbar
          direction='row'
          justify='between'
          margin={{
            horizontal: 'small',
            vertical: 'small',
          }}
          fill='horizontal'
          flex={false}
        >
          <Box direction='row' pad={{ left: 'xxsmall' }} gap='xsmall' flex={false} key='filters'>
            <DataSearch width='medium' placeholder='Search' />
            <DataTableColumns options={options} drop={true} />
            <DataFilters layer={true} heading='Filter Billing Accounts' />
          </Box>
          <Box direction='row' gap='small' margin={{ right: 'medium' }} key='buttons'>
            <Button
              kind='toolbar'
              icon={<Refresh />}
              onClick={() => {
                refreshCustomers();
              }}
              a11yTitle='Refresh Customer List'
              id={IDUtil.getId('ListViewToolbarRefreshButton')}
              key='refreshBtn'
              label='Refresh'
              busy={isCustomersLoading}
            />
            {hasPermissions(pagePermissions.customers.actions.add) && (
              <Button
                kind='toolbar'
                icon={<Add />}
                onClick={addCustomer}
                a11yTitle='New Customer'
                id={IDUtil.getId('ListViewToolbarAddButton')}
                key='addBtn'
                label='Create'
              />
            )}
          </Box>
        </Toolbar>
        <Box
          id='allRowsNumber'
          direction='row'
          margin={{ horizontal: 'xxsmall' }}
          pad={{ horizontal: 'small' }}
          flex={false}
        >
          <DataSummary />
        </Box>
        <Box fill='vertical' overflow='auto'>
          <GLBMDataTable
            columns={columns}
            loading={isCustomersLoading}
            sortable={true}
            data={customers}
            searchText={view.search}
          />
        </Box>
        {renderToast()}
        {renderLayer()}
      </Data>
    </Main>
  );
};

CustomersListPage.contextTypes = {
  router: PropTypes.object,
};

export default CustomersListPage;
