// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, Footer } from 'grommet';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { usePSADataQuery } from '../../../core';
import ValidationDialog from '../../shared/dialogs/ValidationDialog';
import PSAProjectInfoPanel from './PSAProjectInfoPanel';

const getCustomerServiceTypes = ({ services }) => (services || []).map(({ type }) => type);

const getInitialPsaProjectServices = (isEdit, customer) => {
  if (isEdit) {
    return customer.psaProjectServices;
  }
  if (customer.psaNotRequired) {
    return [];
  }
  return getCustomerServiceTypes(customer);
};

const PSAProjectInfoDialog = ({
  onCancel = () => {},
  onSubmit = () => {},
  customer = {
    psaProject: {
      projectId: '',
      services: [],
    },
    psaNotRequired: false,
    psaProjectServices: [],
  },
  isAdmin = false,
  isEdit = false,
}) => {
  // State:
  const [psaProject, setPsaProject] = useState(customer.psaProject);
  const [psaNotRequired, setPsaNotRequired] = useState(customer.psaNotRequired);
  const [psaProjectId, setPsaProjectId] = useState(customer.psaProject ? customer.psaProject.projectId : '');
  const [psaProjectServices, setPsaProjectServices] = useState(getInitialPsaProjectServices(isEdit, customer));
  const [errors, setErrors] = useState({ errors: { psaProjectId: [] } });
  const [showValidation, setShowValidation] = useState(false);
  const [validations, setValidations] = useState([]);

  // create the query object to hold all the needed query for this components:
  const queryClient = useQueryClient();
  const query = usePSADataQuery(psaProjectId, {
    retry: false,
    enabled: false, // using button click to call query.refetch() instead...
  });

  useEffect(() => {
    if (query.isSuccess) {
      setPsaProject(query.data);
    } else if (query.isError) {
      console.error('psaProject.err', query.error);
      setPsaProject(undefined);
    }
  }, [query.isSuccess, query.isError, query.data, query.error]);

  // initialize:
  useEffect(() => {
    if (psaProjectId && psaProject) {
      queryClient.setQueryData(['GLBM:CUSTOMER-PSA-DATA', psaProjectId], psaProject);
    } else if (psaProjectId) {
      query.refetch();
    }
  }, []);

  /**
   * TODO This will need to change once we decide how we will do this..
   * @param callBackFn
   * @private
   */
  const performValidation = (callBackFn) => {
    let validationErrors = false;
    const validationsData = [];

    // if missing psa base project id:
    if (!psaProjectId) {
      errors.psaProjectId = ['Required'];
      validationErrors = true;
    }
    validationsData.push({
      severity: (!psaProjectId ? 'critical' : 'ok'),
      text: 'Base Project ID cannot be blank',
    });

    // if psa data not loaded:
    if (!query.data) {
      validationErrors = true;
    }
    validationsData.push({
      severity: (!query.data ? 'critical' : 'ok'),
      text: 'PSA Data must be populated',
    });

    // if missing services:
    if (!psaProjectServices || psaProjectServices.length === 0) {
      errors.psaProjectServices = ['Required'];
      validationErrors = true;
    }
    validationsData.push({
      severity: (!psaProjectServices || psaProjectServices.length === 0 ? 'critical' : 'ok'),
      text: 'At least one project service must be selected',
    });

    setShowValidation(validationErrors);
    setValidations(validationsData);

    if (validationErrors) {
      return;
    }

    // we have no validation issues, lets call the callback function:
    callBackFn();
  };

  const onSubmitFn = () => {
    const newCustomer = { ...customer };
    newCustomer.psaNotRequired = psaNotRequired;
    newCustomer.psaProject = !psaNotRequired ? psaProject : undefined;
    newCustomer.psaProjectServices = !psaNotRequired ? psaProjectServices : undefined;

    // apply this customer:
    onSubmit(newCustomer);

    // clear the query:
    queryClient.clear();
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={false}
      onEsc={() => {
        // query.clear();
        queryClient.clear();
        onCancel();
      }}
      onClickOutside={() => {
        // query.clear();
        queryClient.clear();
        onCancel();
      }}
      onClose={() => {
        // query.clear();
        queryClient.clear();
        onCancel();
      }}
      title={`${isEdit ? 'Edit' : 'Add'} Base Project ID`}
    >
      <Box size='large'>
        <Box
          pad='none'
          direction='column'
          fill='vertical'
        >
          <Box pad={{ horizontal: 'medium' }} flex={true} overflow='auto'>
            <PSAProjectInfoPanel
              psaProjectId={psaProjectId}
              setPsaProjectId={(id) => {
                queryClient.clear();
                setPsaProject(undefined);
                setPsaProjectId(id);
              }}
              psaProjectServices={psaProjectServices}
              setPsaProjectServices={setPsaProjectServices}
              psaNotRequired={psaNotRequired}
              setPsaNotRequired={setPsaNotRequired}
              errors={errors}
              setErrors={setErrors}
              queryError={query.error}
              psaProject={psaProject}
              isFetching={query.isFetching}
              isAdmin={isAdmin}
              refresh={query.refetch}
              showPsaPortalActivationDate={false}
            />
          </Box>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='between'>
            <Box justify='start' gap='small' direction='row'>
              <Button
                label='Apply'
                type='button'
                primary={true}
                onClick={query.isFetching ? undefined : () => {
                  if (psaNotRequired) {
                    onSubmitFn();
                  } else {
                    performValidation(onSubmitFn);
                  }
                }}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={() => {
                  // query.clear();
                  queryClient.clear();
                  onCancel();
                }}
              />
            </Box>
          </Footer>
        </Box>
      </Box>
      {showValidation && (
        <ValidationDialog
          title='Unable to apply PSA Project Info changes'
          subTitle='One or more of the following conditions failed:'
          hint='All of these conditions must pass before clicking the Apply button'
          cancelLabel='OK'
          onCancel={() => setShowValidation(false)}
          data={validations}
        />
      )}
    </GLBMLayer>
  );
};

PSAProjectInfoDialog.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  customer: PropTypes.shape({
    psaProject: PropTypes.shape({
      projectId: PropTypes.string,
      services: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
      })),
    }),
    psaNotRequired: PropTypes.bool,
    psaProjectServices: PropTypes.arrayOf(PropTypes.string),
  }),
  isAdmin: PropTypes.bool,
  isEdit: PropTypes.bool,
};

export default PSAProjectInfoDialog;
