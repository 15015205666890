// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import * as React from 'react';
import { Box, Tab, Tabs } from 'grommet';
import GLBMHeading from '../shared/component/GLBMHeading';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import ActionsList from './Actions/ActionsList';
import DataMappingListPage from './DataMapping/DataMappingListPage';
import WiseQuoteIdListPage from './WiseQuoteId/WiseQuoteIdListPage';

const PaygAdministrationPage = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const handleTabChange = (index) => {
    setActiveIndex(index);
  };
  const { hasPermissions } = usePermissionChecker();

  return (
    <Box direction='column' fill='vertical' overflow='hidden' data-testid='paygAdministration'>
      <GLBMHeading title='PAYG Administration' />
      <Box border='top' flex={true}>
        <Box flex={true} className='tabWrapper' margin={{ right: 'medium' }}>
          <Tabs
            justify='center'
            activeIndex={activeIndex}
            onActive={handleTabChange}
          >
            {hasPermissions(pagePermissions.payg.tabs.quotes) ? (
              <Tab title='WISE Quote IDs' a11yTitle='WISE Quote IDs' data-testid='WISE Quote IDs'>
                <WiseQuoteIdListPage />
              </Tab>
            ) : undefined}
            {hasPermissions(pagePermissions.payg.tabs.mappings) ? (
              <Tab title='Data Mapping' a11yTitle='Data Mapping' data-testid='Data Mapping'>
                <DataMappingListPage />
              </Tab>
            ) : undefined}
            {hasPermissions(pagePermissions.payg.tabs.actions) ? (
              <Tab title='Actions' data-testid='Actions'>
                <ActionsList />
              </Tab>
            ) : undefined}
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

PaygAdministrationPage.propTypes = {
};

export default PaygAdministrationPage;
