// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { Route, Routes } from 'react-router-dom';
import LoginCallbackPage from './components/authentication/LoginCallbackPage';
import LogoutCallbackPage from './components/authentication/LogoutCallbackPage';
import GlbmMainPage from './GlbmMainPage';

const GlbmRoutes = () => (
  <Routes>
    <Route path='/login' element={<LoginCallbackPage />} />
    <Route path='/login/callback' element={<LoginCallback />} />
    <Route path='/logout' element={<LogoutCallbackPage />} />
    <Route path='/*' element={<GlbmMainPage />} />
  </Routes>
);

export default GlbmRoutes;
