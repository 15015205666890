// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Case from 'case';
import pluralize from 'pluralize';

import { Box, Button, Footer, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';
import GLBMLayer from '../../shared/component/GLBMLayer';
import IDUtil from '../../shared/util/IDUtil';
import { useCustomerServicesUpdateMutate } from '../../../core';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import GLBMSaving from '../../shared/component/GLBMSaving';
import GLBMHeading from '../../shared/component/GLBMHeading';
import { StatusIcon } from '../../shared/component/StatusIcon';
import SaveOptions from './SaveOptions';
import { useServiceEditorContext } from './contexts/ServiceEditorContext';

const ServiceSaveDialog = (props) => {
  const {
    serviceId,
  } = props;

  const [serviceEditor] = useServiceEditorContext();
  const { customer, dirtyEquipment, dirtyComponents } = serviceEditor;
  const serviceType = useMemo(() => ServiceTypeStore.getService(serviceId), [serviceId]);

  const {
    options: mutateOptions,
    mappings: mutateMappings,
    component: mutateComponents,
    partnerRates: mutatePartnerRates,
    rates: mutateRates,
    mutate: saveService,
  } = useCustomerServicesUpdateMutate(customer?.id, serviceId, dirtyEquipment, dirtyComponents, {
    onSuccess: () => {},
    onError: () => {},
  });

  const isSaving = useMemo(
    () => mutateOptions?.isPending
      || mutateMappings?.isPending
      || mutateRates?.isPending
      || mutateComponents?.isPending
      || mutatePartnerRates?.isPending,
    [mutateOptions, mutateMappings, mutateRates, mutateComponents, mutatePartnerRates],
  );

  // when this component is showing - save right away:
  useEffect(() => {
    saveService(serviceEditor);
  }, []);

  const renderSaveComponent = (key, query) => {
    let title;

    // eslint-disable-next-line default-case
    switch (key) {
      case SaveOptions.OPTIONS:
        title = 'Options';
        break;
      case SaveOptions.EQUIPMENT:
        title = 'Resources';
        break;
      case SaveOptions.COMPONENT:
        title = pluralize.plural(Case.capital(serviceType.component));
        break;
      case SaveOptions.RATES:
        title = 'Rates';
        break;
    }

    return (
      <Box key={key}>
        <GLBMHeading level={3} title={title} pad='none' />
        <Box pad={{ bottom: 'small' }}>
          {query ? (
            <GLBMSaving saving={query.isPending} saved={query.isSuccess} error={query.isError && (query?.error?.response.data || query?.error?.message)} />
          ) : (
            <Box direction='row' gap='small' pad={{ left: 'medium' }} align='center'>
              <StatusIcon value='disabled' />
              <Text>N/A</Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderSaveContent = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [{ permissions }] = useServiceEditorContext();

    const content = [];

    if (permissions.canEditOptions && mutateOptions) {
      content.push(renderSaveComponent(SaveOptions.OPTIONS, mutateOptions));
    }
    if (permissions.canEditMappings) {
      content.push(renderSaveComponent(SaveOptions.EQUIPMENT, mutateMappings));
      if (serviceType.component) {
        content.push(renderSaveComponent(SaveOptions.COMPONENT, mutateComponents));
      }
    }
    if (permissions.canEditRates) {
      content.push(renderSaveComponent(SaveOptions.RATES, mutateRates));
    }
    if (permissions.canMarkupRates) {
      content.push(renderSaveComponent(SaveOptions.RATES, mutatePartnerRates));
    }

    return content;
  };

  const getHeading = () => {
    const serviceDef = ServiceTypeStore.getService(props.serviceId);
    if (serviceDef) {
      return `Saving ${serviceDef.label}`;
    }
    return 'Saving';
  };

  return (
    <GLBMLayer
      position='right'
      submitLabel='OK'
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      onClose={props.onClose}
      full='vertical'
      title={getHeading()}
    >
      <Box flex={true} overflow={{ vertical: 'auto' }}>
        <Box flex={false} pad='medium' gap='xsmall'>
          {renderSaveContent()}
        </Box>
      </Box>
      <Footer pad='medium'>
        <Button
          label='Saved'
          id={IDUtil.getId('ServiceSaveDialogOKButton')}
          primary={true}
          icon={<Checkmark />}
          onClick={props.onClose}
          busy={isSaving}
        />
      </Footer>
    </GLBMLayer>
  );
};

ServiceSaveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceId: PropTypes.string.isRequired,
};

export default ServiceSaveDialog;
