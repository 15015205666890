// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Main,
} from 'grommet';
import {
  Add, Refresh,
} from 'grommet-icons';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import GLBMSearch from '../../shared/component/GLBMSearch';
import IDUtil from '../../shared/util/IDUtil';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import { SearchTextContextProvider } from '../../shared/component/HighlightUsingContext';
import { useStateWithSessionStorage } from '../../shared/hooks';
import { useMockDataListQuery } from '../../../core';
import { filterDataByText, useColumns } from './demoUtils';

const defaultSort = {
  direction: 'asc',
  external: true,
  property: 'name',
};

const ListMockData = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const {
    data, refetch, isFetching,
  } = useMockDataListQuery();
  const [sort, onSort] = useStateWithSessionStorage('admin-demo-data-sort', defaultSort);
  const { columns, layers } = useColumns();

  const filteredData = useMemo(() => {
    if (!searchText) return data?.map((el, index) => ({ ...el, index })) || [];
    return filterDataByText(data, searchText);
  }, [data, searchText]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        search={(
          <GLBMSearch
            value={searchText}
            onChange={e => setSearchText(e)}
            data-testid='ListViewToolbarSearchInput'
          />
        )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              refetch();
            }}
            a11yTitle='Refresh Demo Data List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            data-testid='ListViewToolbarRefreshButton'
            key='refreshBtn'
            label='Refresh'
            busy={isFetching}
          />,
          <Button
            kind='toolbar'
            icon={<Add />}
            onClick={() => navigate('/administration/mock/new')}
            a11yTitle='New Data'
            id={IDUtil.getId('ListViewToolbarAddButton')}
            data-testid='ListViewToolbarAddButton'
            key='addBtn'
            label='Create'
          />,
        ]}
      />
      <GLBMDataSummary total={data?.length} filtered={filteredData?.length} />
      <SearchTextContextProvider searchText={searchText}>
        <GLBMDataTable
          primaryKey='id'
          columns={columns}
          data={filteredData}
          onSort={onSort}
          sort={sort}
          total={data?.length}
          loading={isFetching}
        />
      </SearchTextContextProvider>
      {layers}
    </Main>
  );
};

export default ListMockData;
