// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useContext, useMemo, useState } from 'react';
import { Anchor, Box, Tip } from 'grommet';
import { StatusWarningSmall } from 'grommet-icons';
import moment from 'moment/moment';
import Highlight from 'react-highlighter';
import { ApiContext } from '../../../AppContext';
import { useDeletePendingFileMutation } from '../../../core';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import IDUtil from '../../shared/util/IDUtil';
import ServiceTypeStore from '../../stores/ServiceTypeStore';

export const useColumns = (searchText) => {
  const apiContext = useContext(ApiContext);
  const [dataToDelete, setDataToDelete] = useState();

  const { mutate: deletePendingFile } = useDeletePendingFileMutation({
    onSuccess: () => {
      setDataToDelete(undefined);
    },
  });

  const columns = useMemo(
    () => [{
      property: 'fileName',
      header: 'Name',
      render: ({
                 fileName,
                 lastModified,
                 warning,
               }) => (
                 <Box direction='row' align='center'>
                   <Box width={{ min: '24px' }}>
                     {warning && (
                     <Tip
                       content={(
                         <Box
                           pad='small'
                           width='medium'
                         >
                           {`File has not been processed for ${moment.utc()
                      .diff(moment.utc(lastModified), 'hours')} hours, check sync jobs for more information on why.`}
                         </Box>
                )}
                     >
                       <StatusWarningSmall color='status-warning' />
                     </Tip>
            )}
                   </Box>
                   <Highlight search={searchText}>{fileName}</Highlight>
                 </Box>
      ),
      primary: true,
    }, {
      property: 'serviceType',
      header: 'Service Type',
      render: ({ serviceType }) => ServiceTypeStore.getService(serviceType)?.label || serviceType || ' - ',
    }, {
      property: 'accountId',
      header: 'Billing Account',
    }, {
      property: 'sizeInBytes',
      header: 'Size In Bytes',
      align: 'end',
    }, {
      property: 'lastModified',
      header: 'Last Modified',
      dataCallback: ({ lastModified }) => moment.utc(lastModified)
        .format('x'),
      render: ({ lastModified }) => moment.utc(lastModified)
        .format('lll'),
    },
      {
        property: 'actions',
        header: 'Actions',
        render: datum => (
          <Box direction='row' gap='small'>
            <Anchor
              key='download'
              id={IDUtil.getId('PendingFilesDownload', datum.fileName)}
              onClick={() => {
                const path = apiContext('administration.pendingFiles.download.path', { filename: datum.fileName });
                const windowTab = window.open(path, '_blank');
                if (windowTab) {
                  windowTab.focus();
                }
              }}
            >
              Download
            </Anchor>
            <Anchor
              key='delete'
              id={IDUtil.getId('PendingFilesDelete', datum.fileName)}
              onClick={() => {
                setDataToDelete(datum);
              }}
            >
              Delete
            </Anchor>
          </Box>
        ),
        sortable: false,
        size: '250px',
      }],
    [searchText],
  );

  let layers;
  if (dataToDelete) {
    layers = (
      <ConfirmationDialog
        data={dataToDelete}
        title='Are you sure?'
        submitLabel='Yes, delete the Pending File'
        cancelLabel='Cancel'
        text='Deleting will permanently erase the pending file. Please ensure this is what you are intending before choosing "Yes" below.'
        onClose={() => setDataToDelete(undefined)}
        onChange={() => deletePendingFile(dataToDelete?.fileName)}
        details={(
          <Box margin={{ top: 'small' }}>
            <GLBMNameValueList
              title='Selected pending file'
              data={[
                {
                  label: 'Name',
                  value: dataToDelete?.fileName,
                  status: dataToDelete?.warning ? 'warning' : '',
                },
                {
                  label: 'Service Type',
                  value: ServiceTypeStore.getService(dataToDelete?.serviceType)?.label || dataToDelete?.serviceType || ' - ',
                },
                {
                  label: 'Billing Account',
                  value: dataToDelete?.accountId,
                },
                {
                  label: 'Size In Bytes',
                  value: dataToDelete?.sizeInBytes.toLocaleString(),
                },
                {
                  label: 'Last Modified',
                  value: moment.utc(dataToDelete?.lastModified)
                    .format('lll'),
                },
              ]}
            />
          </Box>
        )}
      />
    );
  }

  return {
    columns,
    layers,
  };
};

export const useFilterProperties = () => useMemo(() => ({
  accountId: {
    label: 'Billing Account',
    sort: true,
    filter: true,
    search: false,
  },
  fileName: {
    label: 'Name',
    sort: true,
    filter: false,
    search: true,
  },
  serviceType: {
    label: 'Service Type',
    sort: true,
    filter: true,
    options: ServiceTypeStore.getServices()
      .map(({
              label,
              type,
            }) => ({
        label: label || type,
        value: type,
        id: IDUtil.getId(`Service${type}`),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    search: false,
  },
  sizeInBytes: {
    label: 'Size In Bytes',
    sort: true,
    filter: false,
    search: false,
  },
}), []);
