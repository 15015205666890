// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP


import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Box, Button, Main, Text
} from 'grommet';
import { Refresh } from 'grommet-icons';
import moment from 'moment';
import Highlight from 'react-highlighter';
import { useNavigate } from 'react-router-dom';
import { useCustomersQuery, useGetJobsListQuery } from '../../core';
import CustomerSelector from '../shared/component/CustomerSelector';
import Duration from '../shared/component/Duration';
import FilterControl from '../shared/component/FilterControl';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMSearch from '../shared/component/GLBMSearch';
import {
  SearchTextContextProvider,
} from '../shared/component/HighlightUsingContext';
import OptionalAnchor from '../shared/component/OptionalAnchor';
import { StatusIcon } from '../shared/component/StatusIcon';
import { pagePermissions } from '../shared/constants/Permissions';
import IDUtil from '../shared/util/IDUtil';
import { useStateWithSessionStorage } from '../shared/hooks';
import JobsFilter from './JobsFilter';

const jobTypeMap = {
  charge: 'Charging',
  fill: 'Filling',
  load: 'Loading',
  migrate: 'Migrating',
  other: 'Other',
  sync: 'Syncing',
  transform: 'Transforming',
};

const jobStatusMap = {
  processing: 'Processing',
  failed: 'Failed',
  succeeded: 'Succeeded',
  terminated: 'Terminated',
  aborted: 'Aborted',
};

const JobsListPage = () => {
  const navigate = useNavigate();

  const [filterActive, setFilterActive] = useState(false);
  const [filters, setFilters] = useStateWithSessionStorage('jobs-list-filters', {
    customerId: '',
    list: { searchText: '', sort: { property: 'created', direction: 'desc', external: true } },
    panel: {
      from: moment.utc().startOf('day').format('YYYY-MM-DD'),
      to: moment.utc().endOf('day').format('YYYY-MM-DD'),
    },
  });
  const [customerList, setCustomerList] = useState([]);
  const [customerMap, setCustomerMap] = useState({});
  const [searchText, setSearchText] = useState('');

  const {
    data: customerData,
  } = useCustomersQuery();

  const {
    data: jobsList,
    refetch: refreshJobsList,
    isFetching: jobsLoading,
  } = useGetJobsListQuery(
    filters?.panel.to,
    filters?.panel.from,
    filters?.panel.type,
    filters?.panel.status,
    filters?.customerId,
  );

  useEffect(() => {
    const customers = customerData || [];
    const newCustomerMap = {};
    const newCustomerList = [];
    if (customers) {
      // sort them:
      customers.sort((a, b) => {
        const aTitle = (a.name || a.id);
        const bTitle = (b.name || b.id);
        if (aTitle.toLowerCase() <= bTitle.toLowerCase()) {
          return -1;
        }
        return 1;
      });
    }
    customers.forEach((el) => {
      const id = [];
      if (el.name) {
        id.push(
          <small key={el.id}>
            <em className='secondary'>
              &nbsp;&nbsp;&nbsp;(
              {el.id}
              )
            </em>
          </small>,
        );
      }
      const item = {
        label: (
          <Box direction='row' align='center' justify='between' id={IDUtil.getId(`BillingAccounts_${el.id}`)}>
            <span>{el.name || el.id}</span>
            {id}
          </Box>),
        sub: el.name || el.id,
        value: el.id,
      };
      newCustomerList.push(item);
      newCustomerMap[el.id] = (el.name || el.id);
    });
    setCustomerMap(newCustomerMap);
    setCustomerList(newCustomerList);
  }, [customerData]);

  const onFilterActivate = () => {
    setFilterActive(true);
  };
  const onFilterDeactivate = () => {
    setFilterActive(false);
  };
  const onSelectCustomer = (option) => {
    const updatedFilters = { ...filters, customerId: option.value && option.value !== 'All' ? option.value : undefined };
    setFilters(updatedFilters);
  };

  const onUpdateFilter = (filterPanel) => {
    const updatedFilters = { ...filters, panel: filterPanel };
    setFilters(updatedFilters);
    setFilterActive(false);
  };

  const onSearchChange = useCallback((value) => {
    const search = value;
    const newList = { ...filters?.list };
    newList.searchText = search;
    const newFilters = { ...filters, list: newList };
    setSearchText(search);
    setFilters(newFilters);
  }, [filters]);

  const renderJobStatus = (status) => {
    switch (status) {
      case 'processing':
        return (
          <Box key={1} direction='row' gap='small' align='center'>
            <StatusIcon
              value='unknown'
              size='small'
            />
            <span>{jobStatusMap[status]}</span>
          </Box>
        );
      case 'failed':
      case 'terminated':
      case 'aborted':
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon
              value='critical'
              size='small'
            />
            <span>{jobStatusMap[status]}</span>
          </Box>
        );
      case 'succeeded':
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon
              value='ok'
              size='small'
            />
            <span>{jobStatusMap[status]}</span>
          </Box>
        );
      default:
        return status;
    }
  };

  const columns = useMemo(() => [
    {
      property: 'status',
      header: 'Status',
      render: datum => <Text id={IDUtil.getId('Status', datum.index)}>{renderJobStatus(datum.status)}</Text>,
      size: 'xsmall',
    },
    {
      property: 'type',
      header: 'Type',
      render: ({ type, index }) => <Text id={IDUtil.getId('Type', index)}>{jobTypeMap[type] || type}</Text>,
      size: 'xsmall',
    },
    {
      property: 'created',
      header: 'Start Time (UTC)',
      dataCallback: ({ created }) => moment.utc(created).format('x'),
      render: ({ created, index }) => <Text id={IDUtil.getId('StartTime', index)}>{moment.utc(created).format('lll')}</Text>,
      size: 'small',
    },
    {
      property: 'elapsed',
      header: 'Duration',
      render: ({ elapsed, index }) => <Text id={IDUtil.getId('Duration', index)}>{(elapsed ? <Highlight search={searchText}><Duration value={elapsed} /></Highlight> : '-')}</Text>,
      size: 'xsmall',
    }, {
      property: 'accountId',
      header: 'Billing Account',
      render: ({ accountId, index }) => (
        <OptionalAnchor
          onClick={() => navigate(`/customers/${accountId.trim()}`)}
          permissions={pagePermissions.customers.view.page}
          highlight={searchText}
          id={IDUtil.getId('AccountId', index)}
          disabled={!accountId}
        >
          {customerMap[accountId?.trim()] || '-'}
        </OptionalAnchor>
      ),
      size: 'medium',
    },
    {
      property: 'runBy',
      header: 'User',
      size: 'small',
      render: ({ runBy, index }) => <Text id={IDUtil.getId('User', index)}>{runBy}</Text>,
    }, {
      property: 'id',
      header: 'Job ID',
      render: ({ id, index }) => (
        <Text id={IDUtil.getId('JobId', index)}>
          <Highlight search={searchText}>
            {id}
          </Highlight>
        </Text>
      ),
      primary: true,
    },
  ], [customerMap, navigate, searchText]);

  const onSortColumn = (sort) => {
    const updatedFilters = { ...filters, list: { ...filters.list, sort } };
    setFilters(updatedFilters);
  };

  const filteredJobs = useMemo(() => {
    if (filters) {
      if (!searchText) {
        return jobsList;
      }
      return jobsList?.filter((job) => {
        const sub = customerList?.find(el => el.value === job.accountId)?.sub;
        const jobSearchText = `${job.id}${job.runBy}${job.accountId}${sub}${jobStatusMap[job.status]}${jobTypeMap[job.type]}`;
        return jobSearchText.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return [];
  }, [filters, searchText, jobsList, customerList]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Data Processing Jobs:'
        search={[
          <CustomerSelector
            key='customerSelector'
            enableAllOption={true}
            onCustomerSelected={value => onSelectCustomer({ value })}
          />,
          <GLBMSearch
            key='searchText'
            value={searchText}
            onChange={onSearchChange}
          />,
          <FilterControl
            key='filterCntrl'
            filters={filters?.panel}
            onFilter={onFilterActivate}
            onClear={() => {
              const newFilterPanel = { ...filters.panel };
              delete newFilterPanel.type;
              delete newFilterPanel.status;
              onUpdateFilter(newFilterPanel);
            }}
            ignoreProps={['from', 'to']}
          />,
        ]}
        actions={[<Button
          kind='toolbar'
          icon={<Refresh />}
          onClick={() => {
            refreshJobsList();
          }}
          a11yTitle='Refresh Jobs List'
          id={IDUtil.getId('ListViewToolbarRefreshButton')}
          key='refreshBtn'
          label='Refresh'
          busy={jobsLoading}
        />]}
      />
      <GLBMDataSummary total={jobsList ? jobsList.length : 0} filtered={jobsLoading ? 0 : filteredJobs?.length} />
      <Box flex={true} fill={true}>
        <SearchTextContextProvider searchText={searchText}>
          <GLBMDataTable
            searchText={searchText}
            data={jobsLoading ? [] : filteredJobs?.map((el, i) => ({ ...el, index: i }))}
            columns={columns}
            loading={jobsLoading}
            total={filteredJobs?.length}
            sort={filters?.list?.sort}
            onSort={newSort => onSortColumn(newSort)}
            primaryKey='id'
          />
        </SearchTextContextProvider>

        {filterActive && (
          <JobsFilter
            onClose={onFilterDeactivate}
            filter={filters?.panel}
            onChange={onUpdateFilter}
          />
        )}
      </Box>
    </Main>
  );
};

export default JobsListPage;
