// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import StatusLabel from '../shared/component/StatusLabel';
import IDUtil from '../shared/util/IDUtil';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import { BillingType } from '../shared/constants/BillingType';
import { PurposeType } from '../shared/constants/PurposeType';
import { DealType } from '../shared/constants/DealType';
import { BilledByType } from '../shared/constants/BilledByType';
import { ServiceStatusType } from '../shared/constants/ServiceStatusType';
import { useCompaniesQuery, usePartnersQuery } from '../../core';

export const usePartnerName = () => {
    const {
        data: partnersList,
    } = usePartnersQuery();
    return partnerId => partnersList?.find(({ id }) => id === partnerId)?.name;
};

export const useFilterProperties = () => {
    const {
        data: companyList,
    } = useCompaniesQuery();

    const {
        data: partnersList,
    } = usePartnersQuery();

    return ({
        name: {
            label: 'Billing Account Name',
            key: 'name',
            filter: false
        },
        accountStatus: {
            label: 'Billing Account Status',
            key: 'status',
            options: BillingType.enumValues.map(({
                enumKey,
                label,
                severity,
                order,
            }) => ({
                label: <StatusLabel value={severity} label={label} key={label} />,
                value: enumKey,
                id: IDUtil.getId(`AccountStatus${enumKey}`),
                order,
            }))
                .sort((a, b) => a.order - b.order),
            filter: true,
        },
        purpose: {
            label: 'Billing Account Purpose',
            key: 'purpose',
            options: PurposeType.enumValues.map(({
                enumKey,
                label,
            }) => ({
                label,
                value: enumKey,
                id: IDUtil.getId(`Purpose${label}`),
            }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            filter: true,
        },
        dealType: {
            label: 'Billing Account Deal Type',
            key: 'type',
            options: DealType.enumValues.map(({
                enumKey,
                label,
            }) => ({
                label,
                value: enumKey,
                id: IDUtil.getId(`DealType${enumKey}`),
            }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            filter: true
        },
        billedBy: {
            label: 'Billing Account Billed By',
            key: 'billedBy',
            options: BilledByType.enumValues.map(({
                enumKey,
                label,
            }) => ({
                label,
                value: enumKey,
                id: IDUtil.getId(`BilledByType${enumKey}`),
            }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            filter: true
        },
        serviceStatus: {
            label: 'Service Status',
            key: 'serviceStatus',
            options: ServiceStatusType.enumValues.map(({
                enumKey,
                label,
                severity,
            }) => ({
                label: <StatusLabel value={severity} label={label} key={`service-${severity}`} />,
                value: enumKey,
                id: IDUtil.getId(`ServiceStatus${enumKey}`),
            })),
            filter: true
        },
        companyId: {
            label: 'Company',
            key: 'companyId',
            options: companyList?.sort((a, b) => a.name?.localeCompare(b.name))
                .map(({
                    id,
                    name,
                }) => ({
                    label: name,
                    value: id,
                    id: IDUtil.getId(`Company${id}`),
                })),
            filter: !!companyList?.length
        },
        serviceType: {
            label: 'Service',
            key: 'serviceType',
            options: ServiceTypeStore.getServices()
                .map(({
                    label,
                    type,
                }) => ({
                    label: label || type,
                    value: type,
                    id: IDUtil.getId(`Service${type}`),
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            filter: true,
        },
        distributorPartnerId: {
            label: 'Distributor',
            key: 'distributorPartnerId',
            options: partnersList?.sort((a, b) => a.name?.localeCompare(b.name))
                .map(({
                    id,
                    name,
                }) => ({
                    label: name,
                    value: id,
                    id: IDUtil.getId(`Distributor${id}`),
                })),
            filter: !!partnersList?.length
        },
        resellerPartnerId: {
            label: 'Reseller',
            key: 'resellerPartnerId',
            options: partnersList?.sort((a, b) => a.name?.localeCompare(b.name))
                .map(({
                    id,
                    name,
                }) => ({
                    label: name,
                    value: id,
                    id: IDUtil.getId(`Reseller${id}`),
                })),
            filter: !!partnersList?.length
        },
    });
};
