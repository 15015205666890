// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  Box,
  Button,
  Footer,
  Select,
  Text,
} from 'grommet';
import { useAuditDefinitionsQuery } from '../../core';
import DateRange from '../shared/component/DateRange';
import AutocompleteSelect from '../shared/component/AutocompleteSelect';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import GLBMLayer from '../shared/component/GLBMLayer';
import CustomerSelector from '../shared/component/CustomerSelector';
import StatusLabel from '../shared/component/StatusLabel';
import FilterMultiSelect from '../shared/component/FilterMultiSelect';
import {
  useAuditTypeMap,
} from './handler';

const convertToEvent = value => ({ option: { value: value !== 'All' ? value : '' } });

const AuditFilter = ({
  filter: initialFilter = {},
  users = [],
  onChange,
  onClose,
}) => {
  const [filter, setFilter] = useState(initialFilter);

  // fetch audit definitions:
  const {
    data: auditDefinitions,
    isSuccess: isAuditDefinitionsFetched,
  } = useAuditDefinitionsQuery();

  // convert the audit definitions to a map:
  const resourceTypeMap = useAuditTypeMap(isAuditDefinitionsFetched ? auditDefinitions?.resources : []);
  const actionTypeMap = useAuditTypeMap(isAuditDefinitionsFetched ? auditDefinitions?.actions : []);

  const _onChangeAuditFilter = useCallback((name, isMultiple, event) => {
    setFilter((prevFilter) => {
      const newFilter = cloneDeep(prevFilter);
      if (isMultiple) {
        if (event.value.length === 0 || event.value[event.value.length - 1] === 'all') {
          delete newFilter[name];
        } else {
          newFilter[name] = event.value.filter(el => el !== 'all');
        }
      } else if (!event?.option?.value) {
        delete newFilter[name];
      } else {
        newFilter[name] = event.option.value;
        if (newFilter[name].length === 0) {
          delete newFilter[name];
        }
      }
      if (isEqual(prevFilter, newFilter)) {
        return prevFilter;
      }
      return newFilter;
    });
  }, []);

  const _setFromDate = useCallback((value) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      from: value,
    }));
  }, []);

  const _setToDate = useCallback((value) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      to: value,
    }));
  }, []);

  const _onSubmit = useCallback(() => {
    onChange(filter);
  }, [onChange, filter]);

  const _onClear = useCallback(() => {
    setFilter({
      from: moment().utc().subtract(3, 'd').format('YYYY-MM-DD'),
      to: moment().utc().format('YYYY-MM-DD'),
    });
  }, []);

  const _renderCustomerOptions = useCallback(() => (
    <CustomerSelector
      clear={true}
      initialSelection={filter?.accountId}
      placeholder='Select'
      multiple={false}
      enableAllOption={false}
      onCustomerSelected={value => _onChangeAuditFilter('accountId', false, convertToEvent(value))}
      plain={true}
      allowNoSelection={true}
    />
  ), [_onChangeAuditFilter, filter]);

  const _renderUserOptions = useCallback(() => {
    const options = [];
    const myUsers = Object.values(users).sort((a, b) => a.name.localeCompare(b.name));
    myUsers.forEach((c) => {
      const id = [];
      if (c.id !== c.name) {
        id.push(<small key={c.id}><em className='secondary'>{c.email}</em></small>);
      }

      options.push({
        label: (
          <Box direction='row' gap='small' align='center' style={{ width: '100%', minWidth: '330px' }}>
            <Box flex={true} className='ellipsis'>{c.name ? c.name : '-'}</Box>
            <Box>{id}</Box>
          </Box>),
        sub: c.email,
        value: c.id,
      });
    });

    let values;
    if (filter?.userId) {
      // const c = this.state.users[filter.userId];
      const c = options.filter(option => option.value === filter.userId);
      if (c) {
        [values] = c;
      } else {
        console.error('unable to find user with id', filter.userId);
      }
    }

    return (
      <AutocompleteSelect
        inline={Object.keys(myUsers).length < 10}
        style={{ width: '100%' }}
        multiple={false}
        value={values?.value}
        clear={true}
        placeholder='Select'
        options={options}
        onChange={event => _onChangeAuditFilter('userId', false, event)}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        valueLabel={values?.label && (
          <Box style={{ padding: '5px 11px' }}>
            <Text>{values?.label}</Text>
          </Box>
        )}
        plain={true}
      />
    );
  }, [_onChangeAuditFilter, filter, users]);

  const actionOptions = useMemo(() => Object.entries(actionTypeMap).map(([key, entity]) => ({
    label: entity.displayName,
    value: key,
  })), [actionTypeMap]);

  const _renderActionOptions = useCallback(() => {
    return (
      <FilterMultiSelect
        label='Action'
        labelKey='label'
        value={filter.action ? filter.action : []}
        options={actionOptions}
        onChange={event => _onChangeAuditFilter('action', true, event)}
      />
    );
  }, [_onChangeAuditFilter, filter.action, actionTypeMap]);

  const resourceOptions = useMemo(() => Object.entries(resourceTypeMap).map(([key, entity]) => ({
    label: entity.displayName,
    value: key,
  })), [resourceTypeMap]);

  const _renderResourceOptions = useCallback(() => {
    return (
      <FilterMultiSelect
        label='Resource'
        labelKey='label'
        value={filter.resource ? filter.resource : []}
        options={resourceOptions}
        onChange={event => _onChangeAuditFilter('resource', true, event)}
      />
    );
  }, [_onChangeAuditFilter, filter.resource, resourceOptions]);

  const _renderStatusOption = useCallback((severity, label, subLabel) => (
    <Box gap='small' direction='row' pad={{ horizontal: 'none' }} align='center' style={{ width: '100%' }}>
      <Box><StatusLabel value={severity} label={label} /></Box>
      <Box><small><em className='secondary'>{subLabel}</em></small></Box>
    </Box>
  ), []);

  const _renderStatusOptions = useCallback(() => {
    const options = [
      { label: (_renderStatusOption('ok', 'Success', '2xx - 3xx')), value: 'SUCCESS' },
      { label: (_renderStatusOption('warning', 'Warning', '4xx')), value: 'WARNING' },
      { label: (_renderStatusOption('critical', 'Error', '5xx')), value: 'ERROR' },
    ];

    let value;
    if (filter?.status) {
      const c = options.filter(option => option.value === filter.status);
      if (c) {
        [value] = c;
      } else {
        console.error('unable to find customer with status', filter.status);
      }
    } else {
      value = undefined;
    }

    return (
      <Select
        multiple={false}
        value={value?.value}
        placeholder='Select'
        clear={true}
        options={options}
        onChange={event => _onChangeAuditFilter('status', false, event)}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        valueLabel={value?.label && (
          <Box style={{ padding: '5px 11px' }}>
            <Text>{value?.label}</Text>
          </Box>
        )}
      />
    );
  }, [_onChangeAuditFilter, _renderStatusOption, filter]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={false}
      a11yTitle='Audit Filter'
      style={{ minWidth: '400px' }}
      onClose={onClose}
      title='Filter Audit Logs'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
      >
        <Box flex={true} overflow='auto'>
          <GLBMFilterPropertyBox label='Log Period (UTC)' contentProps={{ pad: { horizontal: 'small' } }} required={true}>
            <DateRange filter={filter} setFromDate={_setFromDate} setToDate={_setToDate} />
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Status'>
            {_renderStatusOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Billing Account'>
            {_renderCustomerOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='User'>
            {_renderUserOptions()}
          </GLBMFilterPropertyBox>
          {_renderActionOptions()}
          {_renderResourceOptions()}
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              primary={true}
              onClick={_onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            onClick={_onClear}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

AuditFilter.propTypes = {
  filter: PropTypes.object,
  users: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AuditFilter;
