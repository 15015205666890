// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Data,
  DataFilters,
  DataSearch,
  DataSummary,
  Main,
  Menu,
  Text,
  Toolbar,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import parser from 'cron-parser';
import { filter } from 'grommet/components/Data/filter';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import IDUtil from '../../shared/util/IDUtil';
import { isDev } from '../../shared/environment/EnvironmentUtil';
import {
  useGetPendingFilesQuery,
  useGetSchedulePendingFilesQuery,
  useSyncScheduleMutation,
} from '../../../core';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import {
  usePermissionChecker,
  useStateWithSessionStorage,
} from '../../shared/hooks';
import { insertIf } from '../../shared/util/BasicUtil';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import { pagePermissions } from '../../shared/constants/Permissions';
import { useColumns, useFilterProperties } from './handler';
import PendingFilesBulkDeleteConfirmationDialog
  from './dialogs/PendingFilesBulkDeleteConfirmationDialog';
import PendingFilesBulkDownloadConfirmationDialog
  from './dialogs/PendingFilesBulkDownloadConfirmationDialog';

const PendingFiles = () => {
  const { hasPermissions } = usePermissionChecker();
  const canReadPendingSchedule = hasPermissions(pagePermissions.pendingFiles.read);
  const canRunPendingSchedule = hasPermissions(pagePermissions.pendingFiles.run);
  const canDeletePendingFiles = hasPermissions(pagePermissions.pendingFiles.actions.delete);
  const properties = useFilterProperties();

  const [data, setData] = useState([]);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [layer, setLayer] = useState('');
  const [select, setSelected] = React.useState([]);
  const [view, setView] = useStateWithSessionStorage('pending-files-filters', {
    search: '',
    properties: {},
    sort: {
      direction: 'asc',
      property: 'fileName',
    },
  });

  const {
    data: pendingFilesData,
    refetch: refreshPendingFiles,
    isFetching: loadingPendingFiles,
    isSuccess: pendingFilesSuccess,
  } = useGetPendingFilesQuery();

  const {
    data: cronResults,
  } = useGetSchedulePendingFilesQuery();

  const onLayerClose = () => {
    setLayer('');
  };
  const {
    mutate: updatePendingFiles,
  } = useSyncScheduleMutation({
    onSuccess: () => onLayerClose(),
  });

  const list = useMemo(() => (pendingFilesData ? pendingFilesData.map((el, i) => ({
    ...el,
    index: i,
    warning: moment.utc().diff(moment.utc(el.lastModified), 'hours') > 3,
  })) : []), [pendingFilesData]);

  useEffect(() => {
    if (pendingFilesSuccess) {
      const response = filter(list, view, properties);
      setData(response.data);
      setFilteredTotal(response.filteredTotal);
    }
  }, [pendingFilesSuccess, list, view, properties]);

  const {
    columns,
    layers,
  } = useColumns(view.search);

  let intervals;
  try {
    if (cronResults) {
      const interval = parser.parseExpression(cronResults.cron);
      intervals = interval.next()
        .toString();
    }
  } catch (err) {
    console.error(err);
  }
  const renderLayer = () => {
    if (layer === 'confirmProcessPendingFiles') {
      return (
        <ConfirmationDialog
          title='Confirm Processing Pending Files'
          submitLabel='Confirm'
          cancelLabel='Cancel'
          text='Are you sure you want to Process the Pending Files?'
          onClose={onLayerClose}
          onChange={updatePendingFiles}
        />
      );
    } else if (layer === 'confirmBulkDownload') {
      const { search,
        properties: {
          accountId,
          serviceType
        } = {} } = view;
      return (
        <PendingFilesBulkDownloadConfirmationDialog
          search={search}
          accountId={accountId}
          serviceType={serviceType}
          onClose={onLayerClose}
        />
      );
    } else if (layer === 'bulkDelete') {
      // create a list of the selected files from list based on 'select':
      const selectedFiles = list.filter(({ fileName }) => select.includes(fileName));
      return (
        <PendingFilesBulkDeleteConfirmationDialog
          onClose={onLayerClose}
          onChange={() => {
            onLayerClose();
            setSelected([]);
          }}
          files={selectedFiles}
        />
      );
    }
    return '';
  };

  const glbmActions = [
    (<Button
      kind='toolbar'
      icon={<Refresh />}
      key={IDUtil.getId('PendingFilesRefreshButton')}
      onClick={refreshPendingFiles}
      a11yTitle='Refresh Pending File List'
      id={IDUtil.getId('PendingFilesRefreshButton')}
      tip='Refresh'
      label='Refresh'
      busy={loadingPendingFiles}
    />)];

  glbmActions.push(
    <Menu
      kind='toolbar'
      key='menuCntrl'
      id='actionDropDown'
      items={[
        ...insertIf(isDev() && canRunPendingSchedule, [{
          key: IDUtil.getId('PendingFilesButton'),
          onClick: () => setLayer('confirmProcessPendingFiles'),
          id: IDUtil.getId('PendingFilesButton'),
          label: 'Process Pending Files',
        }]),
        ...insertIf(canDeletePendingFiles, [{
          justify: 'start',
          onClick: () => setLayer('bulkDelete'),
          label: 'Bulk Delete',
          disabled: select.length === 0,
        }]),
        {
          justify: 'start',
          onClick: () => {
              setLayer('confirmBulkDownload');
          },
          label: 'Download All',
        },
      ]}
      label='Actions'
      reverse={false}
    />,
  );

  const onView = (nextView) => {
    const response = filter(list, nextView, properties);
    setData(response.data);
    setFilteredTotal(response.filteredTotal);

    // if the filter changes so that we no longer see some of the selected items, then we need to unselect them:
    const currentFileNames = response.data.map(item => item.fileName);
    const filteredSelected = select.filter(id => currentFileNames?.indexOf(id) !== -1);
    if (!isEqual(select, filteredSelected)) {
      setSelected(filteredSelected);
    }

    setView(nextView);
  };

  return (
    <Main direction='column' fill='vertical'>
      <Data
        data={loadingPendingFiles ? undefined : list}
        total={loadingPendingFiles ? 0 : list.length}
        properties={properties}
        filteredTotal={loadingPendingFiles ? 0 : filteredTotal}
        view={view}
        onView={onView}
        fill={true}
      >
        <Box flex={false} pad={{ horizontal: 'small', top: 'small' }} gap='xsmall'>
          {canReadPendingSchedule && (
          <Box direction='row' gap='small' flex={false}>
            <Text weight='bold'>Pending Files will be processed : </Text>
            <Text>{intervals}</Text>
          </Box>
          )}
          <Box gap='xxsmall' flex={false}>
            <Toolbar gap='small'>
              <DataSearch width='medium' placeholder='Search' />
              <DataFilters layer={true} />
              <Box
                flex={true}
                direction='row'
                gap='small'
                justify='end'
                pad={{ right: 'small' }}
              >
                {glbmActions}
              </Box>
            </Toolbar>
            <DataSummary />
          </Box>
        </Box>
        <GLBMDataTable
          primaryKey='fileName'
          columns={columns}
          loading={loadingPendingFiles}
          allowSelectAll={true}
          select={select}
          onSelect={setSelected}
          searchText={view.search}
          data={data}
          sortable={true}
        />
      </Data>
      {renderLayer()}
      {layers}
    </Main>
  );
};

export default PendingFiles;
