// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer,
  FormField,
  Header,
  Heading,
  Text,
  TextInput,
} from 'grommet';
import moment from 'moment';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import GLBMNameValueList from '../../../../shared/component/GLBMNameValueList';
import DateTime from '../../../../shared/component/DateTime';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import IDUtil from '../../../../shared/util/IDUtil';
import CACheckbox from '../../../../shared/form/CACheckbox';
import CARadioButton from '../../../../shared/form/CARadioButton';
import { getError } from '../../../../shared/util/FormUtil';
import { roles } from '../../../../shared/constants/Permissions';
import { useRoleChecker } from '../../../../shared/hooks';
import { MarkupType } from '../../../../constants/MarkupType';
import RatesStepEditor from './components/RatesStepEditor';
import { isRateRevisionOverCapactity } from './util';

const MeterRatesEditor = (props) => {
  const { isOneOfRoles } = useRoleChecker();
  const allowDates = isOneOfRoles([roles.ASM, roles.SUPER, roles.SERVICE_DEV, roles.SUPPORT, roles.SPECIALIST]);
  // partial month revision support:
  const [useDates, setUseDates] = useState(!!(props.revision?.effectiveDate && allowDates && moment(props.revision?.effectiveDate).date() !== 1));
  const [revision, setRevision] = useState(() => {
    if (props.revision) {
      return {
        ...JSON.parse(JSON.stringify(props.revision)),
        explicitDownstreamRate: props.revision?.explicitDownstreamRate === undefined ? false : props.revision?.explicitDownstreamRate,
        effectiveDate: props.revision?.effectiveDate && allowDates ? moment(props.revision.effectiveDate).format(useDates ? 'YYYY-MM-DD' : 'YYYY-MM') : props.revision.effectiveDate
      };
    }
    return undefined;
  });

  const [isModified, setIsModified] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasSaved, setHasSaved] = useState(false);
  const [noBandsError, setNoBandsError] = useState(false);
  const [revisionToConfirm, setRevisionToConfirm] = useState(undefined);
  const invalidBandsError = useMemo(() => {
    let hasError = false;
    if (revision && revision.tiers) {
      Object.keys(revision.tiers).forEach((startUnit) => {
        const { name } = revision.tiers[startUnit];
        hasError = ((!startUnit || startUnit === '') || (!name || name === '')) || hasError;
      });
    }
    return hasError;
  }, [revision]);
  const hasMarkupConflicts = useMemo(() => {
    if (props.canMarkupRates) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const markups = Object.entries(revision?.tiers).reduce((arr, [threshold, { markup }]) => [...arr, { markup }], []);
      const markupsAllDefined = markups.every(m => m.markup !== undefined);
      const markupsAllUndefined = markups.every(m => m.markup === undefined);
      return (!markupsAllDefined && !markupsAllUndefined);
    }
    return false;
  }, [props.canMarkupRates, revision]);

  const _onSubmit = () => {
    const revisionToSave = JSON.parse(JSON.stringify(revision));
    const { effectiveDate } = revisionToSave;
    let validationErrors = _getTotalErrors(errors) > 0;

    if (hasMarkupConflicts || invalidBandsError) {
      validationErrors = true;
    } else if (!revisionToSave.effectiveDate) {
      errors.effectiveDate = ['Required'];
      validationErrors = true;
    } else if (props.startDates.indexOf(revisionToSave.effectiveDate) !== -1) {
      errors.effectiveDate = ['Duplicate'];
      validationErrors = true;
    }

    if (validationErrors) {
      setErrors(errors);
      setHasSaved(true);
      return;
    }

    // if the user did not actually change anything, lets just close the dialog down even tho they clicked 'OK'. This way we
    // do not end up with a modified revisions object + show warning banner.
    if (!isModified) {
      props.onClose();
      return;
    }

    // flag this revision as dirty:
    revisionToSave.dirty = true;
    if (props.canMarkupRates) {
      if (!revisionToSave.explicitDownstreamRate && !_hasMarkupsAllDefined(revisionToSave)) {
        delete revisionToSave.explicitDownstreamRate;
      } else if (revisionToSave.explicitDownstreamRate && !_hasDownstreamRatesAllDefined(revisionToSave)) {
        delete revisionToSave.explicitDownstreamRate;
      }
    }
    if (effectiveDate) {
      revisionToSave.effectiveDate = moment(effectiveDate).format('YYYY-MM-DD');
    }
    const isError = isRateRevisionOverCapactity(revisionToSave, props.capacities);
    if (isError && props.isTieredRate) {
      setRevisionToConfirm(revisionToSave);
    } else {
      props.onChange(revisionToSave);
    }
  };

  const onDateChange = (date, paramUseDates) => {
    const newRevision = JSON.parse(JSON.stringify(revision));
    const newErrors = JSON.parse(JSON.stringify(errors));
    const attribute = 'effectiveDate';

    delete newErrors[attribute];
    newErrors[attribute] = [];

    // if paramUserDates is set, we should use it, otherwise we should use useDates:
    const formatAsDays = paramUseDates !== undefined ? paramUseDates : useDates;
    const format = formatAsDays ? 'YYYY-MM-DD' : 'YYYY-MM';

    if (moment(date, format, true).isValid()) {
      newRevision.effectiveDate = moment(date).format(format);
    } else {
      newRevision.effectiveDate = date;
      newErrors.effectiveDate = [`Invalid Date. Use format: ${format}`];
    }

    if (!newRevision.effectiveDate) {
      newErrors.effectiveDate = ['Required'];
    } else if (useDates) {
      if (props.startDates.indexOf(date) !== -1) {
        newErrors.effectiveDate = ['Duplicate'];
      }
    } else if (props.startDates.indexOf(moment(date).format('YYYY-MM-DD')) !== -1) {
      newErrors.effectiveDate = ['Duplicate'];
    }
    setRevision(newRevision);
    setErrors(newErrors);
    setIsModified(true);
  };

  const _onMarkupTypeChange = (explicitDownstreamRate) => {
    const newRevision = JSON.parse(JSON.stringify(revision));

    newRevision.explicitDownstreamRate = explicitDownstreamRate;

    // clear the 'other' value:
    Object.keys(newRevision.tiers).forEach((key) => {
      if (!explicitDownstreamRate && newRevision.tiers[key].markup === undefined) {
        newRevision.tiers[key].downstreamRate = undefined;
      } else if (explicitDownstreamRate && newRevision.tiers[key].downstreamRate === undefined) {
        newRevision.tiers[key].markup = undefined;
      }
    });

    setRevision(newRevision);
    setIsModified(true);
  };

  const _showNoBandsError = () => {
    setNoBandsError(true);
  };

  const _getTotalErrors = (paramErrors) => {
    let totalErrors = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const property in paramErrors) {
      totalErrors += (paramErrors[property].length);
    }
    return totalErrors;
  };

  const _renderContextDetails = meter => (
    <Box margin={{ top: 'none' }} pad='medium'>
      <GLBMNameValueList
        title='Selected Meter'
        data={[
          { label: 'Meter Name', value: meter.name },
        ]}
        fill={true}
      />
    </Box>
  );

  const _hasMarkupsAllDefined = (paramRevision) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const markups = Object.entries(paramRevision.tiers).reduce((arr, [threshold, { markup }]) => [...arr, { markup }], []);
    return markups.every(m => m.markup !== undefined);
  };

  const _hasDownstreamRatesAllDefined = (paramRevision) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const markups = Object.entries(paramRevision.tiers).reduce((arr, [threshold, { downstreamRate }]) => [...arr, { downstreamRate }], []);
    return markups.every(m => m.downstreamRate !== undefined);
  };

  const { meter } = props;
  const submitFunction = (props.canEdit || props.canMarkupRates ? _onSubmit : props.onClose);
  const submitLabel = (props.canEdit || props.canMarkupRates ? 'OK' : 'Close');
  const title = (props.canEdit || props.canMarkupRates ? 'Edit rates' : 'Rates');
  // eslint-disable-next-line no-nested-ternary
  const markupType = (props.canMarkupRates ? (revision.explicitDownstreamRate ? MarkupType.DIRECT_RATE : MarkupType.MARKUP_PERCENT) : undefined);

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={props.onClose}
      onClickOutside={props.onClose}
      onEsc={props.onClose}
      flush={true}
      full='vertical'
      title={title}
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
        style={{ 'maxWidth': '825px', 'minWidth': '825px' }}
      >
        <Box flex={true}>
          <Box flex={false}>
            {_renderContextDetails(meter)}
          </Box>
          <Header size='small' margin={{ top: 'none' }} pad={{ horizontal: 'medium' }}>
            <Heading level='3'>Rate Properties:</Heading>
          </Header>
          <Box flex={false} pad={{ horizontal: 'medium' }}>
            <FormField
              label='Start Date'
              error={getError(errors.effectiveDate)}
            >
              {props.canEditRates
                ? (
                  <Box
                    direction='column'
                    margin={{ vertical: 'small' }}
                    gap='small'
                  >
                    <DateTime
                      name='effectiveDate'
                      id={IDUtil.getId('RatePropertiesStartDateInput')}
                      data-testid={IDUtil.getId('RatePropertiesStartDateInput')}
                      format={useDates ? 'YYYY-MM-DD' : 'YYYY-MM'}
                      onChange={onDateChange}
                      value={revision?.effectiveDate && moment(revision.effectiveDate, useDates ? 'YYYY-MM-DD' : 'YYYY-MM', true).isValid()
                        ? moment(revision.effectiveDate).format(useDates ? 'YYYY-MM-DD' : 'YYYY-MM')
                        : undefined}
                      required={true}
                    />
                    {allowDates
                      && (
                        <Box direction='column' justify='center'>
                          <CACheckbox
                            label='Allow custom date for partial month revision'
                            name='useDates'
                            id={IDUtil.getId('RatePropertiesAllowCustomDateChk')}
                            onChange={(event) => {
                              const newUseDates = event.target.checked;
                              setUseDates(newUseDates);
                              if (!newUseDates) {
                                if (moment(revision.effectiveDate, 'YYYY-MM-DD', true).isValid()) {
                                  const adjustedDate = moment(revision.effectiveDate).date(1).format('YYYY-MM');
                                  onDateChange(adjustedDate, newUseDates);
                                } else {
                                  onDateChange(revision.effectiveDate, newUseDates);
                                }
                              } else if (moment(revision.effectiveDate, 'YYYY-MM', true).isValid()) {
                                const adjustedDate = moment(revision.effectiveDate).date(1).format('YYYY-MM-DD');
                                onDateChange(adjustedDate, newUseDates);
                              } else {
                                onDateChange(revision.effectiveDate, newUseDates);
                              }
                            }}
                            checked={useDates}
                            tooltip='Enter the start date if the cost should be calculated based on the number of days the change is in effect.'
                            tooltipPlace='left'
                          />
                        </Box>
                      )}
                  </Box>
                )
                : (
                  <TextInput
                    name='effectiveDate'
                    value={revision.effectiveDate}
                    disabled={true}
                  />
                )}
            </FormField>
            {props.canMarkupRates
              && (
                <FormField label='Pricing Method'>
                  <CARadioButton
                    id='pricingMethodMarkupPercent'
                    name='pricingMethodMarkupPercent'
                    label='Markup Percentage'
                    onChange={() => _onMarkupTypeChange(false)}
                    checked={!revision.explicitDownstreamRate || false}
                    tooltip='Enter a markup percentage to the rate you pay to calculate the price for your customer.'
                    place='left'
                  />
                  <CARadioButton
                    id='pricingMethodDirectRate'
                    name='pricingMethodDirectRate'
                    label='Direct Rate'
                    checked={revision.explicitDownstreamRate || false}
                    onChange={() => _onMarkupTypeChange(true)}
                    tooltip='Enter an explicit rate independent of the rate you pay to set the price for your customer.'
                    place='left'
                  />
                </FormField>
              )}
          </Box>
          <Box flex={true} pad={{ horizontal: 'medium' }} margin={{ top: 'small' }}>
            <RatesStepEditor
              steps={revision.tiers}
              setSteps={(steps) => {
                setIsModified(true);
                setNoBandsError(false);
                setRevision({ ...revision, tiers: steps });
              }}
              preferences={props.preferences}
              canEdit={props.canEditRates}
              userType={props.userType}
              showNoBandsError={_showNoBandsError}
              markupType={markupType}
            />
          </Box>
          {hasSaved && hasMarkupConflicts ? (
            <Box
              direction='row'
              align='center'
              margin='medium'
              gap='small'
            >
              <StatusIcon value='critical' />
              <Text weight={100} size='medium' margin='none'>
                You must either enter pricing values in all bands or leave all empty to save configuration.
              </Text>
            </Box>
          ) : ''}
          {noBandsError ? (
            <Box
              direction='row'
              align='center'
              margin='small'
              gap='small'
            >
              <StatusIcon value='critical' />
              <Text size='medium' margin='none'>
                At least 1 rate band is required.
              </Text>
            </Box>
          ) : ''}
          {invalidBandsError ? (
            <Box
              direction='row'
              align='center'
              margin='small'
              gap='small'
            >
              <StatusIcon value='critical' />
              <Text size='medium' margin='none'>
                Resolve all errors before submitting.
              </Text>
            </Box>
          ) : ''}
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            {props.canEdit || props.canMarkupRates ? (
              <Button
                label={submitLabel}
                type='button'
                primary={true}
                onClick={submitFunction}
              />
            ) : ''}
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={props.onClose}
            />
          </Footer>
        </Box>
      </Box>
      {revisionToConfirm ? (
        <GLBMLayer>
          <Box direction='column' margin='medium' width='medium' gap='medium'>
            <Box>
              The start value for one or more pricing bands is
              higher than the corresponding reserved capacity.
              This needs to be corrected before you can save this
              service configuration. Are you sure you want to continue?
            </Box>
            <Footer flex={false} justify='center' gap='small'>
              <Button
                label={submitLabel}
                type='button'
                primary={true}
                onClick={() => props.onChange(revisionToConfirm)}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={() => setRevisionToConfirm(undefined)}
              />
            </Footer>
          </Box>
        </GLBMLayer>
      ) : null}
    </GLBMLayer>
  );
};

MeterRatesEditor.propTypes = {
  revision: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  meter: PropTypes.object.isRequired,
  startDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  canEditRates: PropTypes.bool.isRequired,
  canMarkupRates: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  isTieredRate: PropTypes.bool.isRequired,
  capacities: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  preferences: PropTypes.object.isRequired,
  isOneOfRoles: PropTypes.func.isRequired,
  allowDates: PropTypes.bool.isRequired,
  revisionToConfirm: PropTypes.object,
};

export default MeterRatesEditor;
