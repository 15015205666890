// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import { useJobCountQuery } from '../../core';
import SidebarButton from '../main/SidebarButton';

const refreshSeconds = 10;

export const JobsSidebarButton = () => {
  const {
    data: jobCountDetails,
  } = useJobCountQuery({
    refetchInterval: (1000 * refreshSeconds),
  });

  return (
    <SidebarButton to='jobs' key='jobs' id='Processing' title='sidenav.jobs.label' badge={jobCountDetails?.processing || 0} />
  );
};
