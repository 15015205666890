// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Select, TextInput,
} from 'grommet';
import { useServiceMetersQuery } from '../../../core';
import CustomInput from './CustomInput';

const MetersInput = ({
  meters = [], setMeters = () => undefined, errors = [], setErrors = () => undefined, serviceType = undefined,
}) => {
  const metersData = useServiceMetersQuery(serviceType);

  const metersOptions = useMemo(() => metersData?.data ?? [], [metersData]);
  const KeyInput = useCallback(({ id, i, ...rest }) => (
    <Select
      {...rest}
      id={id}
      data-testid={id}
      options={metersOptions}
      labelKey='name'
      valueKey={{ key: 'id', reduce: true }}
      onChange={(e) => {
        const newProperty = JSON.parse(JSON.stringify(meters));
        newProperty[i].key = e.option.id;
        setMeters(newProperty);
      }}
    />
  ), [meters, metersOptions, setMeters]);

  const ValueInput = useCallback(({
    id, i, y, ...rest
  }) => (
    <TextInput
      {...rest}
      id={id}
      data-testid={id}
      type='number'
      onChange={(e) => {
        const newProperty = JSON.parse(JSON.stringify(meters));
        newProperty[i].values[y].value = +e.target.value;
        setMeters(newProperty);
      }}
    />
  ), [meters, setMeters]);

  return (
    <CustomInput
      property={meters}
      keyInput={KeyInput}
      valueInput={ValueInput}
      setProperty={setMeters}
      errors={errors}
      setErrors={setErrors}
      propertyName='Meter'
      title='Meters'
    />
  );
};

MetersInput.propTypes = {
  meters: PropTypes.array,
  setMeters: PropTypes.func,
  errors: PropTypes.array,
  setErrors: PropTypes.func,
  serviceType: PropTypes.string,
};

export default MetersInput;
