// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ToastContainer from './ToastContainer';

const ToastContext = React.createContext(null);

let id = 1;

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    ({
      toast, status, title, message, icon, global
    }) => {
      // eslint-disable-next-line no-return-assign
      setToasts(prevState => [
        ...prevState,
        {

          id: id += 1,
          toast,
          status,
          title,
          message,
          icon,
          global,
        },
      ]);
    },
    [setToasts],
  );

  const removeToast = useCallback((toastId) => {
      setToasts(prevState => prevState.filter(t => t.id !== toastId));
    }, [setToasts]);

  const toastProviderObj = useMemo(() => ({
    addToast,
    removeToast,
  }), [addToast, removeToast]);

  return (
    <ToastContext.Provider
      value={toastProviderObj}
    >
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useToast = () => {
  return useContext(ToastContext);
};

export { ToastContext, useToast };
export default ToastProvider;
