// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ServiceStep } from 'services/model/ServiceStep';

import { Box, FormField, Text, TextInput } from 'grommet';
import IDUtil from '../../../../../../shared/util/IDUtil';

import ReadOnlyField from '../../../../../../shared/component/ReadOnlyField';
import { useValidationMutation } from '../../../../../../../core';

const SingleTierEditor = ({
  tiers,
  disabled,
  onChange,
  readOnly,
}) => {
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (tiers && tiers[0]?.name === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        name: ['Required']
      }));
    }
  }, [tiers]);

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);

  useEffect(() => {
    fetchValidationIfNeeded();
  }, [fetchValidationIfNeeded]);

  const _getError = (paramErrors) => {
    if (!paramErrors || paramErrors.length === 0) {
      return undefined;
    }
    return paramErrors.join(', ');
  };

  const _onChange = (event) => {
    const attribute = event.target.getAttribute('name');
    const updatedTiers = [...tiers];

    switch (attribute) {
      case 'name':
        updatedTiers[0][attribute] = event.target.value;
        break;
      // Add more cases for other attributes if needed
      default:
        break;
    }

    const updatedErrors = { ...errors };
    delete updatedErrors[attribute];
    updatedErrors[attribute] = [];

    if (!updatedTiers[0][attribute]) {
      updatedErrors[attribute].push('Required');
    } else if (!/^[a-zA-Z0-9_]*$/.test(updatedTiers[0][attribute])) {
      updatedErrors[attribute].push('Invalid - can only contain letters, numbers, or underscores');
    }

    setErrors(updatedErrors);
    onChange(updatedTiers, true);
    fetchValidationIfNeeded();
  };

  if (disabled) {
    return (<Box />);
  }

  const readOnlyField = (
    <ReadOnlyField
      data-e2e='tier-name'
      border={false}
      error={_getError(errors.name)}
      label='Single Tier Name'
      value={tiers[0]?.name}
    />
  );

  const editableField = (
    <Box pad={{ 'horizontal': 'small' }}>
      <FormField
        label={<Text size='medium' weight='bold'>Single Tier Name</Text>}
        error={_getError(errors.name)}
      >
        <TextInput
          id={IDUtil.getId('BillingInfoEditorCompanyNameInput')}
          name='name'
          value={tiers[0]?.name}
          onChange={_onChange}
          data-e2e='tier-name'
        />
      </FormField>
    </Box>
  );

  return readOnly ? readOnlyField : editableField;
};

SingleTierEditor.propTypes = {
  tiers: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default SingleTierEditor;
