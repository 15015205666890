// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import StatusItem from './StatusItem';

const PartnerDetails = ({ title, data = {} }) => {
  const getStatusDetails = (status) => {
    switch (status) {
      case 'OK':
        return (<StatusItem severity='ok' status='Account is ready to be promoted for Partner' />);
      case 'CA_NOT_PROVISIONED_FOR_PARTNER':
        return (<StatusItem severity='critical' status='CA Not Provisioned For Partner' />);
      default:
        return status;
    }
  };

  const payload = useMemo(() => [
    { label: 'Tenant Name', value: data?.tenantName },
    { label: 'CA Tenant ID', value: data?.caTenantId },
    { label: 'GLC Tenant ID', value: data?.glcTenantId },
    { label: 'GLP Workspace ID', value: data?.glpWorkspaceId },
    { label: 'Status', value: getStatusDetails(data.status) },
  ], [data]);

  return (
    <Box
      pad={{ 'horizontal': 'small' }}
      align='start'
      margin='small'
      flex={false}
    >
      <GLBMNameValueList
        title={`${title} Partner Details`}
        data={payload}
      />
    </Box>
  );
};

PartnerDetails.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string.isRequired,
    tenantName: PropTypes.string,
    caTenantId: PropTypes.string,
    glcTenantId: PropTypes.string,
    glpWorkspaceId: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
};

export default PartnerDetails;
