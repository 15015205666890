// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, Footer } from 'grommet';
import AgGridContainer from '../../shared/grid/AgGridContainer';
import { useColumns } from './utils';

const EmailGrid = ({
  searchText = '',
  data = [],
  setSearchCount = undefined,
  emailLoading = false,
}) => {
  const [gridApi, setGridApi] = useState();
  const columnDefs = useColumns();

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('quickFilterText', searchText);
      const count = gridApi?.getDisplayedRowCount();
      setSearchCount(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    minWidth: 100,
  }), []);

  const getExportParams = () => ({});

  const onBtnExportCsv = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv(getExportParams());
    }
  };

  const onBtnExportExcel = () => {
    if (gridApi) {
      const gridData = gridApi.getDataAsCsv(getExportParams());
      const blob = new Blob([gridData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'export.xls');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onFirstDataRendered = useCallback((params) => {
    const allColumnIds = [];
    params.api.getColumns()
      .forEach((column) => {
        allColumnIds.push(column.colId);
      });
    params.api.autoSizeColumns(allColumnIds, false);
  }, []);

  const hasRows = data?.length > 0;

  return (
    <Box
      flex={true}
      fill='vertical'
      margin={{ horizontal: 'small' }}
    >
      <AgGridContainer
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          onFirstDataRendered={onFirstDataRendered}
          loading={emailLoading}
        />
      </AgGridContainer>
      <Box
        border='top'
        pad={{ vertical: 'small' }}
        margin={{ top: 'none' }}
        flex={false}
      >
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Export to CSV'
              disabled={!hasRows}
              secondary={true}
              onClick={hasRows ? onBtnExportCsv : undefined}
            />
            <Button
              label='Export to Excel'
              disabled={!hasRows}
              secondary={true}
              onClick={hasRows ? onBtnExportExcel : undefined}
            />
          </Box>
        </Footer>
      </Box>
    </Box>
  );
};

EmailGrid.propTypes = {
  searchText: PropTypes.string,
  data: PropTypes.array,
  emailLoading: PropTypes.bool,
  setSearchCount: PropTypes.func,
};

export default EmailGrid;
