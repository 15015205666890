// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField, Menu, Select, Text } from 'grommet';
import { Next, Previous, StatusCritical } from 'grommet-icons';

import moment from 'moment';
import InvoiceAPI from '@saturn/ca-invoice';

import CurrencyUtils from '../../i18n/CurrencyUtil';
import { isProd } from '../../shared/environment/EnvironmentUtil';
import IDUtil from '../../shared/util/IDUtil';
import { ApiContext } from '../../../AppContext';
import { useInvoiceQuery, useInvoiceSummaryQuery } from '../../../core';
import { pagePermissions } from '../../shared/constants/Permissions';
import { usePermissionChecker } from '../../shared/hooks';
import { insertIf } from '../../shared/util/BasicUtil';
import MeterDetails from '../meter/MeterDetails';

const Invoice = ({
  selectedCustomer = undefined,
  toggleSetOrder = undefined,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedMeter, setSelectedMeter] = useState();
  const [selectedMeterGrouping, setSelectedMeterGrouping] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedMode, setSelectedMode] = useState();
  const [selectedView, setSelectedView] = useState();
  const [invoiceHTML, setInvoiceHTML] = useState('');
  const [periods, setPeriods] = useState([]);
  const [error, setError] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/naming-convention
  const [_, setExportIsOpened] = useState(false);
  const [invoice, setInvoice] = useState();

  const { hasPermissions } = usePermissionChecker();

  const unitOptions = useMemo(() => [
    { label: 'Billing unit', value: 'BILLING' },
    { label: 'Capacity unit', value: 'CAPACITY' },
  ], []);

  const modeOptions = useMemo(() => [
    { label: 'My cost', value: 'COST' },
    { label: 'Customer cost', value: 'CUSTOMER' },
    { label: 'Profitability', value: 'PROFIT' },
  ], []);

  const viewOptions = useMemo(() => [
    { label: 'HPE', value: 'MASTER' },
    { label: 'Distributor', value: 'DISTRIBUTOR' },
    { label: 'Reseller', value: 'RESELLER' },
    { label: 'Customer', value: 'CUSTOMER' },
  ], []);

  const meterGroupingOptions = useMemo(() => [
    ...insertIf(!invoice || invoice?.locationCommitments.length === 0, [{ label: 'Service Category', value: 'SERVICE_CATEGORY' }]),
    { label: 'Service', value: 'SERVICE' },
  ], [invoice]);

  /**
   * Called when the end user changes the account - we need to re-build the list of periods available for this account:
   */
  const generateInvoicePeriods = (invoiceSummaryData) => {
    const accountPeriods = invoiceSummaryData?.periods || [];
    const newPeriods = [];
    if (accountPeriods?.length) {
      accountPeriods?.sort((a, b) => new Date(b) - new Date(a)).forEach((period) => {
        const periodMoment = moment(period);
        if (periodMoment.isValid()) {
          newPeriods.push({
            label: periodMoment.format('MMM YYYY'),
            value: periodMoment.format('YYYY-MM'),
          });
        }
      });
    }
    return newPeriods;
  };

  useEffect(() => {
    if (!selectedMeterGrouping) {
      setSelectedMeterGrouping(meterGroupingOptions[0]);
    } else if (invoice) {
      const index = meterGroupingOptions.map(e => e.value).indexOf(selectedMeterGrouping?.value);
      if (index === -1) {
        setSelectedMeterGrouping(meterGroupingOptions[0]);
      }
    }
  }, [invoice, selectedMeterGrouping, meterGroupingOptions]);

  const {
    data: invoiceSummaryData,
    isFetching: isInvoiceSummaryLoading,
  } = useInvoiceSummaryQuery(selectedCustomer.id);

  useEffect(() => {
    if (selectedCustomer && !isInvoiceSummaryLoading && invoiceSummaryData) {
      const myPeriods = generateInvoicePeriods(invoiceSummaryData);
      const mySelectedPeriod = myPeriods[0];

      // when the customer changes - we need to reset:
      const storedUnit = 'BILLING';
      const storedMode = 'COST';
      const storedView = 'MASTER';

      const unitOption = unitOptions.find(option => storedUnit === option.value);
      const modeOption = modeOptions.find(option => storedMode === option.value);
      const viewOption = viewOptions.find(option => storedView === option.value);

      setSelectedPeriod(mySelectedPeriod);
      setPeriods(myPeriods);
      setSelectedMeter(undefined);
      setSelectedUnit(unitOption);
      setSelectedMode(modeOption);
      setSelectedView(viewOption);
      setInvoiceHTML(undefined);
      setError(false);
    } else {
      setPeriods([]);
      setSelectedPeriod(undefined);
      setSelectedUnit(unitOptions[0]);
      setSelectedMeterGrouping(meterGroupingOptions[0]);
      setSelectedMode(modeOptions[0]);
      setSelectedView(viewOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, isInvoiceSummaryLoading, invoiceSummaryData]);

  const period = useMemo(() => selectedPeriod && moment(selectedPeriod.value).format('YYYYMM'), [selectedPeriod]);

  const rerenderInvoice = (myInvoice, locale) => {
    if (!myInvoice) {
      // eslint-disable-next-line no-param-reassign
      myInvoice = {
        accountId: selectedCustomer.id,
        accountName: selectedCustomer.name,
        accountStartMonth: periods[periods.length - 1].value,
        items: [],
        month: selectedPeriod.value,
        error: false,
      };
    }

    return () => {
      const newInvoiceHTML = InvoiceAPI.transform(
        myInvoice,
        locale,
        true,
        rerenderInvoice(myInvoice, locale),
        selectedUnit.value,
        selectedMode.value,
        undefined,
        selectedMeterGrouping.value
      );
      setInvoiceHTML(newInvoiceHTML);
      setError(myInvoice.error);
    };
  };

  const onInvoiceError = () => {
    const myInvoice = {
      accountId: selectedCustomer.id,
      accountName: selectedCustomer.name,
      accountStartMonth: periods[periods.length - 1].value,
      items: [],
      month: selectedPeriod.value,
      error: true,
    };

    rerenderInvoice(myInvoice, selectedCustomer.locale)();
  };

  const {
    isFetching: isLoadingInvoice,
    isError: isInvoiceError,
    error: invoiceError,
    data: invoiceData,
  } = useInvoiceQuery(selectedCustomer.id, period, selectedView?.value, {
    enabled: !!(selectedCustomer.id && period && !isInvoiceSummaryLoading && invoiceSummaryData),
    gcTime: 0,
    onError: () => {
      onInvoiceError();
    }
  });

  useEffect(() => {
    if (!isLoadingInvoice && invoiceData) {
      setInvoice(invoiceData);
    }
  }, [isLoadingInvoice, invoiceData]);

  useEffect(() => {
    InvoiceAPI.connectExpandClicks();
  }, [invoiceHTML]);

  const onInvoiceChange = (myInvoice) => {
    if (!myInvoice) {
      // eslint-disable-next-line no-param-reassign
      myInvoice = {
        accountId: selectedCustomer.id,
        accountName: selectedCustomer.name,
        accountStartMonth: periods[periods.length - 1].value,
        items: [],
        month: selectedPeriod.value,
        error: false,
      };
    }

    try {
      rerenderInvoice(myInvoice, selectedCustomer.locale)();
    } catch (myError) {
      console.error(myError);
      // eslint-disable-next-line no-param-reassign
      myInvoice = {
        accountId: selectedCustomer.id,
        accountName: selectedCustomer.name,
        accountStartMonth: periods[periods.length - 1].value,
        items: [],
        month: selectedPeriod.value,
        error: true,
      };

      rerenderInvoice(myInvoice, selectedCustomer.locale)();
    }
  };

  useEffect(() => {
    if (invoice) {
      onInvoiceChange(invoice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, selectedUnit, selectedMode, selectedMeterGrouping]);

  const onDateChange = ({ option }) => {
    if (option?.value !== selectedPeriod?.value) {
      setInvoice(undefined);
      setInvoiceHTML('');
      setSelectedPeriod(option);
    }
  };

  const onUnitChange = (event) => {
    setSelectedUnit(event);
  };

  const onModeChange = (event) => {
    setSelectedMode(event.option);
  };

  const onMeterGroupingChange = ({ option }) => {
    setSelectedMeterGrouping(option);
  };

  const onViewChange = (event) => {
    const newSelectedView = event.option;
    const modeOption = modeOptions.find(option => option.value === 'COST');

    setSelectedView(newSelectedView);
    setInvoice(undefined);
    setInvoiceHTML('');
    setSelectedMode(modeOption);
  };

  const getSelectedPeriodIndex = () => {
    for (let i = 0; i < periods.length; i += 1) {
      if (periods[i].value === selectedPeriod.value) {
        return i;
      }
    }
    return -1;
  };

  const onPreviousMonthClick = () => {
    let currentIndex = getSelectedPeriodIndex();
    let newPeriod;
    if (currentIndex < (periods.length - 1)) {
      currentIndex += 1;
      newPeriod = periods[currentIndex];
    } else {
      [newPeriod] = periods;
    }
    setSelectedPeriod(newPeriod);
    setInvoice(undefined);
    setInvoiceHTML('');
  };

  const onNextMonthClick = () => {
    let currentIndex = getSelectedPeriodIndex();
    let newPeriod;
    if (currentIndex > 0) {
      currentIndex -= 1;
      newPeriod = periods[currentIndex];
    } else {
      [newPeriod] = periods;
    }

    setSelectedPeriod(newPeriod);
    setInvoice(undefined);
    setInvoiceHTML('');
  };

  /**
   * This method to get called by 'magic' passing the selected meter (meter id)
   */
  const onMeterDrillDownClick = (mySelectedMeter) => {
    setSelectedMeter(mySelectedMeter);
  };

  const onMeterDrillUpClick = () => {
    setSelectedMeter(undefined);
  };

  const renderMeterDetails = () => (
    <Box
      direction='column'
      style={{ height: '100%' }}
      flex={true}
      margin={{ bottom: 'medium' }}
    >
      <MeterDetails
        customer={selectedCustomer}
        period={selectedPeriod}
        selectedMeter={selectedMeter}
        onBack={onMeterDrillUpClick}
      />
    </Box>
  );

  const onNextFrame = (callback) => {
    setTimeout(() => {
      window.requestAnimationFrame(callback);
    }, 0);
  };

  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  const attachDrilldownFunctions = () => {
    const elements = document.getElementsByClassName('drilldown-trigger');
    for (let i = 0; i < elements.length; i += 1) {
      if (elements[i]?.dataset?.item) {
        const data = elements[i].dataset.item;
        if (isBase64(data)) {
          const item = JSON.parse(atob(data));
          const { units } = elements[i].dataset;
          if (units) {
            item.units = units;
          } else {
            item.units = item.meterUnit;
          }
          elements[i].onclick = () => { onMeterDrillDownClick(item); };
        }
      }
    }
  };

  const toggleSetOrderFn = () => {
    if (toggleSetOrder) {
      toggleSetOrder();
    }
  };

  const hasMismatchedUnits = useMemo(() => {
    if (invoice) {
      return invoice.items.some(item => item.capacityUnit !== item.meterUnit);
    }
    return false;
  }, [invoice]);

  const showViewOptions = useMemo(
    () => (hasPermissions(pagePermissions.analytics.tabs.invoice_partner)
      && !isProd()
      && selectedCustomer),
    [selectedCustomer, hasPermissions]
  );

  const filterViewOptions = (dealType = 'DIRECT', options = []) => options.filter((option) => {
    switch (option.value) {
      case 'DISTRIBUTOR':
        return dealType === 'DISTRIBUTOR_RESELLER';
      case 'RESELLER':
        return (dealType === 'DISTRIBUTOR_RESELLER' || dealType === 'RESELLER');
      default:
        return true;
    }
  });
  const apiCtx = useContext(ApiContext);

  const onInvoiceExport = (fileType) => {
    const customerId = selectedCustomer.id;
    const customerLocale = selectedCustomer.locale;
    const mode = selectedMode ? selectedMode.value : modeOptions[0].value;
    const view = selectedView ? selectedView.value : viewOptions[0].value;
    const unit = selectedUnit ? selectedUnit.value : unitOptions[0].value;
    const meterGroup = selectedMeterGrouping ? selectedMeterGrouping.value : meterGroupingOptions[0].value;
    const myPeriod = moment(selectedPeriod.value).format('YYYYMM');
    setExportIsOpened(false);
    switch (view) {
      case 'MASTER':
        window.open(apiCtx('monthlyCharges.invoice.master.export.path', {
          customerId,
          period: myPeriod,
          fileType,
          customerLocale,
          mode,
          unit,
          meterGroup,
        }), '_blank');
        break;
      default:
        window.open(apiCtx('monthlyCharges.invoice.partner.export.path', {
          customerId,
          period: myPeriod,
          fileType,
          customerLocale,
          view,
          mode,
          unit,
          meterGroup,
        }), '_blank');
        break;
    }
  };

  const totalCost = useMemo(() => {
    let total = 0;
    if (selectedCustomer && invoice && selectedMode) {
      const currency = selectedCustomer.contractCurrency;
      const { locale } = selectedCustomer;
      switch (selectedMode.value) {
        case 'COST':
          total = invoice.totalCost && invoice.totalCost[currency] !== undefined ? invoice.totalCost[currency] : 0;
          break;
        case 'CUSTOMER':
          total = invoice.customerCost && invoice.customerCost[currency] !== undefined ? invoice.customerCost[currency] : 0;
          break;
        case 'PROFIT':
          total = invoice.profitabilityCost && invoice.profitabilityCost[currency] !== undefined ? invoice.profitabilityCost[currency] : 0;
          break;
        default:
          break;
      }
      return CurrencyUtils.getCurrencyString(total, 2, locale, currency);
    }
    return total.toLocaleString();
  }, [invoice, selectedCustomer, selectedMode]);

  onNextFrame(attachDrilldownFunctions);
  return (
    <Box>
      <Box direction='row' justify='between' flex={false} wrap={true}>
        <Box direction='row' pad={{ vertical: 'small' }} flex={false} wrap={true}>
          <Box direction='row' gap='xsmall' align='end' pad={{ horizontal: 'small' }}>

            <FormField label='Period'>
              <Select
                id={IDUtil.getId('InvoicePeriod')}
                options={periods}
                value={selectedPeriod?.value}
                onChange={onDateChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
            <Box direction='row' pad={{ bottom: 'xsmall' }} gap='xsmall'>
              <Button
                kind='toolbar'
                icon={<Previous />}
                disabled={selectedPeriod?.value === periods[periods.length - 1]?.value}
                onClick={onPreviousMonthClick}
              />
              <Button
                kind='toolbar'
                icon={<Next />}
                disabled={selectedPeriod?.value === periods[0]?.value}
                onClick={onNextMonthClick}
              />
            </Box>
          </Box>

          {hasMismatchedUnits && (
            <Box pad={{ horizontal: 'small' }}>
              <FormField label='Unit'>
                <Select
                  options={unitOptions}
                  value={selectedUnit?.value}
                  onChange={onUnitChange}
                  labelKey='label'
                  valueKey={{ key: 'value', reduce: true }}
                />
              </FormField>
            </Box>
          )}
          {showViewOptions && (
            <Box pad={{ horizontal: 'small' }}>
              <FormField label='View'>
                <Select
                  options={filterViewOptions(selectedCustomer.dealType, viewOptions)}
                  value={selectedView?.value}
                  onChange={onViewChange}
                  labelKey='label'
                  valueKey={{ key: 'value', reduce: true }}
                />
              </FormField>
            </Box>
          )}

          {invoice?.partner
            && (
              <Box pad={{ horizontal: 'small' }}>
                <FormField label='Mode'>
                  <Select
                    options={modeOptions}
                    value={selectedMode?.value}
                    onChange={onModeChange}
                    labelKey='label'
                    valueKey={{ key: 'value', reduce: true }}
                  />
                </FormField>
              </Box>
            )}

          {hasPermissions(pagePermissions.analytics.tabs.invoice_setOrder)
            && (
              <Box pad={{ horizontal: 'small', bottom: 'xsmall' }} justify='end'>
                <Button
                  secondary={true}
                  disabled={selectedMeterGrouping?.value === 'SERVICE'}
                  onClick={() => toggleSetOrderFn()}
                  label='Set Order'
                />
              </Box>
            )}
          <Box pad={{ horizontal: 'small', bottom: 'xsmall' }} justify='end'>
            <Menu
              secondary={true}
              label='Export'
              items={[
                { label: 'to PDF', onClick: error ? undefined : () => onInvoiceExport('pdf') },
                { label: 'to CSV', onClick: error ? undefined : () => onInvoiceExport('csv') },
              ]}
            />
          </Box>
          <Box pad={{ horizontal: 'small' }}>
            <FormField label='Meter Grouping'>
              <Select
                options={meterGroupingOptions}
                value={selectedMeterGrouping?.value}
                onChange={onMeterGroupingChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
        </Box>
        <Box alignSelf='center' pad={{ top: 'medium' }} margin={{ horizontal: 'small' }}>
          <Text weight='bold' data-e2e='totalCharges'>{`Total Charges: ${totalCost}`}</Text>
        </Box>
      </Box>

      <Box pad='small'>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: invoiceHTML }} />
      </Box>
      {selectedMeter && renderMeterDetails()}
      {isInvoiceError
        && (
          <Box pad='small'>
            <Box direction='row' align='center' gap='small' justify='center'>
              <StatusCritical />
              <Text weight='bold' color='#FC5A5A'>{invoiceError?.message}</Text>
            </Box>
          </Box>
        )}
    </Box>
  );
};

Invoice.propTypes = {
  selectedCustomer: PropTypes.shape({
    contractStartMonth: PropTypes.any,
    contractEndMonth: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    locale: PropTypes.any,
    dealType: PropTypes.string,
    contractCurrency: PropTypes.any
  }),
  toggleSetOrder: PropTypes.func,
};

export default Invoice;
