// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Spinner, Text } from 'grommet';

const Loader = ({ text = undefined }) => (
  <Box align='center' direction='row' gap='small' pad='small'>
    <Spinner />
    <Text>{text || 'Loading...'}</Text>
  </Box>
);

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
