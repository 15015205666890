// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';

import { executeGet } from '../api';

export const useJobCountQuery = (options) => {
  const path = useContext(ApiContext)('jobs.count.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:JOBS-COUNT'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Job Count Error');
};

export const useGetJobsListQuery = (to, from, type, status, customerId, parameters, options) => {
  const path = useContext(ApiContext)('jobs.status.path', { parameters }, {
    to, from, type, status, customerId
  });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:JOBS-LIST', to, from, type, status, customerId, parameters],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Jobs Error');
};
