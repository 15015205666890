// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CheckBox, FormField, TextInput } from 'grommet';
import { isLessThanFiveDecimalPlaces, isNumber } from '../../rates/util';

const MIN_VALUE = 1.0;
const MAX_VALUE = 4.0;

const ReplicationRatioEdit = (props) => {
  useEffect(() => {
    if (!props.isBulk && !props.value) {
      props.onChange('replicationRatio', 1.0);
    }
  }, []);

  const onChangeReplicationRatio = ({ target: { value } } = { target: { name: null } }) => {
    if (!!value && value > MAX_VALUE) return;
    if (!!value && value < MIN_VALUE) {
      props.onChange('replicationRatio', MIN_VALUE);
      return;
    }
    if (!value || (isNumber(value) && isLessThanFiveDecimalPlaces(value))) {
      props.onChange('replicationRatio', value);
    }
  };

  const onBlur = ({ target: { value } } = { target: { name: null } }) => {
    if (!isNumber(value)) {
      props.onChange('replicationRatio', 0);
    }
  };

    return (
      <Box direction='row' align='center' flex={false}>
        {props.isBulk && (
          <Box margin={{ top: 'medium' }}>
            <CheckBox
              name='replicationRatio'
              checked={props.update}
              onChange={event => props.onBulkChange(event)}
              data-testid='equipment-bulk-edit-replicationRatio-checkbox'
            />
          </Box>
        )}
        <FormField
          key='replicationRatio'
          label={props?.column?.column?.header || 'Replication Ratio'}
          help='(must be between 1.0 and 4.0)'
        >
          <TextInput
            name='replicationRatio'
            value={props.value}
            onChange={event => onChangeReplicationRatio(event)}
            onBlur={event => onBlur(event)}
          />
        </FormField>
      </Box>
    );
};

ReplicationRatioEdit.propTypes = {
  column: PropTypes.object,
  isBulk: PropTypes.bool,
  onChange: PropTypes.func,
  onBulkChange: PropTypes.func,
  update: PropTypes.bool,
  value: PropTypes.number,
};

export default ReplicationRatioEdit;
