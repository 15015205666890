// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, Paragraph, Text,
} from 'grommet';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { StatusIcon } from '../../shared/component/StatusIcon';

const PartnerDeleteDeniedDialog = ({
  onClose,
  text = undefined,
  details = undefined,
}) => (
  <GLBMLayer
    position='right'
    full='vertical'
    closer={true}
    onClose={onClose}
    onEsc={onClose}
    onClickOutside={onClose}
    overlayClose={true}
    flush={true}
    title='Unable to delete partner'
  >
    <Box
      pad='none'
      style={{ 'width': '600px', 'minWidth': '600px' }}
      direction='column'
      flex={true}
      fill='vertical'
    >
      <Box pad='medium' flex={true}>
        <Box direction='row' align='center'>
          <StatusIcon value='warning' size='medium' />
          <Box margin={{ left: 'small' }}>
            <Paragraph>
              Partners with existing customer associations cannot be deleted. For each customer in the
              list below, please remove this partner as either its reseller or distributor before
              attempting to delete this partner again.
            </Paragraph>
          </Box>
        </Box>
        <Text weight={100}>
          {text}
        </Text>
        {details || ''}
      </Box>
    </Box>
    <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
      <Footer flex={false} justify='start' gap='small'>
        <Button secondary={true} onClick={onClose} label='OK' />
      </Footer>
    </Box>
  </GLBMLayer>
);

PartnerDeleteDeniedDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
  details: PropTypes.node,
};

export default PartnerDeleteDeniedDialog;
