// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CheckBox,
  Footer,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import IDUtil from '../shared/util/IDUtil';
import GLBMSearch from '../shared/component/GLBMSearch';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import { renderColumnTableCell } from '../shared/util/tableUtils';

// Initialize the items with the selected items
const initializeItems = (items, selected) => {
  const newItems = [];
  if (items && selected) {
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      item.selected = selected.indexOf(item.id) !== -1;
      newItems.push(item);
    }
  }
  return newItems.sort((a, b) => a.name.localeCompare(b.name));
};

const CustomerListEditor = ({
  searchText: selectedTextProp = undefined,
  items: itemsProp,
  selected,
  onChange,
  onClose,
}) => {
  const [items, setItems] = useState(() => initializeItems(itemsProp, selected));
  const [searchText, setSearchText] = useState(selectedTextProp);

  const sort = {
    sortIndex: 1,
    sortAscending: false,
  };

  const onSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const selected = items?.filter(item => item.selected)
      .map(item => item.id) || [];
    onChange(selected);
  };

  const filter = () => {
    if (!searchText) {
      return items;
    }

    const properties = ['id', 'name'];
    return items?.filter(item => properties.some(prop => item[prop]?.toLowerCase()
      .includes(searchText?.toLowerCase()))) || [];
  };

  const onSelected = (id, checked) => {
    // loop through all items and update the selected flag for the item with the given id
    const newItems = items.map(item => (item.id === id ? { ...item, selected: checked } : item));
    setItems(newItems);
  };

  const filteredItems = useMemo(() => filter(), [searchText, items]);
  const rows = useMemo(() => {
    const newRows = [];
    filteredItems.forEach((item, index) => {
      newRows.push(
        <TableRow key={item.id}>
          <TableCell>
            <CheckBox
              checked={item.selected}
              id={IDUtil.getId('ASMEditorListSelectCheckbox', index)}
              onChange={e => onSelected(item.id, e.target.checked)}
            />
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.id}</TableCell>
        </TableRow>,
      );
    });
    return newRows;
  }, [filteredItems]);

  // if no rows:
  const noRows = useMemo(() => {
    const totalCustomers = items?.length || 0;
    const filteredAdmins = filteredItems?.length || 0;
    if (totalCustomers === 0) {
      return (
        <ListPlaceholder
          emptyMessage='You have added all the available customers to this user.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    if (filteredAdmins === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero Billing Accounts, adjust to continue.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  }, [items.length, filteredItems.length]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      closer={true}
      flush={true}
      title='Assign Billing Accounts'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
        style={{
          'maxWidth': '600px',
          'width': '600px',
        }}
      >
        <Box flex={true}>
          <Box direction='row' pad='small' flex={false}>
            <GLBMSearch
              id={IDUtil.getId('ASMEditorSearchButton')}
              value={searchText}
              onChange={e => setSearchText(e)}
            />
          </Box>
          <Box flex={true} overflow='auto'>
            <Table responsive={false} scrollable={true}>
              <TableHeader
                sortIndex={sort.sortIndex}
                sortAscending={sort.sortAscending}
              >
                <TableRow>
                  {renderColumnTableCell(['Select', 'Billing Account Name', 'Billing ID'])}
                </TableRow>
              </TableHeader>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
            {noRows}
          </Box>
          <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
            <Footer flex={false} justify='start' gap='small'>
              <Button
                label='Assign Selected'
                id={IDUtil.getId('ASMEditorAssignSelectedButton')}
                primary={true}
                onClick={() => onSubmit(false)}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={onClose}
              />
            </Footer>
          </Box>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

CustomerListEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CustomerListEditor;
