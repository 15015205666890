// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapsible,
  Main,
  Notification,
  Spinner,
  Text,
} from 'grommet';
import { StatusCritical } from 'grommet-icons';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import SaveChangesDialog from '../shared/dialogs/SaveChangesDialog';

import IDUtil from '../shared/util/IDUtil';
import { CustomerStatusEnum } from '../shared/constants/CustomerConstants';
import WarningBanner from '../shared/banner/WarningBanner';
import {
  useCurrenciesQuery,
  useCustomerCreateMutate,
  useCustomerQuery,
  useCustomerUpdateMutate,
  useLocalesQuery,
} from '../../core';
import { ReportBy } from '../services';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMTooltip from '../shared/component/GLBMTooltip';
import { DealType } from '../shared/constants/DealType';
import { pagePermissions, roles } from '../shared/constants/Permissions';
import { usePermissionChecker, useRoleChecker } from '../shared/hooks';
import { OptionsBuilder } from '../shared/util/OptionsBuilder';
import UserStore from '../stores/UserStore';
import BillingInfoForm from './BillingInfoForm';
import DefaultServiceForm from './DefaultServiceForm';
import LocationForm from './LocationForm';
import { validatePartnerSelection } from './partner-relationships/handler';

import PartnerRelationshipForm
  from './partner-relationships/PartnerRelationshipForm';
import PartnerRelationshipsDialog
  from './partner-relationships/PartnerRelationshipsDialog';
import PartnerRelationshipsMustDisconnectDialog
  from './partner-relationships/PartnerRelationshipsMustDisconnectDialog';
import PartnerRelationshipView
  from './partner-relationships/PartnerRelationshipView';
import PSAProjectInfoForm from './psa-project-info/PSACustomerForm';
import PSAProjectInfoDialog from './psa-project-info/PSAProjectInfoDialog';
import { validateDate } from './StartMonthField/utils';

const initialDefaultServiceConfig = {
  reportBy: ReportBy.TIER.enumKey,
  maxShrink: 10,
  committedPercent: 80,
  ratePrecision: 4,
  unitPrecision: 2,
};

const EditCustomerPage = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();

  const me = useMemo(() => UserStore.getUser(), []);
  const { hasPermissions } = usePermissionChecker();
  const { isOneOfRoles } = useRoleChecker();

  const [adding, setAdding] = useState(true);
  const readOnly = useMemo(() => (
    (adding && !hasPermissions(pagePermissions.customers.actions.add))
    || (!adding && !hasPermissions(pagePermissions.customers.view.actions.edit))
  ), [hasPermissions, adding]);
  const [customer, setCustomer] = useState({});
  const [originalCustomer, setOriginalCustomer] = useState({});
  const [locations, setLocations] = useState([]);
  const [originalLocations, setOriginalLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(undefined);
  const [currencies, setCurrencies] = useState([]);
  const [locales, setLocales] = useState([]);
  const [showPartnerRelationshipsDialog, setShowPartnerRelationshipsDialog] = useState(false);
  const [showPartnerMustDisconnectDialog, setShowPartnerMustDisconnectDialog] = useState(false);
  const [showPSAProjectDialog, setShowPSAProjectDialog] = useState(false);
  const [options, setOptions] = useState({
    canEditCurrency: true,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    refetch: fetchCustomer,
    data,
    isSuccess,
  } = useCustomerQuery(customerId, {
    enabled: false,
  });

  useEffect(() => {
    if (isSuccess) {
      // if this customer does not have a default service config:
      data.defaultServiceConfig = data.defaultServiceConfig || initialDefaultServiceConfig;

      // if this customer does not have a default reseller:
      data.resellerPartnerId = data.resellerPartnerId || null;

      // if this customer does not have a default distributor:
      data.distributorPartnerId = data.distributorPartnerId || null;

      // if this customer does not have a default deal type:
      data.dealType = data.dealType || DealType.DIRECT.enumKey;

      setCustomer(prevState => ({
        ...prevState,
        ...data,
      }));
      setLocations(data.locations);
      setOriginalLocations(data.locations);
      setOriginalCustomer(cloneDeep(data));

      if (!isOneOfRoles(roles.SUPER, roles.SERVICE_DEV) && customer && customer.status === 'BILLABLE' && customer?.contractCurrency !== undefined) {
        setOptions(prevState => ({
          ...prevState,
          canEditCurrency: false,
        }));
      }
    }
  }, [isSuccess, data]);

  const onCustomerSaveSuccess = useCallback(() => {
    setIsSaving(false);
    const modifiedCustomer = { ...customer };
    delete modifiedCustomer.locations;
    setCustomer(modifiedCustomer);
    setOriginalCustomer(modifiedCustomer);
    const modifiedLocations = [];
    setLocations(modifiedLocations);
    setOriginalLocations(modifiedLocations);
    setTimeout(() => {
      navigate('/customers');
    }, 100);
  }, [customer, navigate]);

  const onCustomerSaveError = useCallback((error) => {
    setIsSaving(false);
    setResponse({
      status: 'critical',
      message: error.message,
    });
  }, []);

  const {
    mutate: createCustomer,
  } = useCustomerCreateMutate({
    onSuccess: onCustomerSaveSuccess,
    onError: onCustomerSaveError,
  });

  const {
    mutate: updateCustomer,
  } = useCustomerUpdateMutate(customerId, {
    onSuccess: onCustomerSaveSuccess,
    onError: onCustomerSaveError,
  });

  const {
    data: currenciesData,
    isError: currenciesIsError,
    error: currenciesError,
  } = useCurrenciesQuery();

  useEffect(() => {
    if (!currenciesIsError && currenciesData) {
      setCurrencies(new OptionsBuilder(currenciesData).labelKey('displayName')
        .valueKey('currencyCode')
        .build());
    }
    if (currenciesIsError && currenciesError) {
      setResponse({
        status: 'critical',
        title: 'Fetch Currencies Error',
        message: currenciesError.message,
      });
    }
  }, [currenciesData, currenciesError, currenciesIsError]);

  const {
    data: localesData,
    isError: localesIsError,
    error: localesError,
  } = useLocalesQuery();

  useEffect(() => {
    if (!localesIsError && localesData) {
      setLocales(new OptionsBuilder(localesData).labelKey('displayName')
        .valueKey('localeCode')
        .build());
    }
    if (localesIsError) {
      setResponse({
        status: 'critical',
        title: 'Fetch Locales Error',
        message: localesError.message,
      });
    }
  }, [localesData, localesIsError, localesError]);

  useEffect(() => {
    if (customerId) {
      setAdding(false);
      fetchCustomer();
    } else {
      setCustomer({
        id: 'HP-',
        status: 'INCOMPLETE',
        dealType: 'DIRECT',
        contractStartMonth: moment()
          .format('YYYY-MM'),
        defaultServiceConfig: initialDefaultServiceConfig,
      });
    }
  }, []);

  const onDealTypeChange = (dealType) => {
    const {
      resellerPartnerId,
      distributorPartnerId,
    } = { ...customer };
    const [, partnerErrors] = validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType);

    setCustomer(prevState => ({
      ...prevState,
      dealType,
    }));
    setErrors({
      ...errors,
      ...partnerErrors,
    });
  };

  const onChangeCallback = (name, isMultiple, value, countryOptions) => {
    const attribute = name;

    switch (attribute) {
      case 'name':
      case 'id':
        customer[attribute] = value;
        break;
      case 'secureSite':
        customer[attribute] = value;
        break;
      default:
        customer[attribute] = value;
    }

    delete errors[attribute];
    errors[attribute] = [];

    // validate email to be valid email as the user types:
    switch (attribute) {
      case 'id':
        if (!customer[attribute]) { // at least 8 character
          errors[attribute].push('Required');
        } else {
          const accountIdSections = customer[attribute].split('-');

          // example:
          // HP-region-subregion-country-customerID
          // HP-AMS-001-BRA-00334

          // It should start with HP
          const hp = (accountIdSections && accountIdSections[0] ? accountIdSections[0] : undefined);
          if (hp !== 'HP') {
            errors[attribute].push('Must start with HP');
          }

          // Region should be AMS, APJ, EMEA, or TEST:
          // region:
          const region = (accountIdSections && accountIdSections[1] ? accountIdSections[1] : undefined);
          if (!region || region.length === 0) {
            errors[attribute].push('Region Missing');
          } else if (['AMS', 'APJ', 'EMEA', 'TEST'].indexOf(region) === -1) {
            errors[attribute].push('Invalid Region (AMS, APJ, EMEA or TEST)');
          }

          // subregion:
          const subregion = (accountIdSections && accountIdSections[2] ? accountIdSections[2] : undefined);
          if (!subregion || subregion.length === 0) {
            errors[attribute].push('Sub region Missing');
          } else if (subregion.length < 2 || subregion.length > 5 || /[^A-Z0-9]+/.test(subregion)) {
            errors[attribute].push('Invalid Sub region (2-5 characters, A-Z and 0-9)');
          }

          // country:
          const country = (accountIdSections && accountIdSections[3] ? accountIdSections[3] : undefined);
          if (!country || country.length === 0) {
            errors[attribute].push('Country Missing');
          } else if (!countryOptions.includes(country)) {
            errors[attribute].push('Invalid Country (must be one from the list)');
          }

          // Customer ID should be 5 digits
          const customerAccountId = (accountIdSections && accountIdSections[4] ? accountIdSections[4] : undefined);
          if (!customerAccountId || customerAccountId.length === 0) {
            errors[attribute].push('Billing ID Missing');
          } else if (customerAccountId.length !== 5 || /^\d+$/.test(customerAccountId) === false) {
            errors[attribute].push('Invalid Billing Id (5 digits)');
          }
        }
        break;
      case 'secureSite':
      case 'companyId':
        break;
      default:
        if (!customer[attribute]) {
          errors[attribute].push('Required');
        }
    }

    // always validate partner selection to produce any errors:
    const {
      resellerPartnerId,
      distributorPartnerId,
      dealType,
    } = { ...customer };
    const [, partnerErrors] = validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType);

    setCustomer(prevState => ({
      ...prevState,
      ...customer,
    }));
    setErrors({
      ...errors,
      ...partnerErrors,
    });
  };

  const onContractDateChange = (date) => {
    // update customer:
    setCustomer(prevState => ({
      ...prevState,
      contractStartMonth: date,
    }));

    // update error:
    const attribute = 'contractStartMonth';
    delete errors[attribute];
    errors[attribute] = validateDate(date, customer.contractEndMonth);

    setErrors(prevState => ({
      ...prevState,
      ...errors,
    }));
  };

  const onServiceChange = (event) => {
    const { defaultServiceConfig } = customer;
    if (event) {
      switch (event.target.type) {
        case 'radio': {
          const [property, value] = event.target.name.split(':');
          defaultServiceConfig[property] = value;
          break;
        }
        case 'number': {
          const {
            name: property,
            value: rawValue,
            min,
            max,
          } = event.target;
          const value = Math.max(min || -Infinity, Math.min(max || Infinity, rawValue));
          defaultServiceConfig[property] = value;
          break;
        }
        default: {
          const property = event.target.name;
          const { value } = event.target;
          defaultServiceConfig[property] = value;
          break;
        }
      }
    }

    setCustomer(prevState => ({
      ...prevState,
      defaultServiceConfig,
    }));
  };

  const getTotalErrors = () => Object.values(errors)
    .reduce((sum, error) => sum + error.length, 0);

  const onSubmitCustomer = () => {
    setIsSubmitted(true);
    // Save to Backend
    const savingCustomer = { ...customer };
    savingCustomer.locations = locations;

    if (isSaving) {
      console.warn('Already saving customer...');
      return;
    }

    let validationErrors = getTotalErrors() > 0;

    const {
      id,
      name,
      contractStartMonth,
      contractCurrency,
      locale,
      resellerPartnerId,
      distributorPartnerId,
      dealType,
      psaProject,
      psaNotRequired,
      purpose
    } = savingCustomer;

    if (!id || id === 'HP-') {
      errors.id = ['Required'];
      validationErrors = true;
    }

    if (!name) {
      errors.name = ['Required'];
      validationErrors = true;
    }

    if (!contractStartMonth) {
      errors.contractStartMonth = ['Required'];
      validationErrors = true;
    }

    if (!contractCurrency) {
      errors.contractCurrency = ['Required'];
      validationErrors = true;
    }

    if (!locale) {
      errors.locale = ['Required'];
      validationErrors = true;
    }

    if (!purpose) {
      errors.purpose = ['Required'];
      validationErrors = true;
    }

    // validate partner selection:
    const [hasPartnerErrors, partnerErrors] = validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType);

    // If PSA Project is required
    if (!psaNotRequired) {
      if (!psaProject || !psaProject.projectId) {
        errors.psaProjectId = ['Required'];
        validationErrors = true;
      }
    }

    if (validationErrors || hasPartnerErrors) {
      setShowFormErrors(true);
      setErrors({
        ...errors,
        ...partnerErrors,
      });
      return;
    }

    // update status:
    if (savingCustomer.status === 'REQUEST') {
      savingCustomer.status = 'INCOMPLETE';
    }

    // remove psaNotRequired flag is user is not Admin:
    const isPSAPageNotRequired = hasPermissions(pagePermissions.customers.view.psa.page_notRequired);
    if (!isPSAPageNotRequired) {
      delete savingCustomer.psaNotRequired;
    }

    // clean up distributor/reseller if direct:
    if (dealType === DealType.DIRECT.enumKey) {
      delete savingCustomer.resellerPartnerId;
      delete savingCustomer.distributorPartnerId;
    } else if (dealType === DealType.RESELLER.enumKey) {
      delete savingCustomer.distributorPartnerId;
    }

    setIsSaving(true);
    setTimeout(() => {
      if (adding) {
        createCustomer(savingCustomer);
      } else {
        updateCustomer(savingCustomer);
      }
    });
  };

  const onCloseOrCancel = () => {
    window.history.back();
  };

  const isDirty = useMemo(() => (JSON.stringify(customer) !== JSON.stringify(originalCustomer)
    || JSON.stringify(locations) !== JSON.stringify(originalLocations)), [customer, originalCustomer, locations, originalLocations]);

  const isInProduction = useMemo(() => (customer?.status === 'BILLABLE'), [customer?.status]);

  const showHistoricalWarning = useMemo(() => isDirty && isInProduction, [isDirty, isInProduction]);

  const renderWarning = () => {
    if (showHistoricalWarning) {
      if (customer?.dealType === 'DIRECT') {
        return (
          <WarningBanner
            message='The changes you are making could affect historical reports. If this is not your intention, please cancel these changes and start again.'
          />
        );
      }
      return (
        <WarningBanner
          message='The changes you are making could affect historical reports and may reset downstream rates, which would require partners to re-enter rates after the change. If this is not your intention, please cancel these changes and start again.'
        />
      );
    }
    return '';
  };

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response.status}
          title={response.title}
          message={response.message}
          onClose={() => onToastClose()}
        />
      );
    }
    return message;
  };

  const renderLayer = () => {
    if (showPartnerRelationshipsDialog) {
      return (
        <PartnerRelationshipsDialog
          customer={customer}
          onCancel={() => setShowPartnerRelationshipsDialog(false)}
          onSubmit={(updatedCustomer) => {
            setCustomer(updatedCustomer);
            setShowPartnerRelationshipsDialog(false);
          }}
        />
      );
    }
    if (showPartnerMustDisconnectDialog) {
      return (
        <PartnerRelationshipsMustDisconnectDialog
          onCancel={() => setShowPartnerMustDisconnectDialog(false)}
          onSubmit={() => {
            setShowPartnerMustDisconnectDialog(false);
            navigate(`/customers/${customer.id}/tenant`);
          }}
        />
      );
    }
    if (showPSAProjectDialog) {
      const isPSAPageNotRequired = hasPermissions(pagePermissions.customers.view.psa.page_notRequired);

      return (
        <PSAProjectInfoDialog
          isEdit={!!(customer.psaProject)}
          isAdmin={isPSAPageNotRequired}
          customer={customer}
          onCancel={() => setShowPSAProjectDialog(false)}
          onSubmit={(updatedCustomer) => {
            const updatedErrors = { ...errors };
            if (updatedErrors) {
              updatedErrors.psaProjectId = [];
            }
            setErrors(prevState => ({
              ...prevState,
              ...updatedErrors,
            }));
            setCustomer(updatedCustomer);
            setShowPSAProjectDialog(false);
          }}
        />
      );
    }
    return '';
  };

  const renderEditorCTAButtons = () => {
    const hasErrors = getTotalErrors() > 0;
    const editCTAButtons = (
      <Box
        direction='row'
        gap='small'
        pad={{
          horizontal: 'small',
          vertical: 'small',
        }}
        border='top'
      >
        <Button
          label='Save'
          id={IDUtil.getId('EditorViewToolbarSaveButton')}
          type='button'
          primary={true}
          disabled={isSaving}
          onClick={onSubmitCustomer}
        />
        <Button
          label='Cancel'
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          type='button'
          secondary={true}
          disabled={isSaving}
          onClick={onCloseOrCancel}
        />
        {isSaving
          && (
            <Box
              direction='row'
              align='center'
              gap='small'
              justify='start'
              fill={true}
              margin='none'
              pad='none'
            >
              <Spinner />
              <Text>Saving. Please wait ...</Text>
            </Box>
          )}
        <Box direction='row' align='center' justify='center'>
          <Collapsible open={showFormErrors && hasErrors}>
            <Box
              direction='row'
              align='center'
              gap='small'
              margin={{ left: 'medium' }}
              justify='center'
            >
              <StatusCritical color='status-critical' />
              <GLBMTooltip
                content='Resolve each form error and then try to save again.'
              >
                <Text
                  weight={100}
                  margin='none'
                  style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)' }}
                >
                  Form Errors Found.
                </Text>
              </GLBMTooltip>
            </Box>
          </Collapsible>
        </Box>
      </Box>
    );
    const readOnlyCTAButtons = (
      <Box
        direction='row'
        gap='small'
        pad={{
          horizontal: 'small',
          vertical: 'small',
        }}
        border='top'
      >
        <Button
          label='Close'
          id={IDUtil.getId('EditorViewToolbarCloseButton')}
          type='button'
          secondary={true}
          onClick={onCloseOrCancel}
        />
      </Box>
    );

    return readOnly ? readOnlyCTAButtons : editCTAButtons;
  };

  const renderDefaultServiceField = () => {
    const { defaultServiceConfig } = customer || {};
    return defaultServiceConfig && (
      <DefaultServiceForm
        readOnly={readOnly}
        onChange={onServiceChange}
        defaultServiceConfig={defaultServiceConfig}
      />
    );
  };

  const determinePartnerRelationshipView = () => {
    const production = customer.status === CustomerStatusEnum.billable;
    const canView = hasPermissions(pagePermissions.customers.view.actions.viewPartner);

    let view = production ? PartnerRelationshipView.PROTECTED : PartnerRelationshipView.EDIT;
    view = readOnly ? PartnerRelationshipView.READ_ONLY : view;
    return canView ? view : PartnerRelationshipView.HIDDEN;
  };

  const renderPSAProjectInfo = () => {
    if (customer) {
      return (
        <PSAProjectInfoForm
          readOnly={readOnly}
          onEdit={() => setShowPSAProjectDialog(true)}
          customer={customer}
          errors={errors}
          dirty={isDirty}
          user={me}
        />
      );
    }
    return '';
  };

  const renderPartnerRelationships = () => {
    if (customer) {
      return (
        <PartnerRelationshipForm
          onPartnerChange={onChangeCallback}
          onDealTypeChange={onDealTypeChange}
          onEdit={() => {
            if (customer.customerTenantId) {
              setShowPartnerMustDisconnectDialog(true);
            } else {
              setShowPartnerRelationshipsDialog(true);
            }
          }}
          view={determinePartnerRelationshipView()}
          customer={customer}
          errors={errors}
          isSubmitted={isSubmitted}
        />
      );
    }
    return '';
  };

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      {renderWarning()}
      <GLBMHeading
        back='/customers'
        title={`${readOnly ? 'View' : 'Edit'} Billing Info: ${!adding ? customer.id : ''}`}
      />
      <Box
        flex={true}
        pad={{
          horizontal: 'medium',
          bottom: 'medium',
        }}
        overflow='auto'
        gap='small'
      >
        <Box flex={false} width='480px'>
          <BillingInfoForm
            adding={adding}
            errors={errors}
            readOnly={readOnly}
            customer={customer}
            currencies={currencies}
            locales={locales}
            canEditCurrency={options.canEditCurrency}
            onDOMChange={onChangeCallback}
            onChange={onContractDateChange}
          />
        </Box>
        <Box flex={false} width='480px'>
          {renderPSAProjectInfo()}
        </Box>
        <Box flex={false} width='480px'>
          {renderPartnerRelationships()}
        </Box>
        <Box flex={false} width='480px'>
          {renderDefaultServiceField()}
        </Box>
        <Box flex={false} width='480px'>
          <LocationForm
            locations={locations}
            readOnly={readOnly}
            customer={customer}
            onChange={setLocations}
          />
        </Box>
      </Box>
      {renderEditorCTAButtons()}
      <ReactRouterPrompt when={isDirty}>
        {({
          onConfirm,
          onCancel,
        }) => (
          <SaveChangesDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
      {renderToast()}
      {renderLayer()}
    </Main>
  );
};

EditCustomerPage.contextTypes = {
  router: PropTypes.object,
};

export default EditCustomerPage;
