// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Header, Heading, Notification, Text } from 'grommet';
import IDUtil from '../shared/util/IDUtil';
import { useMessages } from '../../hooks';

const UnsupportedConfigurationPage = ({ message = undefined, details = undefined }) => {
  const getMessage = useMessages();

  return (
    <Box
      fill={true}
      direction='column'
    >
      <Box fill={true} background='#425563'>
        <Box fill={true} direction='row' justify='center'>
          <Box fill='vertical' justify='center'>
            <Box
              overflow='auto'
              direction='column'
            >
              <Box
                style={{ overflow: 'hidden' }}
                background='white'
                elevation='xlarge'
                round={true}
              >
                <Box id={IDUtil.getId('UnsupportedConfigurationPage')}>
                  <Box pad='large'>
                    <Box
                      pad='medium'
                      id='unsupportedConfigurationForm'
                    >
                      <Header flex={false} direction='column'>
                        <Box style={{ marginBottom: '0px' }}>
                          <img src='/img/hpe_pri_grn_pos_rgb.svg' height='177' alt='HPE Logo' />
                        </Box>
                        <Heading
                          style={{ fontSize: '34px' }}
                          strong={true}
                          uppercase={false}
                          truncate={false}
                        >
                          {getMessage('app.name')}
                        </Heading>
                      </Header>
                    </Box>
                    <Box align='end' pad={{ horizontal: 'medium' }} margin={{ vertical: 'large' }} round={true}>
                      <Notification
                        status='critical'
                        global={true}
                        title='Unable to access GLBM - This service is currently unavailable.'
                        message={(
                          <Box>
                            {message && (<Text>{message}</Text>)}
                            {details && (<Text weight='lighter'>{details}</Text>)}
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

UnsupportedConfigurationPage.propTypes = {
  message: PropTypes.string,
  details: PropTypes.string,
};

export default UnsupportedConfigurationPage;
