// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Button } from 'grommet';

const ClickableTableCell = ({ label, ...buttonProps }) => (
  <Button {...buttonProps}>
    <Box pad={{ vertical: 'xsmall', horizontal: 'small' }}>
      <Text weight='bold'>{label}</Text>
    </Box>
  </Button>
);

ClickableTableCell.propTypes = {
  label: PropTypes.string,
};

ClickableTableCell.defaultProps = {
  label: null,
};

export default ClickableTableCell;
