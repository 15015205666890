// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Text } from 'grommet';
import CARadioButton from '../../../../../shared/form/CARadioButton';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import CATextInput from '../../../../../shared/form/CATextInput';
import { parseStringAsPercent } from './utils';

const LinuxBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _onChangeUtilization = (property, value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config[property] = parseStringAsPercent(value);
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _onChangeBillingMethod = (linuxUtilBillingMethod) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.linuxUtilBillingMethod = linuxUtilBillingMethod;
    // eslint-disable-next-line default-case
    switch (linuxUtilBillingMethod) {
      case 'PER_HOST_UTILIZATION':
        newState.options.config.billingMethodUtilization = 3;
        break;
      case 'PER_CORE_UTILIZATION':
        newState.options.config.billingMethodUtilization = 3;
        break;
    }
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _getEditableLayout = () => (
    <FormField label='Set billing method to'>

      <CARadioButton
        label='Per core utilization (Core ON/OFF based on core utilization)'
        id='radio_billing_options_per_core_utilization'
        name='radio_billing_options_per_core_utilization'
        onChange={() => _onChangeBillingMethod('PER_CORE_UTILIZATION')}
        checked={serviceEditor.options.config.linuxUtilBillingMethod === 'PER_CORE_UTILIZATION'}
      />

      {serviceEditor.options.config.linuxUtilBillingMethod === 'PER_CORE_UTILIZATION'
          && (
            <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center'>
              <Box style={{ minWidth: '50px' }} />
              <div>
                <CATextInput
                  value={serviceEditor.options.config.billingMethodUtilization}
                  name='perCoreUtilization'
                  onChange={(value) => {
                    _onChangeUtilization('billingMethodUtilization', value);
                  }}
                  id='perCoreUtilization'
                />
              </div>
              <Text weight={100} size='small'>Utilization %</Text>
            </Box>
          )}

      <CARadioButton
        label='Per host utilization (Host ON/OFF based on total CPU utilization per host)'
        id='radio_billing_options_per_host_utilization'
        name='radio_billing_options_per_host_utilization'
        onChange={() => _onChangeBillingMethod('PER_HOST_UTILIZATION')}
        checked={serviceEditor.options.config.linuxUtilBillingMethod === 'PER_HOST_UTILIZATION'}
      />

      {serviceEditor.options.config.linuxUtilBillingMethod === 'PER_HOST_UTILIZATION'
          && (
            <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center'>
              <Box style={{ minWidth: '50px' }} />
              <div>
                <CATextInput
                  value={serviceEditor.options.config.billingMethodUtilization}
                  name='perHostUtilization'
                  onChange={(value) => {
                    _onChangeUtilization('billingMethodUtilization', value);
                  }}
                  id='perHostUtilization'
                />
              </div>
              <Text weight={100} size='small'>Utilization %</Text>
            </Box>
          )}
    </FormField>
  );

  const _getReadOnlyLayout = () => {
    const labelFromBillingMethodMap = new Map()
      .set('PER_CORE_UTILIZATION', 'Per core utilization (Core ON/OFF based on core utilization)')
      .set('PER_HOST_UTILIZATION', 'Per host utilization (Host ON/OFF based on average core CPU utilization per host)');

    const { linuxUtilBillingMethod } = serviceEditor.options.config;

    return (
      <ReadOnlyField label='Billing Method' value={labelFromBillingMethodMap.get(linuxUtilBillingMethod)} />
    );
  };

  return props.readOnly ? _getReadOnlyLayout() : _getEditableLayout();
};

LinuxBillingMethodField.propTypes = {
  setDirty: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default LinuxBillingMethodField;
