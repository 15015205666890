// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Form, FormField, Main, RadioButton, Select, Text,
} from 'grommet';
import { useOktaAuth } from '@okta/okta-react';
import RestrictedServices from '../shared/component/RestrictedServices';
import GLBMHeading from '../shared/component/GLBMHeading';
import Toast from '../shared/toast/Toast';
import { StatusIcon } from '../shared/component/StatusIcon';
import IDUtil from '../shared/util/IDUtil';
import { UserType } from '../shared/constants/UserType';
import UserCustomerPage from '../users/UserCustomerPage';
import {
  useAccessApproversQuery, useAccessBillingQuery, useAccessServiceQuery, useMutationRequestAccess, useUserRoleQuery
} from '../../core';
import UserStore from '../stores/UserStore';
import AuthActions from '../actions/AuthActions';
import ErrorBoundary from '../shared/errors/ErrorBoundary';
import { isAssignedRole } from '../stores/UserTypeStore';

const RequestRolePage = () => {
  const navigate = useNavigate();
  const me = UserStore.getUser();
  const isExist = me.role && (me.role !== UserType.UNASSIGNED?.value && me.role !== UserType.REVOKED?.value);
  const initForm = isExist ? { role: me.role, accounts: JSON.parse(JSON.stringify(me.assignedCustomers)), services: JSON.parse(JSON.stringify(me.restrictedServices)) } : {};
  const [requestForm, setRequestForm] = useState(initForm);
  const [errors, setErrors] = useState({});
  const { oktaAuth } = useOktaAuth();
  const [choosenApprover, setChoosenApprover] = useState({});
  const {
    data: userRoles,
  } = useUserRoleQuery();

  const userRolesToBeFiltered = userRoles?.filter(el => el.showAccessChangeMenu === true);
  const roleOptions = userRolesToBeFiltered?.filter(el => isAssignedRole(el.id)).map((el) => {
    if (el.id === 'ASM') return { ...el, sub: 'ASM, CSS, Transition Manager, Implementation Manager, Client Manager, Customer Success Manager' };
    if (el.id === 'BUSINESS') return { ...el, sub: 'Solution Ops' };
    if (el.id === 'SUPPORT') return { ...el, sub: 'GPST' };
    return el;
  });

  const {
    data: billingAccounts,
    isLoading: isLoadingbillingAccounts,
    isError: isGetBillingAccountsError,
    error: getBillingAccountsError,
  } = useAccessBillingQuery();

  const {
    data: serviceDefs,
    isLoading: isLoadingServiceDefs,
    isError: isGetServiceDefsError,
    error: getServiceDefsError,
  } = useAccessServiceQuery();

  const {
    data: approvers,
    isError: isGetApproversError,
    error: getApproversError,
  } = useAccessApproversQuery();

  const {
    mutate: submitRequest,
    isLoading: isSubmitRequestLoading,
    isError: isSubmitRequestError,
    error: submitRequestError,
    isSuccess: isSubmitRequestSuccess,
    reset: clearSubmitRequestMutation,
  } = useMutationRequestAccess(isExist, {
    onSuccess: () => {
      setRequestForm(initForm);
      setErrors({});
    },
  });

  const accountsOptions = useMemo(() => {
    if (billingAccounts) {
      const result = {};
      billingAccounts.forEach((el) => {
        result[el.id] = { ...el };
      });
      return result;
    }
    return {};
  }, [billingAccounts]);

  const approversOptions = useMemo(() => {
    if (approvers) {
      return approvers.filter(el => el.role === requestForm.role)
        .map((app, i) => ({
          value: app.id, label: `${app.location} (${app.name})`, sub: `${app.name} (${app.emails.join(', ')})`, index: i,
        }));
    }
    return [];
  }, [requestForm.role, approvers]);

  const logout = async () => {
    if (isExist) {
      navigate(-1);
    } else {
      const idToken = await oktaAuth.tokenManager.get('idToken');
      const accessToken = await oktaAuth.tokenManager.get('accessToken');

      await AuthActions.logout(true);
      await oktaAuth.signOut({ idToken, accessToken });
    }
  };

  const submit = () => {
    let noErrors = true;
    const updatedErrors = { ...errors };
    if (!requestForm.role) {
      updatedErrors.role = 'Required';
      noErrors = false;
    } else {
      if (!requestForm.approverId || requestForm.approverId === undefined || requestForm.approverId === '') {
        updatedErrors.approverId = 'Required';
        noErrors = false;
      }
      if (requestForm.role === UserType.ASM?.value && (!requestForm.accounts || requestForm.accounts.length === 0)) {
        updatedErrors.accounts = 'At least 1 billing account is required';
        noErrors = false;
      }
    }
    if (noErrors) {
      submitRequest(requestForm);
    } else {
      setErrors(updatedErrors);
    }
  };
  const onChangeRole = (value) => {
    const updatedForm = { ...requestForm };
    updatedForm.role = value;
    const updatedErrors = {};
    delete updatedErrors.role;
    if (value !== requestForm.role) {
      updatedForm.approverId = undefined;
      updatedForm.accounts = undefined;
      updatedForm.services = undefined;
    }

    setRequestForm(updatedForm);
    setErrors(updatedErrors);
  };

  const onChangeApprover = (value) => {
    const approver = approversOptions.find(el => el.index === value);
    setChoosenApprover(approver);
    const updatedForm = { ...requestForm };
    updatedForm.approverId = approver && approver.value;
    const updatedErrors = { ...errors };
    delete updatedErrors.approverId;

    setRequestForm(updatedForm);
    setErrors(updatedErrors);
  };

  const onChangeServices = (services) => {
    const updatedForm = { ...requestForm };
    updatedForm.services = services;
    setRequestForm(updatedForm);
  };
  const onAccountsChange = (assigned) => {
    const resultForm = {
      ...requestForm,
      accounts: assigned
        .filter(el => el.action !== 'DELETE')
        .map(acc => ({ customerId: acc.customerId, asmRole: acc.asmRole, action: acc.action })),
    };
    const updatedErrors = { ...errors };
    delete updatedErrors.accounts;
    setRequestForm(resultForm);
    setErrors(updatedErrors);
  };

  const renderToast = () => {
    let message = '';
    if (isGetBillingAccountsError) {
      message = (
        <Toast
          status='critical'
          message={getBillingAccountsError.response.data.message}
        />
      );
    } else if (isGetServiceDefsError) {
      message = (
        <Toast
          status='critical'
          message={getServiceDefsError.response.data.message}
        />
      );
    } else if (isGetApproversError) {
      message = (
        <Toast
          status='critical'
          message={getApproversError.response.data.message}
        />
      );
    } else if (isSubmitRequestError) {
      message = (
        <Toast
          status='critical'
          onClose={clearSubmitRequestMutation}
          message={submitRequestError.response.data.consumerMessage}
        />
      );
    }
    return message;
  };

  function renderErrorMessage(renderError) {
    if (renderError) {
      return (
        <Box
          direction='row'
          align='center'
          pad={{ between: 'small' }}
        >
          <StatusIcon value='critical' />
          <Text size='medium' margin='none'>
            {renderError}
          </Text>
        </Box>
      );
    }
    return (null);
  }

  if (!requestForm) {
    return (<Box />);
  }

  return (
    <Box fill={true}>
      <ErrorBoundary>
        <Main direction='column' fill='vertical' overflow='hidden'>
          <GLBMHeading
            title='Access Request Form'
          />
          <Box flex={true}>
            {isSubmitRequestSuccess
              ? (
                <Box margin={{ left: 'medium' }}>
                  <Text>
                    An email has been sent to you and approver(s), please follow the instructions in the email for next steps.
                  </Text>
                </Box>
              )
              : (
                <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
                  <Box flex={true} pad={{ horizontal: 'medium' }}>
                    <Form>
                      <FormField
                        label='Select Role'
                        error={errors.role}
                        width={{ max: 'large' }}
                      >
                        <Box
                          margin={{
                            right: 'medium', left: 'medium', top: 'small', bottom: 'small',
                          }}
                        >
                          {roleOptions && roleOptions.map(el => (
                            <Box key={el.value} id={IDUtil.getId('RequestFormRole')}>
                              <RadioButton
                                name='role'
                                id={el.id}
                                label={el.displayName}
                                value={el.id}
                                checked={el.id === requestForm.role}
                                onChange={() => onChangeRole(el.id)}
                              />
                              <Text style={{ marginLeft: '48px', marginBottom: '12px' }} size='small'>{el.sub}</Text>
                            </Box>
                          ))}
                        </Box>
                      </FormField>
                      {requestForm.role && (
                        <Box>
                          <FormField label='Select Approver(s)' error={errors.approverId} width='medium'>
                            <Select
                              name='approverId'
                              options={approversOptions}
                              labelKey='label'
                              valueKey={{ key: 'index', reduce: true }}
                              value={choosenApprover && choosenApprover.index}
                              valueLabel={<Box pad={{ horizontal: '10px', vertical: '5px' }} height='34px'><Text truncate={true}>{choosenApprover && choosenApprover.label}</Text></Box>}
                              id={IDUtil.getId('RequestFormApproversDropDown')}
                              onChange={e => onChangeApprover(e.value)}
                            />
                          </FormField>
                          <Box style={{ minHeight: '22px' }} margin={{ top: 'small', bottom: 'small' }}>
                            {choosenApprover && choosenApprover.sub && (
                              <Text margin='none'>
                                <strong>Approver(s):</strong>
                                {' '}
                                {choosenApprover && choosenApprover.sub}
                              </Text>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Form>
                    {requestForm && UserType.enumValueOf(requestForm.role)?.restrictAccounts ? (
                      <UserCustomerPage
                        error={errors.accounts}
                        role={requestForm && requestForm.role}
                        loading={isLoadingbillingAccounts}
                        customers={accountsOptions}
                        assigned={requestForm && requestForm.accounts ? requestForm.accounts : []}
                        onChange={onAccountsChange}
                        isRequest={true}
                      />
                    ) : ''}
                    {requestForm && UserType.enumValueOf(requestForm.role)?.restrictServices ? (
                      <RestrictedServices
                        loading={isLoadingServiceDefs}
                        services={serviceDefs ? serviceDefs.map(({ id, name }) => ({
                          type: id,
                          label: name,
                        })) : []}
                        assigned={requestForm.services || []}
                        onChange={onChangeServices}
                      />
                    ) : ''}
                  </Box>
                </Box>
              )}
          </Box>
          {renderToast()}
          {!isSubmitRequestSuccess && (
            <Box
              border='top'
              direction='row'
              pad={{ between: 'small', horizontal: 'small', vertical: 'small' }}
              gap='small'
              flex={false}
            >
              <Button
                label='Submit'
                id={IDUtil.getId('RequestFormSubmitButton')}
                type='button'
                primary={true}
                disabled={isSubmitRequestLoading}
                onClick={submit}
              />
              <Button
                label='Cancel'
                id={IDUtil.getId('RequestFormCancelButton')}
                type='button'
                secondary={true}
                onClick={logout}
              />
              {renderErrorMessage(errors.accounts)}
            </Box>
          )}
        </Main>
      </ErrorBoundary>
    </Box>
  );
};

export default RequestRolePage;
