// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button, CheckBox,
  Form,
  FormField,
  Header, Heading,
  Text, TextInput,
} from 'grommet';
import moment from 'moment';
import ErrorBanner from '../../shared/banner/ErrorBanner';
import TypeLabel from '../../shared/component/TypeLabel';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import Loader from '../../shared/loader';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import { PSAConstants } from './PSAConstants';

const PSAProjectInfoPanel = ({
  isAdmin = false,
  psaNotRequired = false,
  psaProjectId = '',
  psaProject = {},
  psaProjectServices = [],
  isFetching = false,
  showPsaPortalActivationDate = false,
  psaPortalActivationDate = '',
  queryError = {},
  errors = {},
  setPsaNotRequired = () => {},
  setPsaProjectId = () => {},
  setPsaProjectServices = () => {},
  setErrors = () => {},
  refresh = () => { }
}) => {
  const onPSAChanged = (attribute, value) => {
    switch (attribute) {
      case 'psaNotRequired':
        setPsaNotRequired(value);
        if (value) {
          setPsaProjectServices([]);
        }
        break;
      case 'psaProjectId':
        setPsaProjectId(value);
        break;
      case 'psaProjectServices':
        setPsaProjectServices(value);
        break;
      default:
        break;
    }

    const newErrors = { ...errors };
    delete newErrors[attribute];
    newErrors[attribute] = [];

    setErrors(newErrors);
  };

  function renderBaseProjectID(error) {
    return (
      <Box direction='column' align='start' flex={false} gap='small' data-e2e='base-project-id-section'>
        {isAdmin && (
        <CheckBox
          checked={psaNotRequired}
          label='Do not require Base Project ID'
          onChange={(event) => {
            onPSAChanged('psaNotRequired', event.target.checked);
          }}
        />
        )}
        <Form>
          <FormField
            label='Base Project ID'
            help='Enter Base Project ID, then click Get PSA Data'
            error={!psaNotRequired && error?.length ? error[0] : undefined}
          >
            <TextInput
              plain={true}
              name='psaProjectId'
              disabled={psaNotRequired}
              value={psaNotRequired ? PSAConstants.NA : (psaProjectId || '')}
              onChange={!psaNotRequired ? (event) => {
                onPSAChanged('psaProjectId', event.target.value);
              } : undefined}
            />
          </FormField>
          {
          showPsaPortalActivationDate && (
            <FormField
              label='Activation Date'
              help='Will be set when all project services are in production.'
            >
              {
                psaPortalActivationDate ? (
                  <Text>
                    {psaPortalActivationDate && moment(psaPortalActivationDate).format('L LTS')}
                  </Text>
                ) : <TextInput disabled={true} plain={true} />
              }
            </FormField>
          )
        }
        </Form>
        <Box gap='small' direction='row'>
          <Box height={{ max: '36px' }} direction='row' align='center' gap='small'>
            <Button
              label='Get PSA Data'
              primary={true}
              disabled={!psaProjectId || isFetching || psaNotRequired}
              onClick={() => refresh()}
            />
            <Button
              label='Go To PSA'
              secondary={true}
              disabled={isFetching || !psaProject?.url}
              onClick={() => {
                window.open(psaProject.url, '_blank');
              }}
            />
          </Box>
          {
          isFetching && (
            <Box direction='row' align='center' gap='small' justify='center'>
              <Loader text='Loading ...' />
            </Box>
          )
        }
        </Box>
      </Box>
    );
  }

  function renderPSAError(error) {
    if (!error) {
      return '';
    }
    return (<ErrorBanner title='Retrieval of PSA data failed' message={error.message} />);
  }

  function renderPSAData(psa) {
    return (
      <Box flex={false} pad={{ vertical: 'medium' }} gap='small' width='large'>
        <GLBMNameValueList
          title='Company Info'
          data={[
            { label: 'Delivery Company Name', value: psa?.gsdDeliveryCoName || '' },
            { label: 'Delivery Account Id', value: psa?.gsdServiceDeliveryAccount || '' },
            { label: 'Sold to Account Name', value: psa?.accountId || '' },
            { label: 'MCDP Org Id', value: psa?.account?.mdcpOrganizationId || '' },
            { label: 'MCDP Site Instance ID', value: psa?.account?.mdcpSiteInstituteId || '' },
          ]}
          valueProps={{ width: 'medium' }}
        />
        <GLBMNameValueList
          title='PSA Info'
          data={[
            { label: 'Project Name', value: psa?.name || '' },
            { label: 'Project Created Date', value: psa?.createdDate ? moment(psa.createdDate).format('L') : '' },
            { label: 'Contract Start Date', value: psa?.gsdContractStartDate ? moment(psa.gsdContractStartDate).format('L') : '' },
            { label: 'Purchase Order', value: psa?.customerPoNumber || '' },
            { label: 'HPE Opportunity ID', value: psa?.hpeOpportunityId || '' },
            { label: 'EMDM Party ID', value: psa?.account?.emdmPartyId || '' },
            { label: 'Top Parent ST ID', value: psa?.account?.topParentStId || '' },
            { label: 'Account ST ID', value: psa?.account?.accountStId || '' },
            { label: 'Initial Project Owner', value: psa?.ownerName || '' },
          ]}
          valueProps={{ width: 'medium' }}
        />
      </Box>
    );
  }

  const renderPSAServices = (services) => {
    const options = ServiceTypeStore.getServices().map(({ type, label }) => ({
      label: <TypeLabel label={label} />,
      value: type,
    })).sort((a, b) => a.label.props.label.localeCompare(b.label.props.label));

    return (
      <Box gap='small'>
        <Header size='small'>
          <Heading level='3' size='medium'>Check all services associated with Base Project ID:</Heading>
        </Header>
        <Text weight='lighter' data-e2e='selected-services'>{`${(services || []).length} selected service${(services || []).length === 1 ? '' : 's'}`}</Text>
        <Box gap='small'>
          {
            options.map(option => (
              <CheckBox
                key={option.value}
                checked={(services || []).includes(option.value)}
                disabled={psaNotRequired}
                label={option.label}
                onChange={(event) => {
                  let newServices = [...services];
                  if (event.target.checked) {
                    newServices.push(option.value);
                  } else {
                    newServices = newServices.filter(service => service !== option.value);
                  }
                  onPSAChanged('psaProjectServices', newServices);
                }}
              />
            ))
          }
        </Box>
      </Box>
    );
  };

  const {
    psaProjectId: psaProjectIdError,
  } = errors;

  return (
    <Box>
      <Box tag='section' flex={false} gap='small'>
        {renderBaseProjectID(psaProjectIdError)}
        {renderPSAError(queryError)}
      </Box>
      <Box flex={true}>
        <Box tag='section' flex={false}>
          {renderPSAData(psaProject)}
        </Box>
        <Box tag='section' pad={{ bottom: 'small' }} flex={false}>
          {renderPSAServices(psaProjectServices)}
        </Box>
      </Box>
    </Box>
  );
};

PSAProjectInfoPanel.propTypes = {
  isAdmin: PropTypes.bool,
  psaNotRequired: PropTypes.bool,
  psaProjectId: PropTypes.string,
  psaProject: PropTypes.object,
  psaProjectServices: PropTypes.array,
  isFetching: PropTypes.bool,
  showPsaPortalActivationDate: PropTypes.bool,
  psaPortalActivationDate: PropTypes.string,
  queryError: PropTypes.object,
  errors: PropTypes.object,
  setPsaNotRequired: PropTypes.func,
  setPsaProjectId: PropTypes.func,
  setPsaProjectServices: PropTypes.func,
  setErrors: PropTypes.func,
  refresh: PropTypes.func,
};

export default PSAProjectInfoPanel;
