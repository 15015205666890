// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import AuthActions from '../../actions/AuthActions';
import UserStore from '../../stores/UserStore';
import {
  getOktaAuthClient,
  getOktaAuthConfig,
} from '../../authentication/config';

const logoutOfApp = async () => {
  const { appEnv } = { ...window.config };
  try {
    const authClient = getOktaAuthClient(appEnv);
    await authClient.tokenManager.clear();
    // eslint-disable-next-line no-console
    console.debug('okta.oktaAuth.tokenManager.clear');
  } catch (e) {
    console.error(e);
  }
};

const logoutOfHub = async () => {
  const { appEnv } = { ...window.config };
  try {
    const authConfig = getOktaAuthConfig(appEnv);
    const authClient = getOktaAuthClient(appEnv);

    await authClient.signOut({
      postLogoutRedirectUri: authConfig.postLogoutRedirectUri,
      revokeAccessToken: true,
    });
    // eslint-disable-next-line no-console
    console.debug('okta.authClient.signOut');
  } catch (err) {
    console.error(err);
  }
};

const signOut = async () => {
  const { appEnv } = { ...window.config };
  try {
    const authClient = getOktaAuthClient(appEnv);
    const idToken = await authClient.tokenManager.get('idToken');
    const accessToken = await authClient.tokenManager.get('accessToken');
    await AuthActions.logout();
    await authClient.signOut({ idToken, accessToken });
    await logoutOfApp();
    await logoutOfHub();
  } catch (err) {
    console.error(err);
  }
};

export default {
  responseInterceptor: (res) => {
    if (res.status === 401 && UserStore.getUser().id) {
      signOut();
    }
  },
};
