// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box, Button, CheckBox, Footer, Meter, Paragraph, Text,
} from 'grommet';
import { Checkmark, Save } from 'grommet-icons';
import GLBMLayer from '../../shared/component/GLBMLayer';
import IDUtil from '../../shared/util/IDUtil';
import { StatusIcon } from '../../shared/component/StatusIcon';
import { useSaveServiceExtensionsMutation } from '../../../core';
import ServiceTypeStore from '../../stores/ServiceTypeStore';

const ServiceOption = ({
  service,
  checked = false,
  onChange,
  disabled = false,
}) => (
  <Box
    key={service.type}
    direction='column'
    align='start'
    flex={true}
    style={{ overflow: 'hidden' }}
    gap='small'
  >
    <CheckBox
      key={service.type}
      label={service.displayName}
      checked={checked}
      onChange={() => onChange(service.type)}
      toggle={false}
      disabled={disabled}
    />
  </Box>
);

ServiceOption.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  service: PropTypes.shape({
    description: PropTypes.string,
    displayName: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

const ServiceExtentionsDialog = ({
  customer = {},
  selectedServices: selectedServicesProp = [],
  existingServices = [],
  onChange,
  onClose,
}) => {
  const [selectedServices, setSelectedServices] = useState(selectedServicesProp);
  const { mutate: onSubmit, invalidateServiceExtensions, ...rest } = useSaveServiceExtensionsMutation(customer.id, selectedServices);

  useEffect(() => {
    invalidateServiceExtensions();
  }, []);

  const toggleService = (serviceType) => {
    const newSelectedServices = [...selectedServices];
    if (selectedServices.indexOf(serviceType) === -1) {
      newSelectedServices.push(serviceType);
    } else {
      newSelectedServices.splice(newSelectedServices.indexOf(serviceType), 1);
    }
    setSelectedServices(newSelectedServices);
  };

  const renderSubmitButton = (data) => {
    const { isSaved, isSaving } = data;

    if (!isSaving && !isSaved) {
      return (
        <Button
          label='Add Services'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          primary={true}
          icon={<Save />}
          onClick={selectedServices.length ? onSubmit : undefined}
        />
      );
    }
    if (isSaving && !isSaved) {
      return (
        <Button
          label='Adding ...'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          secondary={true}
          icon={<Save />}
        />
      );
    }
    if (isSaved) {
      return (
        <Button
          label='Added'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          primary={true}
          icon={<Checkmark />}
          onClick={onChange}
        />
      );
    }
    return undefined;
  };

  const renderServiceOptions = () => {
    const serviceTypes = ServiceTypeStore.getServices();
    const options = serviceTypes.filter(service => (service.manualCreate)).map(service => (
      <ServiceOption
        key={service.type}
        service={service}
        onChange={toggleService}
        disabled={existingServices.indexOf(service.type) !== -1}
        checked={existingServices.indexOf(service.type) !== -1 || selectedServices.indexOf(service.type) !== -1}
      />
    ));

    return (
      <Box direction='column' align='start' gap='small'>
        {options}
      </Box>
    );
  };

  const renderSaveContent = (data) => {
    if (!data.isSaved && !data.isSaving) {
      return '';
    }
    const total = selectedServices.length;
    return (
      <Box pad={{ 'horizontal': 'medium', 'vertical': 'small' }}>
        <Box
          direction='row'
          justify='between'
          align='center'
          gap='small'
          announce={true}
          responsive={false}
        >
          <Text weight='bold'>Adding Services</Text>
        </Box>
        <Meter
          size='large'
          min={0}
          values={[
            {
              value: data && data.success.length,
              color: 'gray',
            },
            {
              value: data && data.error.length,
              color: 'red',
            },
          ]}
          max={total}
        />
      </Box>
    );
  };

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      full='vertical'
      title='Add Extended GreenLake Services'
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={true}>
          <Box direction='row' align='center'>
            <StatusIcon value='unknown' size='medium' />
            <Box margin={{ left: 'small' }}>
              <Paragraph>
                Select one or more services to add to this Billing Account. Once added, you edit the service in the Service Configuration Wizard like any other discovered FC Service.
              </Paragraph>
            </Box>
          </Box>
          {renderServiceOptions()}
        </Box>
        {renderSaveContent(rest)}
      </Box>

      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          {renderSubmitButton(rest)}
          {!rest.isSaved
              && (
                <Button
                  label='Cancel'
                  onClick={onClose}
                  id={IDUtil.getId('ServiceExtentionsDialogCancelButton')}
                  secondary={true}
                />
              )}
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ServiceExtentionsDialog.propTypes = {
  customer: PropTypes.object,
  selectedServices: PropTypes.arrayOf(PropTypes.string),
  existingServices: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ServiceExtentionsDialog;
