// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'grommet';
import CARadioButton from '../../../../../shared/form/CARadioButton';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';

const XPBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _onChangeBillingMethod = (billingUnitsMeasure) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.billingUnitsMeasure = billingUnitsMeasure;
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _getEditableLayout = () => (
    <FormField label='Set billing unit of measure to'>
      <CARadioButton
        label='Uncompressed'
        id='radio_billing_options_uncompressed'
        name='radio_billing_options_uncompressed'
        onChange={() => _onChangeBillingMethod('UNCOMPRESSED')}
        checked={serviceEditor.options.config.billingUnitsMeasure === 'UNCOMPRESSED'}
      />
      <CARadioButton
        label='Compressed'
        id='radio_billing_options_compressed'
        name='radio_billing_options_compressed'
        onChange={() => _onChangeBillingMethod('COMPRESSED')}
        checked={serviceEditor.options.config.billingUnitsMeasure === 'COMPRESSED'}
      />
    </FormField>
  );

  const _getReadOnlyLayout = () => {
    const labelFromBillingMethodMap = new Map()
      .set('UNCOMPRESSED', 'Uncompressed')
      .set('COMPRESSED', 'Compressed');

    const { billingUnitsMeasure } = serviceEditor.options.config;

    return (
      <ReadOnlyField label='Billing units of measure' value={labelFromBillingMethodMap.get(billingUnitsMeasure)} />
    );
  };

  return props.readOnly ? _getReadOnlyLayout() : _getEditableLayout();
};

XPBillingMethodField.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default XPBillingMethodField;
