// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Footer, Heading, List, Text, Tip } from 'grommet';
import { StatusWarningSmall, Trash } from 'grommet-icons';
import moment from 'moment';
import { useDeleteBulkPendingFilesMutation } from '../../../../core';
import GLBMLayer from '../../../shared/component/GLBMLayer';
import GLBMSaving from '../../../shared/component/GLBMSaving';
import IDUtil from '../../../shared/util/IDUtil';

const getRemoveButton = (index, setFiles, files) => {
  return (
    <Button
      key={index}
      icon={<Trash />}
      hoverIndicator={true}
      onClick={() => setFiles(files.filter((_, i) => i !== index))}
    />
  );
};

const PendingFilesBulkDeleteConfirmationDialog = ({ files: initialFiles, onClose, onChange }) => {
  const [files, setFiles] = useState(initialFiles);

  const {
    mutate: deletePendingFiles,
    isPending: isDeletingPendingFiles,
    error: deletingPendingFilesError,
    isSuccess: isDeletingPendingFilesSuccess,
  } = useDeleteBulkPendingFilesMutation();

  useEffect(() => {
    if (isDeletingPendingFilesSuccess) {
      onChange();
    }
  }, [isDeletingPendingFilesSuccess]);

  const onPendingFileBulkDelete = () => {
    deletePendingFiles(files.map(file => file.fileName));
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title='Are you sure?'
    >
      <Box
        pad='medium'
        style={{
          'width': '600px',
          'minWidth': '600px',
        }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box flex={false} pad={{ vertical: 'small' }}>
          Deleting will permanently erase the listed pending files. Please ensure this is what you are intending before choosing &#34;Delete&#34; below.
        </Box>
        <Heading level='3' size='medium' fill={true} margin={{ bottom: 'small' }}>Selected Pending Files</Heading>
        <Box
          background='light-2'
          pad='small'
          gap='small'
          overflow={{ vertical: 'auto' }}
        >
          {files.length === 0 ? (
            <Text>No pending files selected</Text>
            ) : (
              <List
                primaryKey='fileName'
                data={files}
                pad={{ left: 'xsmall', right: 'none' }}
                action={(item, index) => getRemoveButton(index, setFiles, files)}
              >
                {item => (
                  <Box direction='row' align='center' gap='small' pad={{ vertical: 'xsmall' }}>
                    {item.warning && (
                      <Tip
                        content={(
                          <Box
                            pad='small'
                            width='medium'
                          >
                            {`File has not been processed for ${moment.utc().diff(moment.utc(item.lastModified), 'hours')} hours, check sync jobs for more information on why.`}
                          </Box>
                        )}
                      >
                        <StatusWarningSmall color='status-warning' />
                      </Tip>
                    )}
                    {!item.warning && <Box style={{ width: '18px' }} />}
                    <Text>{item.fileName}</Text>
                  </Box>
            )}
              </List>
)
          }
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            primary={true}
            onClick={onPendingFileBulkDelete}
            label='Delete'
            {...{ 'data-testid': IDUtil.getId('CompanyDeleteConfirmationDialogSubmitBtn') }}
            disabled={isDeletingPendingFiles || files.length === 0}
          />
          <Button
            secondary={true}
            onClick={onClose}
            label='Cancel'
            {...{ 'data-testid': IDUtil.getId('CompanyDeleteConfirmationDialogCancelBtn') }}
            disabled={isDeletingPendingFiles}
          />
          <GLBMSaving
            saving={isDeletingPendingFiles}
            saved={isDeletingPendingFilesSuccess}
            error={deletingPendingFilesError}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

PendingFilesBulkDeleteConfirmationDialog.propTypes = {
  files: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PendingFilesBulkDeleteConfirmationDialog;
