// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getMessage } from './utils';

const useMessages = () => {
  const intl = useIntl();
  return useCallback(
    (key: string, values?: any): string => getMessage(intl, key || 'missing.key', values),
    [intl]
  );
};

export default useMessages;
