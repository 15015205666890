// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import * as Case from 'case';
import { ServiceColumn } from 'services/model/ServiceColumn';
import { EquipmentFilter } from 'services/model/EquipmentFilter';
import { ServiceStep } from 'services/model/ServiceStep';
import { getColumnLabel, getEquipmentId } from 'services/Util';
import {
  Anchor,
  Box,
  Header,
  Notification,
  Pagination,
  Text,
  TextInput,
} from 'grommet';
import { Edit, Search } from 'grommet-icons';
import IDUtil from '../../../../shared/util/IDUtil';
import { debounce } from '../../../../shared/util/BasicUtil';
import { useEquipmentQuery, useValidationMutation } from '../../../../../core';
import { ReportBy, TierOptions } from '../../../model';
import ServiceTypeStore from '../../../../stores/ServiceTypeStore';
import FilterControl from '../../../../shared/component/FilterControl';
import Loader from '../../../../shared/loader';
import { ListPlaceholder } from '../../../../shared/component/ListPlaceholder';
import { useServiceEditorContext } from '../../contexts/ServiceEditorContext';
import { getIssue } from '../../../validation/utils';
import ConfigurableComponentEdit from './ConfigurableComponentEdit';
import ConfigureResourcesTable from './ConfigureResourcesTable';

import ConfigureResourcesFilter from './ConfigureResourcesFilter';
import EquipmentBulkEdit from './EquipmentBulkEdit';
import ResourceRevisionAlertModal
  from './components/resource-revision/ResourceRevisionAlertModal';

function columnHeadersUtil(columns, options, readOnly) {
  const { reportBy, tierType } = options.config;
  const tier = tierType === TierOptions.MAPPED.enumKey ? [ServiceColumn.TIER] : [];
  const location = [ReportBy.LOCATION.enumKey, ReportBy.TIER_LOCATION.enumKey].includes(reportBy) ? [ServiceColumn.LOCATION] : [];
  const select = readOnly ? [] : [ServiceColumn.SELECT];
  const action = readOnly ? [] : [ServiceColumn.ACTION];

  return [ServiceColumn.INCLUDE, ...columns, ...tier, ...location, ...select, ...action];
}

const ConfigureResources = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();
  const serviceType = ServiceTypeStore.getService(serviceEditor.options.config.serviceType);
  const { distinctColumns } = serviceType.configureResources;
  const columns = columnHeadersUtil(distinctColumns, serviceEditor.options, props.readOnly);
  const sort = {
    property: columns[1].field(),
    direction: 'asc',
  };

  const [filterActive, setFilterActive] = useState(false);
  const [filter, setFilter] = useState({
    term: ['ALL'],
    status: ['ALL'],
  });
  const [searchText, setSearchText] = useState('');
  const [equipment, setEquipment] = useState(serviceEditor.equipment || []);
  const [equipmentList, setEquipmentList] = useState([]);
  const [componentList, setComponentList] = useState(serviceEditor.dirtyComponents || []);
  const [layer, setLayer] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [editEquipment, setEditEquipment] = useState(undefined);
  const [page, setPage] = useState(1);
  const step = 25;

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.CONFIGURE_RESOURCES);

  useEffect(() => {
    fetchValidationIfNeeded();
  }, [equipment, equipmentList]);

  const setDirtyEquipment = (dirtyEquipment, dirtyComponent) => {
    setServiceEditor(prev => ({
      ...prev,
      dirtyEquipment: dirtyEquipment || prev.dirtyEquipment,
      dirtyComponents: dirtyComponent || prev.dirtyComponents,
      dirtyState: { ...prev.dirtyState, resources: dirtyEquipment?.length > 0 || dirtyComponent?.length > 0 },
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const _processEquipment = (equipment, locationType, serviceStatus) => {
    const { dirtyEquipment } = serviceEditor;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const equipmentList = [];
    if (equipment && equipment.length) {
      // sort:
      equipment.sort((a, b) => ((a.name > b.name) ? -1 : 1));

      equipment.forEach(((eq) => {
        // see if this equipment was already in the dirty equipment list, and should remain so:
        let isDirty = (!!dirtyEquipment.filter(de => de.data.equipmentId === eq.equipmentId).length);

        // if not dirty, then this equipment has not yet been through this logic, so lets include it by default:
        if (serviceStatus === 'NEW') {
          if (!isDirty) {
            // eslint-disable-next-line no-param-reassign
            eq.include = true; // include by default
            isDirty = true; // since we changed the include flag, it's not dirty.
          }
        }

        equipmentList.push({
          selected: false,
          dirty: isDirty,
          data: eq,
        });
      }));
    }
    return equipmentList;
  };

  const combineArrays = (arr1, arr2, id) => {
    const combinedArray = [...arr1];
    arr2.forEach((obj2) => {
      const existsInArr1 = arr1.some(obj1 => obj1[id] === obj2[id]);
      if (!existsInArr1) {
        combinedArray.push(obj2);
      }
    });
    return combinedArray;
  };

  const {
    data: equipmentData,
    isSuccess: isEquipmentFetched,
  } = useEquipmentQuery(serviceEditor.options.customerId, serviceEditor.options.serviceType);

  useEffect(() => {
    if (isEquipmentFetched) {
      setServiceEditor(prev => ({
        ...prev,
        equipment: {
          ...prev.equipment,
          isFetched: true,
          didInvalidate: false,
          items: equipmentData,
        }
      }));
    }
  }, [isEquipmentFetched, equipmentData]);

  useEffect(() => {
    if (isEquipmentFetched && equipmentData && props.customer !== null && serviceEditor.options !== null) {
      const locationType = serviceEditor.options.config.location;
      const serviceStatus = serviceEditor.originalOptions.status;

      const onlyDirtEq = serviceEditor.dirtyEquipment.reduce((array, item) => {
        array.push(item.data);
        return array;
      }, []);

      // combine dirty equipment with fetched equipment:
      const merged = combineArrays(onlyDirtEq, equipmentData, 'equipmentId');
      const modifiedEquipment = _processEquipment(merged, locationType, serviceStatus);

      setEquipment(equipmentData);
      setEquipmentList(modifiedEquipment);

      // we need to update the global store:
      const dirtyEquipment = modifiedEquipment.filter(e => e.dirty);
      if (dirtyEquipment.length) {
        props.setDirty();
      }
      setDirtyEquipment(dirtyEquipment);
    }
  }, [isEquipmentFetched, equipmentData, props.customer, serviceEditor.options]);

  const idLabelIndex = useMemo(() => {
    if (props.customer) {
      const mappedTiers = serviceEditor.options.config.mappedTiers || [];
      const locationAndTierList = [...props.customer.locations, ...mappedTiers];
      return locationAndTierList.reduce((map, item) => Object.assign(map, { [item.id]: item.name }), {});
    }
    return -1;
  }, [props.customer]);

  const onSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const _onLayerClose = () => {
    setLayer(undefined);
  };

  const _onToastClose = () => {
    setResponse(undefined);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onToggleInclude = (event, equipment) => {
    _onIncludeAll([equipment], event.target.checked);
  };

  const _filterEquipment = () => {
    if (!searchText && filter.term.includes(EquipmentFilter.ALL.enumKey) && filter.status.includes('ALL')) {
      return equipmentList;
    }
    return equipmentList.filter(eq => _filterControlPredicate(filter, eq) && _searchFilterPredicate(eq, searchText));
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const _searchFilterPredicate = (equipment, searchText) => {
    const filterBlackList = [ServiceColumn.INCLUDE, ServiceColumn.SELECT, ServiceColumn.ACTION];
    const filterableColumns = columns.filter(column => !filterBlackList.includes(column));

    if (searchText) {
      return filterableColumns.some((column) => {
        const columnText = getColumnLabel(equipment.data, column, serviceType, idLabelIndex) || '';
        return columnText.toString().toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const _filterControlPredicate = (filter, equipment) => {
    const isActive = !equipment.data.decommissionDate || moment.utc().isBefore(moment(equipment.data.decommissionDate));

    const equipmentId = getEquipmentId(equipment.data, serviceType);
    const { validation } = serviceEditor;
    let issues = (!validation.isFetching && validation.properties ? getIssue(validation.properties, equipmentId) : []) || [];
    issues = issues.filter(i => i.severity !== 'ok');
    const hasWarning = issues.length > 0;

    const passesTermFilter = (filter.term.includes(EquipmentFilter.ACTIVE.enumKey) && isActive)
      || (filter.term.includes(EquipmentFilter.END_OF_SYSTEM_TERM.enumKey) && !isActive)
      || filter.term.includes(EquipmentFilter.ALL.enumKey);

    const passesStatusFilter = (filter.status.includes('CONFIGURED') && !hasWarning)
      || (filter.status.includes('WARNING') && hasWarning)
      || filter.status.includes('ALL');

    return passesTermFilter && passesStatusFilter;
  };

  const _allSelections = () => {
    const filteredEquipmentList = _filterEquipment() || [];
    const modifiedEquipment = [...equipmentList];
    modifiedEquipment.filter(e => filteredEquipmentList.includes(e))
      .forEach((eq) => {
        // eslint-disable-next-line no-param-reassign
        eq.selected = true;
      });
    setEquipmentList(modifiedEquipment);
  };

  const _clearSelections = () => {
    const modifiedEquipment = [...equipmentList];
    modifiedEquipment.filter(e => e.selected).forEach((eq) => {
      // eslint-disable-next-line no-param-reassign
      eq.selected = false;
    });
    setEquipmentList(modifiedEquipment);
  };

  const _onBulkEditSelected = () => {
    const filteredEquipmentList = _filterEquipment() || [];
    const selectedEquipmentList = filteredEquipmentList.filter(e => e.selected);
    if (selectedEquipmentList.length) {
      const hasMultiRevisions = selectedEquipmentList.reduce((result, {
        data: { locationRevisions },
        data: { mappedTierRevisions },
      }) => (result || (locationRevisions && locationRevisions.length > 1) || (mappedTierRevisions && mappedTierRevisions.length > 1)), false);
      if (hasMultiRevisions) {
        setLayer('alertModal');
      } else {
        setLayer('edit');
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSingleEditSelected = (equipment) => {
    const modifiedEquipment = [...equipmentList];
    modifiedEquipment.filter(e => e.selected).forEach((eq) => {
      // eslint-disable-next-line no-param-reassign
      eq.selected = false;
    });

    modifiedEquipment.forEach((e) => {
      if (e.data.equipmentId === equipment.data.equipmentId) {
        e.selected = true;
      }
    });

    setEquipmentList(modifiedEquipment);
    setLayer('edit');
  };

  const _onBulkEditChange = (updatedEquipment) => {
    const modifiedEquipment = [...equipmentList];
    modifiedEquipment.forEach((e) => {
      updatedEquipment.forEach((u) => {
        if (e.data.equipmentId === u.data.equipmentId) {
          // eslint-disable-next-line no-param-reassign
          e = u;
          e.dirty = true;
        }
      });
    });
    setEquipmentList(modifiedEquipment);
    setLayer(undefined);

    // we need to update the global store:
    const dirtyEquipment = equipmentList.filter(e => e.dirty);
    if (dirtyEquipment.length) {
      props.setDirty();
    }
    setDirtyEquipment(dirtyEquipment);
  };

  const _onIncludeAll = (devices, include) => {
    // only toggle
    const filteredDeviceIDs = devices.map(device => device.data.equipmentId);

    const modifiedEquipment = [...equipmentList];
    modifiedEquipment.forEach((device) => {
      if (filteredDeviceIDs.indexOf(device.data.equipmentId) > -1) {
        if (device.data.decommissionDate) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        device.data.include = include;
        // eslint-disable-next-line no-param-reassign
        device.dirty = true;
      }
    });
    setEquipmentList(modifiedEquipment);

    // we need to update the global store:
    const dirtyEquipment = modifiedEquipment.filter(e => e.dirty);
    if (dirtyEquipment.length) {
      props.setDirty();
    }
    setDirtyEquipment(dirtyEquipment);
  };

  const _onFilterActivate = () => {
    setFilterActive(true);
  };

  const _onFilterDeactivate = () => {
    setFilterActive(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const _onFilterChange = (filter) => {
    setFilter(filter);
    setServiceEditor(prev => ({
      ...prev,
      equipment: {
        ...prev.equipment,
        filter,
      }
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onComponentEdit = (equipment) => {
    setLayer('componentManagement');
    setEditEquipment(equipment);
  };

  const _onEquipmentDriveApplied = (equipmentId, equipmentDrives) => {
    // update equipment includedComponent value based on drive.include property:
    editEquipment.data.includedComponents = equipmentDrives.filter(d => !d.exclude).length;
    editEquipment.dirty = true;

    // loop through the list, and add to list if not already in list, else replace:
    const modifiedComponents = equipmentDrives.filter(e => e.dirty);
    for (let i = 0; i < modifiedComponents.length; i += 1) {
      const drive = modifiedComponents[i];

      drive.equipmentId = equipmentId;

      const indexOfDrive = componentList.findIndex(d => (d.equipmentId === equipmentId && d.cagePos === drive.cagePos));
      if (indexOfDrive === -1) {
        componentList.push(drive);
      } else {
        componentList[indexOfDrive] = drive;
      }
    }

    setEditEquipment(undefined);
    setLayer(undefined);
    setComponentList(componentList);

    // update the global store:
    // we need to update the global store:
    const dirtyEquipment = equipmentList.filter(e => e.dirty);
    const dirtyComponent = componentList.filter(d => d.dirty);
    if (dirtyEquipment.length || dirtyComponent.length) {
      props.setDirty();
    }
    setDirtyEquipment(dirtyEquipment, dirtyComponent);
  };

  const onEquipmentSelection = (eq) => {
    const modifiedEquipment = [...equipmentList];
    setEquipmentList(modifiedEquipment.map(el => (el.data.equipmentId === eq.data.equipmentId ? eq : el)));
  };

  const _noRowsElement = (totalCount, filteredCount) => {
    if (serviceEditor.equipment.isFetching) {
      return (
        <Box direction='row' align='center' gap='small' justify='center' fill={true}>
          <Loader text='Loading Services. Please wait ...' />
        </Box>
      );
    } if (totalCount === 0) {
      return (
        <ListPlaceholder
          emptyMessage='This customer returned zero equipment of this type.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    } if (filteredCount === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero results, adjust to continue.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  };

  const _getModifiedCountLabel = (label, dirtyCount) => {
    const formattedLabel = pluralize(label, dirtyCount);
    return (
      <span key={formattedLabel}>
        {dirtyCount}
        {' '}
        modified
        {' '}
        {formattedLabel}
      </span>
    );
  };

  const _getModifiedCount = () => {
    const [deviceOrComponent, component] = serviceType.inventoryManagement;
    const dirtyEquipmentCount = equipmentList.filter(e => e.dirty).length;
    const equipmentLabel = serviceType[deviceOrComponent];
    let modifiedCountLabels = [_getModifiedCountLabel(equipmentLabel, dirtyEquipmentCount)];

    if (component) {
      const dirtyComponentCount = componentList.filter(e => e.dirty).length;
      const componentLabel = serviceType.component;
      modifiedCountLabels = [...modifiedCountLabels, ' | ', _getModifiedCountLabel(componentLabel, dirtyComponentCount)];
    }
    return (<Text disabled={true} size='medium' margin='none'>{modifiedCountLabels}</Text>);
  };

  const _filterLayer = () => {
    if (filterActive) {
      return (
        <ConfigureResourcesFilter
          onClose={_onFilterDeactivate}
          onChange={_onFilterChange}
          filters={filter}
        />
      );
    }
    return undefined;
  };

  const _alertModalLayer = () => {
    let result;
    if (layer && layer === 'alertModal') {
      result = (
        <ResourceRevisionAlertModal
          onClose={() => _onLayerClose()}
          show={true}
        />
      );
    }
    return result;
  };

  const _editLayer = () => {
    let result;
    if (layer && layer === 'edit') {
      const filteredEquipmentList = _filterEquipment() || [];
      const serviceOptions = serviceEditor.options;
      const { reportBy } = serviceOptions.config;
      const locationBy = serviceOptions.config.location;
      const tierBy = serviceOptions.config.tierType;
      const { contractStartMonth } = props.customer;

      const options = {
        locationRevisions: 'HIDDEN',
        mappedTierRevisions: (tierBy === 'MAPPED' ? 'EDITABLE' : 'READ_ONLY'),
      };

      // eslint-disable-next-line default-case
      switch (reportBy) {
        case 'LOCATION':
        case 'TIER_LOCATION':
          options.locationRevisions = (locationBy === 'MAPPED' ? 'EDITABLE' : 'READ_ONLY');
          break;
      }

      result = (
        <EquipmentBulkEdit
          onClose={() => _onLayerClose()}
          equipment={filteredEquipmentList.filter(e => e.selected)}
          onChange={_onBulkEditChange}
          locations={props.customer.locations}
          tiers={serviceOptions.config.mappedTiers}
          options={options}
          serviceType={serviceType}
          config={serviceOptions.config}
          contractStartMonth={contractStartMonth}
        />
      );
    }
    return result;
  };

  const _cagePositionLayer = () => {
    let result;
    if (layer && layer === 'componentManagement') {
      // fetch service options to know what to show during edit:
      const serviceOptions = serviceEditor.options;
      const heading = `${Case.title(pluralize(serviceType.component))} for ${editEquipment.data.equipmentId}`;
      // const editEquipment = { ....editEquipment };
      const { equipmentId } = editEquipment.data;
      const modifiedEquipmentComponents = componentList.filter(d => d.equipmentId === equipmentId);

      result = (
        <ConfigurableComponentEdit
          onClose={_onLayerClose}
          heading={heading}
          readOnly={props.readOnly}
          customer={props.customer}
          equipment={editEquipment}
          modifiedComponents={modifiedEquipmentComponents}
          onChange={_onEquipmentDriveApplied}
          serviceOptions={serviceOptions}
          serviceType={serviceType}
        />
      );
    }
    return result;
  };

  const filteredEquipment = _filterEquipment() || [];
  const selectedCount = filteredEquipment?.filter(e => e.selected).length;
  const noRows = _noRowsElement(equipmentList?.length, filteredEquipment?.length);
  const columnHeaders = columns.map(column => column.header(serviceType));

  return (
    <Box direction='column' fill='vertical'>
      <Header flex={false}>
        {!props.readOnly
          && (
            <Box flex={false} direction='row' justify='end' pad='small' align='center'>
              <span>Include:&nbsp;</span>
              <Anchor onClick={() => _onIncludeAll(filteredEquipment, true)}>
                All
              </Anchor>
              &nbsp;|&nbsp;
              <Anchor onClick={() => _onIncludeAll(filteredEquipment, false)}>
                None
              </Anchor>
            </Box>
          )}
        <Box flex={true} />
        <Box justify='end' direction='row' responsive={false} size='medium'>
          <TextInput
            placeholder='Search'
            icon={<Search />}
            id={IDUtil.getId('Search')}
            onChange={event => debounce(onSearchChange(event), 200)}
          />
        </Box>
        <FilterControl
          onFilter={_onFilterActivate}
        />
      </Header>
      <Box border='top' flex={true} overflow='auto'>
        <ConfigureResourcesTable
          columns={columns}
          readOnly={props.readOnly}
          config={serviceEditor.options.config}
          serviceType={serviceType}
          filteredEquipment={filteredEquipment}
          validation={serviceEditor.validation}
          labels={columnHeaders}
          sort={sort}
          idLabelIndex={idLabelIndex}
          showPending={serviceEditor.equipment.isFetching}
          onSingleEditSelected={onSingleEditSelected}
          onEquipmentSelection={onEquipmentSelection}
          onComponentEdit={onComponentEdit}
          onToggleInclude={onToggleInclude}
          searchText={searchText}
          page={page}
          step={step}
        />
        {noRows}
      </Box>
      {!props.readOnly
        && (
          <Box border='top' pad='small'>
            <Box flex={false} direction='row' justify='end' pad='none' align='center'>
              {_getModifiedCount()}
              <Box flex={true} />
              <Anchor
                onClick={() => _onBulkEditSelected()}
                disabled={selectedCount === 0}
              >
                <Box direction='row' gap='small' align='center'>
                  <Edit size='small' />
                  <Text>
                    Edit
                    {' '}
                    {selectedCount}
                    {' '}
                    item
                    {selectedCount !== 1 ? 's' : ''}
                  </Text>
                </Box>
              </Anchor>
              &nbsp;|&nbsp;
              <Anchor
                onClick={() => _allSelections()}
                disabled={selectedCount === filteredEquipment.length}
              >
                Select All
              </Anchor>
              &nbsp;|&nbsp;
              <Anchor
                onClick={() => _clearSelections()}
                disabled={selectedCount === 0}
              >
                Clear selections
              </Anchor>
              {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
              <Box margin={{ left: 'small' }}><Pagination page={page} onChange={({ page }) => setPage(page)} numberMiddlePages={3} numberItems={filteredEquipment?.length} step={step} summary={true} /></Box>
            </Box>
          </Box>
        )}
      {_editLayer()}
      {_cagePositionLayer()}
      {_filterLayer()}
      {
        response
        && (
          <Notification
            toast={true}
            status={response?.status || 'critical'}
            title={response.title}
            message={response.message}
            onClose={_onToastClose}
          />
        )
      }
      {_alertModalLayer()}
    </Box>
  );
};

ConfigureResources.contextTypes = {
  router: PropTypes.object,
};

ConfigureResources.propTypes = {
  filterActive: PropTypes.bool,
  searchText: PropTypes.string,
  currentEffectiveDate: PropTypes.string,
  equipmentList: PropTypes.array,
  columns: PropTypes.array,
  setDirty: PropTypes.func,
  customer: PropTypes.object,
  readOnly: PropTypes.bool,
  setEquipmentList: PropTypes.func,
  setEquipment: PropTypes.func,
  setComponentList: PropTypes.func,
  setLayer: PropTypes.func,
  setResponse: PropTypes.func,
  setEditEquipment: PropTypes.func,
  setEditEquipmentIndex: PropTypes.func,
  setServiceEditor: PropTypes.func,
  setPage: PropTypes.func,
  setFilter: PropTypes.func,
  setSearchText: PropTypes.func,
  setFilterActive: PropTypes.func,
};

ConfigureResources.defaultProps = {
  filterActive: false,
};

export default ConfigureResources;
