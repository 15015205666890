// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { cloneDeep } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Box, Button, Heading,
} from 'grommet';
import PartnerBadge from '../shared/badges/PartnerBadge';
import {
  useCustomerQuery,
  useFixedChargesQuery,
  useFixedChargesUpdateMutate,
} from '../../core';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import { getASMCustomerRole } from '../shared/util/asmCustomerRole';
import UserStore from '../stores/UserStore';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import Loader from '../shared/loader';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import ServiceList from './ServiceList';
import ServiceAttributes from './ServiceAttributes';
import ServiceRevisions from './ServiceRevisions';
import ServiceEditor from './ServiceEditor';
import RevisionEditor from './RevisionEditor';

const FixedCharges = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { state } = useLocation();

  const [layer, setLayer] = useState('none');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [expanded, setExpanded] = useState('NONE');
  const [readOnly, setReadOnly] = useState(false);

  const [serviceForEdit, setServiceForEdit] = useState(undefined);
  const [selectedRevision, setSelectedRevision] = useState(undefined);

  const tenant = useMemo(() => state?.tenant || 'MASTER', [state]);
  const userType = useMemo(() => UserStore.getUser().role, []);
  const isPartner = useMemo(() => (tenant === 'DISTRIBUTOR' || tenant === 'RESELLER'), [tenant]);
  const { hasPermissions } = usePermissionChecker();

  const setReadOnlyState = useCallback((customer) => {
    const canEditFixedCharges = hasPermissions(pagePermissions.customers.view.charges.actions.edit);
    if (!canEditFixedCharges) {
      return true; // user can not edit fixed charges:
    }

    const user = UserStore.getUser();
    const asmRole = getASMCustomerRole(user, customer);
    return asmRole === 'READ';
  }, [hasPermissions]);

  const {
    data: customer,
    isSuccess: isCustomerSuccess,
  } = useCustomerQuery(customerId);

  useEffect(() => {
    if (isCustomerSuccess) {
      setReadOnly(setReadOnlyState(customer));
    }
  }, [isCustomerSuccess, customer, setReadOnlyState]);

  const {
    data,
    isSuccess: isFixedChargesSuccess,
  } = useFixedChargesQuery(customerId, tenant);

  useEffect(() => {
    if (isFixedChargesSuccess) {
      switch (tenant) {
        case 'MASTER':
          setServices(data.meterRates);
          setSelectedService(data.meterRates?.[0]);
          break;
        default:
          setServices(data);
          setSelectedService(data[0]);
      }
    }
  }, [isFixedChargesSuccess, tenant, data]);

  const onSaveSuccess = () => {
    navigate('/customers');
  };

  const {
    mutate: saveFixedCharges,
  } = useFixedChargesUpdateMutate(customerId, tenant, {
    onSuccess: onSaveSuccess,
  });

  const updateSelectedService = (service) => {
    if (services.includes(service)) {
      setSelectedService(service);
    } else if (!selectedService) {
      setSelectedService(service);
    }
  };

  const addService = (service) => {
    const updatedServices = cloneDeep(services);
    // eslint-disable-next-line no-param-reassign
    service.dirty = true;
    updatedServices.push(service);

    setLayer('none');
    setSelectedService(service);
    setServices(updatedServices);
  };

  const editService = (service) => {
    let updatedServices = cloneDeep(services);
    // eslint-disable-next-line no-param-reassign
    service.dirty = true;
    updatedServices = updatedServices.map(el => (el.meterId === serviceForEdit ? service : el));

    setServices(updatedServices);
    setLayer('none');
    setSelectedService(service);
    setServiceForEdit(undefined);
  };

  const deleteService = (service) => {
    let updatedServices = cloneDeep(services);
    updatedServices = updatedServices.filter(s => s.meterId !== service.meterId);

    setServices(updatedServices);
    setSelectedService(null);
    setSelectedRevision(null);
  };

  const onEditService = (service) => {
    setLayer('service');
    setServiceForEdit(service.meterId);
  };

  const updateRevision = (revision) => {
    const updatedSelectedService = cloneDeep(selectedService);

    // if there is a selectedRevision, we are editing an existing one
    if (updatedSelectedService && selectedRevision) {
      updatedSelectedService.rateRevisions = updatedSelectedService.rateRevisions.filter(r => r.effectiveDate !== selectedRevision.effectiveDate);
    }

    // eslint-disable-next-line no-param-reassign
    revision.dirty = true;
    updatedSelectedService.dirty = true;
    updatedSelectedService.rateRevisions.push(revision);

    setLayer('none');
    setSelectedService(updatedSelectedService);

    // now we must update the entire service list:
    let updatedServices = cloneDeep(services);
    updatedServices = updatedServices.map(el => (el.meterId === updatedSelectedService.meterId ? updatedSelectedService : el));
    setServices(updatedServices);
  };

  const deleteRevision = (revision) => {
    const updatedSelectedService = cloneDeep(selectedService);

    if (updatedSelectedService) {
      updatedSelectedService.dirty = true;
      updatedSelectedService.rateRevisions = selectedService.rateRevisions.filter(r => r.effectiveDate !== revision.effectiveDate);
    }

    setSelectedService(updatedSelectedService);

    // now we must update the entire service list:
    let updatedServices = cloneDeep(services);
    updatedServices = updatedServices.map(el => (el.meterId === updatedSelectedService.meterId ? updatedSelectedService : el));
    setServices(updatedServices);
  };

  const onResetMarkups = () => {
    setLayer('confirmResetMarkups');
  };

  const onResetMarkupsConfirmed = () => {
    const updatedSelectedService = cloneDeep(selectedService);

    if (updatedSelectedService) {
      updatedSelectedService.dirty = true;
      const revisions = updatedSelectedService.rateRevisions;
      if (revisions) {
        revisions.forEach((revision) => {
          /* eslint-disable no-param-reassign */
          revision.dirty = true;
          revision.explicitDownstreamRate = undefined;
          Object.keys(revision.tiers).forEach((key) => {
            revision.tiers[key].markup = undefined;
            revision.tiers[key].downstreamRate = undefined;
          });
          /* eslint-enable no-param-reassign */
        });
      }
    }

    setLayer('none');
    setSelectedService(updatedSelectedService);

    // now we must update the entire service list:
    let updatedServices = cloneDeep(services);
    updatedServices = updatedServices.map(el => (el.meterId === updatedSelectedService.meterId ? updatedSelectedService : el));
    setServices(updatedServices);
  };

  const renderConfirmationDetails = service => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Service'
        data={[
          { label: 'Service', value: service.displayName },
        ]}
      />
    </Box>
  );

  const toggleExpansion = () => {
    if (expanded === 'NONE') {
      setExpanded('RATES');
    } else {
      setExpanded('NONE');
    }
  };

  const onEditRevision = (revision) => {
    setLayer('revision');
    setSelectedRevision(revision);
  };

  const onSave = () => {
    saveFixedCharges(services);
  };

  if (!customer) {
    return (
      <Box direction='row' align='center' gap='small' justify='center' fill={true}>
        <Loader text='Loading. Please wait ...' />
      </Box>
    );
  }

  return (
    <Box direction='column' fill={true} flex={true}>
      {layer === 'service'
        && (
          <ServiceEditor
            customer={customer}
            services={services || []}
            serviceId={serviceForEdit}
            onClose={() => {
              setLayer('none');
              setServiceForEdit(undefined);
            }}
            onSubmit={serviceForEdit !== undefined ? editService : addService}
            readOnly={readOnly}
          />
        )}
      {layer === 'revision'
        && (
          <RevisionEditor
            customer={customer}
            revision={selectedRevision}
            revisions={selectedService.rateRevisions}
            onClose={() => setLayer('none')}
            onSubmit={rev => updateRevision(rev)}
            partner={isPartner}
            readOnly={readOnly}
            userType={userType}
          />
        )}

      {layer === 'confirmResetMarkups'
        && (
          <ConfirmationDialog
            data={{ revisions: selectedService.rateRevisions }}
            title='Reset Pricing'
            text='Reset all service pricing will clear all entered service pricing for the selected service meter and return back to the initial state.'
            submitLabel='Yes, reset all Service Pricing'
            cancelLabel='Cancel'
            onClose={() => setLayer('none')}
            onChange={() => onResetMarkupsConfirmed()}
            details={renderConfirmationDetails(selectedService)}
          />
        )}
      <GLBMHeading
        back='/customers'
        height='xsmall'
        title={(
          <Box
            direction='row'
            gap='xxsmall'
            align='center'
          >
            <Heading level='2' overflowWrap='normal'>Set up</Heading>
            {tenant !== 'MASTER' ? <PartnerBadge tenant={tenant} /> : ''}
            <Heading level='2'>Non-Metered Services for</Heading>
            <Heading level='2'>{customer ? `${customer.name} (${customer.id})` : ''}</Heading>
          </Box>
        )}
      />
      <Box flex={true} direction='row' margin={{ horizontal: 'small' }}>
        <ServiceList
          onClick={service => updateSelectedService(service)}
          customer={customer}
          services={services || []}
          partner={isPartner}
          selectedService={selectedService}
          onAddService={() => setLayer('service')}
          onEditService={service => onEditService(service)}
          onDeleteService={service => deleteService(service)}
          readOnly={readOnly}
        />

        <Box direction='column' fill='vertical' flex={true} pad={{ vertical: 'small' }}>
          <Box direction='column' flex={true} gap='small'>
            <ServiceAttributes
              customer={customer}
              service={selectedService}
              expanded={expanded}
              partner={isPartner}
              readOnly={readOnly}
            />
            <ServiceRevisions
              customer={customer}
              service={selectedService}
              expanded={expanded}
              partner={isPartner}
              onEditRevision={revision => onEditRevision(revision)}
              onDeleteRevision={revision => deleteRevision(revision)}
              onResetMarkups={() => onResetMarkups()}
              onToggleExpansion={() => toggleExpansion()}
              readOnly={readOnly}
            />
          </Box>
        </Box>
      </Box>

      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        {!readOnly
          ? (
            <Box flex={false} justify='start' gap='small' direction='row'>
              <Button
                label='Save'
                type='button'
                primary={true}
                onClick={onSave}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={() => window.history.back()}
              />
            </Box>
          )
          : (
            <Box direction='row'>
              <Button
                label='Close'
                type='button'
                secondary={true}
                onClick={() => window.history.back()}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default FixedCharges;
