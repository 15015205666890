// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useErrorToast } from '../../components/shared/hooks';
import { executeGet } from '../api';
import { ApiContext } from '../../AppContext';

export const useAccessApproversQuery = (options) => {
  const path = useContext(ApiContext)('administration.approvers.list.path');
  return useErrorToast(useQuery({
    queryKey: ['APPROVERS'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Approvers Error');
};
