// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

const InputContainer = (props) => {
    return (
      <Box
        direction='row'
        align='center'
        flex={true}
        pad={{ horizontal: 'small' }}
        gap='small'
      >
        <Box size='xsmall'>
          <span>
            {props.label}
            :
          </span>
        </Box>
        <Box flex={true}>
          {props.children}
        </Box>
      </Box>
    );
};

InputContainer.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default InputContainer;
