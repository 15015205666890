// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';

const HeaderTableCell = ({ label, ...rest }) => (
  <Text {...rest} color='text-strong' weight={500}>{label}</Text>
);

HeaderTableCell.propTypes = {
  label: PropTypes.string.isRequired,
};

export default HeaderTableCell;
