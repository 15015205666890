// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

export const getMessage = (intl, key, values) => (
  intl
    ? (
      intl.formatMessage(
        {
          id: key,
          defaultMessage: key,
        },
        values,
      )
    )
    : key
);
