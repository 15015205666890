// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Select, TextInput,
} from 'grommet';
import noop from 'lodash/noop';
import { useQueryAttributes } from '../../../core';
import CustomInput from './CustomInput';

const AttributesInput = ({
  attributes = [], setAttributes = noop, errors = [], setErrors = noop,
}) => {
  const {
    data: attributesData,
  } = useQueryAttributes();

  const attributesOptions = useMemo(() => attributesData ?? [], [attributesData]);

  const getValueType = useCallback((i) => {
    const field = attributesOptions.find(el => el.fieldName === attributes[i].key);
    return field?.valueType === 'INT' || field?.valueType === 'FLOAT' ? 'number' : undefined;
  }, [attributes, attributesOptions]);

  const KeyInput = useCallback(({
    i, id, ...rest
  }) => (
    <Select
      {...rest}
      id={id}
      data-testid={id}
      options={attributesOptions}
      labelKey='displayName'
      valueKey={{ key: 'fieldName', reduce: true }}
      onChange={(e) => {
        const newProperty = JSON.parse(JSON.stringify(attributes));
        newProperty[i].key = e.option.fieldName;
        newProperty[i].values = [{ value: undefined, weight: undefined }];
        setAttributes(newProperty);
      }}
    />
  ), [attributes, attributesOptions, setAttributes]);

  const ValueInput = useCallback(({
    id, i, y, ...rest
  }) => (
    <TextInput
      {...rest}
      id={id}
      data-testid={id}
      type={getValueType(i)}
      onChange={(e) => {
        const newProperty = JSON.parse(JSON.stringify(attributes));
        newProperty[i].values[y].value = getValueType(i) === 'number' ? +e.target.value : e.target.value;
        setAttributes(newProperty);
      }}
    />
  ), [attributes, getValueType, setAttributes]);

  return (
    <CustomInput
      property={attributes}
      keyInput={KeyInput}
      valueInput={ValueInput}
      setProperty={setAttributes}
      errors={errors}
      setErrors={setErrors}
      propertyName='Attr'
      title='Attributes'
    />
  );
};

AttributesInput.propTypes = {
  attributes: PropTypes.array,
  setAttributes: PropTypes.func,
  errors: PropTypes.array,
  setErrors: PropTypes.func,
};

export default AttributesInput;
