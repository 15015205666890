// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, FormField, TextInput,
} from 'grommet';
import { Trash } from 'grommet-icons';
import IDUtil from '../util/IDUtil';

const ManyFieldsItem = ({
  disabled = false,
  onChange = () => undefined,
  field = '',
  remove = () => undefined,
  index = undefined,
  error = false,
}) => (disabled ? (
  <Box
    key={index}
    justify='between'
    pad='none'
    border='top'
  >
    <span>
      {field}
    </span>
  </Box>
) : (
  <Box key={index}>
    <Box direction='row' justify='between' fill={true} margin={{ bottom: 'small' }} align='center'>
      <FormField error={error}>
        <TextInput
          id={IDUtil.getId(`itemName${index}`)}
          name={`itemFieldName${index}`}
          value={field}
          onChange={e => onChange(e.target.value)}
        />
      </FormField>
      <Button
        icon={<Trash size='medium' />}
        onClick={remove}
        a11yTitle={`Delete ${field}`}
      />
    </Box>
  </Box>
));

ManyFieldsItem.propTypes = {
  remove: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  field: PropTypes.string,
  index: PropTypes.number,
};

export default ManyFieldsItem;
