// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNull, isUndefined } from 'lodash';
import isEqual from 'lodash/isEqual';
import { Box, Button, CheckBox, Select, Text } from 'grommet';
import { Hide } from 'grommet-icons';
import { getSelectValue } from '../../../../shared/util/FormUtil';
import IDUtil from '../../../../shared/util/IDUtil';
import CATextInput from '../../../../shared/form/CATextInput';
import InputContainer from './components/InputContainer';
import BaseContainer from './components/BaseContainer';
import DiscontinueMeterEditor from './DiscontinueMeterEditor';
import { canMeterBeDicontinued, requireInstalledCapacities } from './util';

const MeterSettings = ({
  rate = undefined,
  onChange,
  readOnly,
  meter,
  serviceType,
}) => {
  const [layer, setLayer] = React.useState(undefined);
  const shrinkOptions = useMemo(
    () => [
      { label: 'No Shrink', value: 'NO_SHRINK' },
      { label: '10% Shrink', value: 'SHRINK_10' },
      { label: '100% Shrink', value: 'SHRINK_100' },
    ],
    [],
  );

  const tierOptions = useMemo(
    () => [
      { label: 'Absolute', value: 'ABSOLUTE' },
      { label: 'Marginal', value: 'MARGINAL' },
      { label: 'Tiered', value: 'TIERED' },
      /* { label: 'Tiered w/Cap', value: 'TIERED_CAP' }, // this is future work, once we decide to implement it */
    ],
    [],
  );

  // figure out if this meter can be discontinued, meaning it needs to have non zero capacity and rate revisions:
  const canDiscontinueMeter = useMemo(() => canMeterBeDicontinued(rate), [rate]);

  const onChangeCb = (event) => {
    const newRate = { ...rate };
    if (event) {
      switch (event.target.name) {
        case 'tierType':
          newRate.tierType = event.value.value;
          break;
        case 'shrinkPolicy':
          newRate.shrinkPolicy = event.value.value;
          break;
        case 'rounding':
          newRate.rounding = event.target.checked;
          break;
        default:
          console.error(`unsupported property: ${event.target.name}`);
      }

      onChange(newRate);
    }
  };

  const isEqualAndNotUndefined = (value1, value2) => {
    if (
      (isNull(value1) || isUndefined(value1) || isEmpty(value1))
      && (isNull(value2) || isUndefined(value2) || isEmpty(value2))) {
      return true;
    }
    return isEqual(value1, value2);
  };

  const onChangeDescription = (description) => {
    const newRate = { ...rate };
    if (!isEqualAndNotUndefined(description, newRate.description)) {
      newRate.description = description;
      onChange(newRate);
    }
  };

  const getActions = () => {
    const discontinueMeter = (!rate || readOnly || !canDiscontinueMeter ? undefined : (
      <Button
        key='discontinueMeter'
        icon={<Hide />}
        label='Discontinue Meter'
        id={IDUtil.getId('BillingInfoEditorDiscontinueMeter')}
        onClick={() => setLayer('discontinueMeter')}
        hoverIndicator={true}
        kind='toolbar'
      />
    ));

    return ([
      discontinueMeter,
    ]);
  };

  const renderLayer = () => {
    if (layer === 'discontinueMeter') {
      return (
        <DiscontinueMeterEditor
          meter={meter}
          rate={rate}
          onClose={() => setLayer(undefined)}
          onChange={(effectiveDate) => {
            const newRate = { ...rate };

            // create a new capacity revision with 0 values:
            const capacityRevision = {
              effectiveDate,
              committedCapacity: 0.0,
              committedCapacityOverrideEnabled: false,
              requestedCapacity: 0.0,
              committedPct: 0.0,
            };
            if (requireInstalledCapacities(serviceType)) {
              capacityRevision.installedCapacity = 0.0;
            }
            newRate.capacityRevisions.push(capacityRevision);

            // create a new rate revision with 0 values:
            newRate.rateRevisions.push({
              effectiveDate,
              tiers: { '0.0': { rate: 0, name: 'Band 1' } },
            });

            onChange(newRate);
            setLayer(undefined);
          }}

        />
      );
    }
    return undefined;
  };

  return (
    <BaseContainer
      title='Meter Attributes'
      data-testid='meter-attributes-container'
      actions={getActions()}
      fill={true}
      expanded={true}
    >
      <Box
        gap={readOnly ? 'none' : 'small'}
        flex={false}
        pad={{ vertical: 'small' }}
      >
        <Box
          flex={true}
          direction='row'
        >
          <InputContainer label='Description'>
            {!rate || readOnly
              ? <Text id={IDUtil.getId('BillingInfoEditorDescription')} weight={100} margin='small'>{rate?.description || ''}</Text>
              : (
                <CATextInput
                  name='description'
                  id={IDUtil.getId('BillingInfoEditorDescription')}
                  value={rate?.description || ''}
                  onChange={onChangeDescription}
                  debounce={true}
                />
              )}
          </InputContainer>
          <InputContainer label='Pricing Model'>
            {!rate || readOnly
              ? <Text id={IDUtil.getId('BillingInfoEditorPricingModel')} weight={100} margin='small'>{getSelectValue(rate ? rate.tierType : 'ABSOLUTE', tierOptions).label}</Text>
              : (
                <Select
                  options={tierOptions}
                  name='tierType'
                  value={getSelectValue(rate ? rate.tierType : 'ABSOLUTE', tierOptions)}
                  labelKey='label'
                  valueKey='value'
                  onChange={onChangeCb}
                  id={IDUtil.getId('BillingInfoEditorPricingModel')}
                />
              )}
          </InputContainer>
        </Box>
        <Box
          flex={true}
          direction='row'
        >
          <InputContainer label='Max Shrink'>
            {!rate || readOnly
              ? <Text weight={100} id={IDUtil.getId('BillingInfoEditorMaxShrink')} margin='small'>{getSelectValue(rate ? rate.shrinkPolicy : 'SHRINK_100', shrinkOptions).label}</Text>
              : (
                <Select
                  options={shrinkOptions}
                  name='shrinkPolicy'
                  id={IDUtil.getId('BillingInfoEditorMaxShrink')}
                  value={getSelectValue(rate ? rate.shrinkPolicy : 'SHRINK_100', shrinkOptions)}
                  labelKey='label'
                  valueKey='value'
                  onChange={onChangeCb}
                />
              )}
          </InputContainer>
          <InputContainer label='Round Usage'>
            <CheckBox
              toggle={false}
              id={IDUtil.getId('BillingInfoEditorRoundUsage')}
              name='rounding'
              checked={rate ? rate.rounding : false}
              onChange={onChangeCb}
              disabled={readOnly}
            />
          </InputContainer>
        </Box>
      </Box>
      {renderLayer()}
    </BaseContainer>
  );
};

MeterSettings.propTypes = {
  rate: PropTypes.shape({
    description: PropTypes.string,
    rounding: PropTypes.bool,
    tierType: PropTypes.string,
    shrinkPolicy: PropTypes.string,
  }),
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  meter: PropTypes.object.isRequired,
  serviceType: PropTypes.object.isRequired,
};

export default MeterSettings;
