// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';

import { ServiceStep } from 'services/model/ServiceStep';
import { FormField } from 'grommet';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import { useValidationMutation } from '../../../../../../core';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import { useMessages } from '../../../../../../hooks';

const LicenseTypesBillingMethodField = (props) => {
  const getMessages = useMessages();
  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _onChangeLicenseType = (licenseType) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    if (!Array.isArray(newState.options.config.licenseType)) {
      newState.options.config.licenseType = [];
    }
    if (newState.options.config.licenseType.indexOf(licenseType) !== -1) {
      newState.options.config.licenseType.splice(newState.options.config.licenseType.indexOf(licenseType), 1);
    } else {
      newState.options.config.licenseType.push(licenseType);
    }
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
    fetchValidationIfNeeded();
  };

  const _hasLicenseType = (licenseType) => {
    const { options } = serviceEditor;
    return (options.config.licenseType ? options.config.licenseType.indexOf(licenseType) !== -1 : false);
  };

  const _getEditableLayout = () => {
    const licenseTypes = serviceEditor.options.config.supportedLicenseTypes;
    const licenseTypesArray = Object.entries(licenseTypes).sort((a, b) => a[1].localeCompare(b[1]));

    const checkBoxes = licenseTypesArray.map((licenseType) => {
      const type = licenseType[0];
      return (
        <CACheckbox
          key={`radio_license_type_${type}`}
          label={getMessages(`fc.service.license.type.${type}`)}
          id={`radio_license_type_${type}`}
          name={`radio_license_type_${type}`}
          onChange={!props.readOnly ? () => _onChangeLicenseType(type) : undefined}
          checked={_hasLicenseType(type)}
          disabled={props.readOnly}
        />
      );
    });

    return (
      <FormField label='License Types'>
        {checkBoxes}
      </FormField>
    );
  };

  return _getEditableLayout();
};

LicenseTypesBillingMethodField.propTypes = {
  setDirty: PropTypes.func.isRequired,
};

export default LicenseTypesBillingMethodField;
