// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Button } from 'grommet';
import { Add, Copy, Edit, Trash } from 'grommet-icons';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import IDUtil from '../../../../shared/util/IDUtil';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import { insertIf } from '../../../../shared/util/BasicUtil';
import GLBMDataTable from '../../../../shared/component/GLBMDataTable';
import { ValueCell } from '../../../../shared/tablecells';
import BaseContainer from './components/BaseContainer';
import MeterCapacitiesEditor from './MeterCapacitiesEditor';
import { requireInstalledCapacities } from './util';

const MeterCapacities = (props) => {
  const [layer, setLayer] = useState(undefined);
  const [selectedRevision, setSelectedRevision] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [lastEditedRevision, setLastEditedRevision] = useState(undefined);

  useEffect(() => {
    if (lastEditedRevision) setLastEditedRevision(undefined);
  }, [lastEditedRevision]);

  const showMaxInvoiceableCap = () => props?.rate?.capacityRevisions?.some(el => el.maximumInvoiceableCapacity);

  const getAddAction = () => {
    setLayer('add');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const getEditAction = (revision, index) => {
    setLayer('edit');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const getCopyAction = (revision, index) => {
    setLayer('copy');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const getDeleteAction = (index) => {
    const rate = cloneDeep(props.rate);
    rate.capacityRevisions.splice(index, 1);
    props.onChange(rate);
  };

  const getActions = () => {
    const addNew = (!props.rate || props.readOnly ? undefined : (
      <Button
        key='edit'
        icon={<Add />}
        label='Add Revision'
        id={IDUtil.getId('BillingInfoEditorAddRevisionCapacities')}
        onClick={getAddAction}
        hoverIndicator={true}
        kind='toolbar'
      />
    ));

    return ([
      addNew,
    ]);
  };

  // returning false for now, as we are not using tiered rates:
  // once we introduce 'Tiered with max/cap', then we will put this back into place:
  const isTieredRate = () => props?.rate?.tierType === 'TIERED_CAP';

  const getDataColumns = () => {
    return [
      ...insertIf(isTieredRate(), [{
        header: 'Status',
        property: 'Status',
        render: r => getStatus(r),

      }]), {
        header: 'Start Date',
        property: 'effectiveDate',
        render: r => (
          <ValueCell
            primary={true}
            id={IDUtil.getId('CapacityStartDate', r.index)}
            value={formatDate(r.effectiveDate)}
          />
        ),
        primary: true,
        size: 'small',
      },
      ...insertIf(requireInstalledCapacities(props.serviceType), [{
        header: 'Installed Capacity',
        property: 'installedCapacity',
        render: r => (
          <ValueCell
            id={IDUtil.getId('InstalledCapacity', r.index)}
            value={formatNumber(parseFloat(Math.round((r.installedCapacity || 0) * 1000) / 1000))}
          />
        ),
        align: 'end',
      }]), {
        header: 'Requested Cap.',
        property: 'requestedCapacity',
        render: r => (
          <ValueCell
            id={IDUtil.getId('RequestedCapacity', r.index)}
            value={formatNumber(parseFloat(Math.round(r.requestedCapacity * 1000) / 1000))}
          />
        ),
        align: 'end',
      }, {
        header: 'Reserved',
        property: 'committedPct',
        render: r => (
          <ValueCell
            id={IDUtil.getId('CommitedPct', r.index)}
            value={formatNumber(parseFloat(Math.round(r.committedPct * 1000) / 1000))}
          />
        ),
        align: 'end',
        units: '%',
      }, {
        header: 'Reserved Capacity.',
        property: 'committedCapacity',
        render: r => (
          <ValueCell
            id={IDUtil.getId('CommitedCapacity', r.index)}
            value={formatNumber(parseFloat(Math.round(r.committedCapacity * 1000) / 1000).toFixed(2))}
          />
        ),
        align: 'end',
      }, {
        header: 'Adjusted Reserved Cap.',
        property: 'minConCom',
        render: (r) => {
          const minConCom = getMinContractualCommit(r);
          return (
            <GLBMTooltip
              width='medium'
              content={(
                <Box pad='xxsmall' width={{ max: 'medium' }}>
                  Adjusted Reserved Capacity is based on Reserved Capacity, Actual Usage from previous months, and
                  shrink rules or other billing model rules. This value will be calculated after the rate plan has been
                  saved.
                </Box>
              )}
            >
              <span
                className='dotted-tooltip'
              >
                <ValueCell
                  id={IDUtil.getId('MinConCom', r.index)}
                  value={formatNumber(parseFloat(Math.round((minConCom) * 1000) / 1000))}
                />
              </span>
            </GLBMTooltip>
          );
        },
        align: 'end',
      },
      ...insertIf(showMaxInvoiceableCap(), [{
        header: 'Max. Invoiceable Capacity.',
        property: 'maxInvCap',
        render: r => (
          <ValueCell
            id={IDUtil.getId('MaxInvCap', r.index)}
            value={getMaximumInvoiceableCapacity(r) || '--'}
          />
        ),
        align: 'end',
      }]),
      ...insertIf(!props.readOnly, [{
        header: 'Action',
        property: 'actions',
        render: r => (
          <Box direction='row' gap='medium' align='center'>
            <Button
              size='large'
              id={IDUtil.getId('CapacitiesListItemEditButton', r.index)}
              icon={<Edit style={{ height: '18px', width: '18px' }} />}
              onClick={() => getEditAction(r, r.index)}
              style={{ padding: '0' }}
            />
            <Button
              size='large'
              id={IDUtil.getId('CapacitiesListItemCopyButton', r.index)}
              icon={<Copy style={{ height: '18px', width: '18px' }} />}
              onClick={() => getCopyAction(r, r.index)}
              style={{ padding: '0' }}
            />
            <Button
              size='large'
              id={IDUtil.getId('CapacitiesListItemDeleteButton', r.index)}
              icon={<Trash style={{ height: '18px', width: '18px' }} />}
              disabled={props.rate.capacityRevisions.length === 1}
              onClick={() => getDeleteAction(r.index)}
              style={{ padding: '0' }}
            />
          </Box>
        ),
        size: 'small',
      }]),
    ];
  };

  const formatDate = date => new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });

  const formatNumber = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const getMinContractualCommit = (r) => {
    if (!Object.prototype.hasOwnProperty.call(r, 'minimumContractualCapacity') || r.minimumContractualCapacity === 0 || r.resetMinContractualCapacity) {
      return (r.committedCapacityOverrideEnabled ? (r.committedCapacityOverride || 0) : (r.committedCapacity || 0));
    }
    return (r.minimumContractualCapacity || 0).toFixed(2);
  };

  const getMaximumInvoiceableCapacity = (r) => {
    if (Object.prototype.hasOwnProperty.call(r, 'maximumInvoiceableCapacity') && r.maximumInvoiceableCapacity !== undefined) {
      return formatNumber(parseFloat(Math.round((r.maximumInvoiceableCapacity) * 1000) / 1000));
    }
    return undefined;
  };

  const getStatus = (currentCapacity) => {
    const failedCapacities = [];
    props.rate?.rateRevisions?.forEach((currentRate) => {
      const { effectiveDate: rateEffectiveDate } = currentRate;
      const foundCapacity = props.rate?.capacityRevisions?.find((cap, index, allCapacities) => {
        const currentEffectiveDate = cap?.effectiveDate;
        const nextEffectiveDate = allCapacities[index + 1]?.effectiveDate;
        return moment(currentEffectiveDate, 'YYYY-MM-DD').isSameOrBefore(moment(rateEffectiveDate, 'YYYY-MM-DD')) && (!nextEffectiveDate || moment(nextEffectiveDate, 'YYYY-MM-DD').isAfter(moment(rateEffectiveDate, 'YYYY-MM-DD')));
      });
      const limit = foundCapacity?.committedCapacityOverrideEnabled ? foundCapacity?.committedCapacityOverride : foundCapacity?.committedCapacity;
      const formattedLimit = limit && (typeof limit === 'string') ? +limit : limit;
      Object.keys(currentRate.tiers).forEach((tier) => {
        if (formattedLimit && (+tier >= formattedLimit)) {
          failedCapacities.push(foundCapacity?.effectiveDate);
        }
      });
    });
    return failedCapacities.includes(currentCapacity.effectiveDate) ? (
      <GLBMTooltip
        content={(
          <Box pad='xxsmall' width={{ max: 'medium' }}>
            The Reserved Capacity for this revision is lower than one or more
            start ranges for the pricing bands in the corresponding Rate
            revision. Please increase the Reserved Capacity or remove the
            offending pricing bands to fix this issue.
          </Box>
        )}
      >
        <Box><StatusIcon value='critical' size='small' /></Box>
      </GLBMTooltip>
    ) : <StatusIcon value='ok' size='small' />;
  };

  const canEdit = () => {
    if (!props.permissions) {
      return true;
    }
    return props.permissions.canEditRates;
  };

  const onLayerClose = () => {
    setLayer(undefined);
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const onLayerChange = (revision) => {
    const rate = cloneDeep(props.rate);

    if (layer === 'add' || layer === 'copy') {
      rate.capacityRevisions.push(revision);
    } else if (layer === 'edit') {
      rate.capacityRevisions[selectedIndex] = revision;
    }

    onLayerClose();
    props.onChange(rate);

    setLastEditedRevision(revision);
  };

  const getDefaultRevision = () => {
    const revision = {
      effectiveDate: undefined,
      committedCapacity: 0.0,
      committedCapacityOverrideEnabled: false,
      requestedCapacity: 0.0,
      committedPct: 0.0,
    };

    if (requireInstalledCapacities(props.serviceType)) {
      revision.installedCapacity = 0.0;
    }

    return revision;
  };

  const renderLayer = () => {
    const { customer, meter, rate } = props;

    const startDates = (rate ? rate.capacityRevisions : []).reduce(
      (map, revision) => {
        map.push(revision.effectiveDate);
        return map;
      }, [],
    );

    if (layer === 'add') {
      return (
        <MeterCapacitiesEditor
          onClose={onLayerClose}
          revision={getDefaultRevision()}
          onChange={onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          serviceType={props.serviceType}
          userType={props.userType}
        />
      );
    }
    if ((layer === 'edit' || layer === 'copy') && selectedRevision) {
      if (layer === 'edit') {
        startDates.splice(startDates.indexOf(selectedRevision.effectiveDate), 1);
      } else {
        selectedRevision.effectiveDate = undefined;
      }

      return (
        <MeterCapacitiesEditor
          onClose={onLayerClose}
          revision={selectedRevision}
          onChange={onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          serviceType={props.serviceType}
          userType={props.userType}
        />
      );
    }
    return undefined;
  };

  return (
    <BaseContainer
      title='Capacities'
      actions={getActions()}
      expanded={props.expanded === 'NONE' || props.expanded === 'CAPACITY'}
      data-testid='capacities-container'
    >
      <Box
        direction='column'
        fill='vertical'
        data-e2e='capacities'
      >
        <GLBMDataTable
          columns={getDataColumns()}
          data={props.rate.capacityRevisions.map((r, index) => ({
            ...r,
            index,
          }))}
          primaryKey='effectiveDate'
          sortable={false}
          resizeable={false}
        />
      </Box>
      {renderLayer()}
    </BaseContainer>
  );
};

MeterCapacities.propTypes = {
  rate: PropTypes.object,
  expanded: PropTypes.string,
  onToggleExpansion: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  permissions: PropTypes.object,
  customer: PropTypes.object,
  meter: PropTypes.object,
  serviceType: PropTypes.object,
  userType: PropTypes.string,
};

export default MeterCapacities;
