// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import moment from 'moment';

export const validateDate = (date, contractEndMonth) => {
    const errors = [];
     // validate date to be proper date:
     const momentDate = moment(date, 'YYYY-MM', true);
     const isValid = momentDate.isValid();
     if (!date) { // at least 8 character
       errors.push('Required');
     } else if (!isValid) {
       errors.push('Invalid start month format (YYYY-MM)');
     } else if (momentDate.isAfter()) {
       errors.push('No later than current month');
     } else if (momentDate.isAfter(contractEndMonth, 'month')) {
       errors.push('No later than end month');
     } else if (momentDate.isBefore('2013-01-01', 'month')) {
       errors.push('No earlier than 2013-01');
     }
     return errors;
};
