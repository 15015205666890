// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useContext, useState } from 'react';
import {
  Box, Button, CheckBox, Footer, FormField, Text, TextInput,
} from 'grommet';
import { ApiContext } from '../../../AppContext';
import GLBMLayer from '../../shared/component/GLBMLayer';
import IDUtil from '../../shared/util/IDUtil';

interface EncryptionKeyCreateDialogProps {
  accountId: string;
  onClose: () => void;
}

const EncryptionKeyCreateDialog: React.FC<EncryptionKeyCreateDialogProps> = ({
  accountId, onClose
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [created, setCreated] = useState(false);
  const [name, setName] = useState('');
  const [fips, setFips] = useState(false);

  const apiContext = useContext(ApiContext);
  const onCreateAccountKey = () => {
    if (name) {
      setCreated(true);
      const path = apiContext('accountkeys.create.path', { accountId }, { name, fips });
      const windowTab = window.open(path, '_blank');
      if (windowTab) {
        windowTab.focus();
      }
      onClose();
    }
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title='Usage File Encryption'
      style={undefined}
    >
      <Box
        pad='none'
        style={{
          'width': '600px',
          'minWidth': '600px'
        }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={false}>
          <Text margin={{ top: 'none' }}>
            This will create a new encryption key for this billing account.
            A unique encryption key is used to encrypt the usage data that is
            collected by the metering script and sent to GLBM.
            In rare cases, the customer will require FIPS encryption. If so,
            please check the FIPS box below. Note that FIPS encryption should
            ONLY be checked if FIPS has been enabled on the metering VM. The
            metering script requires a file to encrypt the usage data using the
            new encryption key. Please click the Download button below to
            download that file. Note that this file can only be downloaded at
            this time and will not be available for download later. However, a
            new encryption key and file can always be generated later by
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            clicking the 'Add' button again. Do NOT email the file for security
            reasons.
          </Text>
        </Box>
        <Box pad='medium' flex={false} gap='medium'>
          <FormField
            label='Name'
            required={true}
            error={submitted && !name ? 'Required' : undefined}
            help='limit of 64 characters.'
          >
            <TextInput
              value={name}
              onChange={event => setName(event.target.value)}
              maxLength={64}
            />
          </FormField>
          <FormField>
            <CheckBox
              checked={fips}
              label='Use FIPS Encryption'
              onChange={event => setFips(event.target.checked)}
            />
          </FormField>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          {!created && (
            <Button
              primary={true}
              onClick={() => {
                setSubmitted(true);
                onCreateAccountKey();
              }}
              label='Download'
              {...{ 'data-testid': IDUtil.getId('EncryptionKeyCreateSubmitBtn') }}
            />
          )}
          <Button
            secondary={true}
            onClick={onClose}
            label='Cancel'
            {...{ 'data-testid': IDUtil.getId('EncryptionKeyCreateCancelBtn') }}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

export default EncryptionKeyCreateDialog;
