// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useErrorToast } from '../../components/shared/hooks';
import { executeGet } from '../api';
import { ApiContext } from '../../AppContext';

export const usePSADataQuery = (projectId, options) => {
  const path = useContext(ApiContext)('psa.read.path', { projectId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMER-PSA-DATA', projectId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Customer PSA Error');
};
