// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, FormField
} from 'grommet';
import isEqual from 'lodash/isEqual';
import { useQueryExtractCustomerRateCards } from '../../../../core';
import CustomerSelector from '../../../shared/component/CustomerSelector';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';

const ExtractCustomerRateCards = ({ action, onClose }) => {
  const [filter, setFilter] = useState({ customerId: [] });

  const {
    mutate: extract, isPending, isError, error, isSuccess
  } = useQueryExtractCustomerRateCards(filter.customerId || []);

  const onChange = (value) => {
    const name = 'customerId';
    const updatedFilter = { ...filter };
    if (!value || value === 'All') {
      updatedFilter[name] = [];
    } else {
      if (updatedFilter[name].includes(value)) {
        updatedFilter[name] = updatedFilter[name].filter(el => el !== value);
      } else {
        updatedFilter[name] = [...updatedFilter[name], value];
      }
      if (updatedFilter[name].length === 0) {
        delete updatedFilter[name];
      }
    }
    if (!isEqual(filter, updatedFilter)) {
      setFilter(updatedFilter);
    }
  };

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='small' flex={true} margin='small'>
        <FormField label='Billing Accounts' style={{ minWidth: '160px' }}>
          <CustomerSelector
            multiple={true}
            initialSelection={filter?.customerId}
            onCustomerSelected={(selected, last) => onChange(last)}
            plain={true}
          />
        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title={`Unable to extract ${action.name}`}
            message={error.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={extract}
              busy={isPending}
              data-testid='extractButton'
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractCustomerRateCards.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractCustomerRateCards;
