// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import {
  executeDelete, executeGet, executePost, executePut, executeUpload
} from '../api';

export const useQuotesQuery = (options) => {
  const path = useContext(ApiContext)('payg.quotes.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:QUOTES-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Quotes Error');
};

export const useQuotesQueryMap = options => useQuotesQuery({
  ...options,
  select: data => data?.reduce((_map, c) => {
    // eslint-disable-next-line no-param-reassign
    _map[c.id] = {
      id: c.id,
      name: c.name,
    };
    return _map;
  }, {}),
});

export const useQuoteQuery = (id, options) => {
  const path = useContext(ApiContext)('payg.quotes.read.path', { id });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:QUOTE-DETAIL', id],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Quote Error');
};

export const useWISEIdCreateMutate = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('payg.quotes.create.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:QUOTES-LIST'],
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'WISE ID Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'WISE ID Create Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};
export const useWISEIdUpdateMutate = (id, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('payg.quotes.update.path', { id });
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:QUOTES-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:QUOTE-DETAIL', id]
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'WISE ID Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'WISE ID Create Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useQuoteDeleteMutate = (options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (id) => {
      const path = contextFn('payg.quotes.delete.path', { id });
      return executeDelete(path);
    },
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:QUOTES-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:PAYG-ISV-DATAMAPPING-LIST'],
      });
      addToast({
        status: 'normal',
        title: 'Deleted',
        message: 'Quote Successfully Deleted.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Quote Delete Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};
export const usePaygISVFileUploadMutate = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('payg.isv.contract.upload.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (file) => {
      const payload = new FormData();
      payload.append('file', file);

      return executeUpload(path, payload);
    },
    ...(options || {}),
    onSuccess: (response, payload) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:PAYG-ISV-UPLOAD']
      });
      addToast({
        status: 'normal',
        title: 'Uploaded',
        message: 'ISV File Successfully Uploaded',
      });
      options?.onSuccess?.(response, payload);
    },
    onError: (error, file) => {
      options?.onError?.(error, file);
    }
  });
};

export const usePaygISVDataMappings = (options = {}) => {
  const path = useContext(ApiContext)('payg.isv.contract.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:PAYG-ISV-DATAMAPPING-LIST'],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Data Mappings Error');
};

export const usePaygISVDataMappingsMutation = (id, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('payg.isv.contract.quote.update.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:PAYG-ISV-DATAMAPPING-LIST'],
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Data Mapping Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Data Mapping Update Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};
