// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField } from 'grommet';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import CATextInput from '../../../../../shared/form/CATextInput';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import { parseStringAsPercent } from './utils';

const NutanixBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _onChangeUtilization = (property, value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config[property] = parseStringAsPercent(value);
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _onChangeBillingCapUsage = (event) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.capUsage = event.target.checked;

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  return (
    <FormField label='Billing Method VM2.0 (GiB)'>
      <Box gap='small' pad={{ vertical: 'small' }}>
        <Box flex='grow' className='tiny-inputs' direction='row' margin={{ left: 'small' }}>
          <CATextInput
            id='input_subscription_ratio'
            label='Subscription Ratio %'
            name='input_subscription_ratio'
            value={serviceEditor.options.config.subscriptionRatio}
            onChange={(value) => {
              _onChangeUtilization('subscriptionRatio', value);
            }}
            tooltip='Subscription Ratio is the percentage of physical memory available for Nutanixs (the rest is reserved for overhead).'
          />
        </Box>
        <Box flex='grow' className='tiny-inputs' direction='row' gap='small'>
          <div>
            <CACheckbox
              label='Cap usage at Installed Capacity'
              id='check_billing_options_cap_usage'
              name='check_billing_options_cap_usage'
              disabled={props.readOnly}
              onChange={(event) => {
                _onChangeBillingCapUsage(event);
              }}
              checked={serviceEditor.options.config.capUsage || false}
              tooltip='When checked, memory usage exceeding the physical memory of a host due to overprovisioned Nutanixs is not charged for.'
            />
          </div>
        </Box>
      </Box>
    </FormField>
  );
};

NutanixBillingMethodField.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default NutanixBillingMethodField;
