// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, CheckBox, FormField, Select } from 'grommet';

const RaidTypeEdit = (props) => {
  return (
    <Box direction='row' align='center' flex={false}>
      {props.isBulk && (
        <Box margin={{ top: 'medium' }}>
          <CheckBox
            name='raidType'
            checked={props.update}
            onChange={event => props.onBulkChange(event)}
            data-testid='equipment-bulk-edit-raid-type-checkbox'
          />
        </Box>
      )}
      <FormField
        key='serviceMappingRaidConfig'
        label={props?.column?.column?.header || 'Raid Config'}
      >
        <Select
          placeHolder='None'
          name='raidType'
          inline={false}
          multiple={true}
          value={props.value}
          options={props.config.raidTypes}
          onChange={event => props.onChange('raidType', event.option)}
        />
      </FormField>
    </Box>
  );
};

RaidTypeEdit.propTypes = {
  isBulk: PropTypes.bool,
  update: PropTypes.bool,
  onBulkChange: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
  column: PropTypes.object,
  config: PropTypes.shape({
    raidTypes: PropTypes.array
  })
};

export default RaidTypeEdit;
