// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer,
} from 'grommet';
import { cloneDeep } from 'lodash';
import GLBMLayer from '../shared/component/GLBMLayer';
import { UserType } from '../shared/constants/UserType';
import IDUtil from '../shared/util/IDUtil';
import FilterMultiSelect from '../shared/component/FilterMultiSelect';

const UserFilter = ({ filter: initialFilter = {}, onChange = null, onClose = null }) => {
  const [filter, setFilter] = useState(cloneDeep(initialFilter));

  const _onChange = (name, event) => {
    const newFilter = { ...filter };
    if (event.value.length === 0 || event.value[event.value.length - 1] === 'all') {
      delete newFilter[name];
    } else {
      newFilter[name] = event.value.filter(el => el !== 'all');
    }
    setFilter(newFilter);
  };

  const _onSubmit = () => {
    const newFilter = { ...filter };
    onChange(newFilter);
  };

  const _renderRoleOptions = (role) => {
    const options = UserType.enumValues.map(({ enumKey, label }) => ({
      label: label,
      value: enumKey,

    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FilterMultiSelect
        label='Role'
        data-testid='roleCBGroup'
        value={role || []}
        options={options}
        onChange={event => _onChange('role', event)}
      />
    );
  };

  const _renderEmailOptions = (email) => {
    const options = [{
      label: 'HPE Emails',
      value: '^[A-Za-z0-9._%+-]+@hpe\\.com$',
    }, {
      label: 'Non-HPE Emails',
      value: '^(?![A-Za-z0-9._%+-]+@hpe\\.com$)',
    }];

    return (
      <FilterMultiSelect
        label='Email'
        data-testid='emailCBGroup'
        labelKey='label'
        valueKey='value'
        value={email || []}
        options={options}
        onChange={event => _onChange('email', event)}
      />
    );
  };

  const _renderUserTypeOptions = (apiClient) => {
    const options = [{
      label: 'Users',
      value: 'false',
    }, {
      label: 'API Clients',
      value: 'true',
    }];

    return (
      <FilterMultiSelect
        data-testid='apiClientCBGroup'
        label='User Type'
        value={apiClient || []}
        options={options}
        onChange={event => _onChange('apiClient', event)}
      />
    );
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={false}
      a11yTitle='User Filter'
      style={{ minWidth: '400px' }}
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      title='Filter Users'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
        overflow={{ vertical: 'auto' }}
      >
        <Box flex={true}>
          {_renderRoleOptions(filter.role)}
          {_renderEmailOptions(filter.email)}
          {_renderUserTypeOptions(filter.apiClient)}
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              primary={true}
              onClick={_onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            id={IDUtil.getId('ResetBtn')}
            onClick={() => setFilter({})}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

UserFilter.propTypes = {
  filter: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default UserFilter;
