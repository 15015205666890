// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DateInput, MaskedInput } from 'grommet';
import styled from 'styled-components';
import moment from 'moment';

const StyledBox = styled(Box)`
  div {
    border: 0 !important;
  }
  input {
    border: 0 !important;
    box-shadow: none !important;
    &:disabled {
    opacity: 0.6;
    }
  }
`;

const DateTime = ({
  value = undefined, onChange, format, id, calendarProps = undefined, wrapValue = x => x, disabled = false, ...props
}) => {
  const [inputValue, setInputValue] = useState(value);

  const mask = () => {
    switch (format) {
      case 'YYYY-MM':
        return [{
          regexp: /^[0-9]{1,4}$/,
          placeholder: 'YYYY',
        },
        { fixed: '-' },
        {
          regexp: /^[0-9]{1,2}$/,
          placeholder: 'MM',
        }];
      case 'M/D/YYYY':
        return [{
          regexp: /^[0-9]{1,2}$/,
          placeholder: 'M',
        },
        { fixed: '/' },
        {
          regexp: /^[0-9]{1,2}$/,
          placeholder: 'D',
        },
        { fixed: '/' },
        {
          regexp: /^[0-9]{1,4}$/,
          placeholder: 'YYYY',
        }];
      case 'YYYY-MM-DD':
        return [{
          regexp: /^[0-9]{1,4}$/,
          placeholder: 'YYYY',
        },
        { fixed: '-' },
        {
          regexp: /^[0-9]{1,2}$/,
          placeholder: 'MM',
        },
        { fixed: '-' },
        {
          regexp: /^[0-9]{1,2}$/,
          placeholder: 'DD',
        }];
      default:
        break;
    }
    return undefined;
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    (
      <StyledBox
        direction='row'
        align='center'
        justify='between'
        fill='horizontal'
        style={{
        position: 'relative',
      }}
      >
        <MaskedInput
          placeholder={format}
          id={id}
          value={inputValue || ''}
          mask={mask()}
          disabled={disabled}
          onChange={(event) => {
          const regex = format.includes('/') ? /[^0-9/]/gi : /[^0-9-]/gi;
          const res = event.target.value.replace(regex, '');
          setInputValue(res);
          if (moment(res, format).isValid()) {
            onChange(wrapValue(res));
          } else {
            onChange(wrapValue(undefined));
          }
        }}
          {...props}
        />
        {!disabled && (
          <DateInput
            placeholder={format}
            value={value}
            onChange={(event) => {
              onChange(wrapValue(event.value ? moment(event.value).format(format) : undefined));
            }}
            calendarProps={calendarProps}
          />
        )}
      </StyledBox>
)
  );
};

DateTime.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  format: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  calendarProps: PropTypes.object,
  disabled: PropTypes.bool,
  wrapValue: PropTypes.func,
};

export default DateTime;
