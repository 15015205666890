// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiContext } from '../../AppContext';
import { executeGet, executePost, executePut } from '../api';

export const useAccessBillingQuery = (options) => {
  const path = useContext(ApiContext)('access.role.path');
  return useQuery({
    queryKey: ['BILLING_ACCOUNTS'],
    queryFn: () => executeGet(path),
    ...(options || []),
  });
};

export const useAccessServiceQuery = (options) => {
  const path = useContext(ApiContext)('access.service.definitions.path');
  return useQuery({
    queryKey: ['SERVICE_DEFS'],
    queryFn: () => executeGet(path),
    ...(options || []),
  });
};

export const useMutationRequestAccess = (isExist, options) => {
  const path = useContext(ApiContext)('access.roles.update.path');
  return useMutation({
    mutationFn: payload => (isExist ? executePut(path, payload) : executePost(path, payload)),
    ...(options || {}),
  });
};
