// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Anchor,
  Box,
  Button,
  CheckBox,
  ColumnConfig,
  Data,
  // @ts-expect-error
  DataContext,
  DataFilter,
  DataFilters,
  DataSearch,
  DataSummary,
  Main,
  Menu,
  Text,
  Toolbar,
} from 'grommet';
import { Edit, More } from 'grommet-icons';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import {
  useServiceFeedDeleteMutate,
  useServiceFeedUpdateMutate,
} from '../../../core';
import type { Feed } from '../../../core/types';
import { useSelectedCustomerId } from '../../contexts';
import WarningBanner from '../../shared/banner/WarningBanner';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import GLBMSaving from '../../shared/component/GLBMSaving';
import Toast from '../../shared/component/Toast';
import { pagePermissions } from '../../shared/constants/Permissions';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import SaveChangesDialog from '../../shared/dialogs/SaveChangesDialog';
import {
  usePermissionChecker,
  useStateWithSessionStorage,
} from '../../shared/hooks';
import { insertIf } from '../../shared/util/BasicUtil';
import IDUtil from '../../shared/util/IDUtil';
import BulkFeedEditor from './BulkFeedEditor';
import DataHighlighter from './DataHighlighter';
import FeedEditor from './FeedEditor';

type DirtyFeed = Feed & { dirty?: boolean };
type DataFeed = DirtyFeed & {
  hasExcludeBefore: 'Yes' | 'No'
  hasExcludeAfter: 'Yes' | 'No'
  isIncluded: 'Yes' | 'No'
};

const cleanedFeeds = (dirtyFeeds: DirtyFeed[]): Feed[] => dirtyFeeds.map(feed => ({
  feed: feed.feed,
  from: feed.from,
  to: feed.to,
  exclude: feed.exclude,
  excludeBefore: feed.excludeBefore,
  excludeAfter: feed.excludeAfter,
  notes: feed.notes,
}));

const isFeedDirty = (origFeed: Feed, newFeed: Feed) => (
  newFeed.exclude !== origFeed.exclude
  || newFeed.excludeBefore !== origFeed.excludeBefore
  || newFeed.excludeAfter !== origFeed.excludeAfter
  || newFeed.notes !== origFeed.notes
);

const formatDate = (date: string) => {
  if (date) {
    return moment(date).format('L');
  }
  return '--';
};

/*
 * This updates the selected rows in the table to only be those shown.  So
 * if filter is updated and a selected item is no longer visible, then it will be
 * deselected.
 */
const AlignFilterAndSelect = ({
  setSelected,
}: {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const { data } = useContext<{ data: DataFeed[] }>(DataContext);
  useEffect(() => {
    setSelected((selected) => {
      const foundSelected = filter(selected, (id: string) => !!find(data, ['feed', id]));
      if (foundSelected.length !== selected.length) {
        return foundSelected;
      }
      return selected;
    });
  }, [data, setSelected]);
  return null;
};

interface Props {
  customer: {
    id: string
    name: string
    contractEndMonth: string
  }
  feeds: Feed[]
  serviceId: string
}

const FeedList = ({ feeds: feedsProp, customer, serviceId }: Props) => {
  const navigate = useNavigate();
  const [selectedCustomerId] = useSelectedCustomerId();
  const [usageFilters, setUsageFilters] = useStateWithSessionStorage('usageFileFilters', {
    customerId: selectedCustomerId,
    list: { searchText: '', sort: { property: 'fileName', direction: 'asc', external: true } },
    panel: {
      from: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      to: moment.utc().format('YYYY-MM-DD'),
      stage: ['raw'],
    },
  });

  const [editingFeed, setEditingFeed] = useState(null);
  const [deletingFeed, setDeletingFeed] = useState(null);
  const [feeds, setFeeds] = useState<DirtyFeed[]>(feedsProp);
  const [response, setResponse] = useState(undefined);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  const hasSelected = !!selected.length;
  const selectedFeeds = useMemo(() => map(selected, (key: string) => find(feeds, ['feed', key])), [feeds, selected]);

  const dirtyCount = useMemo(() => filter(feeds, 'dirty').length, [feeds]);
  const isDirty = dirtyCount !== 0;

  const tableData = useMemo<DataFeed[]>(() => (
    feeds.map((f): DataFeed => ({
      ...f,
      hasExcludeBefore: f.excludeBefore ? 'Yes' : 'No',
      hasExcludeAfter: f.excludeAfter ? 'Yes' : 'No',
      isIncluded: f.exclude ? 'No' : 'Yes',
    }))
  ), [feeds]);

  const { hasPermissions } = usePermissionChecker();

  const canEdit = useMemo(() => hasPermissions(pagePermissions.customers.view.feeds.actions.edit), [hasPermissions]);
  const canDelete = useMemo(() => hasPermissions(pagePermissions.customers.view.feeds.actions.delete), [hasPermissions]);
  const canViewFiles = useMemo(() => hasPermissions(pagePermissions.usage.page), [hasPermissions]);

  const {
    mutate: saveFeeds,
    isPending: isSavingFeeds,
    isSuccess: isSaveSuccess,
    error: savingFeedError,
  } = useServiceFeedUpdateMutate(customer.id, serviceId, {
    onSuccess: () => {
      setTimeout(() => {
        navigate(`/customers/${customer.id}/services`);
      }, 100);
    },
  });

  const {
    mutate: deleteFeed,
    isPending: isDeletingFeed,
    error: deleteFeedError,
  } = useServiceFeedDeleteMutate(customer.id, serviceId, {
    onSuccess: () => {
      setDeletingFeed(undefined);
    },
  });

  const cancelLayer = useCallback(() => {
    setEditingFeed(undefined);
    setDeletingFeed(undefined);
    setShowBulkEdit(false);
  }, []);

  const toggleExclude = useCallback((feed: DataFeed, e: React.ChangeEvent<HTMLInputElement>) => {
    setFeeds((prevArray) => {
      const newArray = [...prevArray]; // Create a shallow copy of the original array
      const index = findIndex(feeds, ['feed', feed.feed]);
      const origFeed = find(feedsProp, ['feed', feed.feed]);
      const newExclude = !e.target.checked;
      newArray[index] = {
        ...newArray[index],
        ...{
          ...feed,
          exclude: newExclude,
          dirty: newExclude !== origFeed.exclude,
        },
      }; // Update the specific object
      return newArray; // Return the new array as the updated state
    });
  }, [feeds, feedsProp]);

  const submitBulkEdit = useCallback((changedFeeds: Feed[]) => {
    setFeeds((prevState) => {
      const newState = [...prevState];
      changedFeeds.forEach((newFeed) => {
        const foundIndex = findIndex(newState, ['feed', newFeed.feed]);
        const origFeed = find(feedsProp, ['feed', newFeed.feed]);
        newState[foundIndex] = { ...newFeed, dirty: isFeedDirty(origFeed, newFeed) };
      });
      return newState;
    });
    setShowBulkEdit(false);
  }, [feedsProp]);

  const submitEdit = useCallback((feed: Feed) => {
    setFeeds((prevState) => {
      // integrate the copy into the main feed list
      const foundIndex = findIndex(prevState, ['feed', feed.feed]);
      const origFeed = find(feedsProp, ['feed', feed.feed]);
      const newState = [...prevState];
      const newFeed = { ...newState[foundIndex], ...feed };
      newFeed.dirty = isFeedDirty(origFeed, newFeed);
      newState[foundIndex] = newFeed;
      return newState;
    });

    setEditingFeed(null);
  }, [feedsProp]);

  const submitDelete = useCallback(({ feed }) => {
    deleteFeed(feed.feed);
  }, [deleteFeed]);

  const onSaveFeed = useCallback(() => {
    // extract all dirty feeds and send them to the endpoint
    const dirtyFeeds = feeds.filter(feed => feed.dirty === true);
    if (dirtyFeeds.length > 0) {
      saveFeeds(cleanedFeeds(dirtyFeeds));
    } else {
      navigate(`/customers/${customer.id}/services`);
    }
  }, [customer.id, feeds, navigate, saveFeeds]);

  const viewFiles = useCallback((feed: Feed) => {
    const updatedFilters = JSON.parse(JSON.stringify(usageFilters));
    updatedFilters.customerId = customer.id;
    updatedFilters.list.searchText = feed.feed;
    updatedFilters.panel.service = [serviceId];
    updatedFilters.panel.from = moment(feed.to).subtract(3, 'days').format('YYYY-MM-DD');
    updatedFilters.panel.to = feed.to;
    setUsageFilters(updatedFilters);
    navigate('/usage');
  }, [customer.id, navigate, serviceId, usageFilters]);

  const renderContextDetails = (feed: DataFeed) => (
    <GLBMNameValueList
      title='Selected Feed'
      data={[
        { label: 'Feed', value: feed.feed },
        {
          label: 'First Seen Date',
          value: (
            <Box direction='row' gap='medium'>
              <Text>{formatDate(feed.from)}</Text>
              <Text weight={100} size='small'>{moment(feed.from).fromNow()}</Text>
            </Box>
          ),
        },
        {
          label: 'Last Seen Date',
          value: (
            <Box direction='row' gap='medium'>
              <Text>{formatDate(feed.to)}</Text>
              <Text weight={100} size='small'>{moment(feed.to).fromNow()}</Text>
            </Box>
          ),
        },
      ]}
      pairProps={{ direction: 'column' }}
      valueProps={{ width: 'medium' }}
    />
  );

  const renderFeedDeleteConfirm = () => {
    if (deletingFeed) {
      return (
        <ConfirmationDialog
          data={{ feed: deletingFeed }}
          title='Are You Sure?'
          submitLabel='Yes, delete the Feed'
          cancelLabel='Cancel'
          text='Deleting a feed will permanently remove the feed and all usage files and resources associated with the feed, and will affect service configurations that use those resources.  This action cannot be undone.  Do you still want to proceed?'
          onClose={cancelLayer}
          onChange={submitDelete}
          details={renderContextDetails(deletingFeed)}
        />
      );
    }
    return null;
  };

  const renderToast = () => {
    let message: React.ReactNode = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={() => setResponse(undefined)}
        >
          {response.message}
        </Toast>
      );
    }
    return message;
  };

  const getActions = useCallback((feed: Feed) => [
    ...insertIf(canEdit, [{
      onClick: () => setEditingFeed(feed),
      label: 'Edit',
      size: 'medium',
    }]),
    ...insertIf(canDelete, [{
      onClick: () => setDeletingFeed(feed),
      label: 'Delete',
      size: 'medium',
    }]),
    ...insertIf(canViewFiles, [{
      onClick: () => viewFiles(feed),
      label: 'View Files',
      size: 'medium',
    }]),
  ], [canDelete, canEdit, canViewFiles, viewFiles]);

  const renderFeedName = useCallback((feed) => {
    const content = <DataHighlighter>{feed.feed}</DataHighlighter>;
    if (canEdit && !hasSelected) {
      return <Anchor onClick={() => setEditingFeed(feed)}>{content}</Anchor>;
    }
    return <Text weight={500} color='text-strong'>{content}</Text>;
  }, [canEdit, hasSelected]);

  const columns = useMemo<ColumnConfig<DataFeed>[]>(() => [
    {
      property: 'feed',
      header: 'Feed Name',
      primary: true,
      render: renderFeedName,
    },
    {
      property: 'exclude',
      header: 'Include',
      render: feed => <CheckBox name='exclude' toggle={true} checked={!feed.exclude} onChange={e => toggleExclude(feed, e)} disabled={!canEdit || hasSelected} />,
    },
    {
      property: 'from',
      header: 'First Seen Date',
      render: ({ from }) => formatDate(from),
    },
    {
      property: 'to',
      header: 'Last Seen Date',
      render: ({ to }) => formatDate(to),
    },
    {
      property: 'excludeBefore',
      header: 'Exclude Before',
      render: ({ excludeBefore }) => formatDate(excludeBefore),
    },
    {
      property: 'excludeAfter',
      header: 'Exclude After',
      render: ({ excludeAfter }) => formatDate(excludeAfter),
    },
    {
      property: 'notes',
      header: 'Notes',
      render: ({ notes }) => notes || '--',
    },
    ...(canEdit || canDelete || canViewFiles ? [{
      property: 'actions',
      header: 'Actions',
      align: 'start' as const,
      sortable: false,
      render: (feed: DataFeed) => ((
        <Menu
          icon={<More />}
          items={getActions(feed)}
          disabled={hasSelected}
        />
      )),
    }] : []),
  ], [renderFeedName, canEdit, canDelete, canViewFiles, hasSelected, toggleExclude, getActions]);

  const renderWarning = () => {
    if (customer?.contractEndMonth) {
      return (
        <WarningBanner
          message="This billing account has End Month set. Any changes made could affect historical data but will not affect anything after the 'End Month'"
        />
      );
    }
    return null;
  };

  const customerTitle = `${customer.name} (${customer.id})`;
  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      {renderWarning()}
      <GLBMHeading
        title={`Feed Management for ${customerTitle}`}
        back={`/customers/${customer.id}/services`}
      />
      <Data
        pad={{ horizontal: 'small' }}
        flex={true}
        data={tableData}
        properties={{
          feed: { label: 'Feed Name' },
          isIncluded: { label: 'Included', search: false, options: ['Yes', 'No'] },
          hasExcludeBefore: { label: 'Exclude before set', search: false, options: ['Yes', 'No'] },
          hasExcludeAfter: { label: 'Exclude after set', search: false, options: ['Yes', 'No'] },
        }}
        id='feed-list-data'
      >
        <Toolbar>
          <DataSearch width='medium' placeholder='Search' />
          <DataFilters layer={true}>
            <DataFilter property='isIncluded' />
            <DataFilter property='hasExcludeBefore' />
            <DataFilter property='hasExcludeAfter' />
          </DataFilters>
          <AlignFilterAndSelect setSelected={setSelected} />
        </Toolbar>
        <Box
          flex={false}
          background='background-contrast'
          data-e2e='bulk-edit-section'
          pad={{ horizontal: 'small', vertical: 'xxsmall' }}
          margin={{ top: 'small' }}
          direction='row'
          justify='between'
          wrap={true}
        >
          <DataSummary />
          {canEdit && (
            <Box gap='small' direction='row' align='center'>
              <Text margin={{ right: 'small' }}>Select one or more feeds to bulk edit.</Text>
              <Button
                label='Bulk Edit'
                icon={<Edit />}
                disabled={!hasSelected}
                onClick={() => setShowBulkEdit(true)}
              />
            </Box>
          )}
        </Box>
        <GLBMDataTable
          select={selected}
          onSelect={canEdit ? setSelected : undefined}
          columns={columns}
          loading={false}
          sortable={true}
          resizeable={false}
        />
      </Data>
      <Box direction='row' border='top' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} flex={false} align='center'>
        {canEdit && (
          <Button
            label='Save'
            type='button'
            primary={true}
            id={IDUtil.getId('ManageFeedsToolbarSaveButton')}
            disabled={isSavingFeeds}
            onClick={onSaveFeed}
          />
        )}
        <Button
          label='Cancel'
          type='button'
          secondary={true}
          id={IDUtil.getId('ManageFeedsToolbarCancelButton')}
          onClick={() => navigate(`/customers/${customer.id}/services`)}
        />
        <Box fill={true} direction='row' align='center'>
          <Text>{`${dirtyCount} modified feeds`}</Text>
        </Box>
        <GLBMSaving saving={isSavingFeeds} error={savingFeedError} />
        <GLBMSaving saving={isDeletingFeed} error={deleteFeedError} />
      </Box>
      {(editingFeed) && (
        <FeedEditor feed={editingFeed} onSubmit={submitEdit} onClose={cancelLayer} />
      )}
      {showBulkEdit && (
        <BulkFeedEditor feeds={selectedFeeds} onSubmit={submitBulkEdit} onClose={cancelLayer} />
      )}
      {renderFeedDeleteConfirm()}
      {renderToast()}
      <ReactRouterPrompt when={isDirty && !isSavingFeeds && !isSaveSuccess}>
        {({ onConfirm, onCancel }) => (
          <SaveChangesDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
    </Main>
  );
};

export default FeedList;
