// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import pluralize from 'pluralize';
import * as Case from 'case';

import { Box, Button, Footer, Meter, Paragraph, Text } from 'grommet';
import { Checkmark, Save } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import { StatusIcon } from '../shared/component/StatusIcon';
import { useServicePromoteMutate } from '../../core';
import GLBMLayer from '../shared/component/GLBMLayer';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import ServiceTypeStore from '../stores/ServiceTypeStore';

const ServicePromoteDialog = ({
  service: initialService,
  customer,
  onClose,
                              }) => {
  const [service, setService] = useState(initialService);
  const [data, setData] = useState({
    isSaving: false,
    hasError: false,
    error: '',
  });

  const {
    mutate: promoteService
  } = useServicePromoteMutate(customer.id, service.serviceType, {
    onSuccess: () => {
      setService(prevState => ({
        ...prevState,
        status: 'COMPLETE',
      }));
      setData(prevState => ({
        ...prevState,
        isSaving: false,
        hasError: false,
        error: ''
      }));
    },
    onError: (error) => {
      setData(prevState => ({
        ...prevState,
        isSaving: false,
        hasError: true,
        error: error.message
      }));
    }
  });

  const onSubmit = () => {
    setData(prevState => ({
      ...prevState,
      isSaving: true,
      hasError: false,
      error: ''
    }));

    setTimeout(() => {
      promoteService({
        ...service,
        status: 'COMPLETE'
      });
    });
  };

  const renderConfirmationDetails = () => {
    const serviceType = ServiceTypeStore.getService(service.serviceType);

    return (
      <Box pad={{ horizontal: 'medium' }}>
        <GLBMNameValueList
          title='Selected Service'
          data={[
            { label: 'Service', value: serviceType && serviceType.label },
            { label: 'Resources found', value: `${service.equipment ? service.equipment : 0} ${pluralize(Case.capital(serviceType && serviceType.device), service.equipment)}` },
            { label: 'First date collected', value: new Date(service.firstSeen).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' }) },
            { label: 'Defined rates', value: `${service.rates ? service.rates : 0} of ${service.possibleRates ? service.possibleRates : 0}` },
            { label: 'Included feeds', value: `${service.feeds.filter(feed => !feed.exclude).length} of ${service.feeds.length}` },
          ]}
        />
      </Box>
    );
  };


  const renderSaveComponent = () => {
    if (!data) {
      return (<Box />);
    }

    const isPromoted = (service.status === 'COMPLETE');
    const { hasError } = data;
    let status;
    let meter;

    if (isPromoted) {
      meter = (<Meter size='large' color='gray' value={1} min={0} max={1} />);
      status = <StatusIcon value='ok' />;
    } else if (hasError) {
      status = <StatusIcon value='critical' />;
    }

    let error;
    if (hasError) {
      error = (
        <Box className='criticalLabel'>
          <Text weight={100} margin='none'><strong>{data.error}</strong></Text>
        </Box>
      );
    }

    return (
      <Box pad={{ 'vertical': 'small', 'horizontal': 'medium' }} width={{ max: '696px' }}>
        <Box
          direction='row'
          justify='between'
          align='center'
          gap='small'
          announce={true}
          responsive={false}
        >
          {meter}
          {status}
        </Box>

        {error}
      </Box>
    );
  };

  const renderSubmitButton = () => {
    const isPromoted = (service.status === 'COMPLETE');
    const { isSaving } = data;

    if (!isSaving && !isPromoted) {
      return (
        <Button
          label='Promote to Production'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          primary={true}
          icon={<Save />}
          onClick={onSubmit}
        />
      );
    } if (isSaving && !isPromoted) {
      return (
        <Button
          label='Promoting ...'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          secondary={true}
          icon={<Save />}
          disabled={true}
        />
      );
    } if (isPromoted) {
      return (
        <Button
          label='Promoted'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          primary={true}
          icon={<Checkmark />}
          onClick={onClose}
        />
      );
    }
    return '';
  };

  let closeControl;
  if (onClose) {
    closeControl = (
      <Button
        label='Cancel'
        onClick={onClose}
        id={IDUtil.getId('ServicePromoteDialogCancelButton')}
        secondary={true}
      />
    );
  }

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={onClose}
      onClickOutside={onClose}
      onEsc={onClose}
      full='vertical'
      title='Are you sure?'
    >
      <Box direction='row' align='center' pad={{ horizontal: 'medium' }}>
        <StatusIcon value='warning' />
        <Box margin={{ left: 'small' }}>
          <Paragraph>
            Once you promote this service to Production, customer users can see its data.
            You cannot undo this action.
          </Paragraph>
        </Box>
      </Box>
      <Box flex={true}>
        {renderConfirmationDetails()}
        {renderSaveComponent()}
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          {renderSubmitButton()}
          {closeControl}
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ServicePromoteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

export default ServicePromoteDialog;
