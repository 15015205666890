// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Select } from 'grommet';
import { cloneDeep } from 'lodash';

const defaultContractStartMonth = '2016-01-01';

const SingleResourceRevisionEdit = ({
  name,
  attribute,
  revisions,
  onChange,
  contractStartMonth,
  options
}) => (
  <FormField
    label={name}
  >
    <Select
      labelKey='label'
      valueKey={{
        key: 'value',
        reduce: true
      }}
      placeHolder='None'
      name={attribute}
      value={(revisions && revisions.length && revisions[0].id) || undefined}
      options={options}
      onChange={(event) => {
        const updatedRevisions = cloneDeep(revisions || []);
        if (!updatedRevisions || updatedRevisions.length === 0) {
          updatedRevisions.push({ id: event.option.value, effectiveDate: contractStartMonth || defaultContractStartMonth });
        } else {
          updatedRevisions[0].id = event.option.value;
        }
        onChange(attribute, updatedRevisions);
      }}
      data-testid={`single-resource-revision-${attribute}`}
    />
  </FormField>
);

SingleResourceRevisionEdit.propTypes = {
  name: PropTypes.string,
  attribute: PropTypes.string,
  revisions: PropTypes.array,
  onChange: PropTypes.func,
  contractStartMonth: PropTypes.string,
  options: PropTypes.array
};

export default SingleResourceRevisionEdit;
