// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';

import { Anchor, Box, Notification } from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomerQuery, useCustomerServiceQuery } from '../../../core';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import Loader from '../../shared/loader';
import FeedList from './FeedList';

const FeedPage = () => {
  const navigate = useNavigate();
  const { customerId, serviceId } = useParams();

  const {
    data: customer,
    isFetching: isLoadingCustomer,
    isError: isErrorCustomer,
    error: errorCustomer,
  } = useCustomerQuery(customerId);

  const {
    data: service,
    isFetching: isLoadingService,
    isError: isErrorService,
    error: errorService,
  } = useCustomerServiceQuery(customerId, serviceId);

  const getLoadingContent = () => (
    <Box direction='row' align='center' gap='small' justify='center' fill={true}>
      <Loader text='Loading Feeds. Please wait ...' />
    </Box>
  );

  const getErrorResponseContent = (errorResponse) => {
    const serviceTypeEnum = ServiceTypeStore.getService(serviceId);
    return (
      <Box fill={false} direction='column' size='full' justify='center'>
        <Notification
          state={errorResponse.stacktraceRef}
          message={`Unable to show Feed Management for ${serviceTypeEnum.name} service`}
          timestamp={errorResponse.message}
          status={errorResponse.status}
        />
        <Box direction='row' pad='medium' justify='start'>
          <Anchor animateIcon={true} primary={true} onClick={() => navigate(-1)} icon={<FormPreviousLink size='xxlarge' color='brand' />}>Back to services</Anchor>
        </Box>
      </Box>
    );
  };

  if (isLoadingCustomer || isLoadingService) {
    return getLoadingContent();
  }
  if (isErrorCustomer || isErrorService) {
    return getErrorResponseContent(errorCustomer || errorService);
  }
  return (
    <FeedList
      customer={customer}
      serviceId={serviceId}
      feeds={service.feeds}
    />
  );
};

export default FeedPage;
