// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';
import { ThemeContext } from 'grommet/contexts';

const ValueCell = ({
                     value = undefined,
                     primary = false,
                     ...rest
                   }: { value?: any, primary?: boolean }) => {
  const theme = useContext(ThemeContext);
  const isEmpty = !value;
  // @ts-expect-error
  const extraProps = primary ? theme?.dataTable?.primary : {};
  return (
    <Text
      data-testid='value-cell'
      a11yTitle={isEmpty ? 'No value' : undefined}
      {...extraProps}
      {...rest}
    >
      {isEmpty ? '--' : value}
    </Text>
  );
};

ValueCell.propTypes = {
  value: PropTypes.node,
  primary: PropTypes.bool,
};

ValueCell.defaultProps = {
  value: undefined,
  primary: false,
};

export default ValueCell;
