// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

export default {

  // App:
  'app.name': 'HPE GreenLake Billing Manager',

  // Login / Logout Dialogs:
  'login.dialog.button.label': 'HPE Employee sign in',

  // Sidebar Navigation:
  'sidenav.billing-configuration.label': 'Billing Configuration',
  'sidenav.companies.label': 'Companies',
  'sidenav.payg-administration.label': 'PAYG Administration',
  'sidenav.metering-files.label': 'Metering Files',
  'sidenav.analytics.label': 'Analytics',
  'sidenav.alerts.label': 'Alerts',
  'sidenav.users.label': 'Users',
  'sidenav.jobs.label': 'Processing',
  'sidenav.administration.label': 'Administration',
  'sidenav.partners.label': 'Partners',
  'sidenav.audit-logs.label': 'Audit Logs',
  'sidenav.service-mappings.label': 'Service Mappings',
  'sidenav.service-meters.label': 'Service Meters',
  'sidenav.access-requests.label': 'Access Requests',


  // software license: license types:
  'fc.service.license.type.PER_10_VM': 'Per 10 VM',
  'fc.service.license.type.PER_VM_SOCKETS': 'Per VM Socket',
  'fc.service.license.type.PER_FE_TB': 'Per FE TB',
  'fc.service.license.type.PER_OPERATING_INSTANCE': 'Per Operating Instance',
  'fc.service.license.type.PER_USER': 'Per User',
  'fc.service.license.type.PER_VOI': 'Per Virtual Operating Instance',

  // cohesity license: license types:
  'fc.service.license.type.DATAPLATFORM': 'DataPlatform',
  'fc.service.license.type.DATAPROTECT': 'DataProtect',
  'fc.service.license.type.CLOUDARCHIVE': 'CloudArchive',
  'fc.service.license.type.CLOUDSPIN': 'CloudSpin',
  'fc.service.license.type.CLOUDTIER': 'CloudTier',

  // Analytics: Capacity Planning:
  'fc.analytics.capacity-planning.title': 'Capacity Planning',
  'fc.analytics.capacity-planning.dataConvertedFromMeterUsage.footnote': 'The data in the chart and table above represents the physical from the values on the Monthly Charges report since the Monthly Charges report is showing billing values which are based on the sum of the hourly usage over the entire month.',
  'fc.analytics.capacity-planning.type.footnote': 'Available Capacity and Installed Capacity (as seen on the last day of the reported month)',
};
