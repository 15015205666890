// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField, TextInput } from 'grommet';
import { v1 as UUID } from 'uuid';
import GLBMLayer from '../../../../../../shared/component/GLBMLayer';
import CustomTiersCriteria from './CustomTiersCriteria';

const CustomTiersEditor = (props) => {
  const initialModel = useMemo(() => {
    const modelCopy = { ...props.model };
    if (!modelCopy.id || props.isCopy) {
      modelCopy.id = UUID();
    }
    return modelCopy;
  }, [props.model, props.isCopy]);

  const [model, setModel] = useState(initialModel);
  const [isModified, setIsModified] = useState(false);

  const onModelChange = ({ criteria: newCriteria }) => {
    setModel(prevModel => ({
      ...prevModel,
      criteria: newCriteria,
    }));
    setIsModified(true);
  };

  const onNameChange = (event) => {
    setModel({
      ...model,
      name: event.target.value
    });
    setIsModified(true);
  };

  const onClose = () => {
    props.onClose();
  };

  const onSubmit = () => {
    props.onSubmit(model, props.isCopy ? undefined : props.tierIndex, isModified);
  };

  const isGroupValid = (group) => {
    let valid = true;
    group.criteria.forEach((criteria) => {
      if (criteria.value.length === 0) {
        valid = false;
      }
    });
    if (group.groups && group.groups.length) {
      group.groups.forEach((g) => {
        if (!isGroupValid(g)) {
          valid = false;
        }
      });
    }
    return valid;
  };

  const getNameError = () => {
    if (model.name.length === 0) {
      return 'a name is required';
    }

    const tiers = props.allTiers;
    let tierNameExists = false;
    tiers.forEach((tier, index) => {
      if (props.isCopy && model.name === tier.name) {
        tierNameExists = true;
      } else if (index !== props.tierIndex && model.name === tier.name) {
        tierNameExists = true;
      }
    });
    if (tierNameExists) {
      return 'duplicate tier name';
    }
    return undefined;
  };

  const getSubmitButton = () => {
    let errors = 0;
    model.criteria.forEach((group) => {
      if (!isGroupValid(group)) {
        errors += 1;
      }
    });
    if (getNameError()) {
      errors += 1;
    }
    if (!errors) {
      return onSubmit;
    }
    return undefined;
  };

  return (
    <GLBMLayer flush={true} className='full-hack' title='Tier Definition'>
      <Box width='xlarge'>
        <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
          <Box direction='row' flex={false} pad={{ bottom: 'small' }} gap='small'>
            <Box flex={true}>
              <FormField label='Tier Name' error={getNameError()}>
                <TextInput
                  name='name'
                  value={model.name}
                  onChange={onNameChange}
                  disabled={props.isBrim}
                />
              </FormField>
            </Box>
            <Box flex={true}>
              {props.isBrim && (
                <FormField label='Service Instance Id'>
                  <TextInput
                    name='serviceInstanceId'
                    value={model.serviceInstanceId}
                    disabled={true}
                  />
                </FormField>
              )}
            </Box>
          </Box>
          <CustomTiersCriteria
            service={props.service}
            fields={props.fields}
            model={model}
            onModelChange={onModelChange}
            isCopy={props.isCopy}
          />
        </Box>
        <Box
          flex={false}
          direction='row'
          align='center'
          justify='start'
          pad={{
            horizontal: 'small',
            vertical: 'small'
          }}
          gap='small'
        >
          <Button primary={true} label='Save Changes' onClick={getSubmitButton()} />
          <Button secondary={true} label='Cancel' onClick={onClose} />
        </Box>
      </Box>
    </GLBMLayer>
  );
};

CustomTiersEditor.propTypes = {
  allTiers: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  isBrim: PropTypes.bool,
  isCopy: PropTypes.bool,
  model: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  tierIndex: PropTypes.number,
};

export default CustomTiersEditor;
