// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import StatusItem from './StatusItem';

const getStatusDetails = (status) => {
  switch (status) {
    case 'OK':
      return (<StatusItem severity='ok' status='Account is ready to be promoted' />);
    case 'CA_NOT_PROVISIONED':
      return (<StatusItem severity='critical' status='CA Not Provisioned' description='CA has not been provisioned for GLC tenant' />);
    case 'UNKNOWN_BILLING_ID':
      return (<StatusItem severity='critical' status='Unknown Billing Id' description='This billing account has not been setup in GLC' />);
    case 'MULTIPLE_TENANTS_PROVISIONED':
      return (<StatusItem severity='critical' status='Unknown Billing Id' description='Multiple non partner tenants have been provisioned for this billing id' />);
    case 'NO_SERVICES':
      return (<StatusItem severity='critical' status='No Promoted Services' description={'Billing account can\'t be promoted to production unless at least one service has been promoted to production.'} />);
    default:
      return status;
  }
};

const GLCAccountDetails = ({ data = {} }) => {
  const payload = useMemo(() => [
    { label: 'Tenant Name', value: data?.tenantName },
    { label: 'CA Tenant ID', value: data?.caTenantId },
    { label: 'GLC Tenant ID', value: data?.glcTenantId },
    { label: 'GLP Workspace ID', value: data?.glpWorkspaceId },
    { label: 'Status', value: getStatusDetails(data?.status) },
  ], [data]);

  return (
    <Box
      pad={{ 'horizontal': 'small' }}
      align='start'
      margin='small'
      flex={false}
    >
      <GLBMNameValueList
        title='GLC Account Details'
        data={payload}
      />
    </Box>
  );
};

GLCAccountDetails.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string.isRequired,
    tenantName: PropTypes.string,
    caTenantId: PropTypes.string,
    glcTenantId: PropTypes.string,
    glpWorkspaceId: PropTypes.string,
  }),
};

export default GLCAccountDetails;
