// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import {
  Anchor,
  Button, Main, Menu, Text,
} from 'grommet';
import { Add, More, Refresh } from 'grommet-icons';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import GLBMSearch from '../../shared/component/GLBMSearch';
import { useQuotesQuery } from '../../../core';
import {
  HighlightUsingContext,
  SearchTextContextProvider,
} from '../../shared/component/HighlightUsingContext';
import WiseQuoteIdCreatePage from './WiseQuoteIdCreatePage';
import WiseQuoteIdDeletePage from './WiseQuoteIdDeletePage';

const WiseQuoteIdListPage = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedQuoteId, setSelectedQuoteId] = useState(undefined);
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const {
    data: quotesData,
    isFetching: fetchingQuotes,
    refetch: refreshQuotes,
  } = useQuotesQuery();

  const onRemoveQuote = (id) => {
    if (id) {
      setShowDeleteDialogue(true);
      setSelectedQuoteId(id);
    }
  };

  const createWiseId = (id) => {
    setSelectedQuoteId(id);
    setShowEditDialog(true);
  };

  const getActions = ({ id }) => [
    {
      label: 'Edit WISE ID',
      onClick: () => {
        createWiseId(id);
      },
    },
    {
      label: 'Delete WISE ID',
      onClick: () => {
        onRemoveQuote(id);
      },
    },
  ];
  const columns = React.useMemo(() => ([{
    property: 'quoteId',
    header: 'WISE Quote ID',
    sort: true,
    primary: true,
    render: ({ quoteId, id }) => (
      <Anchor weight='bold' onClick={() => createWiseId(id)}>
        <Text weight='bold'>
          <HighlightUsingContext>{quoteId || '--'}</HighlightUsingContext>
        </Text>
      </Anchor>

    ),
  }, {
    property: 'scsCodeLicense',
    header: 'SCS Code-License',
    sort: true,
    render: ({ scsCodeLicense }) => (<HighlightUsingContext>{scsCodeLicense || '--'}</HighlightUsingContext>),
  },
  {
    property: 'scsCodeSupport',
    header: 'SCS Code-Support',
    sort: true,
    render: ({ scsCodeSupport }) => (<HighlightUsingContext>{scsCodeSupport || '--'}</HighlightUsingContext>),
  },
  {
    property: 'scsCodeService',
    header: 'SCS Code-Service',
    sort: true,
    render: ({ scsCodeService }) => (<HighlightUsingContext>{scsCodeService || '--'}</HighlightUsingContext>),
  },
  {
    property: 'wbsCodeLicense',
    header: 'WBS Code-License',
    sort: true,
    render: ({ wbsCodeLicense }) => (<HighlightUsingContext>{wbsCodeLicense || '--'}</HighlightUsingContext>),
  },
  {
    property: 'wbsCodeSupport',
    header: 'WBS Code-Support',
    render: ({ wbsCodeSupport }) => (<HighlightUsingContext>{wbsCodeSupport || '--'}</HighlightUsingContext>),
  },
  {
    property: 'wbsCodeService',
    header: 'WBS Code-Service',
    sort: true,
    render: ({ wbsCodeService }) => (<HighlightUsingContext>{wbsCodeService || '--'}</HighlightUsingContext>),
  },
  {
    property: 'actions',
    header: 'Actions',
    size: '96px',
    align: 'start',
    sort: false,
    id: 'actions-list-page',
    render: datum => (
      <Menu
        icon={<More />}
        items={getActions(datum)}
        data-testid='moreIcon'
      />
    ),
    sortable: false,
  }]), [getActions]);

  const filteredList = useMemo(() => (quotesData && searchText !== undefined ? quotesData.filter(item => (
    item.quoteId?.toLowerCase().includes(searchText.toLowerCase()))
    || item.scsCodeLicense?.toLowerCase().includes(searchText.toLowerCase())
    || item.wbsCodeLicense?.toLowerCase().includes(searchText.toLowerCase())
    || item.wbsCodeService?.toLowerCase().includes(searchText.toLowerCase())
    || item.wbsCodeSupport?.toLowerCase().includes(searchText.toLowerCase())
    || item.scsCodeSupport?.toLowerCase().includes(searchText.toLowerCase())
    || item.scsCodeService?.toLowerCase().includes(searchText.toLowerCase())) : quotesData), [quotesData, searchText]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden' data-testid='wiseQuoteIdListPage'>
      <GLBMHeading
        title=''
        search={(
          <GLBMSearch
            data-testid='wiseQuoteIdListPageSearch'
            value={searchText}
            onChange={e => setSearchText(e)}
          />
          )}
        actions={[
          <Button
            kind='toolbar'
            data-testid='wiseQuoteIdListPageRefreshButton'
            icon={<Refresh />}
            onClick={() => refreshQuotes()}
            a11yTitle='Refresh Company List'
            key='refreshBtn'
            label='Refresh'
          />,
          <Button
            kind='toolbar'
            data-testid='wiseQuoteIdListPageCreateButton'
            label='Create'
            icon={<Add />}
            onClick={() => {
              createWiseId(undefined);
            }}
            a11yTitle='WiseQuoteId'
            key='newBtn'
          />,
        ]}
      />
      <GLBMDataSummary
        total={quotesData?.length}
        filtered={filteredList?.length}
      />
      <SearchTextContextProvider searchText={searchText}>
        <GLBMDataTable
          columns={columns}
          data={filteredList}
          sortable={true}
          sort={true}
          searchText={searchText}
          loading={fetchingQuotes}
          total={filteredList?.length}
          primaryKey='id'
        />
      </SearchTextContextProvider>
      {showDeleteDialogue && (
        <WiseQuoteIdDeletePage
          quoteId={selectedQuoteId}
          onClose={() => {
            setSelectedQuoteId(undefined);
            setShowDeleteDialogue(false);
            setShowEditDialog(false);
            refreshQuotes();
          }}
        />
      )}
      {showEditDialog && (
        <WiseQuoteIdCreatePage
          quoteId={selectedQuoteId}
          onClose={() => {
            setSelectedQuoteId(undefined);
            setShowDeleteDialogue(false);
            setShowEditDialog(false);
            refreshQuotes();
          }}
        />
      )}
    </Main>
  );
};

WiseQuoteIdListPage.propTypes = {

};

export default WiseQuoteIdListPage;
