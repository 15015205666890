// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Heading, Text,
} from 'grommet';
import { StatusIcon } from '../component/StatusIcon';
import GLBMLayer from '../component/GLBMLayer';

const ValidationElement = ({ element: { text, severity } }) => (
  <Box
    key={text}
    direction='row'
    gap='small'
    align='center'
    margin='small'
  >
    <StatusIcon value={severity} size='medium' />
    <span>{text}</span>
  </Box>
);

ValidationElement.propTypes = {
  element: PropTypes.shape({
    severity: PropTypes.string,
    text: PropTypes.string,
  }),
};

const ValidationDialog = ({
  title,
  subTitle = 'subtitle (optional)',
  submitLabel = 'Submit',
  onSubmit = undefined,
  cancelLabel,
  onCancel = undefined,
  data = undefined,
  hint,
}) => (
  <GLBMLayer align='center' flush={true}>
    <Box margin='small'>
      <Box margin={{ horizontal: 'medium' }} direction='row' gap='small' align='center'>
        <Heading level='3' strong={true} margin='none'>{title}</Heading>
      </Box>
      {subTitle && <Text weight={100}>{subTitle}</Text>}
      <Box margin='small' flex='grow' style={{ 'maxWidth': '475px' }}>
        {data && data.map(item => <ValidationElement key={item.text} element={item} />)}
      </Box>
    </Box>
    {hint && (
    <Box margin={{ horizontal: 'medium' }}>
      <Text weight={100}>{hint}</Text>
    </Box>
    )}
    <Box border='top'>
      <Box margin='small' direction='row'>
        {onSubmit && (
        <Box margin={{ right: 'small' }}>
          <Button primary={true} label={submitLabel} onClick={onSubmit} />
        </Box>
        )}
        {onCancel && <Button label={cancelLabel} secondary={true} onClick={onCancel} />}
      </Box>
    </Box>
  </GLBMLayer>
);

ValidationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  cancelLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      severity: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  hint: PropTypes.string,
};

export default ValidationDialog;
