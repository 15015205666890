// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';

export const lineStyle = {
  strokeWidth: 3,
};

const LineLayer = ({
  color,
  bars,
  yScale,
}) => {
  const data = bars.filter(bar => bar.data.id === 'used');
  const lineGenerator = line()
    .defined(bar => bar.data.data.committed !== undefined)
    .x(bar => bar.x + bar.width / 2)
    .y(bar => yScale(bar.data.data.committed));
  return (
    <path
      d={lineGenerator(data)}
      fill='none'
      stroke={color}
      {...lineStyle}
      style={{ pointerEvents: 'none' }}
    />
  );
};

LineLayer.propTypes = {
  bars: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        committed: PropTypes.number,
      }).isRequired,
    }).isRequired,
    x: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  })).isRequired,
  yScale: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default color => (props => <LineLayer color={color} {...props} />);
