// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box, Button, FormField, RadioButton, Text } from 'grommet';
import {
  useCustomerMetersMutate,
  useCustomerMetersQuery,
  useCustomerQuery,
} from '../../../core';
import Loader from '../../shared/loader';
import { getListStyle, serviceCategoryLabels, sortData } from './utils';
import { Location } from './Location';

const SortOrderPage = ({
  selectedCustomer,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    locations: 'AUTOMATIC_ASCENDING',
    services: 'AUTOMATIC_ASCENDING',
    meters: 'AUTOMATIC_ASCENDING',
  });
  const [data, setData] = useState([]);

  const {
    data: customer
  } = useCustomerQuery(selectedCustomer);

  const {
    data: meters,
  } = useCustomerMetersQuery(selectedCustomer);

  const {
    mutate: updateSortOrder,
  } = useCustomerMetersMutate({
    onSuccess: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (customer && meters) {
      meters?.forEach((meter) => {
        const locationId = meter.locationId || customer.id;
        const locationName = meter.locationName || '(no Location)';

        // Create location if location doesn't already exist
        if (!data[locationId]) {
          data[locationId] = {
            id: locationId,
            name: locationName,
            services: [],
          };
        }

        // Create service category if it doesn't already exist
        const locationCategories = data[locationId].services.map(s => s.type);
        const serviceType = meter.serviceType || 'NONE';
        if (locationCategories.indexOf(serviceType) === -1) {
          data[locationId].services.push({
            type: serviceType,
            name: serviceCategoryLabels[meter.serviceCategory] || meter.serviceCategory,
            meters: [],
          });
          locationCategories.push(serviceType);
        }
        const categoryIndex = locationCategories.indexOf(serviceType);

        // Add meter to data
        data[locationId].services[categoryIndex].meters.push({
          name: meter.name,
          id: meter.id,
          serviceType,
          sort: meter.sort || 'AUTOMATIC_ASCENDING:AUTOMATIC_ASCENDING:AUTOMATIC_ASCENDING',
        });
      });
      setData(sortData(Object.values(data), customer.invoiceSortOptions));
      setOptions(customer.invoiceSortOptions);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, meters]);

  const updateSortStrings = (myData) => {
    const pad = '00000';
    const nullOut = Object.values(options).indexOf('MANUAL') === -1;

    let locationOrder = 0;
    let serviceOrder = 0;
    let meterOrder = 0;

    myData.forEach((location) => {
      location.services.forEach((service) => {
        service.meters.forEach((meter) => {
          const locationSort = (options.locations === 'MANUAL') ? (pad + locationOrder).substring((pad + locationOrder).length - pad.length) : options.locations;
          const serviceSort = (options.services === 'MANUAL') ? (pad + serviceOrder).substring((pad + serviceOrder).length - pad.length) : options.services;
          const meterSort = (options.meters === 'MANUAL') ? (pad + meterOrder).substring((pad + meterOrder).length - pad.length) : options.meters;

          const sortString = `${locationSort}:${serviceSort}:${meterSort}`;
          // eslint-disable-next-line no-param-reassign
          meter.sort = (nullOut) ? '' : sortString;
          meterOrder += 1;
        });
        serviceOrder += 1;
      });
      locationOrder += 1;
    });

    setData(myData);
  };

  const onSave = () => {
    const nullOut = Object.values(options).indexOf('MANUAL') === -1;

    const sortMappings = data.reduce((obj, location) => {
      location.services.forEach((service) => {
        service.meters.forEach((meter) => {
          if (!obj[meter.serviceType]) {
            // eslint-disable-next-line no-param-reassign
            obj[meter.serviceType] = {
              serviceType: meter.serviceType,
              mappings: {},
            };
          }
          // eslint-disable-next-line no-param-reassign
          obj[meter.serviceType].mappings[meter.id] = (nullOut) ? null : meter.sort;
        });
      });
      return obj;
    }, {});

    const payload = {
      sortOptions: options,
      sortMappings: Object.values(sortMappings),
    };

    updateSortOrder({
      customerId: selectedCustomer,
      payload
    });
  };

  const onChangeOptions = (event) => {
    const modifiedOptions = { ...options };
    const parts = event.target.name.split('::');
    // eslint-disable-next-line prefer-destructuring
    modifiedOptions[parts[0]] = parts[1];

    // setState({ options, data:  });
    setOptions(modifiedOptions);
    setTimeout(() => {
      setData(sortData(data, modifiedOptions));
      updateSortStrings(data);
    }, 0);
  };

  const onDragEnd = (results) => {
    if (!results.destination || results.destination.index === results.source.index) {
      return;
    }

    switch (results.type.split('&&')[0]) {
      case 'METER': {
        const parts = results.draggableId.split('&&');
        data.forEach((location) => {
          if (location.id === parts[0]) {
            location.services.forEach((service) => {
              if (service.type === parts[1]) {
                const meterId = parts[parts.length - 1];
                const meterIndex = service.meters.map(x => x.id).indexOf(meterId);
                if (meterIndex >= 0) {
                  const removed = service.meters.splice(meterIndex, 1)[0];
                  service.meters.splice(results.destination.index, 0, removed);
                }
              }
            });
          }
        });
        break;
      }
      case 'SERVICE':
        data.forEach((location) => {
          const parts = results.draggableId.split('&&');
          const serviceType = parts[parts.length - 1];
          if (location.id === parts[0]) {
            const serviceIndex = location.services.map(x => x.type).indexOf(serviceType);
            if (serviceIndex >= 0) {
              const removed = location.services.splice(serviceIndex, 1)[0];
              location.services.splice(results.destination.index, 0, removed);
            }
          }
        });
        break;
      case 'LOCATION': {
        const locationIndex = data.map(x => x.id).indexOf(results.draggableId);
        if (locationIndex >= 0) {
          const removed = data.splice(locationIndex, 1)[0];
          data.splice(results.destination.index, 0, removed);
        }
        break;
      }
      default:
        return;
    }

    updateSortStrings(data);
  };

  return (
    <Box direction='row' flex={true}>
      <Box direction='column' flex={true}>

        <Box flex={true} pad='medium' overflow='auto'>
          {!!data && !!data.length
              && (
              <>
                <Text weight={600} size='24px'>
                  {`Meters found for customer ${selectedCustomer}.`}
                </Text>
                <DragDropContext
                  onDragEnd={onDragEnd}
                >
                  <div style={{ maxHeight: 'calc(100vh - 162px)' }}>
                    <Droppable droppableId='locations' type='LOCATION'>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {
                            data.map((location, index) => (
                              <Location location={location} index={index} key={location.id} options={options} />
                            ))
                          }
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </>
              )}
          {
              loading
              && (
                <Box direction='row' align='center' gap='small' justify='center' fill={true}>
                  <Loader text='Loading Meter. Please wait ...' />
                </Box>
              )
            }
          {
              !data.length && !loading && (
                <Text weight={600} size='24px'>
                  {`No meters found for customer ${selectedCustomer}.`}
                </Text>
              )
            }

        </Box>
        <Box direction='row' flex={false} className='sort-order-footer'>
          <Box pad='small'><Button primary={true} label='Save' onClick={onSave} /></Box>
          <Box pad='small'><Button secondary={true} label='Cancel' onClick={onClose} /></Box>
        </Box>
      </Box>
      <Box direction='column' flex={false} pad='small' className='sort-order-options'>
        <Box pad='small'>
          <h3>Invoice Config Options</h3>
        </Box>
        <Box pad='small'>
          <FormField label='Order Locations'>
            <RadioButton
              id='location-auto'
              name='locations::AUTOMATIC_ASCENDING'
              label='Automatic Ascending'
              checked={options.locations === 'AUTOMATIC_ASCENDING'}
              onChange={onChangeOptions}
            />
            <RadioButton
              id='location-manual'
              name='locations::MANUAL'
              label='Manual'
              checked={options.locations === 'MANUAL'}
              onChange={onChangeOptions}
            />
          </FormField>
        </Box>
        <Box pad='small'>
          <FormField label='Order Services'>
            <RadioButton
              id='services-auto'
              name='services::AUTOMATIC_ASCENDING'
              label='Automatic Ascending'
              checked={options.services === 'AUTOMATIC_ASCENDING'}
              onChange={onChangeOptions}
            />
            <RadioButton
              id='services-manual'
              name='services::MANUAL'
              label='Manual'
              checked={options.services === 'MANUAL'}
              onChange={onChangeOptions}
            />
          </FormField>
        </Box>
        <Box pad='small'>
          <FormField label='Order Meters'>
            <RadioButton
              id='meters-auto'
              name='meters::AUTOMATIC_ASCENDING'
              label='Automatic Ascending'
              checked={options.meters === 'AUTOMATIC_ASCENDING'}
              onChange={onChangeOptions}
            />
            <RadioButton
              id='meters-manual'
              name='meters::MANUAL'
              label='Manual'
              checked={options.meters === 'MANUAL'}
              onChange={onChangeOptions}
            />
          </FormField>
        </Box>
      </Box>
    </Box>
  );
};

SortOrderPage.propTypes = {
  selectedCustomer: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SortOrderPage;
