// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { FormField, TextInput } from 'grommet';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';

const EHRBillingMethodField = ({
  readOnly,
  setDirty
}) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();
  const { options } = serviceEditor;
  const isNumber = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));

  const onChange = (property, value) => {
    let updatedValue = value;
    const updatedOptions = JSON.parse(JSON.stringify(options));
    // strip leading zero
    if (updatedValue && updatedValue.length >= 2 && updatedValue.startsWith('0')) {
      updatedValue = value.slice(1);
    }

    // make sure only whole numbers:
    updatedValue = updatedValue.toString().replace(/\D/ig, '');

    if (isNumber(updatedValue)) {
      const number = parseFloat(updatedValue);
      if (number > 24) {
        updatedOptions.config[property] = 24;
      } else if (number < 1) {
        updatedOptions.config[property] = 1;
      } else {
        updatedOptions.config[property] = number;
      }
    } else if (updatedValue === '') {
      updatedOptions.config[property] = 1;
    }
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options = updatedOptions;
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    setDirty();
  };

  return (
    <FormField
      label='Peak Usage Hours Count'
      help='The Peak Usage Hours Count will be used to determine the number of peak hours to use when calculating the average number of concurrent users per day.'
    >

      <TextInput
        data-testid={readOnly ? 'disabled-input' : 'input'}
        disabled={readOnly === true}
        id='input_peakUsageHoursCount'
        name='input_peakUsageHoursCount'
        value={options.config.peakUsageHoursCount || 15}
        onChange={(event) => {
          onChange('peakUsageHoursCount', event.target.value);
        }}
      />
    </FormField>
  );
};

EHRBillingMethodField.propTypes = {
  setDirty: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default EHRBillingMethodField;
