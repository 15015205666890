// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, FormField } from 'grommet';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import DateTime from '../../shared/component/DateTime';
import { useToast } from '../../shared/toast/ToastProvider';
import IDUtil from '../../shared/util/IDUtil';
import { useMutationRunMock } from '../../../core';

const RunMockDataLayer = ({ data = undefined, onClose = undefined }) => {
  const [from, setFrom] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
  const [errorFrom, setErrorFrom] = useState();
  const [errorTo, setErrorTo] = useState();

  const { addToast } = useToast();

  const { mutate } = useMutationRunMock(data?.id, {
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Executed',
        message: 'Mock Data was successfully executed',
      });
      onClose();
    },
    onError: (err) => {
      addToast({
        status: 'critical',
        title: 'Mock Data was not executed',
        message: err.response.data.message,
      });
    }
  });

  const onFromDateChange = (date) => {
    // validate date to be proper date:
    const momentDate = moment(date, 'YYYY-MM-DD', true);
    const newErrors = [];
    const isValid = momentDate.isValid();
    if (!date) { // at least 8 character
      newErrors.push('Required');
    } else if (!isValid) {
      newErrors.push('Invalid date format (YYYY-MM-DD)');
    } else if (!!to && momentDate.isAfter(to, 'day')) {
      newErrors.push('No later than end date');
    }

    setFrom(date);
    setErrorFrom(newErrors.join(', '));
  };

  const onToDateChange = (date) => {
    // validate date to be proper date:
    const momentDate = moment(date, 'YYYY-MM-DD', true);
    const newErrors = [];
    const isValid = momentDate.isValid();
    if (!date) { // at least 8 character
      newErrors.push('Required');
    } else if (!isValid) {
      newErrors.push('Invalid date format (YYYY-MM-DD)');
    } else if (!!from && momentDate.isBefore(from, 'day')) {
      newErrors.push('No earlier than start date');
    }

    setTo(date);
    setErrorTo(newErrors.join(', '));
  };

  const details = () => (
    <Box margin={{ top: 'small' }} gap='medium'>
      <GLBMNameValueList
        title='Selected Mock Data'
        data={[
          { label: 'Name', value: data.name },
          { label: 'Creator', value: data.created.name },
        ]}
      />
      <FormField
        label='From'
        error={errorFrom}
      >
        <DateTime
          id={IDUtil.getId('fromInput')}
          {...{ 'data-testid': 'fromInput' }}
          name='fromInput'
          format='YYYY-MM-DD'
          onChange={(event) => {
            onFromDateChange(event);
          }}
          value={from || ''}
          calendarProps={{
            bounds: ['2016-01-01', '2116-01-01'],
          }}
        />
      </FormField>
      <FormField
        label='To'
        error={errorTo}
      >
        <DateTime
          id={IDUtil.getId('toInput')}
          {...{ 'data-testid': 'toInput' }}
          name='toInput'
          format='YYYY-MM-DD'
          onChange={(event) => {
            onToDateChange(event);
          }}
          value={to || ''}
          calendarProps={{
            bounds: ['2016-01-01', '2116-01-01'],
          }}
        />
      </FormField>
    </Box>
  );

  const onSubmit = () => {
    if (!errorFrom && !errorTo) mutate({ id: data.id, from, to });
  };

  return (
    <ConfirmationDialog
      data={data}
      title='Execute Mock Data'
      submitLabel='Run'
      cancelLabel='Cancel'
      text="Running the below mock data configuration will generate mock data for the specified date range.  If this is not your intention, please click the 'Cancel' button."
      onClose={onClose}
      onChange={() => onSubmit()}
      details={details()}
    />
  );
};

RunMockDataLayer.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default RunMockDataLayer;
