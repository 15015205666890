// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { produce } from 'immer';
import Highlight from 'react-highlighter';

import {
  Anchor,
  Box, Button, Main,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import moment from 'moment';
import {
  useAuditDefinitionsQuery,
  useAuditsQuery,
  useCustomersQueryMap,
  useUsersQueryMap,
} from '../../core';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import { useStateWithSessionStorage } from '../shared/hooks';
import IDUtil from '../shared/util/IDUtil';
import OptionalAnchor from '../shared/component/OptionalAnchor';
import { pagePermissions } from '../shared/constants/Permissions';
import FilterControl from '../shared/component/FilterControl';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import Toast from '../shared/component/Toast';
import { AuditStatus } from './AuditStatus';
import { useAuditTypeMap } from './handler';
import AuditFilter from './AuditFilter';
import AuditDetails from './AuditDetails';

const AuditListPage = () => {
  const navigate = useNavigate();

  const [storedFilters, setStoredFilters] = useStateWithSessionStorage('audit-list-filter', {
    list: { searchText: '', sort: { property: 'time', direction: 'asc', external: true } },
    details: { splitView: false },
    panel: {
      from: moment().utc().subtract(3, 'd').format('YYYY-MM-DD'),
      to: moment().utc().format('YYYY-MM-DD'),
    },
  });

  const [filters, setFilters] = useState(storedFilters);
  const [response, setResponse] = useState(undefined);
  const [filterActive, setFilterActive] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(undefined);

  const {
    data: customerList,
    isFetching: isCustomersFetching,
  } = useCustomersQueryMap();

  const {
    data: userList,
    isFetching: isUsersFetching,
  } = useUsersQueryMap();

  // fetch audit definitions:
  const {
    data: auditDefinitions,
    isFetching: isAuditDefinitionsFetching,
    isSuccess: isAuditDefinitionsFetched,
  } = useAuditDefinitionsQuery();

  // convert the audit definitions to a map:
  const resourceTypeMap = useAuditTypeMap(isAuditDefinitionsFetched ? auditDefinitions?.resources : []);
  const actionTypeMap = useAuditTypeMap(isAuditDefinitionsFetched ? auditDefinitions?.actions : []);

  const searchText = filters?.list?.searchText;
  const sort = filters?.list?.sort;

  const isLoading = useMemo(
    () => isCustomersFetching || isAuditDefinitionsFetching || isUsersFetching,
    [isCustomersFetching, isAuditDefinitionsFetching, isUsersFetching],
  );

  const _onSearchChange = useCallback((event) => {
    const newSearch = event;
    setFilters(prevState => ({
      ...prevState,
      list: { ...prevState.list, searchText: newSearch },
    }));
  }, []);

  useEffect(() => {
    setStoredFilters(filters);
  }, [filters, setStoredFilters]);

  const _onSortColumn = (mySort) => {
    const updatedFilters = produce(filters, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.list.sort = mySort;
      return draft;
    });
    setFilters(prevState => ({
      ...prevState,
      ...updatedFilters,
    }));
  };

  const _getAction = myAudit => (
    <Box direction='row' gap='small'>
      <Anchor key='details' onClick={() => setSelectedAudit(myAudit)}>Details</Anchor>
    </Box>
  );

  const _getAuditCustomer = (accountId, customerName, mySearchText) => (
    <OptionalAnchor
      onClick={() => navigate(`/customers/${accountId}`)}
      permissions={pagePermissions.customers.view.page}
      highlight={mySearchText}
    >
      {customerName}
    </OptionalAnchor>
  );

  const _getAuditUser = (myAudit, userObj, mySearchText) => {
    if (userObj && Object.hasOwn(userObj, 'id')) {
      return (
        <OptionalAnchor
          onClick={() => navigate(`/users/${userObj.id}`)}
          permissions={pagePermissions.users.view}
          highlight={mySearchText}
        >
          {userObj.name}
        </OptionalAnchor>
      );
    }
    return (<Highlight search={mySearchText}>{myAudit.userName}</Highlight>);
  };

  const {
    data: auditList,
    refetch: refreshAuditTrails,
    isFetching: isFetchingAuditTrails,
  } = useAuditsQuery(filters.panel, {
    enabled: (!!(userList && customerList)),
    onError: (_error) => {
      console.error('error', _error);
    },
  });

  // filter the audit list:
  const filteredAudits = useMemo(() => {
    if (!searchText) {
      return auditList;
    }
    const searchTextInternal = searchText.toLowerCase();

    const results = [];
    for (let i = 0; i < auditList?.length; i += 1) {
      const myAudit = auditList[i];
      if ((Object.hasOwn(myAudit, 'message') && myAudit.message !== undefined ? myAudit.message.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
        || (Object.hasOwn(myAudit, 'status') && myAudit.status !== undefined ? myAudit.status.toString().indexOf(searchTextInternal) !== -1 : false)
        || (Object.hasOwn(myAudit, 'resource') && myAudit.resource !== undefined && resourceTypeMap[myAudit.resource] !== undefined ? resourceTypeMap[myAudit.resource].displayName.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
        || (Object.hasOwn(myAudit, 'action') && myAudit.action !== undefined && actionTypeMap[myAudit.action] !== undefined ? actionTypeMap[myAudit.action].displayName.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
        || (myAudit.userId && userList?.[myAudit.userId] ? userList?.[myAudit.userId].name.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
        || (myAudit.accountId && customerList?.[myAudit.accountId] ? customerList?.[myAudit.accountId].name.toLowerCase().indexOf(searchTextInternal) !== -1 : false)) {
        results.push(myAudit);
      }
    }
    return results;
  }, [auditList, searchText, customerList, userList, resourceTypeMap, actionTypeMap]);

  // sort the audit list:
  const sortedAudits = useMemo(() => {
    let property = '';
    let sortedList;
    switch (filters?.list?.sort.property) {
      case 'status': // status:
        sortedList = filteredAudits?.sort((a, b) => (b.status - a.status));
        break;
      case 'time': // time
        sortedList = filteredAudits?.sort((a, b) => new Date(b.time || 0) - new Date(a.time || 0));
        break;
      case 'accountId': // customer
        property = 'accountId';
        sortedList = filteredAudits?.sort((a, b) => {
          const aVal = customerList?.[a[property]]?.name || a[property];
          const bVal = customerList?.[b[property]]?.name || b[property];
          return aVal?.localeCompare(bVal);
        });
        break;
      case 'userId': // user
        property = 'userId';
        sortedList = filteredAudits?.sort((a, b) => {
          const aVal = userList?.[a[property]]?.name || a[property];
          const bVal = userList?.[b[property]]?.name || b[property];
          return aVal?.localeCompare(bVal);
        });
        break;
      case 'resource': // resource
        property = 'resource';
        sortedList = filteredAudits?.sort((a, b) => {
          const aVal = resourceTypeMap?.[a[property]]?.displayName || a[property];
          const bVal = resourceTypeMap?.[b[property]]?.displayName || b[property];
          return aVal?.localeCompare(bVal);
        });
        break;
      case 'message': // message
        property = 'message';
        sortedList = filteredAudits?.sort((a, b) => a[property]?.localeCompare(b[property]));
        break;
      default:
        sortedList = filteredAudits;
        break;
    }

    return (filters?.list?.sort?.direction === 'asc') ? sortedList : (sortedList || []).reverse();
  }, [filteredAudits, filters, customerList, userList, resourceTypeMap]);

  useEffect(() => {
    // start loading:
    setResponse(false);
    refreshAuditTrails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.panel]);

  const _onFilterActivate = () => {
    setFilterActive(true);
    setResponse(undefined);
  };

  const _onFilterDeactivate = () => {
    setFilterActive(false);
  };

  const _onFilterChange = (filterPanel) => {
    const updatedFilters = produce(filters, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.panel = filterPanel;
      return draft;
    });
    setFilters(prevState => ({
      ...prevState,
      ...updatedFilters,
    }));
    setFilterActive(false);
    setResponse(undefined);
  };

  const _renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={() => setResponse(undefined)}
        >
          {response.message}
        </Toast>
      );
    }
    return message;
  };

  const _renderLayer = () => {
    let layer;
    if (filterActive) {
      layer = (
        <AuditFilter
          onClose={_onFilterDeactivate}
          onChange={_onFilterChange}
          filter={filters.panel}
          users={userList}
          customers={customerList}
        />
      );
    } else if (selectedAudit) {
      layer = (
        <AuditDetails
          audit={selectedAudit}
          filters={filters}
          onClose={() => setSelectedAudit()}
        />
      );
    }
    return layer;
  };

  const _getColumns = (mySearchText, myCustomerList, myUserList) => [
    {
      property: 'status',
      header: 'Status',
      size: '75px',
      render: ({ status }) => <AuditStatus status={status} />,
    },
    {
      property: 'time',
      header: 'Date (UTC)',
      dataCallback: ({ time }) => moment(time).format('x'),
      render: ({ time }) => moment(time).format('lll'),
      size: 'small',
    },
    {
      property: 'accountId',
      header: 'Billing Account',
      dataCallback: ({ accountId }) => (myCustomerList && Object.hasOwn(myCustomerList, accountId) ? myCustomerList[accountId].name : accountId),
      render: ({ accountId }) => (myCustomerList && Object.hasOwn(myCustomerList, accountId) ? _getAuditCustomer(accountId, myCustomerList[accountId].name, mySearchText) : accountId || '-'),
      size: 'medium',
    },
    {
      property: 'userId',
      header: 'User',
      dataCallback: ({ userId }) => myUserList[userId]?.name,
      render: datum => _getAuditUser(datum, myUserList[datum.userId], mySearchText),
      size: 'small',
    }, {
      property: 'resource',
      header: 'Log Resource',
      dataCallback: ({ resource }) => resourceTypeMap?.[resource]?.displayName,
      size: 'xsmall',
    },
    {
      property: 'action',
      header: 'Log Action',
      dataCallback: ({ action }) => actionTypeMap?.[action]?.displayName,
      size: 'xsmall',
    }, {
      property: 'message',
      header: 'Message',
      size: 'medium',
    }, {
      property: 'actions',
      header: 'Actions',
      render: datum => _getAction(datum),
      sortable: false,
      size: 'xsmall',
    },
  ];

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Audit Logs'
        search={[
          <GLBMSearch
            key='searchText'
            value={filters?.list?.searchText}
            onChange={_onSearchChange}
          />,
          <FilterControl
            key='filterBtn'
            filters={filters?.panel}
            onFilter={_onFilterActivate}
            onClear={() => {
              const newFilterPanel = { ...filters.panel };
              delete newFilterPanel.status;
              delete newFilterPanel.accountId;
              delete newFilterPanel.userId;
              delete newFilterPanel.action;
              delete newFilterPanel.resource;
              _onFilterChange(newFilterPanel);
            }}
            ignoreProps={['from', 'to']}
          />,
        ]}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              refreshAuditTrails();
            }}
            a11yTitle='Refresh Audit List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={isFetchingAuditTrails}
          />,
        ]}
      />
      <GLBMDataSummary total={auditList?.length} filtered={filteredAudits?.length} />
      <GLBMDataTable
        searchText={searchText}
        data={sortedAudits || []}
        columns={_getColumns(searchText, customerList, userList)}
        loading={isLoading || isFetchingAuditTrails}
        total={sortedAudits?.length}
        sort={sort}
        onSort={_onSortColumn}
        primaryKey='id'
      />
      {_renderLayer()}
      {_renderToast()}
    </Main>
  );
};

AuditListPage.contextTypes = {
  router: PropTypes.object,
};

AuditListPage.propTypes = {};

export default AuditListPage;
