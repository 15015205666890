// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { Box } from 'grommet';
import AgGridContainer from '../../shared/grid/AgGridContainer';

const CapacityPlanningGrid = ({ data = undefined }) => {
  const processCellCallback = (params) => {
    if (params && params.value && params.value.value) {
      return params.value.value;
    }
    if (Array.isArray(params.value)) {
      return '-';
    }
    return typeof params.value === 'object' ? params.value.value : params.value;
  };

  const defaultExportParams = {
    processCellCallback,
  };

  return (
    <Box pad={{ horizontal: 'medium' }}>
      <AgGridContainer
        flex={false}
        style={{ height: '225px', width: '100%' }}
      >
        <AgGridReact
          defaultColDef={{
            sortable: false,
          }}
          rowData={data?.rows}
          columnDefs={data?.cols}
          defaultExcelExportParams={defaultExportParams}
          defaultCsvExportParams={defaultExportParams}
          autoSizeStrategy={{
            type: 'fitCellContents',
            skipHeader: false,
          }}
        />
      </AgGridContainer>
    </Box>
  );
};

CapacityPlanningGrid.propTypes = {
  data: PropTypes.shape({
    cols: PropTypes.any,
    rows: PropTypes.any,
  }),
};

export default CapacityPlanningGrid;
