// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useErrorToast } from '../../components/shared/hooks';
import {
  executeGet,
} from '../api';
import { ApiContext } from '../../AppContext';

export const useAuditsQuery = (query, options) => {
  // transform query.start and query.end to queryPayload with format of 'YYYY-MM-DDTHH:mm':
  const queryPayload = {
    ...query,
    from: `${query.from}T00:00`,
    to: `${query.to}T23:59`,
  };
  const path = useContext(ApiContext)('audits.v2.list.path', {}, queryPayload);
  return useErrorToast(useQuery({
    queryKey: ['GLBM:AUDITS-LIST', queryPayload],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Audit Error');
};

export const useAuditDetailsQuery = (detailsKey, options) => {
  const path = useContext(ApiContext)('audits.v2.details.path', { key: detailsKey });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:AUDITS-DETAIL', { detailsKey }],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Audit Error');
};

export const useAuditVersionsQuery = (versionKey, options) => {
  const path = useContext(ApiContext)('audits.v2.versions.path', { versionKey });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:AUDITS-VERSIONS', { versionKey }],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Audit Error');
};

export const useAuditDefinitionsQuery = (options) => {
  const path = useContext(ApiContext)('audits.v2.definitions.path');
  return useQuery({
    queryKey: ['GLBM:AUDITS-DEFINITIONS'],
    queryFn: () => executeGet(path),
    ...(options || []),
  });
};
