// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CheckBox,
  DateInput,
  FormField,
  Select,
  SelectMultiple,
  TextInput,
} from 'grommet';
import moment from 'moment';
import { useFormFieldProps } from './utils';

const InputProperties = ({
  inputs,
  values,
  onChange,
}) => {
  const getFormFieldProps = useFormFieldProps();
  return (
    (
      <Box pad={{ vertical: 'small' }} gap='small'>
        {
        // eslint-disable-next-line consistent-return
        inputs?.map((el) => {
          // eslint-disable-next-line default-case
          switch (el.type) {
            case 'STRING':
              return (
                <FormField
                  {...getFormFieldProps(el.field, el.display, el.tooltip)}
                  required={el.required}
                >
                  { (el.values) ? (
                    <Select
                      name={el.field}
                      options={el.values}
                      value={values?.[el.field] ? values[el.field] : undefined}
                      onChange={({ value: nextValue }) => onChange({
                        ...values,
                        [el.field]: nextValue || undefined,
                      })}
                      plain={true}
                      labelKey='label'
                      valueKey={{ key: 'value' }}
                    />
                  ) : (
                    <TextInput
                      name={el.field}
                      value={values?.[el.field] ? values[el.field] : undefined}
                      onChange={({ target: { value } }) => onChange({
                        ...values,
                        [el.field]: value || undefined,
                      })}
                      plain={true}
                    />
                  )}
                </FormField>
              );
            case 'LIST':
              return (
                <FormField
                  {...getFormFieldProps(el.field, el.display, el.tooltip)}
                  required={el.required}
                >
                  <SelectMultiple
                    name={el.field}
                    options={el.values}
                    value={values?.[el.field] ? values[el.field] : undefined}
                    onChange={({ value: nextValue }) => onChange({
                      ...values,
                      [el.field]: nextValue || undefined,
                    })}
                    labelKey='label'
                    valueKey={{ key: 'value' }}
                  />
                </FormField>
              );
            case 'INT':
              return (
                (
                  <FormField
                    {...getFormFieldProps(el.field, el.display, el.tooltip)}
                    validate={[
                    (value) => {
                      if (!/^\d+$/.test(value)) {
                        return 'Invalid number, must be a whole number';
                      }
                      return undefined;
                    },
                  ]}
                    required={el.required}
                  >
                    <TextInput
                      name={el.field}
                      type='number'
                      value={values?.[el.field] ? values[el.field] : undefined}
                      onChange={({ target: { value } }) => onChange({
                      ...values,
                      [el.field]: value ? parseInt(value, 10) : undefined,
                    })}
                    />
                  </FormField>
)
              );
            case 'FLOAT':
              return (
                <FormField
                  {...getFormFieldProps(el.field, el.display, el.tooltip)}
                  required={el.required}
                >
                  <TextInput
                    name={el.field}
                    type='number'
                    value={values?.[el.field] ? values[el.field] : undefined}
                    onChange={({ target: { value } }) => onChange({
                      ...values,
                      [el.field]: value || undefined,
                    })}
                  />
                </FormField>
              );
            case 'DATE':
              return (
                <FormField
                  {...getFormFieldProps(el.field, el.display, el.tooltip)}
                  required={el.required}
                  validate={(value) => {
                    if (value) {
                      if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
                        return 'Invalid date, must follow yyyy-mm-dd format';
                      }
                    }
                    return undefined;
                  }}
                >
                  <DateInput
                    name={el.field}
                    format='yyyy-mm-dd'
                    calendarProps={{
                      range: false,
                    }}
                    value={values[el.field] ? moment(values[el.field]).toISOString(true) : undefined}
                    onChange={(event) => {
                      const value = event?.value?.[0] ? moment(event?.value?.[0]).format('YYYY-MM-DD') : event?.target?.value;
                      onChange({
                        ...values,
                        [el.field]: value !== undefined ? value : undefined,
                      });
                    }}
                  />
                </FormField>
              );
            case 'BOOLEAN':
              return (
                <FormField
                  {...getFormFieldProps(el.field, el.display, el.tooltip)}
                  required={false}
                >
                  <CheckBox
                    name={el.field}
                    checked={values?.[el.field]}
                    onChange={event => onChange({
                      ...values,
                      [el.field]: event.target.checked,
                    })}
                  />
                </FormField>
              );
          }
        })
      }
      </Box>
)
  );
};

InputProperties.propTypes = {
  inputs: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputProperties;
