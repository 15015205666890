// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button, Spinner,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import { usePartnerConnectionQuery, usePartnerQuery } from '../../core';

import PartnerConnectionInfoPanel from '../tenant/PartnerConnectionInfoPanel';
import GLBMHeading from '../shared/component/GLBMHeading';

const PartnerTenantPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: partner,
  } = usePartnerQuery(params.partnerId, {
    enabled: !!params.partnerId,
  });

  const {
    data: partnerConnections,
    refetch: refreshPartnerConnections,
  } = usePartnerConnectionQuery(params.partnerId, {
    enabled: !!params.partnerId,
  });

  const loading = useMemo(
    () => !partner || !partnerConnections,
    [partner, partnerConnections]
  );

  const renderNavButtons = () => (
    <Box
      direction='row'
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      flex={false}
      gap='small'
    >
      <Button
        label='Cancel'
        type='button'
        secondary={true}
        id={IDUtil.getId('EditorViewToolbarCancelButton')}
        onClick={() => navigate('/partners/')}
      />
    </Box>
  );

  return (
    <Box direction='column' fill='vertical'>
      <GLBMHeading
        title={`Manage Connections: ${partner?.name}`}
        back='/partners'
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => refreshPartnerConnections()}
            a11yTitle='Refresh Account Details List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
          />,
        ]}
      />
      <Box flex={true} pad={{ horizontal: 'medium' }}>
        <Box margin={{ top: 'small' }} flex={false}>
          <PartnerConnectionInfoPanel
            title='Partner Connection Information'
            data={partnerConnections}
            hideGLCPurpose={true}
          />
        </Box>
      </Box>
      {loading && (
      <Box size='large' justify='center'>
        <Box
          align='center'
          pad='medium'
          margin='small'
        >
          <Spinner size='medium' />
        </Box>
      </Box>
      )}
      <Box border='top'>
        {renderNavButtons()}
      </Box>
    </Box>
  );
};

PartnerTenantPage.contextTypes = {
  router: PropTypes.object,
};

export default PartnerTenantPage;
