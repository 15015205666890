// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, FormField, Select
} from 'grommet';
import moment from 'moment';
import CustomerSelector from '../../../shared/component/CustomerSelector';
import DateTime from '../../../shared/component/DateTime';
import { useMetricValuesQuery, useQueryExtractISVServiceData } from '../../../../core';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';

const ExtractISVServiceData = ({ action, onClose }) => {
  const [serviceType, setServiceType] = useState();
  const [customers, setCustomers] = useState([]);
  const [reportType, setReportType] = useState('Summary');
  const [date, setDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
  const [submitted, setSubmitted] = useState(false);

  const {
    mutate: extract, isPending, isError, error, isSuccess
  } = useQueryExtractISVServiceData(serviceType, customers || [], reportType, date);

  // keep updating an errors object when serviceType and date changes:
  const errors = useMemo(() => {
    const newErrors = {
      serviceType: undefined,
      date: undefined,
    };

    // validate service type:
    if (!serviceType) {
      newErrors.serviceType = 'Required';
    }

    // validate date:
    if (!date) {
      newErrors.date = 'Required';
    } else {
      const momentDate = moment(date, 'YYYY-MM', true);
      const dateError = momentDate.isSameOrAfter(moment(), 'month') ? 'Select a month prior to current month and year' : undefined;
      newErrors.date = (momentDate.isValid() ? dateError : 'Invalid date format (YYYY-MM)');
    }
    return newErrors;
  }, [serviceType, date]);

  // if errors object is not all undefined --> we have errors:
  const hasErrors = useMemo(() => {
    const fieldErrors = Object.entries(errors).map(([key, value]) => (value !== undefined ? key : undefined)).filter(e => e !== undefined);
    return fieldErrors.length > 0;
  }, [errors]);

  const { current: reportTypeOptions } = useRef([
    {
      label: 'Summary',
      value: 'Summary',
    },
    {
      label: 'Full',
      value: 'Full',
    },
  ]);

  const onChange = (value) => {
    if (!value || value === 'All') {
      setCustomers([]);
    }
    setCustomers(value);
  };

  const metricValuesQuery = useMetricValuesQuery();

  const metricValuesOptions = useMemo(() => {
    if (metricValuesQuery.status === 'success' && metricValuesQuery?.data) {
      return Object.entries(metricValuesQuery?.data).map(([key, value]) => ({ label: key, value }));
    }
    return [];
  }, [metricValuesQuery]);

  const renderCustomerOptions = (filter) => {
    let values = [];
    if (filter && filter.length > 0) {
      values = filter;
    }

    return (
      <CustomerSelector
        multiple={true}
        initialSelection={values}
        onCustomerSelected={selected => onChange(selected)}
        plain={true}
      />
    );
  };

  const renderDateOptions = () => (
    <DateTime
      id='extractIsvServiceDataDateTime'
      name='customerExtractDate'
      format='YYYY-MM'
      onChange={setDate}
      value={date}
    />
  );

  const renderReportTypeOptions = options => (
    <Select
      options={options}
      value={reportType}
      labelKey='label'
      data-e2e='reportTypeOptions'
      valueKey={{ key: 'value', reduce: true }}
      onChange={({ value: nextValue }) => setReportType(nextValue)}
    />
  );

  const renderServiceMetricOptions = options => (
    <Select
      options={options}
      value={serviceType}
      labelKey='label'
      valueKey={{ key: 'value', reduce: true }}
      onChange={({ value: nextValue }) => setServiceType(nextValue)}
      placeholder='Select Service Metric'
    />
  );

  const onExtract = () => {
    setSubmitted(true);
    if (!hasErrors) {
      extract();
    }
  };

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='small' flex={true} margin='small'>
        <FormField label='Service Metric' required={true} error={submitted && errors.serviceType}>
          {renderServiceMetricOptions(metricValuesOptions)}
        </FormField>
        <FormField label='Billing Accounts' style={{ minWidth: '160px' }}>
          {renderCustomerOptions(customers)}
        </FormField>
        <FormField label='Report Type'>
          {renderReportTypeOptions(reportTypeOptions)}
        </FormField>
        <FormField label='Month' error={submitted && errors.date}>
          {renderDateOptions()}
        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title={`Unable to extract ${action.name}`}
            message={error.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={onExtract}
              busy={isPending}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractISVServiceData.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractISVServiceData;
