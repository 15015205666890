// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Case from 'case';

import { Box, Button, Footer, Header, Text } from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import WarningBanner from '../shared/banner/WarningBanner';
import {
  useServiceEditorContext,
} from '../services/service-edit/contexts/ServiceEditorContext';
import WizardStepValidation from './WizardStepValidation';

const Wizard = ({
  ...props
                }) => {
  const [{ validation, dirtyState, customer }] = useServiceEditorContext();

  const model = props.model || {};
  const [currentStepIndex, setCurrentStepIndex] = useState((props.forcePage) ? props.forcePage : 0);
  const { callback, origin } = props;

  // Change to previous step
  const prev = () => {
    // if step has a 'onNext' function, call it...
    if (props.children[currentStepIndex].props.onPrev) {
      props.children[currentStepIndex].props.onPrev();
    }

    const prevStepIndex = Math.max(currentStepIndex - 1, 0);
    setCurrentStepIndex(prevStepIndex);
  };

  const canNext = () => {
    const { properties } = validation;
    if (properties && properties.hasIssues?.('critical')) {
      return false;
    }
    return !validation?.errors;
  };

  // Change to next step
  const next = () => {
    if (props.children[currentStepIndex].props.validation()) {
      // if step has a 'onNext' function, call it...
      if (props.children[currentStepIndex].props.onNext) {
        props.children[currentStepIndex].props.onNext();
      }

      const nextStepIndex = Math.min(currentStepIndex + 1, props.children.length - 1);
      setCurrentStepIndex(nextStepIndex);
    } else {
      console.warn('INVALID STEP!');
    }
  };

  // Finish callback or noop if not assigned
  const finish = () => {
    callback(model);
  };

  // If they click Back on the first step
  const cancel = () => {
    window.history.back();
  };

  const navButtons = () => {
    const buttons = [];

    // Previous:
    if (currentStepIndex === 0) {
      buttons.push(
        <Button
          id={IDUtil.getId('WizardPreviousButton')}
          key='previous-button'
          label={origin ? `Back to ${Case.title(origin)}` : 'Previous'}
          onClick={cancel}
          secondary={true}
        />,
      );
    } else {
      buttons.push(
        <Button
          key='previous-button'
          id={IDUtil.getId('WizardPreviousButton')}
          label={`Previous - ${props.children[currentStepIndex - 1].props.label}`}
          onClick={prev}
          secondary={true}
        />,
      );
    }

    // Next/Save:
    if (currentStepIndex === props.children.length - 1) {
      if (callback) {
        buttons.push(
          <Box direction='row' gap='small' align='center' key='wizardButtons'>
            <WizardStepValidation step={props.children[currentStepIndex].props.type} />
            <Button
              label='Save'
              primary={true}
              key='finish-button'
              id={IDUtil.getId('WizardFinishButton')}
              onClick={finish}
              disabled={!canNext()}
            />
          </Box>,
        );
      } else {
        buttons.push(
          <Box direction='row' gap='small' align='center' key='wizardButtons'>
            <WizardStepValidation step={props.children[currentStepIndex].props.type} />
            <Button
              label='Close'
              secondary={true}
              key='finish-button'
              id={IDUtil.getId('WizardFinishButton')}
              onClick={cancel}
            />

          </Box>,
        );
      }
    } else {
      buttons.push(
        <Box direction='row' gap='small' align='center' key='wizardButtons'>
          <WizardStepValidation step={props.children[currentStepIndex].props.type} />
          <Button
            label={`Next - ${props.children[currentStepIndex + 1].props.label}`}
            primary={true}
            key='next-button'
            id={IDUtil.getId('WizardNextButton')}
            onClick={next}
            disabled={!canNext()}
          />
        </Box>,
      );
    }

    return (
      <Box border='top' direction='row' gap='small' pad={{ horizontal: 'medium', vertical: 'small' }} flex={false}>
        <Footer flex={true} justify='between'>
          {buttons}
        </Footer>
      </Box>
    );
  };

  const renderStepTitle = (currentStep) => {
    if (props.children.length > 1) {
      return (`Step ${currentStepIndex + 1} of ${props.children.length} - ${currentStep.props.label}`);
    }
    return currentStep.props.label;
  };

  const isDirty = () => (dirtyState.options || dirtyState.resources || dirtyState.rates);

  const renderWarning = () => {
    if (props.dirtyWarning && isDirty()) {
      if (customer.dealType === 'DIRECT') {
        return (
          <WarningBanner
            message='The changes you are making could affect historical reports. If this is not your intention, please cancel these changes and start again.'
          />
        );
      }
      return (
        <WarningBanner
          message='The changes you are making could affect historical reports and may reset downstream rates, which would require partners to re-enter rates after the change. If this is not your intention, please cancel these changes and start again.'
        />
      );
    }
    if (customer?.contractEndMonth) {
      return (
        <WarningBanner
          message="This billing account has End Month set. Any changes made could affect historical data but will not affect anything after the 'End Month'"
        />
      );
    }
    return null;
  };

  const currentStep = props.children[currentStepIndex];

  return (
    <Box direction='column' fill='vertical'>
      {renderWarning()}
      <Header justify='start' gap='small' align='center' pad={{ left: 'small' }}>
        <Button
          kind='toolbar'
          id={IDUtil.getId('WizardPreviousLink')}
          hoverIndicator={true}
          plain={true}
          onClick={cancel}
          icon={<FormPreviousLink size='xxlarge' color='brand' />}
        />
        <Text size='xlarge' weight='bold'>{props.title}</Text>
      </Header>
      <Box flex={false} pad={{ horizontal: 'medium', bottom: 'small' }}>
        <Text size='large' weight='normal'>{renderStepTitle(currentStep)}</Text>
      </Box>
      <Box flex={true}>
        {currentStep}
      </Box>
      {navButtons()}
    </Box>
  );
};

Wizard.contextTypes = {
  router: PropTypes.object,
};

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  model: PropTypes.object,
  forcePage: PropTypes.number,
  callback: PropTypes.func,
  origin: PropTypes.string,
  dirtyWarning: PropTypes.bool,
};

export default Wizard;
