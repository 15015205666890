// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PurposeType } from '../shared/constants/PurposeType';
import { DealType } from '../shared/constants/DealType';
import { BillingType } from '../shared/constants/BillingType';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';


const BillingAccountInfoPanel = ({ data: customer = undefined }) => {
  const data = useMemo(() => [
    {
      label: 'Billing Account Name',
      value: customer?.name || '-',
    },
    {
      label: 'Billing Account ID',
      value: customer?.id || '-',
    },
    {
      label: 'Purpose',
      value: PurposeType.enumValueOf(customer?.purpose)?.label || '-',
    },
    {
      label: 'Deal Type',
      value: DealType.enumValueOf(customer?.dealType)?.label || '-',
    },
    {
      label: 'Status',
      value: BillingType.enumValueOf(customer?.status)?.label || '-',
      status: BillingType.enumValueOf(customer?.status)?.severity || 'unknown',
    },
  ], [customer]);

  return (
    <GLBMNameValueList
      title='Billing Account Information'
      data={data}
    />
  );
};

BillingAccountInfoPanel.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    purpose: PropTypes.string,
    dealType: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default BillingAccountInfoPanel;
