// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { CapacityOption, MeterOption, RatesOption } from '../../../model';
import { CommitmentType } from '../../../model/CommitmentType';
import MeterSettings from './MeterSettings';
import MeterCapacities from './MeterCapacities';
import MeterRates from './MeterRates';

const MeterDetails = (props) => {
  const [expanded, setExpanded] = useState('NONE');

  const onToggleExpansion = (state) => {
    setExpanded(state);
  };

  const hasMeterCapacities = () => {
    if (props.options?.config?.reportBy === 'LOCATION' && props.serviceType?.commitmentType === CommitmentType.MONETARY) {
      return false;
    }

    const capacityOptions = (props?.serviceType?.rateManagement?.capacityOptions || []);
    return !(capacityOptions.includes(CapacityOption.NONE));
  };

  const isReadOnlySettings = () => {
    const options = props?.serviceType?.rateManagement?.meterOptions || [];
    return options.includes(MeterOption.READ_ONLY);
  };

  const isReadOnlyCapacities = () => {
    const options = props?.serviceType?.rateManagement?.capacityOptions || [];
    return options.includes(CapacityOption.READ_ONLY);
  };

  const isReadOnlyRates = () => {
    const options = props?.serviceType?.rateManagement?.ratesOptions || [];
    return options.includes(RatesOption.READ_ONLY);
  };

  const {
    meter, rate, onChange, customer, serviceType, readOnly, options
  } = props;

  return (
    <Box
      direction='column'
      flex={true}
      pad={{ vertical: 'small' }}
    >
      <Box
        direction='column'
        flex={true}
        gap='medium'
        overflow='auto'
      >
        <MeterSettings
          key='settings'
          meter={meter}
          rate={rate}
          expanded={expanded}
          onToggleExpansion={onToggleExpansion}
          onChange={onChange}
          customer={customer}
          serviceType={serviceType}
          readOnly={readOnly || isReadOnlySettings()}
          options={options}
        />
        {hasMeterCapacities()
          && (
          <MeterCapacities
            key='capacities'
            meter={meter}
            rate={rate}
            expanded={expanded}
            onToggleExpansion={onToggleExpansion}
            onChange={onChange}
            customer={customer}
            serviceType={serviceType}
            readOnly={readOnly || isReadOnlyCapacities()}
            options={options}
            userType={props.userType}
          />
          )}
        <MeterRates
          key='rates'
          meter={meter}
          rate={rate}
          expanded={expanded}
          onToggleExpansion={onToggleExpansion}
          onChange={onChange}
          customer={customer}
          serviceType={serviceType}
          readOnly={readOnly || isReadOnlyRates()}
          options={options}
          userType={props.userType}
        />
      </Box>
    </Box>
  );
};

MeterDetails.propTypes = {
  meter: PropTypes.object.isRequired,
  rate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  serviceType: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  userType: PropTypes.string,
};

export default MeterDetails;
