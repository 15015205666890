// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ServiceStep } from 'services/model/ServiceStep';
import { Box, Button, FormField, Header, Text, TextInput } from 'grommet';
import { Add, Edit, Trash } from 'grommet-icons';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import IDUtil from '../../../../../shared/util/IDUtil';
import { ListPlaceholder } from '../../../../../shared/component/ListPlaceholder';
import { useValidationMutation } from '../../../../../../core';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import GoogleAnthosProjectNameEditor from './GoogleAnthosProjectNameEditor';

const GoogleAnthosBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();
  const [discountPercentage, setDiscountPercentage] = useState(serviceEditor.options.config.discountPercentage);
  const [projectNames, setProjectNames] = useState(serviceEditor.options.config.projectExport || []);
  const [addProjectName, setAddProjectName] = useState(false);
  const [editProjectNameIndex, setEditProjectNameIndex] = useState(-1);
  const [layer, setLayer] = useState(undefined);

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);

  useEffect(() => {
    if (serviceEditor.options.config.discountPercentage === undefined) {
      setDiscountPercentage(18);
    }
    fetchValidationIfNeeded();
  }, []);

  useEffect(() => {
    _onChangeProjectExport();
  }, [projectNames]);

  const _onChangeProjectExport = () => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.projectExport = [...projectNames];
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
    fetchValidationIfNeeded();
  };

  const _onChange = (event) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));

    if (event) {
      switch (event.target.name) {
        case 'discountPercentage':
          newState.options.config[event.target.name] = event.target.value;
          if (Object.prototype.hasOwnProperty.call(event.target.attributes, ('min')) && (!newState.options.config[event.target.name] || +newState.options.config[event.target.name] < +event.target.attributes.min.value)) {
            newState.options.config[event.target.name] = event.target.attributes.min.value;
          }
          if (Object.prototype.hasOwnProperty.call(event.target.attributes, ('max')) && (+newState.options.config[event.target.name] > +event.target.attributes.max.value)) {
            newState.options.config[event.target.name] = event.target.attributes.max.value;
          }
          setDiscountPercentage(newState.options.config[event.target.name]);
          break;
        default:
          break;
      }
    }

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
    fetchValidationIfNeeded();
  };

  const _onEdit = (index) => {
    setAddProjectName(false);
    setEditProjectNameIndex(index);
    setLayer('editProjectName');
  };

  const _onAdd = () => {
    setAddProjectName(true);
    setLayer('editProjectName');
  };

  const _onProjectNameChange = (changedProjectName, addAnother) => {
    const template = [...projectNames];
    let modifiedLayer;

    if (addProjectName) {
      template.push(changedProjectName);
    } else {
      template[editProjectNameIndex] = changedProjectName;
    }

    if (addAnother) {
      // eslint-disable-next-line no-param-reassign
      addAnother = true;
      modifiedLayer = 'editProjectName';
    }

    setProjectNames(template);
    setAddProjectName(addAnother);
    setEditProjectNameIndex(-1);
    setLayer(modifiedLayer);
  };

  const _onRemove = (index) => {
    const template = [...projectNames];

    template.splice(index, 1);

    setProjectNames(template);
    setEditProjectNameIndex(-1);
    setLayer(undefined);

    // project names have changed, call to parent container to let it know as well:
    _onChangeProjectExport();
  };

  function _renderAdjustmentPercentageFields() {
    const readOnlyInput = (
      <ReadOnlyField
        label='Adjustment Percentage'
        value={discountPercentage}
      />
    );

    const editableInput = (
      <FormField
        label='Adjustment Percentage'
      >
        <TextInput
          type='number'
          name='discountPercentage'
          value={discountPercentage}
          min={0}
          max={100}
          onChange={_onChange}
        />
      </FormField>
    );
    return (props.readOnly ? readOnlyInput : editableInput);
  }

  function _renderProjectNameFields() {
    const template = [...projectNames];

    const projectNameNodes = template.map((projectName, index) => (
      <Box
        direction='row'
        key={projectName}
        style={{ height: '50px' }}
        pad='none'
        border='top'
        responsive={false}
        align='center'
      >
        <Text>
          {projectName}
        </Text>
        {!props.readOnly
        && (
          <Box direction='row' flex={true} align='end' justify='end'>
            <Button
              icon={<Edit />}
              id={IDUtil.getId('LocationListEditButton', index)}
              onClick={() => _onEdit(index)}
              a11yTitle='Edit Project Name'
            />
            <Button
              icon={<Trash />}
              onClick={() => _onRemove(index)}
              a11yTitle='Delete Project Name'
            />
          </Box>
        )}
      </Box>
    ));
    const noProjectNamesNode = (template.length === 0
      ? (
        <ListPlaceholder
          emptyMessage='You do not have any project names defined at the moment.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      )
      : '');
    return (
      <Box>
        <Header size='small' justify='between'>
          { !props.readOnly && (
            <Box border='bottom' flex={true} align='end'>
              <Button
                icon={<Add />}
                id={IDUtil.getId('LocationListAddButton')}
                onClick={_onAdd}
                a11yTitle='Add Location'
                label='Add'
              />
            </Box>
          )}
        </Header>
        {noProjectNamesNode}
        <Box>
          {projectNameNodes}
        </Box>
      </Box>
    );
  }

  const getOtherLocationNames = (location) => {
    const template = [...projectNames];

    // validate:
    const otherLocations = [];
    const currentIndex = template.indexOf(location);
    for (let i = 0; i < template.length; i += 1) {
      if (i !== currentIndex) {
        otherLocations.push(template[i]);
      }
    }
    return otherLocations;
  };

  const _onLayerClose = () => {
    setLayer(undefined);
  };

  const _renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'editProjectName') {
        const heading = (addProjectName ? 'Add Project Name' : 'Edit Project Name');
        const projectName = (addProjectName ? '' : (projectNames || [])[editProjectNameIndex]);
        const otherNames = getOtherLocationNames(projectName);
        result = (
          <GoogleAnthosProjectNameEditor
            onClose={_onLayerClose}
            heading={heading}
            projectName={projectName}
            otherNames={otherNames}
            onChange={_onProjectNameChange}
          />
        );
      }
    }
    return result;
  };

  function _getEditableLayout() {
    return (
      <>
        {_renderAdjustmentPercentageFields()}
        <FormField label='Project Names'>
          <Box margin={{ left: 'small', right: 'small' }}>
            {_renderProjectNameFields()}
          </Box>
        </FormField>
        {_renderLayer()}
      </>
    );
  }

  return _getEditableLayout();
};

GoogleAnthosBillingMethodField.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default GoogleAnthosBillingMethodField;
