// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, TextInput, Tip } from 'grommet';
import { useDebounce } from 'use-debounce';
import { CATextInputProps } from '../types/CATextInput';

const CATextInput: React.FC<CATextInputProps> = ({
  value: initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue] = useDebounce(value, 300);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (onChange) {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <Box
      direction='row'
      gap='small'
    >
      <TextInput
        id={props.id}
        name={props.name}
        value={value}
        onChange={e => setValue(e.target.value)}
        disabled={props.disabled || false}
        {...props.rest}
      />
      {props.label && props.tooltip
        && (
          <Tip
            content={(
              <Box width='medium'>
                {props.tooltip}
              </Box>
            )}
            dropProps={{ align: { left: 'right' } }}
          >
            <Text
              weight={100}
              size='small'
              className='dotted-tooltip'
            >
              {props.label}
            </Text>
          </Tip>
        )}
      {props.label && !props.tooltip
        && (
          <Text
            weight={100}
            size='small'
          >
            {props.label}
          </Text>
        )}
    </Box>
  );
};

CATextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CATextInput;
