// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, FormField } from 'grommet';
import DateTime from '../../../../shared/component/DateTime';
import GLBMNameValueList from '../../../../shared/component/GLBMNameValueList';
import ConfirmationDialog from '../../../../shared/dialogs/ConfirmationDialog';
import IDUtil from '../../../../shared/util/IDUtil';

const DiscontinueMeterEditor = ({
  meter,
  rate,
  onClose,
  onChange,
}) => {
  const [endMonth, setEndMonth] = useState('');
  const [error, setError] = useState();

  const usedCapacityMonths = useMemo(() => rate.capacityRevisions.map(rev => rev.effectiveDate), [rate.capacityRevisions]);
  const usedRateMonths = useMemo(() => rate.rateRevisions.map(rev => rev.effectiveDate), [rate.rateRevisions]);

  const onDateChange = useCallback((date) => {
    // validate date to be proper date:
    const newErrors = [];
    const momentDate = moment(date, 'YYYY-MM', true);
    const isValid = momentDate.isValid();
    if (!date) {
      newErrors.push('Required');
    } else if (!isValid) {
      newErrors.push('Invalid end month format (YYYY-MM)');
    }
    if (usedCapacityMonths.includes(`${date}-01`)) {
      newErrors.push('Capacity Revision exists for this month');
    }
    if (usedRateMonths.includes(`${date}-01`)) {
      newErrors.push('Rate Revision exists for this month');
    }

    setEndMonth(date);
    setError(newErrors.join(', '));
  }, [usedCapacityMonths, usedRateMonths]);

  const details = useMemo(() => (
    <Box margin={{ top: 'small' }} gap='medium'>
      <GLBMNameValueList
        title='Selected Meter'
        data={[
          { label: 'Meter Name', value: meter?.name },
        ]}
      />
      <FormField
        label='Discontinue Month'
        error={error}
        required={true}
        htmlFor={IDUtil.getId('EndMonthLayerDateInput')}
      >
        <DateTime
          id={IDUtil.getId('EndMonthLayerDateInput')}
          {...{ 'data-testid': IDUtil.getId('EndMonthLayerDateInput') }}
          name='contractEndMonth'
          format='YYYY-MM'
          onChange={(event) => {
            onDateChange(event);
          }}
          value={endMonth || ''}
          calendarProps={{
            bounds: ['2013-01-01', '2116-01-01'],
          }}
        />
      </FormField>
    </Box>
  ), [meter?.name, error, endMonth, onDateChange]);

  const onSubmit = () => {
    if (!error && endMonth) {
      onChange(`${endMonth}-01`);
    }
  };

  return (
    <ConfirmationDialog
      data={meter}
      title='Discontinue Meter:'
      submitLabel='OK'
      cancelLabel='Cancel'
      text="Submitting this will add a 'zero' revision to both Capacity and Rates tables. This will hide this meter from the Monthly Charges report as of the selected month.  If this is not your intention, please click the 'Cancel' button."
      onClose={onClose}
      onChange={() => onSubmit()}
      details={details}
    />
  );
};

DiscontinueMeterEditor.propTypes = {
  meter: PropTypes.object.isRequired,
  rate: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DiscontinueMeterEditor;
