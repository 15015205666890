// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import LocationsList from '../locations/LocationsList';

const LocationForm = ({
  readOnly = false,
  locations = [],
  customer,
  onChange
}) => (
  <Box margin={{ top: 'small' }}>
    <Heading level='2' pad='none' size='small' weight='bold'>
      Billing Account
      Locations
    </Heading>
    <LocationsList
      locations={locations}
      readOnly={readOnly}
      customerId={customer.id}
      onChange={onChange}
      border={true}
    />
  </Box>
);

LocationForm.propTypes = {
  readOnly: PropTypes.bool,
  locations: PropTypes.any,
  customer: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LocationForm;
