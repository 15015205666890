// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Highlight from 'react-highlighter';

const SearchTextCtx = createContext('');

export const SearchTextContextProvider = ({ searchText = '', children = null }) => (
  <SearchTextCtx.Provider value={searchText}>
    {children}
  </SearchTextCtx.Provider>
);
SearchTextContextProvider.propTypes = {
  children: PropTypes.node,
  searchText: PropTypes.string,
};

export const HighlightUsingContext = (props) => {
  const searchText = useContext(SearchTextCtx);
  return <Highlight {...props} search={searchText} />;
};
