// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button, CheckBoxGroup,
  Footer,
} from 'grommet';
import IDUtil from '../shared/util/IDUtil';
import { BillingType } from '../shared/constants/BillingType';
import CustomerSelector from '../shared/component/CustomerSelector';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import GLBMLayer from '../shared/component/GLBMLayer';
import StatusLabel from '../shared/component/StatusLabel';
import FilterMultiSelect from '../shared/component/FilterMultiSelect';
import { AlertType } from './AlertType';

const AlertsFilter = (props) => {
  const [filter, setFilter] = useState(JSON.parse(JSON.stringify(props.filter)));

  const _onChangeAlert = (name, value, option) => {
    const newFilter = JSON.parse(JSON.stringify(filter));
    const options = value.map(v => (typeof v === 'object' ? v.value : v)).filter(item => item !== '');
    if (option?.label === 'All' || option?.value === '') {
      delete newFilter[name];
    } else {
      newFilter[name] = options;
      if (newFilter[name].length === 0) {
        delete newFilter[name];
      }
    }
    setFilter(newFilter);
  };

  const _onSubmit = () => {
    props.onChange(filter);
  };

  const _onClear = () => {
    setFilter({});
  };

  const serviceOptions = useMemo(() => ServiceTypeStore.getServices().map(({ label, type }) => ({
    label: label || type,
    value: type,
    id: IDUtil.getId(`Service${type}`),
  })).sort((a, b) => a.label.localeCompare(b.label)), [ServiceTypeStore]);

  const _renderServiceOptions = (filters) => {
    return (
      <FilterMultiSelect
        label='Service'
        name='service'
        value={filters.serviceType || []}
        id={IDUtil.getId('ServicesChkGroup')}
        options={serviceOptions}
        labelKey='label'
        onChange={({ value, option }) => _onChangeAlert('serviceType', value, option)}
      />
    );
  };

  const billingOptions = useMemo(() => BillingType.enumValues.map(({
    enumKey, label, severity, order
  }) => ({
    'label': <StatusLabel value={severity} label={label} />,
    'value': enumKey,
    'id': IDUtil.getId(`AccountStatus${enumKey}`),
    'data-testid': IDUtil.getId(`AccountStatus${enumKey}`),
    order,
  })).sort((a, b) => a.order - b.order), [BillingType]);


  const _renderAccountStatusOptions = (filters) => {
    return (
      <CheckBoxGroup
        value={filters.accountStatus || ['']}
        id={IDUtil.getId('AccountStatusChkGroup')}
        options={billingOptions}
        onChange={({ value, option }) => _onChangeAlert('accountStatus', value, option)}
      />
    );
  };

  const alertOptions = useMemo(() => AlertType.enumValues.map(({ enumKey, description }) => ({
    label: description,
    value: enumKey,
    id: IDUtil.getId(`Alerts${enumKey}`),
  })).sort((a, b) => a.label.localeCompare(b.label)), [AlertType]);

  const _renderAlertOptions = (filters) => {
    return (
      <FilterMultiSelect
        label='Alert'
        value={filters.alertType || []}
        id={IDUtil.getId('AlertsChkGroup')}
        options={alertOptions}
        labelKey='label'
        onChange={({ value, option }) => _onChangeAlert('alertType', value, option)}
      />
    );
  };

  const hasFilter = Object.keys(filter).length > 0;
  return (
    <GLBMLayer
      position='right'
      flush={true}
      closer={false}
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      onClose={props.onClose}
      a11yTitle='Customers Filter'
      full='vertical'
      title='Filter Alerts'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
      >
        <Box flex={true} overflow='auto'>
          <GLBMFilterPropertyBox label='Billing Account'>
            <CustomerSelector
              multiple={true}
              allowNoSelection={true}
              initialSelection={filter.customerId || []}
              placeholder='Select'
              clear={true}
              enableAllOption={false}
              onCustomerSelected={(selected, last) => _onChangeAlert('customerId', selected, { label: last })}
              plain={true}
            />
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Billing Account Status'>
            {_renderAccountStatusOptions(filter)}
          </GLBMFilterPropertyBox>
          {_renderServiceOptions(filter)}
          {_renderAlertOptions(filter)}
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              id={IDUtil.getId('ApplyBtn')}
              {...{ 'data-testid': IDUtil.getId('ApplyBtn') }}
              primary={true}
              onClick={_onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              id={IDUtil.getId('CancelBtn')}
              {...{ 'data-testid': IDUtil.getId('CancelBtn') }}
              secondary={true}
              onClick={props.onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            id={IDUtil.getId('ResetBtn')}
            {...{ 'data-testid': IDUtil.getId('ResetBtn') }}
            onClick={hasFilter ? _onClear : undefined}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

AlertsFilter.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  filter: PropTypes.object,
};

AlertsFilter.defaultProps = {
  onClose: null,
  onChange: null,
  filter: {},
};

export default AlertsFilter;
