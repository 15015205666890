// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, FormField
} from 'grommet';
import moment from 'moment';
import { useQueryExtractAllCustomerInvoices } from '../../../../core';
import DateTime from '../../../shared/component/DateTime';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';

const ExtractAllCustomerInvoices = ({ action, onClose }) => {
  const [date, setDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const onDateChange = (newDate) => {
    const momentDate = moment(newDate, 'YYYY-MM', true);
    let newError = momentDate.isSameOrAfter(moment(), 'month') ? 'Select a month prior to current month and year' : undefined;
    newError = momentDate.isValid() ? newError : 'Invalid month format (YYYY-MM)';
    setDate(newDate);
    setError(newError);
  };

  const {
    mutate: extract, isPending, isError, isSuccess, error: extractError
  } = useQueryExtractAllCustomerInvoices(date);

  const onExtract = () => {
    if (!error) {
      extract();
    } else {
      setShowError(true);
    }
  };

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='small' flex={true} margin='small'>
        <FormField error={showError && error} label='Invoice Month'>
          <DateTime
            id='extractAllCustomerInvoicesDateTime'
            name='customerExtractDate'
            format='YYYY-MM'
            onChange={onDateChange}
            value={date}
          />
        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title={`Unable to extract ${action.name}`}
            message={extractError.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={onExtract}
              busy={isPending}
              data-testid='extractButton'
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractAllCustomerInvoices.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractAllCustomerInvoices;
