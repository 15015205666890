// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useContext, useMemo } from 'react';
import { Box, ColumnConfig, DataTable, Text } from 'grommet';
import { DataContext } from 'grommet/contexts';
import isString from 'lodash/isString';
import Highlight from 'react-highlighter';
import { renderTablePlaceholder } from '../util/tableUtils';

const EMPTY = '--';

type GLBMColumnConfig<T> = ColumnConfig<T> & {
  dataCallback?: (value: T) => string
};

interface Props<T> extends Omit<React.ComponentProps<typeof DataTable>, 'ref'> {
  searchText?: string
  columns: GLBMColumnConfig<T>[]
  data?: T[]
  onSort?: () => void
  loading?: boolean
  total?: number
  primaryKey?: string
}

const GLBMDataTable = <T,>({
  searchText = '',
  columns,
  data = undefined,
  onSort = undefined,
  sort = undefined,
  loading = false,
  total = undefined,
  primaryKey = undefined,
  ...rest
}: Props<T>) => {
  const dataCtx = useContext<{ view?: { search: string }, total?: number, filteredTotal?: number }>(DataContext);

  const render = useCallback(({ primary, property, dataCallback }: GLBMColumnConfig<T>) => (value: T) => {
    let internalValue = EMPTY;
    try {
      if (dataCallback && dataCallback(value)) {
        internalValue = dataCallback(value).toString();
      } else if (value && value[property]) {
        internalValue = value[property].toString();
      }

      return (
        <Text weight={primary ? 500 : 'normal'} color={primary ? 'text-strong' : 'text'}>
          <Highlight search={dataCtx?.view?.search || searchText}>
            {internalValue || EMPTY}
          </Highlight>
        </Text>
      );
    } catch (e) {
      console.error(e);
    }
    return internalValue;
  }, [dataCtx?.view?.search, searchText]);

  const enhancedColumns = useMemo(() => columns.map((column) => {
    const newCol = { ...column };
    newCol.render = column.render ? datum => column.render({ ...datum, searchText: dataCtx?.view?.search || searchText }) : render(column);
    delete newCol.dataCallback;
    return newCol as ColumnConfig<T>;
  }), [columns, render, dataCtx?.view?.search, searchText]);

  /*
   * Currently this logic assumes that dataCallback function returns a string that can be used
   * to compare two values for sorting.
   */
  const sortedData = useMemo(() => {
    if (sort && data) {
      const { property, direction } = { ...sort };
      const column = columns.find(element => element.property === property);
      const dataCallback = column ? column.dataCallback : undefined;
      const multiplier = direction === 'asc' ? 1 : -1;
      return data.sort((aObj, bObj) => {
        const a = dataCallback ? dataCallback(aObj) : aObj[property];
        const b = dataCallback ? dataCallback(bObj) : bObj[property];
        if (a === b) { // identical? return 0
          return 0;
        }
        if (a === undefined) { // identical? return 0
          return 1;
        }
        if (b === undefined) { // identical? return 0
          return -1;
        }
        if (isString(a) && isString(b)) {
          return a.localeCompare(b) * multiplier;
        }
        return (a - b) * multiplier;
      });
    }
    return data;
  }, [columns, data, sort]);

  return (
    <Box overflow='auto'>
      <DataTable<T>
        pin={true}
        replace={true}
        columns={enhancedColumns}
        data={!loading ? sortedData : []}
        sortable={!!onSort}
        onSort={onSort}
        sort={sort}
        placeholder={renderTablePlaceholder(loading, total ?? data?.length ?? dataCtx.total, data?.length ?? dataCtx.filteredTotal)}
        resizeable={true}
        data-testid='datatable'
        primaryKey={primaryKey}
        background={{
          body: ['white', 'light-1'],
        }}
        {...rest}
      />
    </Box>
  );
};

export default GLBMDataTable;
