// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckBoxGroup, SelectMultiple } from 'grommet';
import { isString } from 'lodash';
import GLBMFilterPropertyBox from './GLBMFilterPropertyBox';

const FilterMultiSelect = ({ label, options, ...rest }) => {
  const [searchText, setSearchText] = useState('');
  const memoOptions = useMemo(() => options.filter(el => !isString(el?.label) || el?.label?.toLowerCase()?.includes(searchText?.toLowerCase())), [options, searchText]);
  return (
    <GLBMFilterPropertyBox label={label}>
      {options?.length > 5
        ? (
          <SelectMultiple
            placeholder='Select'
            {...rest}
            options={memoOptions}
            sortSelectedOnClose={true}
            multiple={true}
            labelKey='label'
            onSearch={options?.length > 10 ? setSearchText : undefined}
            valueKey={{ key: 'value', reduce: true }}
            dropHeight='medium'
            showSelectedInline={true}
          />
        ) : (
          <CheckBoxGroup
            {...rest}
            labelKey='label'
            valueKey='value'
            options={memoOptions}
          />
        )}
    </GLBMFilterPropertyBox>
  );
};

FilterMultiSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
};

export default FilterMultiSelect;
