// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, Text
} from 'grommet';
import { Trash } from 'grommet-icons';
import Dropzone from 'react-dropzone';
import { usePaygISVFileUploadMutate } from '../../../core/query/payg';

const ImportISVContract = ({
  onExecute,
  onClose,
}) => {
  const [selectedFile, setSelectedFile] = useState(false);
  const [uploadButtonCLick, setUploadButtonClick] = useState(false);
  const dropzoneRef = useRef();

  const {
    mutateAsync: uploadFile,
    reset: resetUploadedFile,
    error: uploadErrorMessage
  } = usePaygISVFileUploadMutate();

  const onDrop = (e) => {
    resetUploadedFile();
    if (e?.length > 0) {
      setSelectedFile(e[0]);
    } else {
      setSelectedFile(false);
    }
  };

  const onUploadImportFile = async () => onExecute(() => uploadFile(selectedFile));
  const renderErrorMessage = () => {
    if (uploadErrorMessage?.response?.data.consumerMessage && selectedFile) {
      const errorMessage = uploadErrorMessage.response.data.consumerMessage;
      const maxLength = 50;
      const truncatedMessage = errorMessage.length > maxLength ? `${errorMessage.substring(0, maxLength)}...` : errorMessage;

      return (
        <Box margin={{ left: 'small' }} className='critical'>
          {truncatedMessage}
          {' '}
          {selectedFile && 'Please fix errors.'}
        </Box>
      );
    }
    return null;
  };
  return (
    <>
      <Box flex='grow' margin='small' width='large' data-testid='UploadPaygAdministration'>
        <Box flex={false}>
          <Dropzone
            multiple={false}
            className='file-upload-target'
            activeClassName='file-upload-target-active'
            rejectClassName='file-upload-target-reject'
            onDrop={onDrop}
            ref={dropzoneRef}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <Box
                align='center'
                pad={{ vertical: 'medium' }}
                border={{ color: 'green', size: 'small', style: 'dashed' }}
                flex={false}
                {...getRootProps()}
                focusIndicator={false}
              >
                Drag a .csv file to upload here.
                <input {...getInputProps()} />
              </Box>
            )}
          </Dropzone>

          <Box pad={{ vertical: 'small' }} basis='full'>
            <Button
              fill={true}
              disabled={selectedFile}
              data-testid='selectFile'
              label='Select a file'
              onClick={() => {
                dropzoneRef.current?.open();
              }}
            />
          </Box>
          {
            selectedFile && (
              <Box>
                <Box flex={false} direction='row'>
                  <Box>
                    <Text style={{ color: (uploadButtonCLick && selectedFile.type !== 'text/csv') || uploadErrorMessage?.response?.data.consumerMessage ? '#FF4040' : 'inherit' }}>
                      <strong>{selectedFile.name}</strong>
                    </Text>
                    <Text truncate={true} size='small'>
                      <em>
                        {selectedFile.size}
                        {' '}
                        bytes
                      </em>
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      margin={{ top: '-5px' }}
                      data-testid='deleteUploadedFile'
                      icon={<Trash />}
                      href='#'
                      onClick={() => {
                        setSelectedFile(false); // Clear the selected file
                        resetUploadedFile();
                        setUploadButtonClick(false);
                      }}
                    />
                  </Box>
                </Box>
                {uploadButtonCLick && selectedFile.type !== 'text/csv' && (
                  <Box className='critical'>
                    Invalid file type
                  </Box>
                )}
              </Box>
            )
          }
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Upload'
              type='button'
              primary={true}
              data-testid='uploadBtn'
              disabled={!selectedFile || uploadErrorMessage?.response?.data.consumerMessage}
              onClick={() => {
                if (selectedFile && selectedFile.type !== 'text/csv') {
                  setUploadButtonClick(true);
                } else {
                  onUploadImportFile();
                }
              }}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
            {renderErrorMessage()}
          </Box>
        </Footer>
      </Box>
    </>
  );
};

ImportISVContract.propTypes = {
  action: PropTypes.object.isRequired,
  onExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportISVContract;
