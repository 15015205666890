// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import {
  Anchor,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet';
import { Add, Trash } from 'grommet-icons';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import IDUtil from '../util/IDUtil';
import { renderColumnTableCell } from '../util/tableUtils';
import ServiceListEditor from './ServiceListEditor';
import { ListPlaceholder } from './ListPlaceholder';
import GLBMHeading from './GLBMHeading';
import GLBMNameValueList from './GLBMNameValueList';

const RestrictedServices = ({
  isRequest = false,
  onChange = () => undefined,
  assigned = [],
  services = [],
}) => {
  const [layer, setLayer] = useState();
  const [serviceToRemove, setServiceToRemove] = useState();

  const sortedAssigned = useMemo(() => (
    sortBy(assigned, [x => find(services, ['type', x])?.label.trim().toLowerCase() ?? x])
  ), [assigned, services]);

  const onRemoveClick = (selectedService) => {
    setLayer('removeConfirm');
    setServiceToRemove(selectedService);
  };

  const layerClosed = () => {
    setLayer(undefined);
    setServiceToRemove(undefined);
  };

  const onAddServiceClicked = () => {
    setLayer('addService');
  };

  const onServiceListRemoveConfirmed = (data) => {
    onChange(assigned.filter(el => el !== data));
    setLayer(undefined);
    setServiceToRemove(undefined);
  };

  const renderConfirmationDetails = service => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Service'
        data={[
          { label: 'Name', value: find(services, ['type', service])?.label ?? '-' },
        ]}
      />
    </Box>
  );

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={serviceToRemove}
            title='Are You Sure?'
            submitLabel='Yes, remove the Service'
            cancelLabel='Cancel'
            onClose={layerClosed}
            onChange={onServiceListRemoveConfirmed}
            details={renderConfirmationDetails(serviceToRemove)}
          />
        );
      } else if (layer === 'addService') {
        result = (
          services
          && (
          <ServiceListEditor
            onClose={layerClosed}
            onChange={onChange}
            items={services}
            selected={assigned}
          />
          )
        );
      }
    }
    return result;
  };

  function noRowsElement(totalCustomers) {
    if (totalCustomers === 0) {
      return (
        <ListPlaceholder
          emptyMessage='You have not added any Services to this user.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return null;
  }

  // if no rows:
  const noRows = noRowsElement(assigned.length);

  return (
    <Box
      direction='column'
      margin={{ top: 'large' }}
      style={{ minHeight: '300px' }}
      flex='grow'
    >
      <GLBMHeading
        title='Assigned Services'
        actions={[
          <Button
            kind='toolbar'
            icon={<Add />}
            id={IDUtil.getId('ServicesListToolbarAddButton')}
            onClick={onAddServiceClicked}
            key='addBtn'
            label='Add'
          />,
        ]}
        size='large'
        pad={{ horizontal: 'none' }}
        inline={true}
      />
      <Box margin={{ bottom: 'small' }}>
        <Text margin='none'>
          {isRequest
            ? 'Select Services that you are assigned to.'
            : 'Add services for the Service Developer to be able to access in the portal.'}
        </Text>
      </Box>
      <Box flex={true}>
        <Box
          border='top'
          flex={true}
        >
          <Table
            responsive={true}
            scrollable={false}
          >
            <TableHeader
              labels={['Service Name', 'Action']}
            >
              <TableRow>
                {renderColumnTableCell(['Service Name', 'Action'])}
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedAssigned?.map(service => (
                <TableRow key={service}>
                  <TableCell>{find(services, ['type', service])?.label ?? '-'}</TableCell>
                  <TableCell>
                    <Anchor
                      icon={<Trash color='black' />}
                      label='Remove'
                      id={IDUtil.getId('ServiceRemoveButton', service)}
                      data-testid={IDUtil.getId('ServiceRemoveButton', service)}
                      onClick={() => onRemoveClick(service)}
                      size='small'
                    />
                  </TableCell>
                </TableRow>
              )) ?? null}
            </TableBody>
          </Table>
          {noRows}
        </Box>
      </Box>
      {renderLayer()}
    </Box>
  );
};

RestrictedServices.propTypes = {
  isRequest: PropTypes.bool,
  onChange: PropTypes.func,
  assigned: PropTypes.array,
  services: PropTypes.array,
};

export default RestrictedServices;
