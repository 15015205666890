// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { Grommet, Box } from 'grommet';
import type { ThemeType } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { FormPin, More } from 'grommet-icons';

const hpeTheme = hpe as ThemeType;

const getSvg = (node) => {
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(node);
  });
  return div.innerHTML;
};
const ContractSvg = getSvg(
  <Grommet theme={hpe} background='transparent'>
    <Box pad={{ horizontal: 'small' }}>
      <hpeTheme.dataTable.icons.contract />
    </Box>
  </Grommet>
);
const ExpandSvg = getSvg(
  <Grommet theme={hpe} background='transparent'>
    <Box pad={{ horizontal: 'small' }}>
      <hpeTheme.dataTable.icons.expand />
    </Box>
  </Grommet>
);
const AscendingSvg = getSvg(
  <Grommet theme={hpe} background='transparent'>
    <Box>
      <hpeTheme.dataTable.icons.ascending />
    </Box>
  </Grommet>
);
const DescendingSvg = getSvg(
  <Grommet theme={hpe} background='transparent'>
    <Box>
      <hpeTheme.dataTable.icons.descending />
    </Box>
  </Grommet>
);
const PinSvg = getSvg(
  <Grommet theme={hpe} background='transparent'>
    <Box>
      <FormPin />
    </Box>
  </Grommet>
);
const MenuSvg = getSvg(
  <Grommet theme={hpe} background='transparent'>
    <Box>
      <More />
    </Box>
  </Grommet>
);

export const agGridIcons = {
  groupExpanded: ContractSvg,
  groupContracted: ExpandSvg,
  sortAscending: DescendingSvg,
  sortDescending: AscendingSvg,
  menuPin: PinSvg,
  menuAlt: MenuSvg,
};

export const agGridDefaultColDef = {
  sortingOrder: ['asc' as const, 'desc' as const],
  sortable: true,
  headerClass: 'header-left-aligned',
};
