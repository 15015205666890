// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, Footer } from 'grommet';
import AgGridContainer from '../../shared/grid/AgGridContainer';

const ProcessedFilesGrid = ({
  loading = false, data = undefined, search = undefined, onSearchChange
}) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(undefined);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getExportParams = () => ({});

  const onBtnExportCsv = () => {
    gridApi.exportDataAsCsv(getExportParams());
  };

  const onFirstDataRendered = useCallback((params) => {
    const colIDs = params.api.getAllGridColumns().map(({ colId }) => colId);
    params.api.autoSizeColumns(colIDs, false);
  }, []);

  const onBtnExportExcel = () => {
    if (gridApi) {
      const exportData = gridApi.getDataAsCsv(getExportParams());
      const blob = new Blob([exportData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'export.xls');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const hasRows = data?.rows?.length > 0;

  const refreshRowCount = (params) => {
    const count = params?.api?.getDisplayedRowCount();
    onSearchChange(count);
  };

  const filterChanged = (params) => {
    const count = params?.api?.getDisplayedRowCount();
    onSearchChange(count);
  };

  return (
    <Box
      flex={true}
      fill='vertical'
      margin={{ horizontal: 'small' }}
    >
      <AgGridContainer
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={data?.cols}
          rowData={data?.rows}
          quickFilterText={search}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onRowDataUpdated={refreshRowCount}
          loading={loading}
          onFilterChanged={filterChanged}
          defaultColDef={{
            sortable: true,
            resizable: true,
            floatingFilter: false,
          }}
          enableFilter={true}
          animateRows={true}
          autoSizeStrategy={{
            type: 'fitCellContents',
            skipHeader: false,
          }}
        />
      </AgGridContainer>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Export to CSV'
              type='button'
              disabled={!hasRows}
              secondary={true}
              onClick={hasRows ? onBtnExportCsv : undefined}
            />
            <Button
              label='Export to Excel'
              type='button'
              disabled={!hasRows}
              secondary={true}
              onClick={hasRows ? onBtnExportExcel : undefined}
            />
          </Box>
        </Footer>
      </Box>
    </Box>
  );
};

ProcessedFilesGrid.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.shape({
      headerName: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        filter: PropTypes.string,
        flex: PropTypes.bool,
        headerName: PropTypes.string,
        minWidth: PropTypes.number,
        resizable: PropTypes.bool,
        sortable: PropTypes.bool,
        type: PropTypes.string,
        width: PropTypes.number,
      })),
    })),
    rows: PropTypes.array,
    gridOptions: PropTypes.shape({
      animateRows: PropTypes.bool,
      defaultColDef: PropTypes.shape({
        flex: PropTypes.number,
        floatingFilter: PropTypes.bool,
        minWidth: PropTypes.number,
        resizable: PropTypes.bool,
        sortable: PropTypes.bool,
      }),
      enableFilter: PropTypes.bool,
      enableSorting: PropTypes.bool,
    }),
  }),
  search: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
};

export default ProcessedFilesGrid;
