// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

export const isNumber = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

export const parseStringAsPercent = (inputValue) => {
  // eslint-disable-next-line no-nested-ternary
  let value = typeof inputValue === 'string' ? (inputValue === '' ? 0 : parseFloat(inputValue)) : inputValue;
  value = value && value.length >= 2 && value.startsWith('0') ? value.slice(1) : value;

  if (isNumber(value)) {
    value = Math.min(Math.max(parseFloat(value), 0), 100);
  } else if (value === '' || !isNumber(value)) {
    value = 0;
  }
  return value;
};
