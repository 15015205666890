// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
export function hasValue(value) {
  // eslint-disable-next-line no-self-compare
  return typeof value !== 'undefined' && value !== null && value === value; // check for NaN
}

export function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

// https://github.com/rwu823/react-addons-text-content/blob/master/src/index.js
export function getElementText(rootChild) {
  let res = '';
  const rr = (child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      res += child;
    } else if (Array.isArray(child)) {
      child.forEach(c => rr(c));
    } else if (child && child.props) {
      const { children } = child.props;

      if (Array.isArray(children)) {
        children.forEach(c => rr(c));
      } else {
        rr(children);
      }
    }
  };

  rr(rootChild);

  return res;
}

export function insertIf(condition, elements) {
  return (condition ? elements : []);
}
