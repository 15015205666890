// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import URLUtil from '../../components/shared/util/URLUtil';
import { executeDelete, executeGet, executePost, executePut } from '../api';
import type { Customer } from '../types';

export const useCustomersQuery = (options: Partial<UseQueryOptions<any[]>> = {}) => {
  const path = useContext(ApiContext)('customers.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMERS-LIST'],
    queryFn: () => executeGet<any[]>(path),
    ...options,
  }), 'Fetch Customers Error');
};

export const useCustomersQueryMap = (options: Partial<UseQueryOptions<any[]>> = {}) => useCustomersQuery({
  ...options,
  select: data => data?.reduce((_map, c) => {
    // eslint-disable-next-line no-param-reassign
    _map[c.id] = {
      id: c.id,
      name: c.name,
    };
    return _map;
  }, {}),
});

export const useCustomerQuery = (customerId: string, options: Partial<UseQueryOptions<Customer>> = {}) => {
  const path = useContext(ApiContext)('customers.read.path', { customerId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMER', customerId],
    queryFn: () => executeGet<Customer>(path),
    gcTime: 0,
    ...options,
  }), 'Fetch Customer Error');
};

export const useCustomerCreateMutate = (options: Partial<UseMutationOptions>) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('customers.create.path');
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || {}),
    onSuccess: (...args) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMERS-LIST'],
      });
      options?.onSuccess?.(...args);
    },
  });
};

export const useCustomerUpdateMutate = (customerId: string, options: Partial<UseMutationOptions> = {}) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('customers.update.path', { customerId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: (...args) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMERS-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMER', customerId],
      });
      options?.onSuccess?.(...args);
    },
  });
};

export const useCustomerActionsQuery = (options: Partial<UseQueryOptions> = {}) => {
  const path = useContext(ApiContext)('customers.actions.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMER-ACTIONS'],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Customer Actions Error');
};

export const useMutationCustomerAction = (option: any, urlTemplate: string, options: Partial<UseMutationOptions> = {}) => {
  const { action, parameters } = (option || {});
  const queryParams = URLUtil.objectToQueryParams(parameters);
  const execute = action === 'POST' ? executePost : executePut;
  return useMutation({
    mutationFn: () => {
      // @ts-expect-error
      return execute(`${window.APIURL}${urlTemplate}/${queryParams}`);
    },
    ...options,
  });
};

export const useFeaturesQuery = (options: Partial<UseQueryOptions> = {}) => {
  const path = useContext(ApiContext)('features.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:FEATURES-LIST'],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Features Error');
};

export const useFeaturesUpdateMutate = (customerId: string, options: Partial<UseMutationOptions> = {}) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('features.update.path', { customerId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...options,
    onSuccess: (...args) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:FEATURES-LIST'],
      });
      options?.onSuccess?.(...args);
    },
  });
};

export const useCustomerLocationServiceTypesQuery = (customerId: string, locationId: string, options: Partial<UseQueryOptions> = {}) => {
  const path = useContext(ApiContext)('customers.services.types.path', { customerId, locationId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMER-LOCATION-SERVICETYPES', customerId, locationId],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Customer Location Service Type Error');
};

export const useCustomerAddEndMonthMutate = (customerId: string, options: Partial<UseMutationOptions<any, Error | AxiosError, string>> = {}) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation<any, Error | AxiosError, string>({
    mutationFn: (endMonth) => {
      const path = contextFn('customers.endMonth.add.path', { customerId, endMonth });
      return executePost(path);
    },
    ...options,
    onError: ((err, ...args) => {
      addToast({
        status: 'critical',
        title: 'End Month was not added',
        message: (isAxiosError(err) && err?.response?.data?.message) || err?.message,
      });
      options?.onError?.(err, ...args);
    }),
  });
};

export const useCustomerClearEndMonthMutate = (options: Partial<UseMutationOptions<any, Error | AxiosError, string>> = {}) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation<any, Error | AxiosError, string>({
    mutationFn: (customerId) => {
      const path = contextFn('customers.endMonth.clear.path', { customerId });
      return executePost(path);
    },
    ...options,
    onError: ((err, ...args) => {
      addToast({
        status: 'critical',
        title: 'Clear End Month Error',
        message: (isAxiosError(err) && err?.response?.data?.message) || err?.message,
      });
      options?.onError?.(err, ...args);
    }),
  });
};

export const useCustomerResetMutate = (options: Partial<UseMutationOptions<any, Error | AxiosError, string>> = {}) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation<any, Error | AxiosError, string>({
    mutationFn: (customerId) => {
      const path = contextFn('customers.reset.path', { customerId });
      return executePost(path);
    },
    ...options,
    onError: ((err, ...args) => {
      addToast({
        status: 'critical',
        title: 'Customer Reset Error',
        message: (isAxiosError(err) && err?.response?.data?.message) || err?.message,
      });
      options?.onError?.(err, ...args);
    }),
  });
};

export const useCustomerDeleteMutate = (options: Partial<UseMutationOptions<any, Error | AxiosError, string>> = {}) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation<any, Error | AxiosError, string>({
    mutationFn: (customerId) => {
      const path = contextFn('customers.delete.path', { customerId });
      return executeDelete(path);
    },
    ...options,
    onError: ((err, ...args) => {
      addToast({
        status: 'critical',
        title: 'Customer Delete Error',
        message: (isAxiosError(err) && err?.response?.data?.message) || err?.message,
      });
      options?.onError?.(err, ...args);
    }),
  });
};

export const useCustomerMetersQuery = (customerId: string, options: Partial<UseQueryOptions> = {}) => {
  const path = useContext(ApiContext)('customers.meters.read.path', { customerId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMER-METERS', customerId],
    queryFn: () => executeGet(path),
    ...options,
  }), 'Fetch Customer Meters Error');
};

export const useCustomerMetersMutate = (options: Partial<UseMutationOptions<any, Error | AxiosError, { customerId: string, payload: any }>> = {}) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation<any, Error | AxiosError, { customerId: string, payload: any }>({
    mutationFn: ({ customerId, payload }) => {
      const path = contextFn('customers.meters.update.path', { customerId });
      return executePost(path, payload);
    },
    ...options,
    onError: ((err, ...args) => {
      addToast({
        status: 'critical',
        title: 'Update Customer Meters Error',
        message: (isAxiosError(err) && err?.response?.data?.message) || err?.message,
      });
      options?.onError?.(err, ...args);
    }),
  });
};
