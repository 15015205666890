// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import moment from 'moment/moment';
import { UserContext } from './AppContext';
import LoginPage from './components/authentication/LoginPage';
import UnsupportedBrowserPage
  from './components/authentication/UnsupportedBrowserPage';
import MainPage from './components/main/MainPage';

let isIE11OrLess = false;
let isIE11Expired = false;
try {
  isIE11OrLess = !!document.documentMode && document.documentMode <= 11;
  isIE11Expired = moment().isAfter('2020-09-30');
} catch (error) {
  // istanbul ignore next
}

const GlbmMainPage = () => {
  const user = useContext(UserContext);
  // istanbul ignore next
  if (isIE11OrLess && isIE11Expired) {
    return <UnsupportedBrowserPage />;
  }

  let resumeSession = false;
  if (typeof Storage !== 'undefined' && Object.prototype.hasOwnProperty.call(window.sessionStorage, 'lastTokenRefresh')) {
    const lastRefresh = new Date(sessionStorage.getItem('lastTokenRefresh'));
    const diff = new Date() - lastRefresh;
    const minutes = Math.floor(diff / 1000 / 60);
    resumeSession = (minutes < 15);
  }

  if (user || resumeSession) {
    return <MainPage />;
  }
  return <LoginPage />;
};

export default GlbmMainPage;
