// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paragraph } from 'grommet';
import { StatusIcon } from '../../shared/component/StatusIcon';

const StatusItem = ({ severity = undefined, status = undefined, description = undefined }) => (
  <Box direction='column' gap='small'>
    <Box direction='row' gap='small' align='center'>
      <StatusIcon value={severity} size='small' />
      <span><strong>{status}</strong></span>
    </Box>
    {description
      && (
        <Box>
          <Paragraph margin='none' style={{ maxWidth: '300px' }}>
            {description}
          </Paragraph>
        </Box>
      )}
  </Box>
);

StatusItem.propTypes = {
  severity: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
};

export default StatusItem;
