// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import {
  Form, FormField, TextInput,
} from 'grommet';
import IDUtil from '../shared/util/IDUtil';
import {
  useCountriesQuery,
} from '../../core';
import { OptionsBuilder } from '../shared/util/OptionsBuilder';

import { getSelectValue } from '../shared/util/FormUtil';
import AutocompleteSelect from '../shared/component/AutocompleteSelect';

const CompanyEditorForm = ({
  company,
  errors = {},
  onChange,
}) => {
  const { data: countriesData } = useCountriesQuery();

  const countries = useMemo(() => {
    if (countriesData) {
      return new OptionsBuilder(countriesData).labelKey('displayName')
        .valueKey('countryCode')
        .build();
    }
    return [];
  }, [countriesData]);

  const selectedCountryValue = useMemo(
    () => (countries && company?.country ? getSelectValue(company?.country, countries) : { label: '' }),
    [company.country, countries]
  );

  return (
    <Form>
      <FormField
        label='Company Name'
        htmlFor='name'
        error={errors?.name || undefined}
        required={true}
      >
        <TextInput
          id='name'
          data-testid={IDUtil.getId('CompanyEditorNameInput')}
          name='name'
          value={company.name || ''}
          onChange={onChange}
        />
      </FormField>
      <FormField
        label='Address 1'
        htmlFor='address1'
        error={errors?.address1}
        required={true}
      >
        <TextInput
          id='address1'
          data-testid={IDUtil.getId('CompanyEditorAddress1Input')}
          name='address1'
          value={company.address1 || ''}
          onChange={onChange}
        />
      </FormField>
      <FormField
        label='Address 2'
        htmlFor='address2'
        error={errors?.address2}
      >
        <TextInput
          id='address2'
          data-testid={IDUtil.getId('CompanyEditorAddress2Input')}
          name='address2'
          value={company.address2 || ''}
          onChange={onChange}
        />
      </FormField>
      <FormField
        label='City'
        htmlFor='city'
        error={errors?.city}
        required={true}
      >
        <TextInput
          id='city'
          data-testid={IDUtil.getId('CompanyEditorCityInput')}
          name='city'
          value={company.city || ''}
          onChange={onChange}
        />
      </FormField>
      <FormField
        label='State/Province'
        htmlFor='stateOrProvince'
        error={errors?.stateOrProvince}
      >
        <TextInput
          id='stateOrProvince'
          data-testid={IDUtil.getId('CompanyEditorStateOfProvinceInput')}
          name='stateOrProvince'
          value={company.stateOrProvince || ''}
          onChange={onChange}
        />
      </FormField>
      <FormField
        label='Zip/Postal Code'
        htmlFor='postalCode'
        error={errors?.postalCode}
        required={true}
      >
        <TextInput
          id='postalCode'
          data-testid={IDUtil.getId('CompanyEditorPostalCodeInput')}
          name='postalCode'
          value={company.postalCode || ''}
          onChange={onChange}
        />
      </FormField>
      <FormField
        label='Country'
        htmlFor='country'
        error={errors?.country}
        required={true}
      >
        <AutocompleteSelect
          id='country'
          data-testid={IDUtil.getId('CompanyEditorCountryInput')}
          options={countries}
          name='country'
          value={selectedCountryValue}
          onChange={onChange}
          plain={true}
          labelKey='label'
          valueKey='value'
        />
      </FormField>
    </Form>
  );
};

CompanyEditorForm.contextTypes = {
  router: PropTypes.object,
};

CompanyEditorForm.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    stateOrProvince: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.array,
    address1: PropTypes.array,
    address2: PropTypes.array,
    city: PropTypes.array,
    stateOrProvince: PropTypes.array,
    postalCode: PropTypes.array,
    country: PropTypes.array,
  }),
  onChange: PropTypes.func.isRequired,
};

export default CompanyEditorForm;
