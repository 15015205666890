// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Box, Button, CheckBox, Footer, NameValueList, NameValuePair,
} from 'grommet';
import { ApiContext } from '../../../AppContext';
import { useInvoiceDetailsQuery } from '../../../core';
import Toast from '../../shared/component/Toast';
import GLBMLayer from '../../shared/component/GLBMLayer';
import MeterDetailsGrid from './MeterDetailsGrid';

const HeaderLabel = ({ label = '', value = '' }) => (
  <Box
    direction='column'
    align='start'
    flex={true}
    style={{ overflow: 'hidden' }}
  >
    <NameValueList pairProps={{ direction: 'column' }}>
      <NameValuePair name={label}>
        {value}
      </NameValuePair>
    </NameValueList>
  </Box>
);

HeaderLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const convertUnit = (value, fromUnit, toUnit, conversionFactor, precision) => {
  const totalBytes = {
    PB: 1000000000000000,
    PiB: 1125899906842624,
    TB: 1000000000000,
    TiB: 1099511627776,
    GB: 1000000000,
    GiB: 1073741824,
    MB: 1000000,
    MiB: 1048576,
    KB: 1000,
    kB: 1000, // for case reasons
    KiB: 1024,
    Bytes: 1,
    Byte: 1,
  };
  const formatNumber = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  // if we are passed conversion factor, use that over fromUnit, toUnit:
  if (conversionFactor !== undefined && parseFloat(conversionFactor) !== 0 && conversionFactor !== 0) {
    const val = value * parseFloat(conversionFactor);
    return `${(formatNumber(val.toFixed(precision)))} ${toUnit}`;
  }

  if ((fromUnit === toUnit || !Object.hasOwn(totalBytes, fromUnit) || !Object.hasOwn(totalBytes, toUnit))) {
    return `${(formatNumber(value.toFixed(precision)))} ${fromUnit}`;
  }
  const val = (value * totalBytes[fromUnit]) / totalBytes[toUnit];
  return `${(formatNumber(val.toFixed(precision)))} ${toUnit}`;
};

const MeterDetails = ({
  customer,
  selectedMeter,
  period = undefined,
  onBack,
}) => {
  const [showOnlyEstimated, setShowOnlyEstimated] = useState(false);
  const {
    data: details,
    error,
  } = useInvoiceDetailsQuery(
    customer.id,
    moment(period.value).format('YYYYMM'),
    selectedMeter.meterId,
    {
      pivotColumn: 'CAPACITY',
      units: (['GB', 'GiB', 'TB', 'TiB', 'Each'].includes(selectedMeter.units)) ? selectedMeter.units : undefined,
    },
  );

  const _renderUsage = (meter) => {
    const units = meter.units || meter.meterUnit;
    const conversionFactor = (units === meter.capacityUnit ? meter.capacityConversionFactor : 1);
    const precision = meter.unitPrecision || 3;
    return convertUnit(meter.consumedQuantity, meter.meterUnit, units, conversionFactor, precision);
  };

  const _renderEstimate = (myDetails) => {
    if (!myDetails) {
      return '-';
    }
    return (myDetails?.columns.filter(c => c.estimated).length ?? '').toString();
  };

  const _renderHeader = () => (
    <Box flex={false}>
      <Box direction='row' flex='grow' pad={{ horizontal: 'small', bottom: 'small' }}>
        <HeaderLabel label='Billing Account' value={customer?.name} />
        <HeaderLabel label='Meter' value={decodeURIComponent(escape(selectedMeter?.meterName)) || 'undefined'} />
        <HeaderLabel label='Period' value={period?.label || 'undefined'} />
        <HeaderLabel label='Actual Usage' value={_renderUsage(selectedMeter)} />
        <HeaderLabel label='Estimated Days' value={_renderEstimate(details)} />
        <CheckBox
          toggle={true}
          label='Show only estimated data'
          checked={showOnlyEstimated}
          onChange={() => setShowOnlyEstimated(value => !value)}
        />
      </Box>
    </Box>
  );

  const _renderDetails = () => (
    <Box
      flex={true}
      direction='column'
    >
      <MeterDetailsGrid
        meter={selectedMeter}
        data={details}
        showOnlyEstimated={showOnlyEstimated}
      />
    </Box>
  );

  const _renderFootnotes = () => {
    const data = details;
    if (!data) {
      return (<Box />);
    }

    const estimatedCols = data?.columns.filter(col => (Object.hasOwn(col, 'estimated') && col.estimated));
    if (estimatedCols && estimatedCols.length > 0) {
      return (
        <div className='invoice-footnote-container'>
          <div className='invoice-footnote-numbered'>
            {customer?.secureSite && (<span>*&nbsp;Some or all usage data was not received for this day. In this case, the usage for the missing day will be set to the installed capacity.</span>)}
            {!customer?.secureSite && (<span>*&nbsp;Some or all usage data was not received for this day. As an estimate, we are using data copied from the previous valid day.</span>)}
          </div>
        </div>
      );
    }
    return (<Box />);
  };

  const _renderToast = () => {
    let message = '';
    if (error) {
      message = (
        <Toast open={error} status='critical'>
          {error?.response?.data?.message || error?.message}
        </Toast>
      );
    }
    return message;
  };

  // assemble csv export path:
  const csvPath = useContext(ApiContext)('monthlyCharges.details.export.path', {
    customerId: customer?.id,
    period: moment(period?.value).format('YYYYMM'),
    meterId: selectedMeter?.meterId,
  }, {
    pivotColumn: 'CAPACITY',
    units: (['GB', 'GiB', 'TB', 'TiB', 'Each'].includes(selectedMeter.units)) ? selectedMeter.units : undefined,
  });

  const _onInvoiceExportToCSV = () => {
    window.open(csvPath, '_blank');
  };

  return (
    <GLBMLayer
      flush={true}
      full={true}
      margin='large'
      onEsc={onBack}
      title='Detailed Usage:'
    >
      <Box direction='column' fill={true} flex={true}>
        <Box flex={true} direction='row' margin={{ right: 'small' }}>
          <Box
            direction='column'
            flex={true}
            pad={{ horizontal: 'small' }}
            gap='small'
          >
            {_renderHeader()}
            {_renderDetails()}
            {_renderFootnotes()}
            {_renderToast()}
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button label='Close' type='button' primary={true} onClick={onBack} />
            <Button label='Export to CSV' type='button' secondary={true} onClick={_onInvoiceExportToCSV} />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

MeterDetails.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    secureSite: PropTypes.any,
  }).isRequired,
  selectedMeter: PropTypes.shape({
    meterId: PropTypes.string,
    units: PropTypes.string,
    meterName: PropTypes.string,
  }).isRequired,
  period: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  onBack: PropTypes.func.isRequired,
};

export default MeterDetails;
