// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import {
  SearchTextContextProvider
} from '../shared/component/HighlightUsingContext';
import { useAvailableColumns } from './utils';

const ASMListTable = ({
  searchText = '',
  asms = [],
  users = [],
  sort = undefined,
  onSort,
  onRemoveClick,
  canEdit = false,
  primaryASMId = undefined,
  onPrimaryASMSelected,
  loading = false,
}) => {
  const tableColumns = useAvailableColumns({
    canEdit,
    onRemoveClick,
    primaryASMId,
    onPrimaryASMSelected,
  });

  const data = useMemo(() => asms?.map((row, index) => ({
    index,
    id: row.id,
    firstName: users[row.id].firstName,
    lastName: users[row.id].lastName,
    email: users[row.id].email,
    role: row.roles[0],
  })), [asms, users]);

  return (
    <SearchTextContextProvider searchText={searchText}>
      <GLBMDataTable
        searchText={searchText}
        columns={tableColumns}
        data={data}
        sort={sort}
        onSort={onSort}
        sortable={true}
        loading={loading}
      />
    </SearchTextContextProvider>
  );
};

ASMListTable.propTypes = {
  searchText: PropTypes.string,
  asms: PropTypes.array,
  users: PropTypes.object,
  onRemoveClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  primaryASMId: PropTypes.string,
  onPrimaryASMSelected: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  sort: PropTypes.object,
  onSort: PropTypes.func.isRequired,
};

export default ASMListTable;
