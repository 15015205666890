// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { StatusWarning } from 'grommet-icons';

const WarningBanner = ({ message }) => {
  return (
    <Box
      align='center'
      direction='row'
      gap='small'
      justify='between'
      elevation='medium'
      pad='small'
      background='status-warning'
    >
      <Box align='center' direction='row' gap='xsmall'>
        <StatusWarning />
        <Text>
          {message}
        </Text>
      </Box>
    </Box>
  );
};

WarningBanner.propTypes = {
  message: PropTypes.string.isRequired,
};

export default WarningBanner;
