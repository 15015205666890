// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { executeDelete, executeGet, executePut } from '../api';

interface AccountKey {
  keyId: string;
  active: boolean;
}

export const useAccountKeysQuery = (accountId: string, options?: Partial<UseQueryOptions<AccountKey[], Error | AxiosError>>) => {
  const path = useContext(ApiContext)('accountkeys.get.path', { accountId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:ACCOUNT-KEYS-LIST', accountId],
    queryFn: () => executeGet<AccountKey[]>(path),
    ...(options || []),
  }), 'Fetch Account Keys Error');
};

export const useAccountKeyDeleteMutate = (accountId: string, options?: any) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (keyId: string) => {
      const path = contextFn('accountkeys.delete.path', { accountId, keyId });
      return executeDelete(path);
    },
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:ACCOUNT-KEYS-LIST', accountId]
      });
      addToast({
        status: 'normal',
        title: 'Deleted',
        message: 'Account Key Successfully Deleted.',
      });
      options?.onSuccess?.();
    },
    onError: ((err: any) => {
      addToast({
        status: 'critical',
        title: 'Account Key Delete Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useAccountKeyActivateMutate = (accountId: string, options?: any) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation<unknown, Error, object>({
    mutationFn: ({ keyId, active }: AccountKey) => {
      const path = contextFn(active ? 'accountkeys.inactivate.path' : 'accountkeys.activate.path', { accountId, keyId });
      return executePut(path);
    },
    ...(options || {}),
    onMutate: (accountKey: AccountKey) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      // @ts-expect-error
      queryClient.cancelQueries(['GLBM:ACCOUNT-KEYS-LIST', accountId]);

      // Snapshot the previous value
      const previousKeys = queryClient.getQueryData(['GLBM:ACCOUNT-KEYS-LIST', accountId]);

      // Optimistically update to the new value
      queryClient.setQueryData(['GLBM:ACCOUNT-KEYS-LIST', accountId], (oldKeys: AccountKey[] | undefined) => {
        if (!oldKeys) return [];
        return oldKeys.map(key => (key.keyId === accountKey.keyId ? { ...key, active: !key.active } : key));
      });

      return { previousKeys };
    },
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Account Key Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err: any) => {
      addToast({
        status: 'critical',
        title: 'Account Key Update Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useAccountKeyUpdateMutate = (accountId: string, keyId: string, options?: any) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('accountkeys.update.path', { accountId, keyId });
  const { addToast } = useToast();
  return useMutation<unknown, Error, object>({
    mutationFn: (payload: any) => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:ACCOUNT-KEYS-LIST', accountId],
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Account Key Successfully Updated.',
      });
      options?.onSuccess?.();
    },
    onError: ((err: any) => {
      addToast({
        status: 'critical',
        title: 'Account Key Update Error',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};
