// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import PartnerBadge from '../../shared/badges/PartnerBadge';

import SaveChangesDialog from '../../shared/dialogs/SaveChangesDialog';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import Wizard from '../../wizard/Wizard';
import Step from '../../wizard/WizardStep';
import { ServiceStep } from '../model';
import ServiceSaveDialog from './ServiceSaveDialog';
import ConfigureResources from './steps/configure-resources/ConfigureResources';
import ManageRates from './steps/rates/ManageRates';
import ServiceOptions from './steps/service-options/ServiceOptions';
import { useServiceEditorContext } from './contexts/ServiceEditorContext';

const ServiceEdit = (props) => {
  const [serviceEditor] = useServiceEditorContext();
  const { permissions, customer } = serviceEditor;
  const serviceType = ServiceTypeStore.getService(serviceEditor.options.config.serviceType);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // provide a default location.state object if needed:
  useEffect(() => {
    if (!location.state) {
      location.state = {
        origin: 'services',
        tenant: 'MASTER',
      };
    }
  }, [location.state]);

  const { customerId, serviceId } = params;
  const { origin } = location.state ? location.state : { origin: 'services' };

  const [layer, setLayer] = useState(undefined);
  const [isDirty, setIsDirty] = useState(false);

  const tenant = useMemo(() => {
    if (location && location.state) {
      return (location.state.tenant);
    }
    return 'MASTER';
  }, [location]);

  const setDirty = () => {
    if (!permissions.asmRole || (permissions.canEditMappings || permissions.canEditOptions || permissions.canEditRates)) {
      setIsDirty(true);
    }
  };

  const steps = useMemo(() => {
    const p = (permissions);
    const newSteps = [];
    if (p.canReadOptions) {
      newSteps.push(

        <Step key={ServiceStep.OPTIONS.enumKey} type={ServiceStep.OPTIONS.enumKey} label='Service options' validation={() => true} onNext={onOptionsNext}>
          <ServiceOptions setDirty={setDirty} readOnly={!permissions.canEditOptions} />
        </Step>,
      );
    }
    if (p.canReadMappings) {
      newSteps.push(

        <Step key={ServiceStep.CONFIGURE_RESOURCES.enumKey} type={ServiceStep.CONFIGURE_RESOURCES.enumKey} label='Configure resources' validation={() => true} onNext={onMappingsNext} onPrev={onMappingsPrev}>
          <ConfigureResources setDirty={setDirty} customer={customer} readOnly={!permissions.canEditMappings} />
        </Step>,
      );
    }
    if (p.canReadRates) {
      newSteps.push(
        <Step
          key={ServiceStep.RATES.enumKey}
          type={ServiceStep.RATES.enumKey}
          label={p.canEditRates || p.canMarkupRates ? 'Manage rates' : 'View rates'}
          validation={() => true}
        >
          <ManageRates
            setDirty={setDirty}
            customer={customer}
            serviceType={serviceType}
            tenant={tenant}
            readOnly={!permissions.canEditRates}
            permissions={permissions}
          />
        </Step>,
      );
    }

    return newSteps;
  }, [serviceEditor]);

  const onOptionsNext = () => {

  };

  const onMappingsNext = () => {

  };

  const onMappingsPrev = () => {

  };

  const onFinish = () => {
    setLayer('saveService');
  };

  const onSaveServiceDialogClosed = (success) => {
    setLayer(undefined);
    if (success) {
      setIsDirty(false);
      setTimeout(() => {
        navigate(`/customers/${customerId}/services`);
      }, 100);
    }
  };

  const canSave = useMemo(() => {
    const {
      canEditOptions, canEditMappings, canEditRates, canMarkupRates,
    } = permissions || {};
    return canEditOptions || canEditMappings || canEditRates || canMarkupRates;
  }, [permissions]);

  const _isDirty = useMemo(
    () => layer !== 'saveService' && !!(isDirty),
    [layer, isDirty]
  );

  const isDirtyWarning = () => (customer && customer.status === 'BILLABLE' && serviceEditor.options && serviceEditor.options.status === 'COMPLETE');

  const customerTitle = (customer ? `${customer.name} (${customer.id})` : '');
  const forcePage = (props.params.page) ? 1 : 0;
  const { canEditOptions, canEditMappings, canEditRates } = permissions;
  const editableServiceWizard = canEditOptions || canEditMappings || canEditRates;

  if (!customer) {
    return (<Box />);
  }

  // if the user editing can save changes:
  const callback = (canSave ? onFinish : undefined);
  return (
    <Box direction='column' fill='vertical'>
      <Wizard
        title={(
          <Box direction='row' gap='xxsmall' align='center'>
            <Heading level='2'>Set up</Heading>
            {tenant && tenant !== 'MASTER' ? <PartnerBadge tenant={tenant} /> : ''}
            <Heading level='2'>{serviceType && serviceType.label}</Heading>
            <Heading level='2'>service:</Heading>
            <Heading level='2'>{customerTitle}</Heading>
          </Box>
        )}
        forcePage={forcePage}
        callback={callback}
        origin={origin}
        history={props.history}
        dirtyWarning={isDirtyWarning()}
        unsavedChanges={() => true}
      >
        {steps}
      </Wizard>
      <ReactRouterPrompt when={_isDirty && editableServiceWizard}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {({ isActive, onConfirm, onCancel }) => (
          <SaveChangesDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
      {(layer === 'saveService') && (
        <ServiceSaveDialog
          onClose={onSaveServiceDialogClosed}
          customerId={customerId}
          serviceId={serviceId}
        />
      )}
    </Box>
  );
};

ServiceEdit.contextTypes = {
  router: PropTypes.object,
};

ServiceEdit.propTypes = {
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default ServiceEdit;
