// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, DataTable,
} from 'grommet';
import {
  Add, Copy, Edit, Trash,
} from 'grommet-icons';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import CurrencyUtil from '../../../../i18n/CurrencyUtil';
import BaseContainer from './components/BaseContainer';
import LocationRateDelete from './LocationRateDelete';
import LocationRateEditor from './LocationRateEditor';

const LocationRates = ({
  setExpanded, expanded, locationId, locationRates, onChange, readOnly
}) => {
  const [showAddEditLayer, setShowAddEditLayer] = useState(undefined);
  const [showDeleteLayer, setShowDeleteLayer] = useState(undefined);
  const addRevision = () => {
    setShowAddEditLayer({
      title: 'Add Contractual Values Revision',
      initialValues: { commitmentAmount: '', discountPct: '', effectiveDate: undefined },
      newRev: true,
    });
  };

  const actions = useMemo(() => {
    /* const toggle = ((expanded === 'NONE' || expanded !== 'RATES')
      ? <Button key='expand' icon={<Expand />} onClick={() => setExpanded?.('RATES')} kind='toolbar' />
      : <Button key='collapse' icon={<Contract />} onClick={() => setExpanded?.('NONE')} kind='toolbar' />
    ); */
    const addNew = readOnly ? null : (
      <Button
        key='addRevision'
        icon={<Add />}
        label='Add Revision'
        onClick={addRevision}
        kind='toolbar'
      />
    );

    return ([
      addNew,
      // toggle,
    ]);
  }, [expanded, readOnly, setExpanded]);

  const tableData = useMemo(() => (
    sortBy(map(locationRates, r => ({
      ...r,
      discount: (r.discountPct / 100) * r.commitmentAmount,
      id: `${r.locationId}-${r.effectiveDate}`,
    })), 'effectiveDate')
  ), [locationRates]);

  const onEdit = useCallback(rate => () => {
    setShowAddEditLayer({
      title: 'Edit Contractual Values Revision',
      initialValues: { ...rate, effectiveDate: moment(rate.effectiveDate).format('YYYY-MM') },
      newRev: false,
    });
  }, []);

  const onCopy = useCallback(rate => () => {
    setShowAddEditLayer({
      title: 'Add Contractual Values Revision',
      initialValues: { ...rate, effectiveDate: undefined, id: undefined },
      newRev: true,
    });
  }, []);

  const onDelete = useCallback(rate => () => {
    setShowDeleteLayer(rate);
  }, []);

  const onSubmitEdit = useCallback((rate, isNew) => {
    let modData;
    if (isNew) {
      modData = [...tableData];
    } else {
      modData = tableData.filter(r => r.id !== rate.id);
    }
    modData.push(rate);
    onChange(locationId, modData.map(r => ({
      effectiveDate: r.effectiveDate,
      commitmentAmount: r.commitmentAmount,
      discountPct: r.discountPct,
    })));
    setShowAddEditLayer(undefined);
  }, [locationId, onChange, tableData]);

  const onSubmitDelete = useCallback((rate) => {
    onChange(
      locationId,
      tableData.filter(r => r.id !== rate.id).map(r => ({
        effectiveDate: r.effectiveDate,
        commitmentAmount: r.commitmentAmount,
        discountPct: r.discountPct,
      }))
    );
    setShowDeleteLayer(undefined);
  }, [locationId, onChange, tableData]);

  return (
    <BaseContainer
      title='Contractual Values'
      fill='vertical'
      expanded={expanded === 'NONE' || expanded === 'RATES'}
      actions={actions}
      data-testid='contractual-values-container'
    >
      <Box>
        <DataTable
          columns={[
            {
              property: 'effectiveDate',
              header: 'Start Date',
              render: ({ effectiveDate }) => moment(effectiveDate).format('l'),
            },
            {
              property: 'commitmentAmount',
              header: 'Monthly Usage Reserve',
              render: ({ commitmentAmount }) => CurrencyUtil.getNumberString(commitmentAmount),
              align: 'end',
            },
            {
              property: 'discountPct',
              header: 'Monthly Savings %',
              render: ({ discountPct }) => `${CurrencyUtil.getNumberString(discountPct)}%`,
              align: 'end',
            },
            {
              property: 'discount',
              header: 'Monthly Savings',
              render: ({ discount }) => CurrencyUtil.getNumberString(discount),
              align: 'end',
            },
            ...(readOnly ? [] : [{
              property: 'id',
              header: 'Actions',
              render: datum => (
                <Box direction='row' gap='small'>
                  <Button
                    icon={<Edit size='18px' />}
                    a11yTitle='Edit revision'
                    onClick={onEdit(datum)}
                    size='small'
                  />
                  <Button
                    icon={<Copy size='18px' />}
                    a11yTitle='Copy revision'
                    onClick={onCopy(datum)}
                    size='small'
                  />
                  <Button
                    icon={<Trash size='18px' />}
                    a11yTitle='Delete revision'
                    onClick={onDelete(datum)}
                    size='small'
                  />
                </Box>
              ),
            }]),
          ]}
          data={tableData}
          primaryKey='id'
        />
      </Box>
      {showAddEditLayer && (
        <LocationRateEditor
          {...showAddEditLayer}
          onClose={() => setShowAddEditLayer(undefined)}
          onSubmit={onSubmitEdit}
          otherRates={tableData.filter(d => d.id !== showAddEditLayer.initialValues.id)}
        />
      )}
      {showDeleteLayer && (
        <LocationRateDelete
          data={showDeleteLayer}
          onClose={() => setShowDeleteLayer(undefined)}
          onSubmit={onSubmitDelete}
        />
      )}
    </BaseContainer>
  );
};

LocationRates.propTypes = {
  setExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  locationRates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default LocationRates;
