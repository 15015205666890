// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer,
  Select,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';

const ServiceMappingsFilter = (props) => {
  const [filter, setFilter] = useState(JSON.parse(JSON.stringify(props.filter)));

  const _onSubmit = () => {
    props.onChange(filter);
  };

  const _onClear = () => {
    setFilter({});
  };

  const _onChangeFilter = (name, isMultiple, event) => {
    const newFilter = JSON.parse(JSON.stringify(filter));
    if (!event?.option?.value) {
      delete newFilter[name];
    } else if (isMultiple) {
      newFilter[name] = event.value.map(value => (
        typeof value === 'object' ? value.value : value));
      if (newFilter[name].length === 0) {
        delete newFilter[name];
      }
    } else {
      newFilter[name] = event.option.value;
      if (newFilter[name].length === 0) {
        delete newFilter[name];
      }
    }
    setFilter(newFilter);
  };

  const _renderAssignmentOptions = () => {
    const options = [];
    options.push({ label: 'Assigned', value: 'ASSIGNED' });
    options.push({ label: 'Unassigned', value: 'UNASSIGNED' });

    let value;
    if (filter && Object.prototype.hasOwnProperty.call(filter, 'status') && filter.status) {
      value = filter.status;
    } else {
      value = 0;
    }

    return (
      <Select
        style={{ width: '100%' }}
        inline={true}
        multiple={false}
        clear={true}
        value={value}
        options={options}
        labelKey='label'
        placeholder='Select'
        valueKey={{ key: 'value', reduce: true }}
        onChange={event => _onChangeFilter('status', false, event)}
      />
    );
  };

  const _renderServiceOptions = () => {
    const options = [];
    const { serviceTypes } = props;
    for (let i = 0; i < serviceTypes.length; i += 1) {
      options.push({ label: serviceTypes[i], value: serviceTypes[i] });
    }

    let value;
    if (filter && Object.prototype.hasOwnProperty.call(filter, 'service') && filter.service) {
      value = filter.service;
    } else {
      value = 0;
    }

    return (
      <Select
        style={{ width: '100%' }}
        inline={true}
        multiple={false}
        value={value}
        options={options}
        clear={true}
        placeholder='Select'
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        onChange={event => _onChangeFilter('service', false, event)}
      />
    );
  };
  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={false}
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      onClose={props.onClose}
      a11yTitle='Filter Service Mappings'
      title='Filter Service Mappings'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
      >
        <Box flex={true}>
          <GLBMFilterPropertyBox label='Billing ID:'>
            {_renderAssignmentOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Service:'>
            {_renderServiceOptions()}
          </GLBMFilterPropertyBox>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              primary={true}
              onClick={_onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={props.onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            onClick={_onClear}
          />
        </Footer>
      </Box>

    </GLBMLayer>
  );
};

ServiceMappingsFilter.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  filter: PropTypes.object,
  serviceTypes: PropTypes.array,
};

ServiceMappingsFilter.defaultProps = {
  onClose: null,
  onChange: null,
  filter: {},
  serviceTypes: []
};

export default ServiceMappingsFilter;
