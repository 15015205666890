// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Box, Heading, NameValueList, NameValuePair, Text
} from 'grommet';
import keys from 'lodash/keys';
import { isEmpty, isUndefined, kebabCase } from 'lodash';

const emptyText = '--';

const DetailsBox = ({
  detailsBoxHeading = '',
  detailsBoxSubheading = '',
  detailsData,
  detailsSubHeading,
  hideDetails = {},
  detailsBoxActions = undefined,
}) => {
  if (typeof hideDetails === 'function') {
    // eslint-disable-next-line no-param-reassign
    hideDetails = hideDetails();
  }

  return (
    <Box
      margin='small'
      fill='horizontal'
      id={`detailsbox-wrapper-${kebabCase(detailsBoxHeading)}`}
      data-testid={`detailsbox-wrapper-${kebabCase(detailsBoxHeading)}`}
      gap='small'
    >
      {!isEmpty(detailsBoxHeading) && (
        <Box direction='row'>
          <Heading
            id={`detailsbox-detailsBoxHeading-${kebabCase(detailsBoxHeading)}`}
            data-testid={`detailsbox-detailsBoxHeading-${kebabCase(detailsBoxHeading)}`}
            level='2'
            margin={{
              vertical: 'xsmall',
              horizontal: 'none',
            }}
          >
            {detailsBoxHeading}
          </Heading>
          <Box flex='grow' />
          {!isEmpty(detailsBoxActions) && (
            <Box
              flex={true}
              direction='row'
              gap='small'
              justify='end'
              align='center'
            >
              {detailsBoxActions}
            </Box>
          )}
        </Box>

      )}
      {!isEmpty(detailsBoxSubheading) && (
        <Text
          id={`detailsbox-detailsBoxSubheading-${kebabCase(detailsBoxSubheading)}`}
          data-testid={`detailsbox-detailsBoxSubheading-${kebabCase(detailsBoxSubheading)}`}
          margin={{
            bottom: 'xsmall',
            horizontal: 'none',
            top: 'none',
          }}
        >
          {detailsBoxSubheading}
        </Text>
      )}
      <NameValueList id={`detailsbox-value-list-${kebabCase(detailsBoxHeading)}`} data-testid={`detailsbox-value-list-${kebabCase(detailsBoxHeading)}`}>
        {keys(detailsSubHeading).map(item => (!hideDetails[item] && (
          <NameValuePair
            name={detailsSubHeading[item]}
            key={item}
          >
            {isUndefined(detailsData[item]) || isEmpty(detailsData[item]) ? emptyText : detailsData[item]}
          </NameValuePair>
        )))}
      </NameValueList>
    </Box>
  );
};

DetailsBox.propTypes = {
  detailsBoxHeading: PropTypes.string,
  detailsBoxSubheading: PropTypes.string,
  detailsData: PropTypes.object.isRequired,
  detailsSubHeading: PropTypes.object.isRequired,
  hideDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  detailsBoxActions: PropTypes.array,
};

export default DetailsBox;
