// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ServiceStep } from 'services/model/ServiceStep';
import CurrencyUtils from 'i18n/CurrencyUtil';
import { Box, FormField, RadioButton, Select, Text, TextInput } from 'grommet';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import Loader from '../../../../../shared/loader';
import { StatusIcon } from '../../../../../shared/component/StatusIcon';
import Toast from '../../../../../shared/component/Toast';
import {
  useAzureStackCSPListQuery,
  useAzureStackCustomerListQuery,
  useValidationMutation,
} from '../../../../../../core';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';

const convertRates = (tiers, change) => {
  const tempTiers = {};
  Object.entries(tiers).forEach(([threshold, tier]) => {
    const newTier = { ...tier };
    newTier.rate *= change;
    tempTiers[threshold] = newTier;
  });
  return tempTiers;
};

const AzureStackBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();
  const [cspId, setCspId] = useState(serviceEditor.options.config.cspId);
  const [customerOptionId, setCustomerOptionId] = useState(serviceEditor.options.config.cspCustomerId);
  const [customerOption, setCustomerOption] = useState(serviceEditor.options.config.cspCustomerId ? 'SINGLE' : 'ALL');
  const [errors, setErrors] = useState({ cspId: undefined, cspCustomerId: undefined, discountTiers: undefined });
  const [response, setResponse] = useState(undefined);
  const [discountTiers, setDiscountTiers] = useState({});

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);
  const { data: cspList, isFetching: cspLoading } = useAzureStackCSPListQuery();
  const {
    data: customerList,
    isFetching: customerLoading,
    isSuccess: isCustomerSuccess,
    isError: isCustomerError,
  } = useAzureStackCustomerListQuery(serviceEditor.options?.config?.cspId, {
    enabled: !!cspList && !!serviceEditor.options?.config?.cspId,
  });

  useEffect(() => {
    if (isCustomerSuccess) {
      fetchValidationIfNeeded();
    } else if (isCustomerError) {
      errors.cspCustomerId = 'Problem Loading Customers';
      setErrors(errors);
      fetchValidationIfNeeded();
    }
  }, [isCustomerSuccess, customerList, isCustomerError, errors]);

  useEffect(() => {
    setDiscountTiers(convertRates({ ...serviceEditor.options.config.discountTiers }, 100));
  }, []);

  useEffect(() => {
    if (cspList) {
      const { options } = serviceEditor;
      if (options.config.cspId) {
        const csp = cspList.filter(cspItem => cspItem.id === options.config.cspId)[0];
        if (csp && csp.connectionStatus !== 'CONNECTED') {
          options.config.cspId = undefined;
          errors.cspId = 'Disconnected Tenant Selected';
          setErrors(errors);
          setServiceEditor(prev => ({ ...prev, options }));
        }
      }
    }
  }, [cspList]);

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={onToastClose}
        >
          {response.text}
        </Toast>
      );
    }
    return message;
  };

  const onToastClose = () => setResponse(undefined);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-shadow
  const validateDiscountTiers = discountTiers => Object.entries(discountTiers).reduce((hasError, [threshold, { rate }]) => hasError || (rate === '' || rate === undefined || rate === null), false);

  const onDiscountChanged = ({ target: { name: property, value } }) => {
    discountTiers[property].rate = value;
    setDiscountTiers(discountTiers);

    errors.discountTiers = validateDiscountTiers(discountTiers) ? 'Required' : undefined;
    setErrors(errors);

    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.discountTiers[property].rate = (value !== '' ? (value / 100) : undefined);
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
    fetchValidationIfNeeded();
  };

  const _onChange = (property, value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));

    // if the end user changes the Azure Tenant, then we need to clear things out:
    if (property === 'cspId') {
      setCspId(value);
      newState.options.config.cspCustomerId = undefined;

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const cspId = value;
      const csp = cspList.filter(cspItem => cspItem.id === cspId)[0];
      if (csp && csp.connectionStatus === 'CONNECTED') {
        newState.options.config[property] = value;
        errors.cspId = undefined;
        errors.cspCustomerId = undefined;
      } else {
        newState.options.config[property] = undefined;
        errors.cspId = 'Disconnected Tenant Selected';
        errors.cspCustomerId = 'Problem Loading Customers';
      }
      setErrors(errors);
    } else if (property === 'cspCustomerId') {
      newState.options.config[property] = value;
      setCustomerOptionId(newState.options.config[property]);
      errors.cspCustomerId = undefined;
      setErrors(errors);
    }

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
    fetchValidationIfNeeded();
  };

  const _onChangeCustomerOption = (option) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    if (option === 'ALL') {
      newState.options.config.cspCustomerId = undefined;
      errors.cspCustomerId = undefined; // undefined is good - should pass validation
      setErrors(errors);
    } else {
      newState.options.config.cspCustomerId = customerOptionId;
      if (newState.options.config.cspCustomerId === undefined) {
        errors.cspCustomerId = 'Customer not selected';
        setErrors(errors);
      }
    }

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
    fetchValidationIfNeeded();

    setCustomerOption(option);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const _getCSPName = (cspId) => {
    if (cspId && cspList) {
      const csp = cspList.filter(cspItem => cspItem.id === cspId)[0];
      if (csp) {
        return _getAzureCSPOption(csp);
      }
    }
    return '';
  };

  const _getCustomerName = (customerId) => {
    if (customerId && customerList) {
      const filteredCustomer = customerList.filter(customer => customer.customerId === customerId);
      if (filteredCustomer && filteredCustomer.length) {
        return ({
          value: filteredCustomer[0].customerId,
          label: (
            <Box direction='row' justify='between' height='34px' align='center' pad={{ left: '12px' }}>
              <span>{filteredCustomer[0].companyName}</span>
              <span className='secondary'>{filteredCustomer[0].primaryDomainName}</span>
            </Box>
          ),
        });
      }
    }
    return '';
  };

  const _getAzureCSPOption = csp => ({
    value: csp.id,
    label: (
      <Box
        direction='row'
        justify='between'
        margin={{ left: 'small' }}
        align='center'
        height='34px'
      >
        <Box direction='row' align='center' margin={{ right: 'small' }} gap='small'>
          <StatusIcon
            size='small'
            value={csp.connectionStatus === 'CONNECTED' ? 'ok' : 'critical'}
          />
          <Text>{csp.name}</Text>
        </Box>
      </Box>
    ),
  });

  const renderAzureDiscountTiers = () => {
    const { contractCurrency } = serviceEditor.customer;

    const discountSteps = Object.entries(discountTiers)
      // eslint-disable-next-line no-nested-ternary
      .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
      .reduce((arr, [threshold, { rate, name }]) => (
        [...arr, {
          threshold, rate, name,
        }]
      ), []);

    const discountNodes = discountSteps.map((discount, index) => {
      const nextThreshold = (index < discountSteps.length - 1 ? discountSteps[index + 1].threshold : undefined);
      return (
        <Box
          key={discount.threshold}
          pad='none'
          justify='between'
          flex={false}

        >
          <Text size='small'>
            {discount.name}
            {' - '}
            {CurrencyUtils.getCurrencyString(parseInt(discount.threshold), 0, null, contractCurrency)}
            {nextThreshold ? ' to ' : '+'}
            {nextThreshold && CurrencyUtils.getCurrencyString(parseInt(nextThreshold - 1), 0, null, contractCurrency)}
          </Text>
          <Box className='tiny-inputs' direction='row' align='end' justify='end'>
            {!props.readOnly
              ? (
                <TextInput
                  type='number'
                  name={discount.threshold}
                  value={discount.rate}
                  onChange={onDiscountChanged}
                  data-testid={`discount-input-${discount.threshold}`}
                />
              )
              : (
                <span>
                  {discount.rate}
                  %
                </span>
              )}
          </Box>
        </Box>
      );
    });

    return (
      <FormField
        label='Adjustment Percentages'
        error={errors && errors.discountTiers}
      >
        <Box margin='medium'>
          {discountNodes}
        </Box>
      </FormField>
    );
  };

  function _getEditableLayout() {
    const azureCSPOptions = (cspList || []).map(csp => _getAzureCSPOption(csp));
    const azureCustomerOptions = (customerList || []).map(customer => ({
      value: customer.customerId,
      label: (
        <Box direction='row' justify='between'>
          <span>{customer.companyName}</span>
          <span className='secondary'>{customer.primaryDomainName}</span>
        </Box>
      ),
    }));

    return (
      <>
        {renderAzureDiscountTiers()}
        <FormField
          label='Azure Tenant'
          error={errors && errors.cspId}
        >
          <Select
            value={cspId}
            options={azureCSPOptions}
            name='cspId'
            placeHolder='Select Azure Tenant'
            onChange={({ value }) => _onChange('cspId', value)}
            labelKey='label'
            valueKey={{ key: 'value', reduce: true }}
            valueLabel={_getCSPName(cspId).label}
          />
          {cspLoading
          && (
          <Box
            direction='row'
            align='center'
            gap='small'
            margin={{ left: 'medium' }}
            justify='start'
          >
            <Box direction='row' align='center' gap='small' justify='center' fill={true}>
              <Loader text='Loading Services. Please wait ...' />
            </Box>
          </Box>
          )}
        </FormField>
        {serviceEditor.options.config.cspId
        && (
        <FormField
          label='Azure Customer'
          error={errors && errors.cspCustomerId}
        >
          <Box
            flex='grow'
            className='tiny-inputs'
            direction='row'
            pad='small'
          >
            <Box direction='column'>
              <RadioButton
                key='allCSPCustomers'
                id='allCSPCustomers'
                name='allCSPCustomers'
                label='All'
                checked={customerOption === 'ALL'}
                onChange={() => _onChangeCustomerOption('ALL')}
              />
              <RadioButton
                key='singleCSPCustomers'
                id='singleCSPCustomers'
                name='singleCSPCustomers'
                label='Single'
                checked={customerOption === 'SINGLE'}
                onChange={() => _onChangeCustomerOption('SINGLE')}
              />
            </Box>
          </Box>
          {!customerLoading && customerOption === 'SINGLE'
          && (
            <Box
              flex='grow'
              direction='row'
              pad={{ horizontal: 'medium', bottom: 'medium' }}
            >
              <Box flex='grow'>
                <Select
                  value={serviceEditor.options.config.cspCustomerId}
                  options={azureCustomerOptions}
                  name='cspCustomerId'
                  placeHolder='Select Azure Customer'
                  onChange={({ value }) => _onChange('cspCustomerId', value)}
                  labelKey='label'
                  valueKey={{ key: 'value', reduce: true }}
                  valueLabel={_getCustomerName(serviceEditor.options.config.cspCustomerId).label}
                />

              </Box>
            </Box>
          )}
          {customerLoading
          && (
          <Box
            direction='row'
            align='center'
            gap='small'
            justify='start'
          >
            <Box direction='row' align='center' gap='small' justify='center' fill={true}>
              <Loader text='Loading Services. Please wait ...' />
            </Box>
          </Box>
          )}
        </FormField>
        )}
      </>
    );
  }

  function _getReadOnlyLayout() {
    const labelFromBillingMethodMap = new Map()
      .set('PER_CORE_UTILIZATION', 'Per core utilization');

    const { windowsUtilBillingMethod } = serviceEditor.options.config;

    return (
      <>
        <ReadOnlyField label='Azure Tenant Name' value={labelFromBillingMethodMap.get(windowsUtilBillingMethod)} />
        <ReadOnlyField label='Azure Customer Name' value={labelFromBillingMethodMap.get(windowsUtilBillingMethod)} />
      </>
    );
  }

  return (
    <>
      {props.readOnly ? _getReadOnlyLayout() : _getEditableLayout()}
      {renderToast()}
    </>
  );
};

AzureStackBillingMethodField.propTypes = {
  setDirty: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default AzureStackBillingMethodField;
