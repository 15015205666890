// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box, CheckBox } from 'grommet';
import { FormAdd } from 'grommet-icons';
import { cloneDeep } from 'lodash';
import SingleResourceRevisionEdit from './SingleResourceRevisionEdit';
import MultiResourceRevisionEdit from './MultiResourceRevisionEdit';

const ResourceRevisionEdit = ({
  name,
  attribute,
  mappedOptions,
  isBulk,
  update,
  onBulkChange,
  revisions,
  onChange,
  contractStartMonth,
  isSubmitted,
}) => {
  const [showTable, setShowTable] = useState(false);

  const allowExtraRevisions = useMemo(() => !isBulk && revisions && revisions.length > 0, [isBulk, revisions]);

  const options = useMemo(() => {
    const result = [];
    if (mappedOptions && mappedOptions.length > 0) {
      for (let index = 0; index < mappedOptions?.length; index += 1) {
        const option = mappedOptions[index];
        result.push({
          value: option.id,
          label: option.name
        });
      }
    }
    return result;
  }, [mappedOptions]);

  // this effect is responsible for adjusting the 1st revision to always have effectiveDate = contractStartMonth:
  useEffect(() => {
    if (revisions && revisions.length) {
      const updatedRevisions = cloneDeep(revisions);
      if (updatedRevisions && updatedRevisions.length && updatedRevisions[0].effectiveDate !== contractStartMonth) {
        updatedRevisions[0].effectiveDate = contractStartMonth;
        onChange(attribute, updatedRevisions);
      }
    }
    setShowTable(revisions && revisions.length > 1);
  }, []);

  const doAddRevision = () => {
    const updatedRevisions = cloneDeep(revisions);
    updatedRevisions.push({ id: undefined, effectiveDate: undefined });
    onChange(attribute, updatedRevisions);
    setShowTable(true);
  };

  return (
    <Box direction='row' align='center' flex={false}>
      {isBulk && (
        <Box margin={{ top: 'medium' }}>
          <CheckBox
            name={attribute}
            checked={update}
            onChange={event => onBulkChange(event)}
            data-testid={`equipment-bulk-edit-${attribute}-checkbox`}
          />
        </Box>
      )}
      <Box direction='column'>
        {!showTable && (
          <SingleResourceRevisionEdit
            name={name}
            attribute={attribute}
            revisions={revisions}
            onChange={onChange}
            contractStartMonth={contractStartMonth}
            isSubmitted={isSubmitted}
            options={options}
          />
)}
        {showTable && (
          <MultiResourceRevisionEdit
            name={name}
            attribute={attribute}
            revisions={revisions}
            onChange={onChange}
            contractStartMonth={contractStartMonth}
            isSubmitted={isSubmitted}
            options={options}
          />
)}
        {allowExtraRevisions && (
          <Anchor
            onClick={() => doAddRevision()}
            icon={<FormAdd />}
            label='Add Revision'
            size='small'
          />
        )}
      </Box>
    </Box>
  );
};

ResourceRevisionEdit.propTypes = {
  name: PropTypes.string,
  attribute: PropTypes.string,
  mappedOptions: PropTypes.array,
  isBulk: PropTypes.bool,
  update: PropTypes.bool,
  onBulkChange: PropTypes.func,
  revisions: PropTypes.array,
  onChange: PropTypes.func,
  contractStartMonth: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default ResourceRevisionEdit;
