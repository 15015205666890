// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'grommet';
import { useToast } from './ToastProvider';

const Toast = ({
  toast = true, status, title = undefined, message = undefined, id, global = false, icon = undefined
}) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast]);
  return (
    <Notification
      toast={toast}
      status={status}
      title={title}
      message={message}
      global={global}
      icon={icon}
    />
  );
};

Toast.propTypes = {
  toast: PropTypes.bool,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  global: PropTypes.bool,
  icon: PropTypes.node,
};

export default Toast;
