// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'grommet';
import { getElementText } from '../util/BasicUtil';

/**
 * AutocompleteSelect has the same API as Select
 * This component was created to better support the auto-complete search. Currently there are no enhancements to for the
 * Grommet Select "inline" property for radio-buttons/check-boxes.
 *
 * The enhancements include:
 * 1. Facilitate the basic filtering of the options using the filter label element/text
 * 2. Auto-focus the input
 * 3. Add debounce function for search functionality to improve performance
 * 4. Improved filtering performance
 * 5. Sorting, with default alphabetical sort on the text content. {@link sortFn}
 */
const AutocompleteSelect = (props) => {
  const [searchText, setSearchText] = React.useState('');

  const sortOptions = (options) => {
    const copiedOptions = [...options];
    return props.sortFn ? copiedOptions.sort(props.sortFn) : options;
  };

  const _onSearch = (option) => {
    setSearchText(option);
  };

  const getLabelText = (option) => {
    const label = option[props?.labelKey || 'label'];
    return React.isValidElement(label) ? getElementText(label)
      .trim() : label?.toString()
      .trim();
  };

  const containsSearchText = (option) => {
    const filter = searchText.toLowerCase();
    const labelText = getLabelText(option);

    return labelText.toLowerCase()
      .includes(filter);
  };

  const filterOptions = (options = []) => (searchText ? options.filter(option => containsSearchText(option)) : options);

  const _onClose = () => {
    _onSearch('');
  };

  const {
    options,
    onSearch,
    ...otherAttributes
  } = props;
  const filteredOptions = filterOptions(options);
  const sortedOptions = sortOptions(filteredOptions);

  /* Supports custom onSearch function from props */
  const onSearchHandler = onSearch || _onSearch;
  return (
    <Select
      options={sortedOptions}
      onSearch={onSearchHandler}
      searchPlaceholder='Search'
      onClose={() => _onClose()}
      dropHeight='medium'
      {...otherAttributes}
    />
  );
};

AutocompleteSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  /* if no sort function defined then it will do a basic alphabetical sort based on the text content of the label */
  sortFn: PropTypes.func,
  inline: PropTypes.bool,
  labelKey: PropTypes.string,
  onSearch: PropTypes.func,
};

export default AutocompleteSelect;
