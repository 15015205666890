// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import ConfirmationDialog from '../../../shared/dialogs/ConfirmationDialog';
import GLBMNameValueList from '../../../shared/component/GLBMNameValueList';
import { ApiContext } from '../../../../AppContext';

const PendingFilesBulkDownloadConfirmationDialog = ({ search = undefined,
                                                      accountId = undefined,
                                                      serviceType = undefined,
                                                      onClose }) => {
  const apiContext = useContext(ApiContext);
  return (
    <ConfirmationDialog
      title='Confirm Download Pending Files'
      submitLabel='Download'
      cancelLabel='Cancel'
      text='Are you sure you want to Download all the matching Pending Files using the current filter?'
      onClose={onClose}
      details={(
        <GLBMNameValueList
          title='Filter'
          data={[
            {
              label: 'Search',
              value: search,
            }, {
              label: 'Billing Account',
              value: accountId && <Box>{accountId?.map(item => <Text key={item}>{item}</Text>)}</Box>,
            }, {
              label: 'Service Type',
              value: serviceType && <Box>{serviceType?.map(item => <Text key={item}>{item}</Text>)}</Box>,
            },
          ]}
        />
      )}
      onChange={() => {
        const path = apiContext('administration.pendingFiles.bulkDownload.path', undefined, {
          search: search,
          account: accountId,
          service: serviceType,
        });
        window.open(path, '_blank');
        onClose();
      }}
    />
  );
};

PendingFilesBulkDownloadConfirmationDialog.propTypes = {
  search: PropTypes.string,
  accountId: PropTypes.array,
  serviceType: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export default PendingFilesBulkDownloadConfirmationDialog;
