// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormField, Select } from 'grommet';
import { range } from 'lodash';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';

const GpuUtilizationThresholdField = ({
  readOnly,
  setDirty
}) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();
  const values = useMemo(() => range(0, 16).map(n => ({ label: n.toString(), value: n })), []);

  const onChange = (property, value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config[property] = value;
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    setDirty();
  };

  return (
    <FormField
      label='GPU memory utilization'
    >
      <Select
        data-testid={readOnly ? 'disabled-select' : 'select'}
        disabled={readOnly === true}
        placeHolder='None'
        name='input_gpuMemoryUtilization'
        inline={false}
        multiple={false}
        labelKey='label'
        valueKey='value'
        value={values.find(option => serviceEditor.options?.config?.gpuMemoryUtilization === option.value)}
        options={values}
        onChange={event => onChange('gpuMemoryUtilization', event.option.value)}
      />
    </FormField>
  );
};

GpuUtilizationThresholdField.propTypes = {
  setDirty: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default GpuUtilizationThresholdField;
