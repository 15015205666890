// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Footer, FormField, Text } from 'grommet';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { StatusIcon } from '../../shared/component/StatusIcon';
import DateTime from '../../shared/component/DateTime';
import { getError } from '../../shared/util/FormUtil';
import IDUtil from '../../shared/util/IDUtil';
import { validateDate } from './utils';

const EditPromotedStartMonthDialog = ({ contractStartMonth, onSubmit, onClose, contractEndMonth }) => {
  // State:
  const [date, setDate] = useState(contractStartMonth);
  const [errors, setErrors] = useState([]);

  const onApply = () => {
    const newErrors = validateDate(date, contractEndMonth);
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    } else {
      onSubmit(date);
    }
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={false}
      onEsc={() => {
        onClose();
      }}
      onClickOutside={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      title='Edit Start Month'
    >
      <Box width='large' fill='vertical' justify='between'>
        <Box gap='medium' pad={{ horizontal: 'medium' }}>
          <Box direction='row' align='center'>
            <StatusIcon value='warning' size='medium' />
            <Box margin={{ left: 'small' }} fill={true}>
              <Text margin={{ left: 'small' }}>Any changes to a customer start date once in production means:</Text>
              <ul>
                <li>The customer will be billed for the new start date</li>
                <li>Services might need to be re-edited</li>
                <li>Mapped tier revisions, capacity revisions, rate revisions might all need to be modified for ALL services.</li>
              </ul>
            </Box>
          </Box>
          <FormField
            label='Start Month'
            width='medium'
            error={getError(errors)}
          >
            <DateTime
              id={IDUtil.getId('BillingInfoEditorContractStartDateInputProduction')}
              name='contractStartMonth'
              data-testid='contractStartMonthProduction'
              format='YYYY-MM'
              onChange={(value) => {
                setDate(value);
                setErrors([]);
              }}
              value={date || ''}
              calendarProps={{
                bounds: ['2013-01-01', '2116-01-01'],
              }}
            />
          </FormField>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='between'>
            <Box justify='start' gap='small' direction='row'>
              <Button
                label='Apply'
                type='button'
                primary={true}
                onClick={onApply}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={() => {
                  onClose();
                }}
              />
            </Box>
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

EditPromotedStartMonthDialog.propTypes = {
  contractStartMonth: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  contractEndMonth: PropTypes.string,
};

export default EditPromotedStartMonthDialog;
