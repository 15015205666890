// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  CapacityOption,
  ConfigurableComponent,
  EquipmentColumn,
  EquipmentIdColumn,
  LocationOption,
  MeterOption,
  RatesOption,
  ReportBy,
  ReportingUnits,
  ServiceCategory,
  TierOptions,
} from '../services';
import { CommitmentType } from '../services/model/CommitmentType';

/**
 * function validates a service definition - looking for certain properties being there (for now)
 * @param service
 * @returns {boolean}
 */
export function validateService(service) {
  const missingProps = [];

  if (!Object.prototype.hasOwnProperty.call(service, 'configureResources')) {
    missingProps.push('configureResources');
  }
  if (!Object.prototype.hasOwnProperty.call(service, 'diagnosticDetails')) {
    missingProps.push('diagnosticDetails');
  }
  if (!Object.prototype.hasOwnProperty.call(service, 'serviceOptions')) {
    missingProps.push('serviceOptions');
  }
  if (missingProps.length > 0) {
    // eslint-disable-next-line no-console
    console.log('-- [INVALID SERVICE] ServiceTypeStore', service, 'Missing the following properties', missingProps);
  }
  return (missingProps.length === 0);
}

const transformEquipmentColumns = columns => (columns || []).map((column, index) => {
  if (column.unique || index === 0) {
    return new EquipmentIdColumn(new EquipmentColumn(column));
  } if (column.drilldown) {
    return new ConfigurableComponent(new EquipmentColumn(column));
  }
  return new EquipmentColumn(column);
});

export const transformService = (service) => {
  const modifiedService = { ...service };
  try {
    if (Object.prototype.hasOwnProperty.call(service, 'chargingUnits')) {
      modifiedService.chargingUnits = service.chargingUnits.map(value => ReportingUnits.enumValueOf(value));
    }
    if (Object.prototype.hasOwnProperty.call(service, 'configureResources')) {
      modifiedService.configureResources.distinctColumns = transformEquipmentColumns(service.configureResources.distinctColumns);
    }
    if (Object.prototype.hasOwnProperty.call(service, 'configureComponents')) {
      modifiedService.configureComponents.distinctColumns = transformEquipmentColumns(service.configureComponents.distinctColumns);
    }
    if (Object.prototype.hasOwnProperty.call(service, 'diagnosticDetails')) {
      if (Object.prototype.hasOwnProperty.call(service.diagnosticDetails, 'equipmentDistinctColumns')) {
        modifiedService.diagnosticDetails.equipmentDistinctColumns = transformEquipmentColumns(service.diagnosticDetails.equipmentDistinctColumns);
      }
      if (Object.prototype.hasOwnProperty.call(service.diagnosticDetails, 'meteringDistinctColumns')) {
        modifiedService.diagnosticDetails.meteringDistinctColumns = transformEquipmentColumns(service.diagnosticDetails.meteringDistinctColumns);
      }
    }
    if (Object.prototype.hasOwnProperty.call(service, 'serviceOptions')) {
      modifiedService.serviceOptions.locationOptions = service.serviceOptions.locationOptions.map(value => LocationOption.enumValueOf(value));
      modifiedService.serviceOptions.reportByOptions = service.serviceOptions.reportByOptions.map(value => ReportBy.enumValueOf(value));
      modifiedService.serviceOptions.tierOptions = service.serviceOptions.tierOptions.map(value => TierOptions.enumValueOf(value));
    }
    if (Object.prototype.hasOwnProperty.call(service, 'serviceCategory')) {
      modifiedService.serviceCategory = ServiceCategory.enumValueOf(service.serviceCategory);
    }
    if (Object.prototype.hasOwnProperty.call(service, 'rateManagement')) {
      if (Object.prototype.hasOwnProperty.call(service.rateManagement, 'meterOptions')) {
        modifiedService.rateManagement.meterOptions = service.rateManagement.meterOptions.map(value => MeterOption.enumValueOf(value));
      }
      if (Object.prototype.hasOwnProperty.call(service.rateManagement, 'capacityOptions')) {
        modifiedService.rateManagement.capacityOptions = service.rateManagement.capacityOptions.map(value => CapacityOption.enumValueOf(value));
      }
      if (Object.prototype.hasOwnProperty.call(service.rateManagement, 'ratesOptions')) {
        modifiedService.rateManagement.ratesOptions = service.rateManagement.ratesOptions.map(value => RatesOption.enumValueOf(value));
      }
    }
    if (Object.prototype.hasOwnProperty.call(service, 'commitmentType')) {
      modifiedService.commitmentType = CommitmentType.enumValueOf(service.commitmentType);
    }
  } catch (e) {
    console.warn('Error transforming service', service);
    console.warn(e);
  }

  // validate the service:
  validateService(modifiedService);

  return modifiedService;
};

export function transformServiceTypesToEnums(services) {
  return services.map(service => transformService(service));
}

export function debugServiceProperty(services, test, property) {
  const reducedService = services.reduce(
    (map, service) => {
      const value = service.lookup(property);
      if (value instanceof Array) {
        if (value && value.length > 0) {
          value.forEach((enumType) => {
            const name = enumType && Object.prototype.hasOwnProperty.call(enumType, 'name') ? enumType.name : enumType;
            if (!Object.prototype.hasOwnProperty.call(map, name)) {
              // eslint-disable-next-line no-param-reassign
              map[name] = [];
            }
            map[name].push(service.type);
          });
        } else if (value && value.length === 0) {
          if (!Object.prototype.hasOwnProperty.call(map, 'undefined')) {
            // eslint-disable-next-line no-param-reassign
            map.undefined = [];
          }
          map.undefined.push(service.type);
        }
      } else {
        const name = value && Object.prototype.hasOwnProperty.call(value, 'name') ? value.name : value;
        if (!Object.prototype.hasOwnProperty.call(map, name)) {
          // eslint-disable-next-line no-param-reassign
          map[name] = [];
        }
        map[name].push(service.type);
      }
      return map;
    },
    {}
  );
  // eslint-disable-next-line no-console
  console.log(test, reducedService);
}

export function debugServices(services) {
  // eslint-disable-next-line no-console
  console.groupCollapsed('GLBM.ServiceTypes');
  debugServiceProperty(services, 'Report By Options', 'serviceOptions.reportByOptions');
  debugServiceProperty(services, 'Tier Options', 'serviceOptions.tierOptions');
  debugServiceProperty(services, 'Location Options', 'serviceOptions.locationOptions');
  debugServiceProperty(services, 'Hide Precision Fields', 'serviceOptions.hidePrecisionFields');
  debugServiceProperty(services, 'Read Only Tiers', 'serviceOptions.readOnlyTiers');
  debugServiceProperty(services, 'Has Components', 'hasComponents');
  debugServiceProperty(services, 'Has Metered Components', 'hasMeteredComponents');
  debugServiceProperty(services, 'Service Category', 'serviceCategory');
  debugServiceProperty(services, 'Charging Units', 'chargingUnits');
  // eslint-disable-next-line no-console
  console.groupEnd();
}
