// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlight from 'react-highlighter';

import {
  Anchor,
  Box,
  Button,
  Main,
  Menu,
  Notification,
  Text,
} from 'grommet';
import {
  Add, Download, More, Refresh,
} from 'grommet-icons';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import IDUtil from '../shared/util/IDUtil';
import {
  useCustomersQuery,
  usePartnerDeleteMutate,
  usePartnersQuery,
  useQueryPartnersDownload,
} from '../../core';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import { usePermissionChecker, useStateWithSessionStorage } from '../shared/hooks';
import { pagePermissions } from '../shared/constants/Permissions';
import { insertIf } from '../shared/util/BasicUtil';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import PartnerDeleteDeniedDialog from './dialogs/PartnerDeleteDeniedDialog';

const PartnerListPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useStateWithSessionStorage('partners-filters', {
    list: {
      searchText: '', sort: { property: 'name', direction: 'asc', external: true },
    },
    panel: {},
  });
  const [layer, setLayer] = useState(undefined);
  const [selectedPartnerId, setSelectedPartnerId] = useState(undefined);
  const [response, setResponse] = useState(undefined);

  const {
    data: partnerList,
    isFetching: loading,
    refetch: refetchPartners,
  } = usePartnersQuery();

  const { refetch: exportPartners } = useQueryPartnersDownload();

  const {
    mutate: deletePartner,
  } = usePartnerDeleteMutate({
    onSuccess: () => {
      setLayer(undefined);
      setSelectedPartnerId(undefined);
      setTimeout(() => {
        refetchPartners();
      }, 0);
    },
    onError: (_error) => {
      setResponse({
        status: 'critical',
        message: `Unable to delete partner: ${_error.message}`,
      });
    },
  });

  const {
    data: customerList,
    isSuccess,
  } = useCustomersQuery();

  const customersMap = useMemo(() => {
    if (isSuccess) {
      const resultMap = {};
      if (customerList?.length) {
        customerList.forEach((customer) => {
          resultMap[customer.id] = customer;
        });
      }
      return resultMap;
    }
    return undefined;
  }, [isSuccess, customerList]);

  const { hasPermissions } = usePermissionChecker();
  const onSearchChange = (search) => {
    setFilters(prevState => ({
      ...prevState,
      list: { ...prevState.list, searchText: search },
    }));
  };

  const getCustomerNameById = (customerId) => {
    if (customersMap?.[customerId]) {
      return customersMap[customerId]?.name;
    }
    return customerId;
  };

  const onSortColumn = (sort) => {
    setFilters(prevState => ({
      ...prevState,
      list: { ...prevState.list, sort },
    }));
  };

  const onRemoveClick = (partner) => {
    // if there are no assigned customers, allow user to remove:
    if (!partner.customerIds || partner.customerIds.length === 0) {
      setSelectedPartnerId(partner.id);
      setLayer('removeConfirm');
    } else {
      setSelectedPartnerId(partner.id);
      setLayer('removeDenied');
    }
  };

  const filteredPartners = useMemo(() => {
    const { list: { searchText } } = filters;
    const filterByText = (!!searchText);

    if (!searchText) {
      return partnerList || [];
    }
    const results = [];

    if (Array.isArray(partnerList)) {
      const properties = ['name', 'partnerId', 'id', 'tenantId', 'ownerEmail', 'ownerFirstName', 'ownerLastName'];

      for (let i = 0; i < partnerList.length; i += 1) {
        const partner = partnerList[i];

        let matchOnText;

        // see if the partner is a match by text:
        if (filterByText) {
          for (let k = 0; k < properties.length; k += 1) {
            if (partner[properties[k]].toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
              matchOnText = true;
              break;
            }
          }
        }

        if (matchOnText) {
          results.push(partner);
        }
      }
    }
    return results;
  }, [filters, partnerList]);

  const renderConfirmationDetails = partner => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Partner'
        data={[
          { label: 'Name', value: partner.name },
          { label: 'Partner Id', value: partner.partnerId },
          { label: 'Owner Name', value: `${partner.ownerFirstName} ${partner.ownerLastName}` },
          { label: 'Owner Email', value: partner.ownerEmail },
        ]}
      />
    </Box>
  );

  const renderCustomerDetails = (partner) => {
    const rows = [];
    (partner.customerIds || []).forEach((customerId) => {
      rows.push({
        label: getCustomerNameById(customerId),
        value: customerId,
      });
    });
    return (
      <Box margin={{ top: 'none' }}>
        <GLBMNameValueList
          title='Assigned Billing Accounts'
          data={rows}
        />
      </Box>
    );
  };

  const layerClosed = () => {
    setSelectedPartnerId(undefined);
    setLayer(undefined);
    refetchPartners();
  };

  const onDeleteConfirmed = (data) => {
    const partnerId = data.id;
    deletePartner(partnerId);
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      const partner = partnerList.filter(u => u.id === selectedPartnerId)[0];
      if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={{ partnerId: selectedPartnerId }}
            title='Are You Sure?'
            text='Once you delete this partner, you cannot undo this action.'
            submitLabel='Yes, remove the Partner'
            cancelLabel='Cancel'
            onClose={() => layerClosed()}
            details={renderConfirmationDetails(partner)}
            onChange={() => onDeleteConfirmed(partner)}
          />
        );
      } else if (layer === 'removeDenied') {
        result = (
          <PartnerDeleteDeniedDialog
            onClose={layerClosed}
            details={renderCustomerDetails(partner)}
          />
        );
      }
    }
    return result;
  };

  const onManageTenantClick = (partner) => {
    navigate(`/partners/${partner.id}/tenant`);
  };

  const renderToast = () => {
    let message = '';
    if (response) {
      message = (
        <Notification
          toast={true}
          title={response.title}
          status={response.status}
          onClose={() => setResponse(undefined)}
          message={response.message}
        />
      );
    }
    return message;
  };

  const getActions = datum => [
    ...insertIf(hasPermissions(pagePermissions.partners.edit), [{
      onClick: () => navigate(`/partners/${datum.id}`),
      label: 'Edit Partner',
    }]),
    ...insertIf(hasPermissions(pagePermissions.partners.actions.delete), [{
      onClick: () => onRemoveClick(datum),
      label: 'Delete Partner',
    }]),
    ...insertIf(hasPermissions(pagePermissions.partners.tenant), [{
      onClick: () => onManageTenantClick(datum),
      label: 'Manage Connections',
    }]),
  ];

  const getColumns = (searchText) => {
    const canEditPartners = hasPermissions(pagePermissions.partners.edit);
    return [
      {
        property: 'name',
        header: 'Name',
        render: datum => (canEditPartners ? (
          <Anchor weight='bold' onClick={() => navigate(`/partners/${datum.id}`)}>
            <Text weight='bold'>
              <Highlight search={searchText}>{datum.name}</Highlight>
            </Text>
          </Anchor>
        ) : (
          <Text weight='bold'>
            <Highlight search={searchText}>{datum.name}</Highlight>
          </Text>
        )),
        size: 'small',
      },
      {
        property: 'partnerId', header: 'Partner ID', render: ({ partnerId }) => (<Highlight search={searchText}>{partnerId}</Highlight>), size: 'small',
      },
      {
        property: 'id',
        header: 'ID',
        render: ({ id }) => (<Highlight search={searchText}>{id}</Highlight>),
        size: 'small',
        primary: true,
      },
      {
        property: 'tenantId', header: 'CA Tenant ID', render: ({ tenantId }) => (<Highlight search={searchText}>{tenantId}</Highlight>), size: 'small',
      },
      {
        property: 'owner',
        header: 'Owner',
        dataCallback: ({ ownerFirstName, ownerLastName, ownerEmail }) => `${ownerFirstName} ${ownerLastName} (${ownerEmail})`,
        render: ({ ownerFirstName, ownerLastName, ownerEmail }) => (
          <Text><Highlight search={searchText}>{`${ownerFirstName} ${ownerLastName} (${ownerEmail})`}</Highlight></Text>),
        size: 'small',
      },
      {
        property: 'actions',
        header: 'Actions',
        size: '96px',
        align: 'start',
        render: datum => (
          <Menu
            icon={<More />}
            items={getActions(datum)}
          />
        ),
        sortable: false,
      },
    ];
  };

  const { searchText, sort } = filters.list;

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Partners'
        search={(
          <GLBMSearch
            value={searchText}
            onChange={onSearchChange}
          />
        )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              refetchPartners();
            }}
            a11yTitle='Refresh Customer List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={loading}
          />,
          hasPermissions(pagePermissions.partners.export) && hasPermissions(pagePermissions.partners.add) && (
            <Menu
              kind='toolbar'
              key='menuCntrl'
              id='actionDropDown'
              items={[
                {
                  onClick: () => navigate('/partners/add'),
                  a11yTitle: 'New Partner',
                  id: IDUtil.getId('ListViewToolbarAddButton'),
                  key: 'newBtn',
                  label: 'Create',
                },
                {
                  onClick: exportPartners,
                  label: 'Download',
                  a11yTitle: 'Export Partners',
                  id: IDUtil.getId('ListViewToolbarExportButton'),
                  key: 'exportBtn'
                },
              ]}
              label='Actions'
              reverse={false}
            />
          ),
          ...insertIf(hasPermissions(pagePermissions.partners.export) && !hasPermissions(pagePermissions.partners.add), [
            <Button
              kind='toolbar'
              label='Download'
              icon={<Download />}
              onClick={exportPartners}
              a11yTitle='Export Partners'
              id={IDUtil.getId('ListViewToolbarExportButton')}
              key='exportBtn'
            />]),
          ...insertIf(hasPermissions(pagePermissions.partners.add) && !hasPermissions(pagePermissions.partners.export), [
            <Button
              kind='toolbar'
              label='Create'
              icon={<Add />}
              onClick={() => navigate('/partners/add')}
              a11yTitle='New Partner'
              id={IDUtil.getId('ListViewToolbarAddButton')}
              key='newBtn'
            />]),
        ]}
      />
      <GLBMDataSummary total={partnerList?.length} filtered={filteredPartners?.length} />
      <GLBMDataTable
        columns={getColumns(searchText)}
        data={filteredPartners}
        sortable={true}
        onSort={onSortColumn}
        sort={sort}
        loading={loading}
        total={partnerList?.length}
      />
      {renderLayer()}
      {renderToast()}
    </Main>
  );
};

export default PartnerListPage;
