// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, NameValueList, NameValuePair, Text } from 'grommet';
import { StatusIcon } from './StatusIcon';

const GLBMNameValueList = ({
  title = undefined,
  message = undefined,
  data = [],
  valueProps = { width: 'medium' },
  pairProps = { direction: 'row' },
  ...rest
}) => (
  <Box align='start'>
    {title && <Heading level='3' size='medium' fill={true} margin={{ bottom: 'small' }}>{title}</Heading>}
    <Box
      background='light-2'
      pad='small'
      gap='small'
      {...rest}
    >
      {message && <Text>{message}</Text>}
      <NameValueList valueProps={valueProps} pairProps={pairProps}>
        {data?.map(({
          label,
          value,
          status,
        }) => (
          <NameValuePair key={label} name={`${label}:`}>
            <Box flex={true} align='center' direction='row' gap='xsmall'>
              {status && <StatusIcon value={status} size='small' />}
              <Text color='text-strong' wordBreak='break-word'>{value || '--'}</Text>
            </Box>
          </NameValuePair>
        ))}
        {!data && <Text>No data provided</Text>}
      </NameValueList>
    </Box>
  </Box>
);

GLBMNameValueList.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    status: PropTypes.string,
  })),
  valueProps: PropTypes.object,
  pairProps: PropTypes.object,
};

export default GLBMNameValueList;
