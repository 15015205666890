// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { pagePermissions } from '../shared/constants/Permissions';
import {
  usePermissionChecker,
} from '../shared/hooks';
import EditCustomerPage from './EditCustomerPage';
import CustomerInfoPage from './CustomerInfoPage';

const CustomerPage = () => {
  const { hasPermissions } = usePermissionChecker();
  const readOnly = useMemo(() => (
    (hasPermissions(pagePermissions.customers.view.page) && !hasPermissions(pagePermissions.customers.actions.add) && !hasPermissions(pagePermissions.customers.view.actions.edit))
  ), [hasPermissions]);

  return (
   readOnly ? <CustomerInfoPage /> : <EditCustomerPage />
  );
};

CustomerPage.contextTypes = {
  router: PropTypes.object,
};

export default CustomerPage;
